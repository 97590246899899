import { Switch } from "@/atoms"
import { preventDefaultWrapper } from "@/helpers/utils"

export const StatusSwitcherCell = <R = unknown,>(
  disabled: boolean,
  onChange: (enabled: boolean, record: R) => void,
  noPermissions?: boolean
) =>
  function StatusSwitcherCell(enabled: boolean, record: R) {
    return (
      <span
        data-tooltip-translation="true"
        data-tooltip-content={
          noPermissions ? "general.noPermissionsTooltip" : undefined
        }
      >
        <Switch
          disabled={disabled}
          checked={enabled}
          onChange={(checked, event) => {
            preventDefaultWrapper(() => onChange(checked, record))(event)
          }}
        />
      </span>
    )
  }
