import { Text } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { LogoIntegration } from "../LogoIntegration"

import styles from "./HeaderCard.module.scss"

import { FeatureStatusTag } from "@/molecules/FeatureStatusTag"
import type { HeaderCardProps } from "."

export const HeaderCard = (props: HeaderCardProps) => {
  const { t } = useTranslation()
  //TODO as part of refactor, use Type featureStatusType instead of isBeta/isNew
  const { isEnabled, isBeta, isNew } = props

  return (
    <>
      <LogoIntegration {...props} />
      {!isEnabled && (
        <div className={styles.comingSoon}>
          <Text>{t("general.coming_soon")}</Text>
        </div>
      )}
      {isEnabled && isBeta && (
        <div className={styles.comingSoon}>
          <FeatureStatusTag type="beta" />
        </div>
      )}
      {isEnabled && isNew && (
        <div className={styles.comingSoon}>
          <FeatureStatusTag type="new" />
        </div>
      )}
    </>
  )
}
