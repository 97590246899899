import { Card, Divider, Space } from "@/atoms"
import { Language } from "@/icons"
import { FixesLocationCard } from "@/molecules/FixesLocationCard"
import { LabelExpandedRow } from "@/molecules/LabelExpandedRow"
import { LinkRisk } from "@/molecules/LinkRisk"

import styles from "./IssueExpandableCell.module.scss"

import type {
  ExposedSecretFinding,
  VulnerableCodeFinding
} from "@/domain/issue"
import type { TFunction } from "@/hooks/useTranslation"
import type { IssueExpandableCellPropsView } from "./IssueExpandableCell.types"

export const CodeIssueExpandableCell = (
  props: IssueExpandableCellPropsView & { t: TFunction }
) => {
  const { metadata, issueType, t } = props

  return (
    <div data-element-issue-expandable-cell-view="fix-container">
      <div className={styles.cardContainer}>
        <Card className="embedded-card-ks" bordered={false}>
          <Space split={<Divider type="vertical" />}>
            <LabelExpandedRow
              label={`${t("general.type")}:`}
              value={t(`issueTypes.${issueType}`)}
            />
            <LabelExpandedRow
              label="CWE:"
              value={
                <LinkRisk
                  cve={metadata.cwe.description ? `${metadata.cwe.id}` : "-"}
                  classes={{ text: styles.value }}
                />
              }
            />
            <LabelExpandedRow
              label="OWASP:"
              value={metadata.owasp ? metadata.owasp : "-"}
            />
            <LabelExpandedRow
              label={`${t("general.language")}:`}
              value={<Language language={metadata.language} />}
            />
          </Space>
        </Card>
      </div>
      <div className={styles.cardContainer}>
        <FixesLocationCard
          t={t}
          record={metadata as VulnerableCodeFinding | ExposedSecretFinding}
        />
      </div>
    </div>
  )
}
