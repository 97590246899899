import { Link, type ItemType } from "@/atoms"
import { getItems } from "@/pages/SettingsPage/helpers/menu"

import styles from "./MenuHeader.module.scss"

import { Permission } from "@/domain/user"
import type { TFunction } from "@/hooks/useTranslation"

export const getNavMenu = (
  currentKey: string,
  {
    hasPermission,
    t
  }: {
    hasPermission: (permission: Permission) => boolean | undefined
    t: TFunction
  }
) => {
  const PREFIX_URL = "/settings"
  const defaultItems = getItems(t)
  let isActive = false

  const itemsWithClassName = defaultItems
    .filter((item) => {
      if (item.permission) return hasPermission(item.permission)
      return true
    })
    .map((item, index) => {
      const { label, key } = item

      if (key) {
        const to = `${PREFIX_URL}/${key}`
        const isMatch = currentKey === to
        const className = isMatch ? styles.active : ""

        if (isMatch) isActive = true

        return {
          key,
          className,
          label: <Link to={to}>{label}</Link>
        }
      }

      return { label, key: index, disabled: true }
    }) as ItemType[]

  return { isActive, items: itemsWithClassName }
}
