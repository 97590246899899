export const PARAMS = {
  ID: "id",
  DUPLICATE_ID: "duplicateId",
  SUB_TYPE: "subType"
}
export const VALUES_PARAMS = {
  NEW_ENTITY: "new"
}

export const NAMES_ROUTES = {
  GOVERNANCE: {
    ROOT: "governance",
    SCM: "scm",
    CI: "ci",
    SAST: "sast",
    SCAN_HISTORY: "scan-history",
    AUTOMATION_HISTORY: "automation-history",
    AUTOMATION_POLICIES: "automation-policies",
    THREAT_DETECTION_POLICIES: "threat-detection",
    WORKFLOWS: "workflows"
  },
  SETTINGS: {
    ROOT: "settings",
    SCOPES: "scopes"
  },
  INTEGRATIONS: {
    ROOT: "integrations",
    CALLBACK: "callback"
  },
  DISCOVERY: {
    ROOT: "discovery",
    BRANCH: "branch",
    APPLICATIONS: "applications",
    IMAGES: "images",
    BASE_IMAGES: "baseImages",
    SBOM: "sbom",
    VM: "vm",
    API: "api",
    AI: "ai"
  },
  TRIAGE: {
    ROOT: "triage",
    ISSUES: "issues",
    INCIDENTS: "incidents",
    ATTACK_SCENARIOS: "attack-scenarios"
  },
  DEPLOYMENTS: {
    ROOT: "deployments",
    NEW_DEPLOYMENT: "new-deployment"
  }
}

export const EXTERNAL_ROUTES = {
  API_DOCS: "https://docs.kodemsecurity.com",
  PUBLIC_DOCS: "https://public.kodemsecurity.com/docs",
  PARAMS: {
    JWT_TOKEN: "jwt_token"
  }
}
