import { useMemo } from "react"

import { Drawer } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { TitleDrawer } from "@/molecules/TitleDrawer"
import { useCustomMaskClass } from "@/organisms/Drawers/hooks/useDrawer"
import { Form } from "@/organisms/Form"
import { HeaderInventoryPage } from "@/pages/DiscoveryPage/components/organisms/HeaderInventoryPage"
import {
  ActiveKeyTabsPreviewContext,
  getQueryKeyHashFn,
  useActiveKeyTabsPreview
} from "@/pages/SettingsPage/hooks/useActiveKeyTabsPreview"

import styles from "./PreviewDrawerScopePage.module.scss"

export const PreviewDrawerScopePage = () => {
  const { t } = useTranslation()

  const [, maskClassName] = useCustomMaskClass()
  const { form } = Form.useForm()
  const currentScopeId = Form.useWatch("id", form)
  const preview = Form.useWatch("preview", form) || {}
  const contextValue = useMemo(() => {
    return {
      activeKey: preview.activeKey,
      setActiveKey: (key: string) =>
        form.setFieldValue("preview", { ...preview, activeKey: key }),
      config: {
        queryKeyHashFn: getQueryKeyHashFn(currentScopeId),
        headers: {
          "X-Scope-Id": currentScopeId
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentScopeId, preview.activeKey])

  return (
    <Drawer
      destroyOnClose
      id="preview-scope-page"
      open={preview.open}
      width={1100}
      maskClassName={maskClassName}
      title={
        <TitleDrawer
          title={`${t("general.preview")} ${form.getFieldValue("name_details") || ""}`}
        />
      }
      onClose={() => form.setFieldValue("preview", { ...preview, open: false })}
    >
      <div className={styles.previewContainer}>
        {currentScopeId && (
          <ActiveKeyTabsPreviewContext.Provider value={contextValue}>
            <HeaderInventoryPage
              useActiveKeyTabsInventory={useActiveKeyTabsPreview}
            />
          </ActiveKeyTabsPreviewContext.Provider>
        )}
      </div>
    </Drawer>
  )
}
