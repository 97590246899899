import { Tag, Text, Title } from "@/atoms"
import { useLogger } from "@/hooks/useLogger"
import { HeaderCard } from "../../molecules/HeaderCard"
import { IntegrationCardTemplate } from "../../templates/IntegrationCardTemplate"

import styles from "./ListIntegrations.module.scss"

import { ListIntegrationsProps } from "."

export const ListIntegrations = ({ list }: ListIntegrationsProps) => {
  const { logger, EVENTS } = useLogger()

  return (
    <>
      {list.map(
        ({
          displayName,
          description,
          name,
          tags,
          isInstalled,
          isEnabled,
          isBeta,
          isNew,
          onlyLogo = false
        }) => (
          <IntegrationCardTemplate
            to={`/integrations/${name}`}
            key={name}
            isEnabled={isEnabled}
            header={
              <HeaderCard
                name={name}
                isInstalled={isInstalled}
                isEnabled={isEnabled}
                isBeta={isBeta}
                isNew={isNew}
                onlyLogo={onlyLogo}
              />
            }
            title={
              <Title className={styles.displayName} level={3}>
                {displayName}
              </Title>
            }
            description={
              <Text className={styles.description} type="secondary">
                {description}
              </Text>
            }
            tags={tags.map((tag: string) => (
              <Tag key={tag}>{tag}</Tag>
            ))}
            onClick={() => {
              logger.info(EVENTS.ANALYTIC_EVENTS.INTEGRATIONS.CLICKED, {
                name
              })
            }}
          />
        )
      )}
    </>
  )
}
