import {
  Checkbox,
  Collapse,
  Divider,
  Form,
  Input,
  Radio,
  Text,
  TextArea
} from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { useUser } from "@/hooks/useUser"
import { useState } from "react"

import { EXTERNAL_ROUTES } from "@/const/routes.constants"
import { defaultRole } from "@/domain/user"
import { ReadOutlined } from "@/icons"
import { LinkButton } from "@/molecules/LinkButton"
import { RoleSelect } from "../../molecules/RoleSelect"
import { SCIMCollapseForm } from "../SCIMCollapseForm"
import styles from "./SamlAuthenticationMethodForm.module.scss"
import type {
  SamlAuthenticationMethodFormProps,
  SamlAuthenticationPermissionMethod
} from "./SamlAuthenticationMethodForm.types"

export const SamlAuthenticationMethodForm = ({
  activeKey,
  dataKey,
  form
}: SamlAuthenticationMethodFormProps) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "settings.auth.methods.saml.form"
  })
  const { t: tGen } = useTranslation()
  const rules =
    activeKey === dataKey
      ? [{ required: true, message: tGen("error.requiredField") }]
      : []
  const autoProvisioningEnabledFormKey = "idpAutoProvisioningEnabled"
  const [enabledAutoSize, setEnabledAutoSize] = useState(false)
  const { user } = useUser()
  const isEnabled = form.getFieldValue([
    dataKey,
    autoProvisioningEnabledFormKey
  ])
  const [isChecked, setIsChecked] = useState(isEnabled ? "checked" : "")

  const permissionsOptions: {
    label: string
    value: SamlAuthenticationPermissionMethod
  }[] = [
    { label: t("noScopesPermissions"), value: "NONE" },
    { label: t("allScopesPermissions"), value: "ALL" }
  ]
  return (
    <>
      <Divider className={styles.upperDivider} />
      <div className={styles.note}>
        <Text>{`${t("notes.mes")}:`}</Text>
        <Text>
          {`${t("notes.appName")}:`}
          <b>Kodem Security</b>
        </Text>
        <Text>
          {`${t("notes.entityId")}:`}
          <b>https://{window.location.hostname}</b>
        </Text>
        <Text>
          {`${t("notes.acs")}:`}
          <b>{`https://${window.location.hostname}/api/v1/auth/acs/${user.company}`}</b>
        </Text>
      </div>
      <Text className={styles.text}>{t("title")}</Text>
      <Form.Item
        required={false}
        rules={rules}
        name={[dataKey, "entityId"]}
        label={t("entityId")}
      >
        <Input />
      </Form.Item>
      <Form.Item
        required={false}
        rules={rules}
        name={[dataKey, "ssoUrl"]}
        label={t("signUrl")}
      >
        <Input />
      </Form.Item>
      <Form.Item
        required={false}
        rules={rules}
        name={[dataKey, "x509PublicCert"]}
        label={t("cert509")}
      >
        <TextArea
          autoSize={enabledAutoSize}
          rows={4}
          onBlur={() => {
            setEnabledAutoSize(false)
          }}
          onFocus={() => {
            setEnabledAutoSize(true)
          }}
        />
      </Form.Item>
      <Collapse
        activeKey={isChecked}
        ghost
        bordered={false}
        className={styles.authorizationCollapse}
        items={[
          {
            key: "checked",
            showArrow: false,
            label: (
              <Form.Item
                name={[dataKey, autoProvisioningEnabledFormKey]}
                valuePropName="checked"
                noStyle
              >
                <Checkbox
                  onChange={(changed) => {
                    if (changed.target.checked) {
                      setIsChecked("checked")
                      form.setFieldValue(
                        [dataKey, "idpAutoProvisioningAuthorizationSettings"],
                        form.getFieldValue([
                          dataKey,
                          "idpAutoProvisioningAuthorizationSettings"
                        ]) || "NONE"
                      )
                      form.setFieldValue(
                        [dataKey, "defaultRole"],
                        form.getFieldValue([dataKey, "defaultRole"]) ||
                          defaultRole
                      )
                    } else {
                      setIsChecked("")
                    }
                  }}
                >
                  {t("idp")}{" "}
                  <span className={styles.optionalLabel}>{t("optional")}</span>
                </Checkbox>
              </Form.Item>
            ),

            className: styles.panel,
            children: (
              <>
                <Form.Item
                  name={[dataKey, "idpAutoProvisioningAuthorizationSettings"]}
                  noStyle
                >
                  <Radio.Group
                    className={styles.verticalRadioGroup}
                    options={permissionsOptions}
                  />
                </Form.Item>
                <Form.Item
                  name={[dataKey, "defaultRole"]}
                  label={t("defaultRole")}
                  initialValue={defaultRole}
                >
                  <RoleSelect className={styles.roleSelect} />
                </Form.Item>
                <SCIMCollapseForm
                  idpFormItemKey={dataKey}
                  form={form}
                  classNames={{
                    note: styles.note,
                    authorizationCollapse: styles.authorizationCollapse,
                    optionalLabel: styles.optionalLabel
                  }}
                />
              </>
            )
          }
        ]}
      />
      <LinkButton
        icon={<ReadOutlined />}
        size="small"
        type="link"
        className={styles.instructionsButton}
        href={`${EXTERNAL_ROUTES.API_DOCS}`} // TODO change link
        target="_blank"
      >
        {t("instructionsButtonText")}
      </LinkButton>
    </>
  )
}
