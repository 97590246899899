import cn from "classnames"

import { Tag } from "@/atoms"

import styles from "./KeyStatusTag.module.scss"

import { AuthKeyStatusValues } from "@/api/useAuthKeys.selector"
import { AuthKeyStatus } from "@/api/useAuthKeys.types"

export const KeyStatusTag = (t: (v: string) => string) =>
  function KeyStatusTagComponent(status: AuthKeyStatus) {
    return (
      <Tag
        className={cn(
          styles.container,
          { [styles.active]: status === AuthKeyStatusValues.ACTIVE },
          { [styles.expiring]: status === AuthKeyStatusValues.EXPIRING_SOON },
          { [styles.expired]: status === AuthKeyStatusValues.EXPIRED }
        )}
      >
        {t(`status.${status.toLowerCase()}`)}
      </Tag>
    )
  }
