import { INTEGRATION_VENDORS } from "@/const/vendors-constants"

import { ConfigurationPageTemplate } from "@/pages/IntegrationsPage/components/templates/ConfigurationPageTemplate"
import { EcrConfigurationTab } from "./EcrConfigurationTab"

export const EcrConfigurationPage = () => (
  <ConfigurationPageTemplate
    vendor={INTEGRATION_VENDORS.ecr}
    config={<EcrConfigurationTab vendor={INTEGRATION_VENDORS.ecr} />}
    titleKey="integrations.configuration.ecr.configurationTitle"
  />
)
