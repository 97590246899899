import { Link } from "@/atoms"
import { NAMES_ROUTES } from "@/const/routes.constants"
import { Trans } from "@/hooks/useTranslation"

import styles from "./ConfirmModal.module.scss"

import type { PreconditionFailedContentProps } from "."

export const PreconditionFailedContent: React.FC<
  PreconditionFailedContentProps
> = ({ name, type, action, t }) => {
  const { ROOT, WORKFLOWS } = NAMES_ROUTES.GOVERNANCE
  const keyPrefix = "settings.labels.message.delete."

  return (
    <div>
      <div className={styles.contentTitle}>
        <Trans>
          {t(`${keyPrefix}preconditionFailedTitle`, {
            name: name,
            type: type,
            action: action
          })}
        </Trans>
      </div>
      <div>
        <div className={styles.contentBody}>
          <span>{t(`${keyPrefix}assignToWorkflow`, { type: type })}</span>
          <span>{t(`${keyPrefix}callToAction`, { type: type })}</span>
          <Link href={`/${ROOT}/${WORKFLOWS}`} target="_blank">
            {t(`${keyPrefix}editWorkflows`)}
          </Link>
        </div>
      </div>
    </div>
  )
}
