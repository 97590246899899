import { Card, Divider, Drawer, Tag } from "@/atoms"
import {
  DEFAULT_LONG_FORMAT,
  getDateInUserLocale
} from "@/helpers/date.helpers"
import { Injection } from "@/icons"
import { CodeHighlight } from "@/molecules/CodeHighlight"
import { Heading } from "@/molecules/Heading"
import { LineClamp } from "@/molecules/LineClamp"
import { TitleDrawer } from "@/molecules/TitleDrawer"
import { OverviewDescriptions } from "@/organisms/Drawers/components/OverviewDescriptions"
import { OverviewCardTemplate } from "@/organisms/Drawers/components/PackageDrawer/components/OverviewCardTemplate"
import { useCustomMaskClass } from "@/organisms/Drawers/hooks/useDrawer"
import { useDismissIncident } from "../../applications/useIncidents"
import { Buttons } from "../Buttons"

import { Incident } from "@/api/useIncidents.types"
import { getColorBySeverity } from "@/domain/vulnerability"
import { useTranslation } from "@/hooks/useTranslation"
import { CSSProperties } from "react"
import { getSubTitleValue } from "../helpers"
import styles from "./ThreatDrawer.module.scss"

// TODO: Implement buttons functionality
export const ThreatDrawer = (props: any) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "triage.incidents"
  })
  const { open, onClose, record: data } = props
  const record: Incident & { injectedCall?: string } = data || {}
  const { dismissIncident, isLoading: isDeleting } = useDismissIncident()
  const {
    where,
    callerProc,
    attackProc,
    injectedCall,
    suspiciousSyscall,
    threatDetails
  } = record
  const { environment = "", namespace = "", podName = "" } = where || {}

  const bgColor = threatDetails?.severity
    ? getColorBySeverity(threatDetails?.severity)
    : "transparent"
  const subTitle = `${environment}${getSubTitleValue(namespace)}${getSubTitleValue("")}${getSubTitleValue(podName)}`
  const items = [
    {
      label: (
        <OverviewDescriptions.Label>{t("where")}</OverviewDescriptions.Label>
      ),
      children: subTitle
    },
    {
      label: (
        <OverviewDescriptions.Label>
          {t("callerProcessID")}
        </OverviewDescriptions.Label>
      ),
      children: callerProc?.callerPid
    },

    {
      label: (
        <OverviewDescriptions.Label>
          {t("callerProcessName")}
        </OverviewDescriptions.Label>
      ),
      children: callerProc?.callerProcessName
    },

    {
      label: (
        <OverviewDescriptions.Label>
          {t("callerProcessArguments")}
        </OverviewDescriptions.Label>
      ),
      children: `${callerProc?.binaryExecutePath} ${callerProc?.callerProcessArguments}`
    },
    {
      label: (
        <OverviewDescriptions.Label>
          {t("attackTriggeredProcessName")}
        </OverviewDescriptions.Label>
      ),
      children: attackProc?.callerProcessName
    },
    {
      label: (
        <OverviewDescriptions.Label>
          {t("suspiciusSyscall")}
        </OverviewDescriptions.Label>
      ),
      children: suspiciousSyscall
    },

    {
      label: (
        <OverviewDescriptions.Label>
          {t("binaryExecuted")}
        </OverviewDescriptions.Label>
      ),
      children: attackProc?.binaryExecutePath
    },

    {
      label: (
        <OverviewDescriptions.Label>
          {t("injectedCall")}
        </OverviewDescriptions.Label>
      ),
      children: injectedCall
    }
  ]

  const [, maskClassName] = useCustomMaskClass()

  return (
    <Drawer
      resizable
      width={900}
      minWidth={700}
      open={open}
      maskClassName={maskClassName}
      className={styles.drawer}
      title={
        <div className={styles.titleContainer}>
          <TitleDrawer
            style={
              {
                "--icon-background-color-title-drawer": bgColor
              } as CSSProperties
            }
            icon={
              <Injection
                className={styles.icon}
                style={{
                  background: bgColor
                }}
              />
            }
            title={threatDetails?.attackType}
            subTitle={subTitle}
          />
          <Buttons
            record={record}
            onClose={onClose}
            isDeleting={isDeleting}
            dismissIncident={dismissIncident}
          />
        </div>
      }
      onClose={onClose}
    >
      <div className={styles.contentContainer}>
        <div className={styles.meta}>
          <div>
            <OverviewDescriptions.Label>
              {t("discovered")}
            </OverviewDescriptions.Label>
            <OverviewDescriptions.Label>
              {getDateInUserLocale(record.detectedAt, DEFAULT_LONG_FORMAT)}
            </OverviewDescriptions.Label>
          </div>
          <Divider type="vertical" />
          <div>
            <OverviewDescriptions.Label>
              {t("actionsTaken")}
            </OverviewDescriptions.Label>
            <OverviewDescriptions.Label>
              <Tag className={styles.threatDetectedTagThreatPage}>
                {record.action}
              </Tag>
            </OverviewDescriptions.Label>
          </div>
        </div>
        <Card title={<Heading level={6} title="Description" />}>
          <LineClamp clamp={2}>{threatDetails?.description}</LineClamp>
        </Card>
        <OverviewCardTemplate title="Findings" items={items} />
        <Card title={<Heading level={6} title="Call Stack Evidence" />}>
          <CodeHighlight
            key={record.id}
            code={record.evidence?.frames?.join("\n")}
            language={
              record.appInfo?.runtimeTypeId === 1 ? "java" : "javascript"
            }
          />
        </Card>
      </div>
    </Drawer>
  )
}
