import { Form, Input, type Rule } from "@/atoms"

import { CopyButton } from "@/molecules/CopyButton"
import { InformationPopover } from "@/molecules/InformationPopover"
import type { FormWebhookProps } from "."
import styles from "./FormWebhook.module.scss"

export const FormWebhook = (props: FormWebhookProps) => {
  const { form, formId, values, onFieldsChange, onFinish, t } = props
  const rules = [{ required: true, message: t("error.requiredField") }]
  const urlRules = [
    { required: true, message: t("error.requiredField") },
    { type: "url", message: t("error.invalidURL") } as Rule,
    { pattern: /^https:\/\//, message: t("error.urlHTTPS") }
  ]
  const prefix = "integrations.webhook.form"
  const isNew = !values?.id
  const secretPlaceholder = isNew ? t(`${prefix}.secret.placeholder`) : ""

  return (
    <Form
      form={form}
      id={formId}
      layout="vertical"
      initialValues={values}
      onFieldsChange={onFieldsChange}
      onFinish={onFinish}
    >
      <Form.Item
        label={t("general.name")}
        name="name"
        required={false}
        rules={rules}
      >
        <Input
          ref={props.focusRef}
          placeholder={t(`${prefix}.name.placeholder`)}
        />
      </Form.Item>
      <Form.Item label={t(`${prefix}.description.label`)} name="description">
        <Input placeholder={t(`${prefix}.description.placeholder`)} />
      </Form.Item>
      <Form.Item
        validateFirst
        label={t(`${prefix}.url.label`)}
        name={props.URL_FIELD_NAME}
        required={false}
        rules={urlRules}
      >
        <Input placeholder="e.g https://webhook.com/endpoint" />
      </Form.Item>
      <Form.Item
        label={
          <div className={styles.container}>
            {t(`${prefix}.secret.label`)}
            {isNew && (
              <InformationPopover.Popover
                placement="right"
                content={
                  <InformationPopover.CommonTemplate>
                    {t(`${prefix}.secret.tooltip`)}
                  </InformationPopover.CommonTemplate>
                }
              />
            )}
          </div>
        }
        name="secret"
      >
        <Input
          disabled={!isNew}
          placeholder={secretPlaceholder}
          addonAfter={
            <CopyButton
              type="link"
              text={values?.["secret"] || ""}
              disabled={isNew}
            />
          }
        />
      </Form.Item>
    </Form>
  )
}
