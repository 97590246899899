import { Incident } from "@/api/useIncidents.types"
import { useTranslation } from "@/hooks/useTranslation"
import { Filter } from "@/organisms/FilterNew/Filter"
import { InputSmartSearchLoading } from "@/organisms/SmartSearch"
import { useGetIncidents } from "@/pages/TriagePage/components/pages/IncidentsPage/applications/useIncidents"
import { TabTemplate } from "@/templates/TabTemplate"
import { useDismissIncident } from "../../applications/useIncidents"
import { EmptyText } from "../EmptyText"
import { ThreatDrawer } from "../ThreatDrawer"
import { getColumnsTable } from "./getColumnsTable"

export const ThreatTab = ({ incidents }: { incidents?: Incident[] }) => {
  const { t } = useTranslation()
  const { response, isLoading } = useGetIncidents()
  const { dismissIncident, isLoading: isDeleting } = useDismissIncident()
  const { data = [], metadata } = response || {}

  return (
    <TabTemplate
      showHeader={false}
      columns={getColumnsTable({ t, dismissIncident, isDeleting })}
      loading={isLoading || isDeleting}
      dataSource={data}
      metadata={metadata}
      locale={{
        emptyText: !isLoading && <EmptyText />
      }}
      DrawerComponent={ThreatDrawer}
      SearchComponent={
        // eslint-disable-next-line no-constant-binary-expression
        false &&
        incidents && ( // TODO: Implement search
          <Filter.Provider keys={[]}>
            <Filter.Template
              search={
                <InputSmartSearchLoading placeholder="Input search text" />
              }
            ></Filter.Template>
          </Filter.Provider>
        )
      }
    />
  )
}
