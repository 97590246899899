import { DEFAULT_VALUES } from "@/pages/DeploymentsPage/const"
import { getKortexFilename } from "@/pages/DeploymentsPage/helpers"
import { getDeployCommandString } from "@/pages/DeploymentsPage/helpers/deployment-command"
import { getDefaultValues as getCommonDefaultValues } from "../CommonDeployCommand/helpers"
import type { InitialValues } from "../CommonDeployCommand/helpers.types"

export const getDefaultValues = (initialValues?: InitialValues) => {
  return {
    efsVolumeHandle: DEFAULT_VALUES.EFS_VOLUME_HANDLE,
    awsRoleArn: DEFAULT_VALUES.AWS_ROLE_ARN,
    ...getCommonDefaultValues(initialValues)
  }
}

export const getLines = ({ version }: { version: string }) => ({
  kubectl_create_namespace: {
    name: "namespace",
    text: "kubectl create namespace",
    align: "space-between" as const,
    suffix: " &&"
  },
  helm_upgrade: {
    text: `helm upgrade --install kortex ${getKortexFilename(version)}`
  },
  namespace: {
    name: "namespace",
    text: "--namespace",
    align: "space-between" as const
  },
  environment: { name: "environment", text: "--set kortex.environment=" },
  kortex_fargate: { text: "--set kortex.fargate=true" },
  deploymentKey: { name: "deploymentKey", text: "--set kortex.deploymentKey=" },
  pullSecretName: {
    name: "pullSecretName",
    text: "--set kortex.image.pullSecretName="
  },
  efsVolumeHandle: {
    name: "efsVolumeHandle",
    text: "--set koltan.efsVolumeHandle="
  },
  awsRoleArn: {
    name: "awsRoleArn",
    text: "--set koltan.serviceAccount.awsRoleArn="
  },
  create_namespace: { text: "--create-namespace" }
})

export const getDeployCommand = (
  values: ReturnType<typeof getDefaultValues>,
  defaultValues: ReturnType<typeof getDefaultValues>,
  version: string
) => {
  const lines = getLines({ version })

  return getDeployCommandString(
    {
      lines,
      values,
      defaultValues
    },
    [
      "kubectl_create_namespace",
      "helm_upgrade",
      "namespace",
      "environment",
      "kortex_fargate",
      "deploymentKey",
      "pullSecretName",
      "efsVolumeHandle",
      "awsRoleArn",
      "create_namespace"
    ]
  )
}
