import { useRef } from "react"

import { Form, TextArea } from "@/atoms"
import styles from "./DismissModal.module.scss"
import { DismissReasonPropsComponent } from "./DismissModal.types"

export const DismissReason: DismissReasonPropsComponent = (props) => {
  const { t, classes, name = "reason" } = props
  const reasonMaxLength = 250
  const refTextArea = useRef<HTMLTextAreaElement>(null)
  const dismissSuggestionWrite = t("triage.dismiss_modal.suggest_write")
  const className = classes?.title

  return (
    <>
      <span className={className}>Add your dismiss reason (Recommended)</span>
      <Form.Item name={name} className={styles.formItemReason}>
        <TextArea
          showCount
          autoSize={{ minRows: 3, maxRows: 6 }}
          ref={refTextArea}
          maxLength={reasonMaxLength}
          placeholder={dismissSuggestionWrite}
        />
      </Form.Item>
    </>
  )
}
