import { useGetIntegrationDetails } from "@/api/useIntegrations"
import { Breadcrumb, Link, Skeleton } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { HeaderPage } from "@/organisms/HeaderPage"

export const HeaderIntegrationPage = ({ vendor }: { vendor: string }) => {
  const { t } = useTranslation()
  const { response } = useGetIntegrationDetails(vendor)
  const { data } = response || {}
  const { integration } = data || {}
  const { connectPage, displayName = "" } = integration || {}
  const { submitButton } = connectPage || {}
  const { label } = submitButton || {}

  return (
    <HeaderPage
      title={
        <Breadcrumb
          items={[
            {
              title: (
                <Link to="/integrations">{t("navigation.integrations")}</Link>
              )
            },
            {
              title: displayName ? (
                <Link to={`/integrations/${vendor}`}>{displayName}</Link>
              ) : (
                <Skeleton.Input active size="small" />
              )
            },
            {
              title: label || <Skeleton.Input active size="small" />
            }
          ]}
        />
      }
    />
  )
}
