import { Button } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { TagOutlined } from "@/icons"

import type { ButtonProps } from "@/atoms"

export const LabelButton = ({
  children,
  ...props
}: Omit<ButtonProps, "icon">) => {
  const { t } = useTranslation()

  return (
    <Button icon={<TagOutlined />} {...props}>
      {children || t("settings.labels.create")}
    </Button>
  )
}
