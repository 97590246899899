import cn from "classnames"

import { Form as AntdForm, Spin } from "@/atoms"
import { useForm } from "@/organisms/Form/hooks/useForm"
import { Aside } from "./components/Aside"
import { DetailsSectionForm } from "./components/DetailsSectionForm"
import { Footer } from "./components/Footer"
import { FormPage } from "./components/FormPage"
import { Header } from "./components/Header"
import { RadioGroup } from "./components/RadioGroup"
import { ResourcesSectionForm } from "./components/ResourcesSectionForm"
import { SectionFormTemplate } from "./components/SectionFormTemplate"

import styles from "./Form.module.scss"

import type { FormProps } from "."

export const Form = <V = any,>(props: FormProps<V>) => {
  const { loading, className, layout = "vertical", onFinish, ...rest } = props
  const { form, custom } = useForm()

  if (loading || !form || !custom) {
    return <Spin className={styles.loader} />
  }

  return (
    <AntdForm
      form={form}
      className={cn(styles.form, className)}
      layout={layout}
      onFieldsChange={custom.onFieldsChange}
      onFinish={async (values) => {
        await onFinish?.(values)

        custom.setIsDirty(false)
      }}
      {...rest}
    >
      {props.children}
    </AntdForm>
  )
}

Form.Section = {
  Template: SectionFormTemplate,
  Details: DetailsSectionForm,
  Resources: ResourcesSectionForm
}

Form.RadioGroup = RadioGroup

Form.Aside = Aside

Form.Header = Header

Form.Footer = Footer

Form.Page = FormPage

Form.useForm = useForm

Form.Item = AntdForm.Item

Form.useWatch = AntdForm.useWatch
