import { useGetCallbackIntegrations } from "@/api/useIntegrations"
import { useLogger } from "@/hooks/useLogger"
import { useStatus } from "@/hooks/useStatus"
import { useEffect } from "react"
import { Navigate } from "react-router-dom"
import { useIntegrationCallbackParams } from "../../../hooks/useIntegrationCallbackParams"
import { IntegrationLoader } from "../../molecules/IntegrationLoader"
import { StatusComponent } from "./StatusComponent"

import type { Status } from "@/pages/IntegrationsPage"

export const IntegrationStatus = () => {
  const { status, setStatus } = useStatus("LOADING")
  const { values, error } = useIntegrationCallbackParams()
  const { valid, invalidReason } = error
  const { vendor } = values
  const { isLoading, isError } = useGetCallbackIntegrations(vendor, values, {
    enabled: valid,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: Infinity
  })
  const { logger, EVENTS } = useLogger()

  useEffect(() => {
    if (isLoading) {
      return
    }

    const messages = EVENTS.ERROR_EVENTS.INTEGRATIONS
    const meta = {
      extra: values,
      tags: { vendor: vendor || "not_exist" }
    }

    if (!valid) {
      const isActionRequest = invalidReason === "SETUP_ACTION_REQUEST"
      const currentStatus = isActionRequest ? "REQUESTED" : "REDIRECT"

      if (isActionRequest) logger.info(messages.REQUESTED, meta)
      else logger.error(messages.INVALID_PARAMS, meta)

      setStatus(currentStatus)
      return
    }

    const currentStatus: Status = isError ? "ERROR" : "SUCCESS"
    setStatus(currentStatus)

    if (isError) logger.error(messages.ERROR, meta)
    else logger.info(messages.SUCCESS, meta)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  if (status === "REDIRECT") {
    return <Navigate to="/" />
  }

  if (isLoading) {
    return <IntegrationLoader />
  }

  return <StatusComponent vendor={vendor} status={status} />
}
