import { useApiLoggingDemo } from "@/api/useDemo"
import {
  useAttackChains,
  useIssue as useIssueAPI,
  useIssueFindings
} from "@/api/useTriage"
import { getIssuesTriageSelector } from "@/pages/TriagePage/application/useTriage.selector"
import {
  getAttackChains,
  getFindingsInfoForExpandRow
} from "./useTriage.selectors"

import type { FindingsTriageResponse } from "@/api/useTriage.types"
import type { Params } from "@/hooks/useHttp.types"

export const useIssueFindingsDrawer = (issueId: string | undefined) => {
  const params = { needle: `${issueId}` }
  const context = useIssueFindings<
    FindingsTriageResponse,
    ReturnType<typeof getFindingsInfoForExpandRow>
  >(params, {
    enabled: !!issueId,
    select: getFindingsInfoForExpandRow
  })
  return context
}

export const useIssue = (issueId: string | undefined) => {
  const context = useIssueAPI(issueId, undefined, {
    enabled: !!issueId,
    select: getIssuesTriageSelector
  })
  const nextContext = useApiLoggingDemo(context)

  return nextContext
}

export const useAttackScenarios = (
  issueId: string | undefined,
  params?: Params
) => {
  const context = useAttackChains<ReturnType<typeof getAttackChains>>(
    issueId,
    params,
    { select: getAttackChains }
  )

  return context
}
