import { useEffect, useRef } from "react"

import { Form, Modal, Text, Title } from "@/atoms"
import { usePrevious } from "@/hooks/usePrevious"
import { useTranslation } from "@/hooks/useTranslation"
import { InformationPopover } from "@/molecules/InformationPopover"
import styles from "./DismissModal.module.scss"
import { DismissReason } from "./DismissReason"

import type { DismissModalComponent } from "./DismissModal.types"

export const DismissModal: DismissModalComponent = (props) => {
  const { count, open, onOk, onCancel } = props
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const refTextArea = useRef<HTMLTextAreaElement>(null)
  const prevOpen = usePrevious(open)
  const dismissExplain = t("triage.dismiss_modal.explain")
  const dismissExcludeFromSummary = t("triage.dismiss_modal.excludeFromSummary")

  useEffect(() => {
    if (open) setTimeout(() => refTextArea.current?.focus(), 100)
    if (!open && prevOpen) form.resetFields()
  }, [open, prevOpen, form])

  return (
    <Modal
      open={open}
      okText={t("general.dismiss")}
      onOk={() => {
        onOk({ reason: form.getFieldValue("reason") })
      }}
      onCancel={onCancel}
      footer={(originNode) => <div className={styles.footer}>{originNode}</div>}
    >
      <Title className={styles.title} level={4}>
        {t("triage.dismiss_modal.title", { count })}
        <InformationPopover.Popover
          iconSize="large"
          classNamePopover={styles.popover}
          classNameIcon={styles.iconPopover}
          content={
            <InformationPopover.CommonTemplate>
              {dismissExcludeFromSummary}
            </InformationPopover.CommonTemplate>
          }
        />
      </Title>
      <Text>{dismissExplain}</Text>
      <Form form={form} name="dismiss-issue">
        <DismissReason t={t} classes={{ title: styles.labelReason }} />
      </Form>
    </Modal>
  )
}
