import { Input } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { AlignLeftOutlined } from "@/icons"
import { Form } from "../.."
import { SectionFormTemplate } from "../SectionFormTemplate"

import type { DetailsSectionFormProps } from "."

const sectionId = "details"

export const DetailsSectionForm = (props: DetailsSectionFormProps) => {
  const { t } = useTranslation()
  const rules = [{ required: true, message: t("error.requiredField") }]
  const desc = "forms.fields.description"
  const { children, suffix, disabled, title = t("general.details") } = props
  const { custom } = Form.useForm()

  return (
    <SectionFormTemplate id={sectionId} title={title} Icon={AlignLeftOutlined}>
      <Form.Item
        required={false}
        name="name_details"
        label={props.label}
        rules={rules}
      >
        <Input
          disabled={disabled}
          ref={custom.focusRef}
          placeholder={t("forms.fields.name.placeholder")}
        />
      </Form.Item>
      {children}
      <Form.Item
        required={false}
        name="description_details"
        label={t(`${desc}.label`)}
      >
        <Input disabled={disabled} placeholder={t(`${desc}.placeholder`)} />
      </Form.Item>
      {suffix}
    </SectionFormTemplate>
  )
}

DetailsSectionForm.sectionId = sectionId
