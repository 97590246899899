import cn from "classnames"

import { Avatar, Popover, Tooltip } from "@/atoms"
import { getInitials } from "@/domain/user"
import { ShowMore } from "@/molecules/ShowMore"

import styles from "./Avatars.module.scss"

import type { AvatarsProps } from "."

export const Avatars = (props: AvatarsProps) => {
  const { contributorsCount, t, list = [], maxCount = 1 } = props

  if (!!contributorsCount && list.length > maxCount) {
    const maxAvatarsInTooltip = 8
    const plusNumber = contributorsCount - maxAvatarsInTooltip

    return (
      <div className={cn(styles.container, styles.showMore)}>
        {Array(maxCount)
          .fill(0)
          .map((_, index) => {
            if (!list[index]) return null

            const { name } = list[index]

            return (
              <Tooltip
                destroyTooltipOnHide
                key={`${name}_${index}`}
                title={name}
                className={styles.tooltip}
              >
                <Avatar>{getInitials(list[index].name)}</Avatar>
              </Tooltip>
            )
          })}
        <Popover
          destroyTooltipOnHide
          content={
            <div className={styles.tooltipShowMore}>
              {Array(maxAvatarsInTooltip)
                .fill(0)
                .map((_, index) => {
                  const currentIndex = index + maxCount

                  if (!list[currentIndex]) return null

                  const { name } = list[currentIndex]

                  return (
                    <Tooltip
                      destroyTooltipOnHide
                      key={`${name}_${index}`}
                      title={name}
                      className={styles.tooltip}
                    >
                      <Avatar size="small">{getInitials(name)}</Avatar>
                    </Tooltip>
                  )
                })}
              {plusNumber > 0 && (
                <ShowMore.Label.Counter
                  count={`${plusNumber} ${t?.("general.other")}`}
                />
              )}
            </div>
          }
        >
          <span>
            <ShowMore.Label.Counter count={contributorsCount - maxCount} />
          </span>
        </Popover>
      </div>
    )
  }

  return (
    <Avatar.Group className={styles.container} max={{ count: maxCount }}>
      {list.map((item: any, index: number) => (
        <Tooltip
          destroyTooltipOnHide
          key={`${item.name}_${index}`}
          title={item.name}
          className={styles.tooltip}
        >
          <Avatar>{getInitials(item.name)}</Avatar>
        </Tooltip>
      ))}
    </Avatar.Group>
  )
}
