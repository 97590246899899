import { useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"

import { Button, Form, Text, Title } from "@/atoms"
import { useServices } from "@/hooks/useServices"
import { useMultiFactorForm } from "@/pages/SignInPage/hooks/useMultiFactorForm"
import { ConfirmCodeInput } from "../ConfirmCodeInput"

import styles from "./ConfirmCodeSignInForm.module.scss"

import type { ConfirmCodeSignInFormProps } from "."

export const ConfirmCodeSignInForm = ({
  className
}: ConfirmCodeSignInFormProps) => {
  const fieldName = "code"
  const { value, inputRef, form, onKeyDown, t } = useMultiFactorForm(fieldName)
  const navigate = useNavigate()
  const { auth } = useServices()
  const requestRef = useRef<null | Promise<string | null | undefined>>(null)

  useEffect(() => {
    requestRef.current = auth.sendVerificationCodeOnPhoneNumber()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.container}>
      <Title level={3}>{t("auth.mfa.verify.title")}</Title>
      <Text>{t("auth.mfa.verify.description")}</Text>

      <Form
        form={form}
        className={className}
        name="sigInForm"
        layout="vertical"
        requiredMark={false}
        onFinish={async () => {
          try {
            if (requestRef.current) {
              const verificationId = await requestRef.current

              if (value && verificationId) {
                await auth.verificationCode(verificationId, value)

                return
              }
            }

            throw new Error(t("auth.mfa.errors.invalid-code"))
          } catch {
            return form.setFields([
              {
                name: fieldName,
                errors: [t("auth.mfa.errors.invalid-code")]
              }
            ])
          }
        }}
      >
        <ConfirmCodeInput
          inputRef={inputRef}
          name={fieldName}
          onKeyDown={onKeyDown}
        />
        <div className={styles.buttons}>
          <Button
            size="large"
            type="text"
            onClick={() => {
              auth.signOut()
              navigate("/")
            }}
          >
            {t("auth.mfa.verify.cancelAction")}
          </Button>
          <Button htmlType="submit" size="large" type="ks">
            {t("general.continue")}
          </Button>
        </div>
      </Form>
    </div>
  )
}
