import { Text } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import {
  DeployTab,
  TitleStepDeployTab,
  type CommonDeployTabProps
} from "@/pages/DeploymentsPage/components/molecules/DeployTab"
import { DownloadStep } from "@/pages/DeploymentsPage/components/organisms/DownloadStep"
import { EcsDeployCommand } from "@/pages/DeploymentsPage/components/organisms/EcsDeployCommand"
import { EcsSecretDeployCommand } from "@/pages/DeploymentsPage/components/organisms/EcsSecretDeployCommand"
import {
  REGCRED_CF_YAML_FILENAME,
  REGISTRY_CREDENTIALS_DOWNLOAD_URL
} from "@/pages/DeploymentsPage/const"

import type { Query } from "@/pages/DeploymentsPage/components/molecules/DownloadFileButton"

export const EcsTab = (props: CommonDeployTabProps & { fargate?: boolean }) => {
  const { noError, version, fargate } = props
  const packageSpecArch = "amd64"
  const name = `kortex-${version}-ecs-${fargate ? "fargate" : "asg"}.tgz`
  const packageSpecType = fargate ? "ecs-fargate" : "ecs"
  const files = [
    { name, query: { packageSpecType, packageSpecArch } as Query },
    {
      name: REGCRED_CF_YAML_FILENAME,
      url: `${REGISTRY_CREDENTIALS_DOWNLOAD_URL}?type=cloudformation`
    }
  ]

  const { t } = useTranslation()
  const steps = [
    {
      title: (
        <DownloadStep
          noError={noError}
          files={files}
          title={t("deployments.ecs.deploymentStep")}
        />
      ),
      status: "process" as const
    },
    {
      title: (
        <TitleStepDeployTab prefix={t("deployments.deploySecret")}>
          {`- ${t("deployments.ecs.deploySecretStep")}`}
        </TitleStepDeployTab>
      ),
      description: <EcsSecretDeployCommand {...props} />,
      status: "process" as const
    },
    {
      title: (
        <TitleStepDeployTab prefix={t("general.deploy")}>
          {`- ${t("deployments.ecs.commandStep")}`}
        </TitleStepDeployTab>
      ),
      description: <EcsDeployCommand {...props} />,
      status: "process" as const
    }
  ]

  if (fargate) {
    steps.push({
      title: (
        <TitleStepDeployTab prefix={t("general.deploy")}>
          {`- ${t("deployments.ecs.readmeFargate")}`}
        </TitleStepDeployTab>
      ),
      status: "process" as const
    })
  }

  return (
    <DeployTab
      steps={steps}
      message={
        <span>
          <Text>
            {t("deployments.ecs.alert.text1")}
            <br />
            {t("deployments.ecs.alert.text2")}
          </Text>
          <br />
          <b>{t("deployments.ecs.alert.note")}</b>
        </span>
      }
    />
  )
}
