import cn from "classnames"
import { useEffect, useState } from "react"

import {
  Alert,
  Button,
  Card,
  Form,
  Input,
  Modal,
  RadioChangeEvent
} from "@/atoms"
import { Trans, useTranslation } from "@/hooks/useTranslation"
import { CopyButton } from "@/molecules/CopyButton"
import { ExpiryRadioGroupButton } from "./ExpiryRadioButton"

import { MONTH_IN_SECONDS } from "@/const/default-values"
import { InfoCircleOutlined, KeyIcon } from "@/icons"
import styles from "./AuthKeysTab.module.scss"
import { CreateKeyModelProps } from "./CreateKeyModal.types"

export const CreateKeyModal = ({
  open,
  useCreateKey,
  keyType,
  onClose,
  sourceIntegration,
  logCreateKey
}: CreateKeyModelProps) => {
  const keyPrefix = "settings.createKeyModal"
  const { t } = useTranslation()
  const { t: tModal } = useTranslation("translation", { keyPrefix })
  const [serverError, setServerError] = useState(false)
  const {
    data,
    isPending: isLoading,
    mutateAsync: mutateCreateKey
  } = useCreateKey(keyType)
  const [values, setValues] = useState(null)
  const [copied, setCopied] = useState(false)
  const [inputBlocked, setInputBlocked] = useState(false)

  const [form] = Form.useForm()
  const onSubmit = () => {
    form.submit()
  }

  const onFinish = async (values: {
    keyName: string
    keyDescription: string
  }) => {
    try {
      await mutateCreateKey({
        expiresIn,
        sourceIntegration,
        ...values
      })
      logCreateKey(values.keyName)
    } catch {
      setServerError(true)
    }
  }

  const formName = Form.useWatch("name", form)
  const formDescription = Form.useWatch("description", form)
  const defaultExpiration: number = 3 * MONTH_IN_SECONDS
  const [expiresIn, setExpiresIn] = useState<number>(defaultExpiration)
  const handleExpiresInChange = (e: RadioChangeEvent) => {
    setExpiresIn(e.target.value)
  }

  useEffect(() => {
    if (formName) {
      form
        .validateFields()
        .then((values) => {
          setValues(values)
        })
        .catch(() => {
          setValues(null)
        })
    } else {
      setValues(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formName, formDescription])

  const generatedKey = data?.data.plain

  useEffect(() => {
    if (!isLoading && generatedKey) {
      form.setFieldValue("generatedKey", generatedKey)
      setCopied(false)
    }
  }, [generatedKey, isLoading, form])

  function isGeneratedKeyNotCopied() {
    // prevend closing modal if key was generated and not copied
    return generatedKey && generatedKey !== "" && !copied
  }

  return (
    <Modal
      width={480}
      open={open}
      className={styles.container}
      footer={null}
      title={tModal("title")}
      onCancel={onClose}
      cancelButtonProps={{
        disabled: isLoading || isGeneratedKeyNotCopied()
      }}
      closable={!isGeneratedKeyNotCopied()}
    >
      {serverError && (
        <Alert type="error" message={t("error.serverError")} banner />
      )}
      <Form
        form={form}
        layout="vertical"
        className={styles.form}
        onFinish={onFinish}
      >
        <Form.Item
          className={styles.itemForm}
          required={true}
          name="name"
          label={tModal("name")}
        >
          <Input
            placeholder={tModal("namePlaceholder")}
            onKeyDown={() => {
              if (serverError) setServerError(false)
            }}
            disabled={inputBlocked}
          />
        </Form.Item>
        <Form.Item
          className={styles.itemForm}
          name="description"
          label={tModal("description")}
        >
          <Input
            placeholder={tModal("descriptionPlaceholder")}
            onKeyDown={() => {
              if (serverError) setServerError(false)
            }}
            disabled={inputBlocked}
          />
        </Form.Item>
        <Form.Item
          className={styles.itemForm}
          name="expiration"
          label={tModal("expiration")}
          initialValue={defaultExpiration}
        >
          <ExpiryRadioGroupButton
            expiresIn={expiresIn}
            onChange={handleExpiresInChange}
            disabled={inputBlocked}
            t={t}
          />
        </Form.Item>

        <Card className={cn(styles.cardItem)}>
          <Alert
            className={cn(styles.alertItemForm, styles.marginItem)}
            icon={<InfoCircleOutlined />}
            description={<Trans>{tModal("infoText")}</Trans>}
            type="info"
            showIcon
          />
          <Button
            className={styles.marginItem}
            type="primary"
            icon={<KeyIcon />}
            onClick={() => {
              setInputBlocked(true)
              onSubmit()
            }}
            loading={isLoading}
            disabled={!values || generatedKey}
            block
          >
            {tModal("okText")}
          </Button>
          <Form.Item className={styles.keyItemForm} name="generatedKey">
            <Input
              readOnly={true}
              disabled={!generatedKey}
              addonAfter={
                <CopyButton
                  text={generatedKey}
                  disabled={!generatedKey}
                  onCopy={() => setCopied(true)}
                />
              }
            />
          </Form.Item>
        </Card>
      </Form>
    </Modal>
  )
}
