import { Button, Flex, Form, Input, Steps, Text } from "@/atoms"
import { generateExternalID } from "@/helpers/utils"
import { Trans, useTranslation } from "@/hooks/useTranslation"

import { InfoCircleOutlined } from "@/icons"
import { CopyButton } from "@/molecules/CopyButton"
import { InformationCard } from "@/molecules/InformationCard"
import connectStyles from "../../../ConnectIntegrationPage/ConnectIntegrationPage.module.scss"
import styles from "../../../GitLabIntegrationPage/components/FormGitLabIntegrationPage/FormGitLabIntegrationPage.module.scss"
import { FormAzureIntegrationPageComponent } from "./FormAzureIntegrationPage.types"

export const FormAzureIntegrationPage: FormAzureIntegrationPageComponent = ({
  focusRef,
  form,
  onFieldsChange
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "integrations.connect.azure.steps"
  })
  const { t: tError } = useTranslation()
  const rules = [{ required: true, message: tError("error.requiredField") }]
  const kodemTokenFieldName = "kodemAccessToken"
  const initialValues = { [kodemTokenFieldName]: generateExternalID().value }

  const includeWebhooks = false

  const webhookUrl = "https://integrations.kodemsecurity.com/api/azure/webhooks"
  const kodemAccessPrefix = "X-Kodem-Token"
  const kodemToken = Form.useWatch(kodemTokenFieldName, form)

  return (
    <Form
      initialValues={initialValues}
      layout="vertical"
      form={form}
      onFieldsChange={onFieldsChange}
    >
      <Steps className={connectStyles.steps} size="small" direction="vertical">
        <>
          <Steps.Step
            title={t("generate.title")}
            description={
              <>
                <Text type="secondary">{t("generate.description")}</Text>
                <ol className={styles.list}>
                  {[1, 2, 3, 4, 5, 6, 7].map((number) => (
                    <li key={number}>
                      <Text>
                        <Trans>{t(`generate.${number}`)}</Trans>
                      </Text>
                    </li>
                  ))}
                </ol>
              </>
            }
            status="process"
          />
          <Steps.Step
            title={t("accessToken.title")}
            description={
              <>
                <Text type="secondary">{t("accessToken.description")}</Text>
                <Form.Item
                  required={false}
                  name="azureAccessToken"
                  rules={rules}
                >
                  <Input
                    ref={focusRef}
                    placeholder="e.g azure personal access token"
                  />
                </Form.Item>
              </>
            }
            status="process"
          />
          {includeWebhooks && (
            <Steps.Step
              title={t("createWebhook.title")}
              description={
                <>
                  <InformationCard
                    infoMessage={t("createWebhook.description")}
                  ></InformationCard>
                  <ol className={styles.list}>
                    {[1, 2, 3, 4].map((number) => (
                      <li key={number}>
                        <Text>
                          <Trans>{t(`createWebhook.${number}`)}</Trans>
                        </Text>
                      </li>
                    ))}
                    <li>
                      <Text>
                        <Trans>{t("createWebhook.5.title")}</Trans>
                      </Text>
                      <ul className={styles.nestedList}>
                        <li>
                          <Text
                            strong
                          >{`${t("createWebhook.5.steps.1")}:`}</Text>
                          <Form.Item className={styles.formItem}>
                            <Input
                              value={webhookUrl}
                              addonBefore={t("createWebhook.5.steps.1")}
                              addonAfter={
                                <CopyButton text={webhookUrl} type="link" />
                              }
                            />
                          </Form.Item>
                        </li>
                        <li>
                          <Flex vertical gap={10}>
                            <Flex align="center" gap={6}>
                              <Text strong>
                                {`${t("createWebhook.5.steps.2.title")}:`}
                              </Text>
                            </Flex>
                            <Text type="secondary">
                              {t("createWebhook.5.steps.2.description")}
                            </Text>
                            <Flex gap={12}>
                              <Form.Item
                                className={styles.formItem}
                                required={false}
                                name={"kodemAccessToken"}
                                rules={rules}
                              >
                                <Input
                                  addonBefore={t(
                                    "createWebhook.5.steps.2.addon"
                                  )}
                                  ref={focusRef}
                                  placeholder="YZA9KFV2QHHH"
                                  addonAfter={
                                    <CopyButton text={kodemToken} type="link" />
                                  }
                                />
                              </Form.Item>
                              <Button
                                onClick={() => {
                                  form.setFieldValue(
                                    kodemTokenFieldName,
                                    generateExternalID().value
                                  )
                                  form.validateFields([kodemTokenFieldName])
                                }}
                              >
                                {t("createWebhook.5.steps.2.generate")}
                              </Button>
                            </Flex>
                            <Flex gap={12}>
                              <Text type="secondary">
                                <InfoCircleOutlined />
                              </Text>
                              <Text type="secondary">
                                {t("accessToken.kodemToken")}
                              </Text>
                            </Flex>
                            <Flex gap={12}>
                              <Text type="secondary">
                                <Trans>
                                  {t("accessToken.pasteDescription")}
                                </Trans>
                              </Text>
                            </Flex>
                            <Flex gap={12}>
                              <Input
                                value={`${kodemAccessPrefix}:${kodemToken}`}
                                disabled
                                addonAfter={
                                  <CopyButton
                                    text={`${kodemAccessPrefix}:${kodemToken}`}
                                    type="link"
                                  />
                                }
                              />
                            </Flex>
                          </Flex>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Text>
                        <Trans>{t("createWebhook.6")}</Trans>
                      </Text>
                    </li>
                  </ol>
                </>
              }
              status="process"
            />
          )}
        </>
      </Steps>
    </Form>
  )
}
