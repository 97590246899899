import { useRef } from "react"

import { Breadcrumb, Link, Skeleton } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { Form } from "@/organisms/Form"
import { HeaderPage } from "@/organisms/HeaderPage"

import type { HeaderComponent } from "."

export const Header: HeaderComponent = (props) => {
  const { items } = props
  const { t } = useTranslation()
  const { isNewEntity, form } = Form.useForm()
  const nameFieldName = items[items.length - 1].fieldName
  const entityName = Form.useWatch(nameFieldName, form)
  const { loading = !isNewEntity && !entityName } = props
  const titleRef = useRef(entityName)

  if (!titleRef.current && !!entityName) titleRef.current = entityName

  return (
    <HeaderPage
      title={
        <Breadcrumb
          items={items.map((item) => {
            const { tKey = "", link, title = t(tKey) } = item

            if (link) return { title: <Link to={link}>{title}</Link> }

            if (loading)
              return { title: <Skeleton.Input active size="small" /> }

            const currentTitle = item.title || titleRef.current

            return { title: isNewEntity ? t(tKey) : currentTitle }
          })}
        />
      }
    />
  )
}
