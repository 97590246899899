import { useNavigate } from "react-router-dom"

import { Button, Flex } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { SaveOutlined } from "@/icons"
import { CancelConfirmModal, useConfirmModal } from "@/molecules/ConfirmModal"
import { Form } from "@/organisms/Form"

import type { FooterComponent } from "."

export const Footer: FooterComponent = (props) => {
  const { t } = useTranslation()
  const { confirm } = useConfirmModal()
  const navigate = useNavigate()
  const { custom, formId, isNewEntity, permissions } = Form.useForm()
  const { isDirty } = custom || {}
  const id = `footer-${formId}`
  const { createKey = "general.save", updateKey = "general.save" } = props
  const tKey = isNewEntity ? createKey : updateKey
  const { okText } = props

  return (
    <Flex id={id} gap="small" justify="end">
      <Button
        id="cancel-button-footer-form"
        onClick={() => {
          const onOk = () => {
            const { cancelRedirect = "-1" } = props

            custom.setIsDirty(false)
            window.queueMicrotask(() => {
              navigate(cancelRedirect)
            })
          }
          const action = isNewEntity ? "create" : "update"

          if (isDirty)
            return confirm((attr) =>
              CancelConfirmModal({
                onOk,
                okText: t(`forms.buttons.cancel.${action}`),
                content: t(`forms.messages.cancel.${action}`),
                ...attr
              })
            )

          onOk()
        }}
      >
        {t("general.cancel")}
      </Button>
      {props.children}
      <Button
        form={formId}
        htmlType="submit"
        disabled={!isDirty || !permissions.write}
        type="primary"
        icon={<SaveOutlined />}
        loading={props.submitting}
      >
        {okText?.(isNewEntity) || t(tKey)}
      </Button>
    </Flex>
  )
}
