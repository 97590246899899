import dayjs from "dayjs"
import { memo, useMemo } from "react"

import type { useActionsOverTimeStatisticsWorkflow } from "@/api/useWorkflow"
import { ActionType, OverTimeStatistic } from "@/api/useWorkflow.types"
import { Column } from "@/atoms"
import { useNumberConvertor } from "@/hooks/useNumberConvertor"
import { useTranslation } from "@/hooks/useTranslation"
import { Widget } from "@/molecules/Widget"
import type { ActionOverTime } from "./ActionsOverTimeTooltip"
import { ActionsOverTimeTooltip } from "./ActionsOverTimeTooltip"

const actionTypes: ActionType[] = ["webhook", "label", "dismiss", "jira_issue"]

export const ActionsOverTimeWidget: React.FC<{
  context: ReturnType<typeof useActionsOverTimeStatisticsWorkflow>
}> = memo(
  function ActionsOverTimeWidgetComponent({ context }) {
    const { t } = useTranslation("translation", {
      keyPrefix: "settings.automatedActions.widgets.overtime"
    })
    const { intlNumber } = useNumberConvertor()
    const DATE_FORMAT = "MMM D"

    const config = useMemo(() => {
      const documentStyle = getComputedStyle(document.documentElement)

      const ACTION_COLORS: Record<ActionType, string> = {
        webhook: documentStyle.getPropertyValue("--webhook-icon-color"),
        label: documentStyle.getPropertyValue("--label-icon-color"),
        dismiss: documentStyle.getPropertyValue("--dismiss-icon-color"),
        jira_issue: documentStyle.getPropertyValue("--jira-icon-color")
      }

      const res = context.response?.data || []
      const data =
        res.flatMap((item: OverTimeStatistic) =>
          actionTypes.map((type) => {
            const value = item.actions[type] || 0

            return {
              type,
              value,
              valueTooltip: intlNumber(value),
              year: `${dayjs(item.weekStart).format(DATE_FORMAT)} - ${dayjs(item.weekEnd).format(DATE_FORMAT)}`
            }
          })
        ) || []

      const max = Math.max(
        ...res.map((items) =>
          actionTypes.reduce((sum, type) => {
            sum += items.actions[type] || 0

            return sum
          }, 0)
        )
      )

      return {
        data,
        height: 152,
        maxColumnWidth: 12,
        legend: false,
        isStack: true,
        xField: "year",
        yField: "value",
        yAxis: { max: max === 0 ? undefined : max + Math.round(max * 0.1) },
        offsetY: 100,
        seriesField: "type",
        color: Object.values(ACTION_COLORS),
        interactions: [
          {
            type: "active-region",
            enable: false
          }
        ],
        responsive: true
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.response]) as any

    return (
      <Widget
        loading={context.isLoading}
        title={t("title")}
        popover={{ content: t("tooltip") }}
      >
        <Column
          tooltip={{
            customContent: (_, data) => {
              return (
                <ActionsOverTimeTooltip
                  actions={
                    data
                      .filter((item) => item.data.value > 0)
                      .map((item) => item.data) as ActionOverTime[]
                  }
                />
              )
            }
          }}
          {...config}
        />
      </Widget>
    )
  },
  (prev, curr) => prev.context.response === curr.context.response
)
