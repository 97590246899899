import { RESOURCES_NAMES } from "@/const/resource.constants"
import {
  predicateHelpers,
  useCreate,
  useDelete,
  useHttp,
  useUpdate
} from "@/hooks/useHttp"

import type { Config, Params, Updater } from "@/hooks/useHttp"
import type { ScopesResponse } from "./useScopes.types"

export const useGetScopes = <T = ScopesResponse, S = T>(
  params?: Params<{ needle?: string; pageSize?: number; query?: string }>,
  config?: Config<T, S>
) => {
  const { needle, ...restParams } = params || {}
  const url = needle
    ? `${RESOURCES_NAMES.SETTINGS.SCOPES}/${needle}`
    : RESOURCES_NAMES.SETTINGS.SCOPES
  const context = useHttp<T, S>(url, restParams, config)

  return context
}

export const getScopePredicate = () =>
  predicateHelpers.includesKeysPredicate(
    [RESOURCES_NAMES.SETTINGS.SCOPES],
    [`${RESOURCES_NAMES.SETTINGS.SCOPES}/`]
  )

export const usePostScope = <T extends ScopesResponse, S = T>() => {
  const creator = useCreate<T, S>(
    RESOURCES_NAMES.SETTINGS.SCOPES,
    undefined,
    undefined,
    (queryClient) => ({
      onSuccess: (res) => {
        queryClient.setQueryData(
          [`${RESOURCES_NAMES.SETTINGS.SCOPES}/${res.data[0].id}`, {}],
          res
        )

        return queryClient.refetchQueries({ predicate: getScopePredicate() })
      }
    })
  )

  return creator
}

export const usePutScope = () => {
  const updater = useUpdate(
    RESOURCES_NAMES.SETTINGS.SCOPES,
    undefined,
    undefined,
    (queryClient) => ({
      onSuccess: (_, variables: { needle: string }) => {
        queryClient.invalidateQueries({
          predicate: predicateHelpers.includesKeysPredicate([
            `${RESOURCES_NAMES.SETTINGS.SCOPES}${variables.needle}`
          ])
        })

        return queryClient.refetchQueries({ predicate: getScopePredicate() })
      }
    })
  )

  return updater
}

export const useDeleteScope = (
  params: Params | undefined = undefined,
  updater?: Updater<ScopesResponse, string>
) => {
  const remover = useDelete(RESOURCES_NAMES.SETTINGS.SCOPES, params, updater)

  return remover
}

export const useGetFiltersScopes = <T = ScopesResponse, S = T>(
  params?: Params<object>,
  config?: Config<T, S>
) => {
  const context = useHttp<T, S>(
    `${RESOURCES_NAMES.SETTINGS.SCOPES}/filters/options`,
    params,
    config
  )

  return context
}
