import { ActionsCell } from "@/molecules/ActionsCell"

import type { UserActionsCellComponent } from "."

export const UserActionsCell: UserActionsCellComponent = ({
  deleteUser,
  resetOrResendPassword,
  editUser,
  t
}) =>
  function UserActionsCellComponent(_, record) {
    const { status } = record

    if (status === "DECLINED") return null

    const prefix = "settings.users.actions"
    const keyActionByStatus = status === "INVITED" ? "resend" : "reset"
    const items = [
      { key: "edit", label: t(`${prefix}.edit`) },
      { key: keyActionByStatus, label: t(`${prefix}.${keyActionByStatus}`) },
      { key: "delete", label: t(`${prefix}.delete`) }
    ]

    return (
      <ActionsCell
        items={items}
        onClick={({ key }) => {
          if (key === items[0].key) return editUser(record)
          if (key === items[2].key) return deleteUser(record)

          resetOrResendPassword(record, key === "resend")
        }}
      />
    )
  }
