import { Form, Segmented } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { MinusCircleFilled, PlusCircleFilled } from "@/icons"

import styles from "./CardField.module.scss"

import type { CardFieldProps } from "."

export const IncludeExcludeField: React.FC<
  Pick<CardFieldProps, "name" | "componentProps">
> = (props) => {
  const { t } = useTranslation()
  const { name, componentProps } = props
  const disabled = !!componentProps?.disabled
  const parentName = Array.isArray(name) ? name : [name]

  return (
    <Form.Item name={[parentName[0], "includeExclude"]}>
      <Segmented
        disabled={!!disabled}
        className={styles.segmented}
        options={[
          {
            label: (
              <span className={styles.option} data-type="include">
                <PlusCircleFilled />
                <span>{t("general.include")}</span>
              </span>
            ),
            value: "Include",
            disabled
          },
          {
            label: (
              <span className={styles.option} data-type="exclude">
                <MinusCircleFilled />
                <span>{t("general.exclude")}</span>
              </span>
            ),
            value: "Exclude",
            disabled
          }
        ]}
      />
    </Form.Item>
  )
}
