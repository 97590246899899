import { Card, Statistic, Text } from "@/atoms"

import styles from "./StatisticCard.module.scss"

import type { StatisticCardComponent } from "."

export const StatisticCard: StatisticCardComponent = (props) => {
  const { title, Icon, value } = props

  return (
    <Card className={styles.card}>
      <Statistic
        title={
          <Text className={styles.text} type="secondary">
            <Icon />
            <span>{title}</span>
          </Text>
        }
        value={value}
      />
    </Card>
  )
}
