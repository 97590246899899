import { Text } from "@/atoms"
import type { ImageIntroducedThrough } from "@/domain/issue"
import { HistoryImagePackageData } from "@/domain/package"
import type { TFunction } from "@/hooks/useTranslation"
import cn from "classnames"
import { BaseImagesHierarchy } from "./BaseImagesHierarchy/BaseImagesHierarchy"
import {
  getCommandsHistoryTitle,
  HistoryCommandsList
} from "./HistoryCommandsList/HistoryCommandsList"

import styles from "./ImageIntroduceThrough.module.scss"
import { PackageLocations } from "./PackageLocations"

export type ImageIntroduceThroughProps = {
  introducedThrough: ImageIntroducedThrough[]
}

function getHistoryEntriesOfImageLayer(
  images: ImageIntroducedThrough[]
): HistoryImagePackageData[] {
  return images
    .filter((image) => !image.baseImageDetails?.baseImageDigest)
    .flatMap((image) => image.historyEntries)
}

function getImagePackageLocations(images: ImageIntroducedThrough[]): string[] {
  const uniqueLocations = new Set<string>()

  images
    .filter(
      (image) => image.baseImageDetails || image.historyEntries.length > 0
    )
    .forEach((image) => {
      if (image.packageLocations.length > 0) {
        image.packageLocations.forEach((location) =>
          uniqueLocations.add(location)
        )
      }
    })

  return Array.from(uniqueLocations)
}

export const ImageIntroduceThrough: React.FC<
  ImageIntroduceThroughProps & { t: TFunction; tKey: string }
> = ({ introducedThrough, t, tKey }) => {
  introducedThrough.sort(
    (a, b) =>
      (b.baseImageDetails?.baseImageLayerIndex ?? 0) -
      (a.baseImageDetails?.baseImageLayerIndex ?? 0)
  )
  const introducedThroughWithoutEmptyBaseImage = introducedThrough.filter(
    (image) => image.baseImageDetails?.baseImageDigest
  )

  const packageLocations = getImagePackageLocations(introducedThrough)
  const historyEntries = getHistoryEntriesOfImageLayer(introducedThrough)
  const hasBaseImageDetails = introducedThrough.some(
    (image) => image.baseImageDetails?.baseImageDigest
  )
  const baseImagesTitle =
    introducedThroughWithoutEmptyBaseImage.length > 1
      ? `${t(`${tKey}baseImages`)}:`
      : `${t(`${tKey}baseImage`)}:`
  const packageLocationsTitle =
    packageLocations.length > 1
      ? `${t(`${tKey}packageLocations`)}:`
      : `${t(`${tKey}packageLocation`)}:`
  return (
    <>
      <>
        <div className={styles.skeletonContainer}>
          <Text className={styles.title}>{packageLocationsTitle}</Text>
        </div>
        <PackageLocations packageLocations={packageLocations} />
      </>
      {hasBaseImageDetails && (
        <>
          <div className={styles.skeletonContainer}>
            <Text className={styles.title}>{baseImagesTitle}</Text>
          </div>
          <BaseImagesHierarchy
            introducedThrough={introducedThroughWithoutEmptyBaseImage}
            tKey={tKey}
          />
        </>
      )}

      {!!historyEntries?.length && (
        <>
          <div className={styles.skeletonContainer}>
            <Text className={styles.title}>
              {getCommandsHistoryTitle(tKey, t, historyEntries)}
            </Text>
          </div>
          <div
            className={cn(
              "container-items-issue-expanded-cell-component-issue-tab",
              styles.imageCommands
            )}
          >
            <HistoryCommandsList
              historyEntries={historyEntries}
              tKey={tKey}
              t={t}
            />
          </div>
        </>
      )}
    </>
  )
}
