import { useEffect, useState } from "react"
import showdown from "showdown"

import { useCalculateFixes, useProjectDescriptionTriage } from "@/api/useTriage"
import { Button, DRAWER_ID_SUFFIX, Flex, message, Tooltip } from "@/atoms"
import { CHECKBOX_ISSUE_DATA_ID } from "@/const/table-constants"
import { isCodeIssueType, type Issue } from "@/domain/issue"
import { getColorBySeverity } from "@/domain/vulnerability"
import { useQueryClient } from "@/hooks/useHttp"
import { useServices } from "@/hooks/useServices"
import { useTranslation } from "@/hooks/useTranslation"
import {
  CheckSquareOutlined,
  ClockCircleOutlined,
  IssueTypeIcon,
  Language
} from "@/icons"
import { CopyLinkButton } from "@/molecules/CopyLinkButton"
import { ContentDismissedTag, IconDismissedTag } from "@/molecules/DismissedTag"
import { DescriptionVulnerableCodeIssue } from "@/molecules/IssueDetails/DescriptionVulnerableCodeIssue"
import { LinkRisk } from "@/molecules/LinkRisk"
import { Protected } from "@/molecules/Protected"
import { Resources } from "@/molecules/Resources"
import { TitleDrawer } from "@/molecules/TitleDrawer"
import { useIssue } from "@/organisms/Drawers/applications/triage/useTriage"
import { OverviewDescriptions } from "@/organisms/Drawers/components/OverviewDescriptions"
import { SkeletonTitle } from "@/organisms/Drawers/components/SkeletonTitle"
import { CreateJiraTicketButton } from "@/organisms/Drawers/components/TriageDrawer/components/CreateJiraTicketButton"
import { useJiraIntegration } from "@/organisms/Drawers/components/TriageDrawer/hooks/useJiraIntegration"
import { useDrawer } from "@/organisms/Drawers/hooks/useDrawer"
import { useLink } from "@/organisms/Drawers/hooks/useLink"
import { JiraIssueModal } from "@/organisms/JiraIssueModal"
import { LabelModal } from "@/organisms/LabelModal"
import { getIssueRequestFilter } from "@/organisms/LabelModal/applications/useLabelsModal"
import {
  useDismissIssues,
  useIssues
} from "@/pages/TriagePage/application/useTriage"
import { DismissModal } from "@/pages/TriagePage/components/molecules/DismissModal"
import { useDismissModal } from "@/pages/TriagePage/hooks/useDismissModal"
import { getIssueHTML } from "./getTicketContent"

import styles from "./TitleTriageDrawer.module.scss"

import type { Fix } from "@/api/useTriage.types"
import type { EntityRecord } from "@/organisms/Drawers/applications/common"

export const TitleTriageDrawer: React.FC<
  EntityRecord & { drawerId: string; previousDrawerId?: string }
> = (props) => {
  const recordId = props.record?.id
  const { response, isLoading } = useIssue(recordId)
  const record = response?.data?.[0]

  const projectDescriptionContext = useProjectDescriptionTriage({
    needle: record?.projectId
  })
  const resources = projectDescriptionContext.response?.data?.resources
  const displayResources = !!resources?.length

  const { hasIntegration } = useJiraIntegration()
  const linkToDrawer = useLink(props.drawerId)

  const { t } = useTranslation()
  const [messageApi, messageContextHolder] = message.useMessage()
  const { auth } = useServices()

  const onOkCallback = (attr?: { reason?: string }) => {
    if (!record) return

    const payload = { [record.projectId]: [record] }

    if (isDismissed) return reopenIssues(payload)

    dismissIssues(payload, attr)
  }

  const queryClient = useQueryClient()
  const [{ dismissIssues, reopenIssues }, context] = useDismissIssues(
    {},
    {
      messageApi,
      showLoadingMessage: true,
      onSuccess: () => queryClient.invalidateQueries(getIssueRequestFilter()),
      componentName: "TitleTriageDrawer"
    },
    null
  )
  const { open, openModal, onOk, onCancel } = useDismissModal({
    onOk: onOkCallback
  })

  const createTicket = useIssues()

  const contextCalculateFixes = useCalculateFixes()

  const [jira, setJira] = useState<null | any>(null)

  const selector = `[${CHECKBOX_ISSUE_DATA_ID}="${record?.id}"]`
  const [isSelected, setIsSelected] = useState<boolean | null>(null)

  const getIssueBySelector = (id?: string) =>
    id
      ? document
          .getElementById(`${id}${DRAWER_ID_SUFFIX}`)
          ?.querySelector(selector)
      : document.querySelector(selector)
  const { previousDrawerId } = props

  useEffect(() => {
    const el = getIssueBySelector(previousDrawerId)

    if (!el) return setIsSelected(null)

    setIsSelected(!!el.closest(".ant-checkbox-wrapper-checked"))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record, selector, previousDrawerId])

  const { onRowClick } = useDrawer("")

  if (isLoading || projectDescriptionContext.isLoading || !record)
    return (
      <Flex vertical justify="center" className={styles.loading}>
        <SkeletonTitle />
      </Flex>
    )

  const { language, cwe, riskId, packageName, issueType, severity, filePath } =
    record
  const { description } = cwe || {}
  const isDismissed = record.status === "dismissed"
  const isProcessing = record.status === "processing"

  const LanguageComponent = (
    <Tooltip title={language || "unknown"}>
      <Language
        noTooltip
        secondary
        language={language}
        className={styles.languageIcon}
      />
    </Tooltip>
  )

  const onCloseModal = () => setJira(null)

  const setJiraTicket = (
    record: Issue,
    description: string,
    summary: string
  ) => {
    if (!record) return setJira(null)

    const converter = new showdown.Converter()

    setJira({
      description: converter.makeHtml(description),
      summary: summary,
      id: record.id
    })
  }

  const title = description || riskId
  const showDismissedPlaceholder = record.status === "dismissed"

  return (
    <>
      {showDismissedPlaceholder && (
        <div className={styles.dismissedSection}>
          <IconDismissedTag className={styles.iconDismissedSection} />
          <ContentDismissedTag
            dismissMethod={record.dismissMethod}
            dismissedBy={record.dismissedBy}
            timestamp={record.dismissedAt}
            reason={record.dismissReason}
          />
        </div>
      )}
      {
        //TODO: need to refactor ContentDismissedTag to be generic and use it
        isProcessing && (
          <div className={styles.dismissedSection}>
            <ClockCircleOutlined className={styles.iconDismissedSection} />
            <div>
              <div
                style={{ fontSize: "var(--md-font-size)", fontWeight: "500" }}
              >
                {t("general.processing")}
              </div>
              <div
                style={{ fontSize: "var(--sm-font-size)", fontWeight: "400" }}
              >
                {t("triage.drawer.issueProcessing")}
              </div>
            </div>
          </div>
        )
      }
      {displayResources && (
        <div className={styles.resourcesWrapper} data-testid="project-title">
          <Resources
            placement="drawer"
            resources={resources}
            t={t}
            onRowClick={onRowClick}
          />
        </div>
      )}
      <TitleDrawer
        style={
          {
            "--icon-background-color-title-drawer":
              getColorBySeverity(severity),
            "--icon-color-color-title-drawer": "var(--neutral-1-color)"
          } as React.CSSProperties
        }
        classes={{ title: styles.title, container: styles.container }}
        icon={<IssueTypeIcon type={issueType} />}
        title={title}
        subTitle={
          isCodeIssueType(issueType) ? (
            <DescriptionVulnerableCodeIssue
              language={language}
              LanguageComponent={LanguageComponent}
              filePath={filePath || ""}
            />
          ) : (
            <div className={styles.subTitle}>
              <OverviewDescriptions.Label>
                <div className={styles.wrapper}>
                  {riskId && (
                    <LinkRisk cve={riskId} classes={{ text: styles.cveLink }} />
                  )}
                  <span>{t("general.onPackage", { str: "" })}</span>
                  {language && LanguageComponent}
                  <span>{packageName}</span>
                </div>
              </OverviewDescriptions.Label>
            </div>
          )
        }
        aside={<CopyLinkButton link={linkToDrawer} />}
      />
      <div className={styles.actionsWrapper}>
        <Protected
          permission={{ resource: "tickets", action: "write" }}
          shouldDisable
        >
          <CreateJiraTicketButton
            id={`${record.id}-create-jira-ticket`}
            sizeBtn="small"
            typeBtn={auth.isDemo() ? "primary" : "secondary"}
            disabled={record.status === "dismissed"}
            t={t}
            hasIntegration={hasIntegration}
            isLoading={contextCalculateFixes.isPending}
            outlined={true}
            onClick={async () => {
              const res = await contextCalculateFixes.mutateAsync({
                issuesIds: [record.id]
              })
              const fixes = res.data as Fix[]
              const attr = { t, linkToDrawer }
              const { description, summary } = getIssueHTML(fixes, attr)

              setJiraTicket(record, description, summary)
            }}
          />
        </Protected>

        {hasIntegration && (
          <JiraIssueModal
            type={jira ? "jira" : undefined}
            jira={jira}
            showLoaderCreateJiraIssueButton={false}
            closeModal={onCloseModal}
            onCreateJiraIssue={createTicket}
          />
        )}
        <Protected
          permission={{ resource: "issues", action: "write" }}
          shouldDisable
        >
          <Button
            icon={<IconDismissedTag />}
            size="small"
            type="secondary"
            className={styles.button}
            disabled={context.isPending}
            onClick={() => (isDismissed ? onOkCallback() : openModal())}
          >
            {isDismissed ? t("general.reopen") : t("general.dismiss")}
          </Button>
        </Protected>
        <Protected
          permission={{ resource: "labels", action: "write" }}
          shouldDisable
        >
          <LabelModal
            btnSize="small"
            btnType="secondary"
            record={record}
            placement="drawer"
            component="triage_drawer"
          />
        </Protected>
        <Button
          disabled={isSelected === null}
          ghost={!!isSelected}
          type={isSelected ? "primary" : "secondary"}
          size="small"
          icon={<CheckSquareOutlined />}
          className={styles.button}
          onClick={() => {
            ;(getIssueBySelector(previousDrawerId) as HTMLInputElement)?.click()

            setIsSelected(!isSelected)
          }}
        >
          {t(`triage.drawer.${isSelected ? "unselect" : "select"}Issue`)}
        </Button>
      </div>
      <DismissModal count={1} open={open} onOk={onOk} onCancel={onCancel} />
      {messageContextHolder}
    </>
  )
}
