import { useEffect, useRef, useState } from "react"

import { Button, message, Modal } from "@/atoms"
import { TAB_KEYS_BY_PAGE } from "@/const/tab.constants"
import { Permission, UserEntity } from "@/domain/user"
import { useLogger } from "@/hooks/useLogger"
import { useOrderBy } from "@/hooks/useOrderBy"
import { useTooltip } from "@/hooks/useTooltip"
import { useTranslation } from "@/hooks/useTranslation"
import { ExclamationCircleOutlined, UserAddOutlined } from "@/icons"
import { DeleteConfirmModal } from "@/molecules/ConfirmModal"
import { Heading } from "@/molecules/Heading"
import { useUsers } from "@/pages/SettingsPage/application/users/useUsers"
import { useActiveKeyTabsUsers } from "@/pages/SettingsPage/hooks/useActiveKeyTabsUsers"
import { TabTemplate } from "@/templates/TabTemplate"
import { VerticalTabTemplate } from "../../templates/VerticalTabTemplate"
import { Filter } from "../Filter"
import { UserModal } from "../UserModal"
import { getColumnsTable } from "./getColumnsTable"

import styles from "./UserTab.module.scss"

import type { ModalFuncProps } from "@/atoms"
import { useUser } from "@/hooks/useUser"
import { Protected } from "@/molecules/Protected"

export const UsersTab = () => {
  const { onSorterChange, ...orderByProps } = useOrderBy()
  const { t } = useTranslation()
  const TAB_KEYS = TAB_KEYS_BY_PAGE.SETTINGS

  const { activeKey } = useActiveKeyTabsUsers()
  const [
    { response, isLoading },
    { loading, deleteUser, resetPassword, sendInviteByEmail, updateMembership }
  ] = useUsers(undefined, {
    enabled: activeKey === TAB_KEYS[0]
  })
  const { data, metadata }: any = response || ({} as any)
  const currentDialog = useRef<any>(null)
  const [modal, modalContextHolder] = Modal.useModal()
  const [userForModal, setUserForModal] = useState<Partial<UserEntity> | null>(
    null
  ) //null is for when the modal is closed
  const [messageApi, messageContextHolder] = message.useMessage()
  const { hasPermission } = useUser()
  const writePermission: Permission = {
    resource: "settings.users",
    action: "write"
  }
  const { logger, EVENTS } = useLogger()

  useEffect(() => {
    currentDialog.current?.update({ okButtonProps: { loading } })
  }, [loading])

  useTooltip(data)

  const showConfirmDialog = ({
    message,
    title,
    okText,
    onOk
  }: ModalFuncProps & {
    message: { success: string; error: string }
    onOk: (...args: any[]) => any
  }) => {
    currentDialog.current = modal.confirm({
      className: styles.confirmDialog,
      icon: <ExclamationCircleOutlined />,
      cancelText: t("general.cancel"),
      title,
      okText,
      okButtonProps: { loading },
      onOk: async (close) => {
        try {
          const { err, data } = await onOk()
          const type = !err ? "success" : "error"
          const content = !err || !data?.detail ? message[type] : data.detail

          messageApi.open({
            type,
            content
          })
        } catch {
          messageApi.open({
            type: "error",
            content: "Error"
          })
        } finally {
          close()
        }
      }
    })
  }

  const columns = useRef(
    getColumnsTable(orderByProps, {
      t,
      hasUsersWritePermissions: hasPermission(writePermission),
      resetOrResendPassword: (record: { email: string }, resend?: boolean) => {
        const { email } = record
        const action = resend ? "resend" : "reset"

        showConfirmDialog({
          title: t(`settings.confirm.${action}.title`, { email }),
          okText: t(`general.${action}`),
          message: {
            error: t(`settings.confirm.resend.error`, { email }),
            success: t(`settings.confirm.resend.success`, { email })
          },
          onOk: async () => {
            if (resend) return await sendInviteByEmail({ userEmail: email })
            else return await resetPassword(email)
          }
        })
      },
      deleteUser: (record: { id: string; email: string }) => {
        const { email } = record

        currentDialog.current = modal.confirm(
          DeleteConfirmModal({
            EVENTS,
            t,
            logger,
            messageAPI: messageApi,
            entityName: "user",
            name: email,
            getModal: () => currentDialog.current,
            onOk: () => deleteUser(record.id)
          })
        )
      },
      editUser: (record: UserEntity) => {
        setUserForModal(record)
      }
    })
  ).current

  return (
    <VerticalTabTemplate
      title={<Heading level={4} title="Users" />}
      button={
        <Protected permission={writePermission} shouldDisable>
          <Button
            icon={<UserAddOutlined />}
            type="primary"
            className={styles.button}
            onClick={() => setUserForModal({})}
          >
            {t("settings.users.actions.inviteUser")}
          </Button>
        </Protected>
      }
    >
      <TabTemplate
        dataSource={data}
        metadata={metadata}
        columns={columns}
        loading={isLoading}
        tabPosition="left"
        locale={{
          dataReadiness: null
        }}
        SearchComponent={<Filter />}
        onChange={(_pagination, _filters, sorter) => {
          onSorterChange(sorter)
        }}
      />
      <UserModal
        onClose={() => setUserForModal(null)}
        currentUser={userForModal}
        actions={{ inviteUser: sendInviteByEmail, editUser: updateMembership }}
      />
      {modalContextHolder}
      {messageContextHolder}
    </VerticalTabTemplate>
  )
}
