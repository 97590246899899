import { Flex, Form, Select } from "@/atoms"
import { EmptyLabels } from "@/icons"
import { CreateActionEmptyTable } from "@/molecules/EmptyTable"
import { LabelTag } from "@/molecules/LabelTag"
import {
  CreateModal,
  LabelButton,
  useCreateLabelModal
} from "@/organisms/LabelModal"

import styles from "./ActionsSectionForm.module.scss"

import type { ActionOption } from "@/api/useWorkflow.types"
import type { FormInstance } from "@/atoms"
import type { QueryObserverBaseResult } from "@/hooks/useHttp.types"
import type { DefaultOptionType } from "antd/es/select"
import type { ActionProps } from "."

interface LabelActionProps extends ActionProps {
  actions: ActionOption[]
  form: FormInstance
  refetch: QueryObserverBaseResult["refetch"]
}

export const LabelAction: React.FC<LabelActionProps> = (
  props: LabelActionProps
) => {
  const { t, selectTitle, actions, form, path } = props
  const rules = [{ required: true, message: t("error.requiredField") }]

  const renderLabel = (option: DefaultOptionType) => {
    const color =
      option.data?.color || actions.find((a) => a.value === option.value)?.color

    return <LabelTag name={option.label as string} color={color || "default"} />
  }

  const [labelState, labelActions] = useCreateLabelModal()
  const emptyProps = actions.length
    ? {
        action: t("settings.labels.create"),
        message: t("settings.labels.modal.empty.noSearchResults")
      }
    : {}

  return (
    <div className={styles.actionExtraFields}>
      <Form.Item
        required={false}
        name={[path, "labels"]}
        className={styles.endpointField}
        label={
          <Flex
            id="anchor-scroll-actions-section"
            align="center"
            justify="space-between"
          >
            <span>{selectTitle}</span>
            <LabelButton
              className={styles.button}
              type="link"
              onClick={labelActions.onCreate}
            />
          </Flex>
        }
        rules={rules}
      >
        <Select
          open={labelState.dropdownOpen}
          mode="multiple"
          placeholder={selectTitle}
          options={actions}
          labelRender={renderLabel}
          optionRender={renderLabel}
          filterOption={(input, option) => {
            if (!option) return false
            return option.label.toLowerCase().includes(input.toLowerCase())
          }}
          notFoundContent={
            <CreateActionEmptyTable
              placement="select"
              tKey="label"
              image={<EmptyLabels />}
              onClick={labelActions.onCreate}
              {...emptyProps}
            />
          }
          searchValue={labelState.searchValue}
          onSearch={labelActions.onSearch}
          onDropdownVisibleChange={labelActions.onDropdownVisibleChange}
        />
      </Form.Item>
      <CreateModal
        open={labelState.open}
        label={labelState.label}
        placement="button"
        component="workflow"
        onCancel={labelActions.onCancel}
        onCreate={async (label) => {
          const { refetch } = props

          await refetch()
          form.setFieldValue(
            ["actions", path, "labels"],
            [
              ...(form.getFieldValue(["actions", path, "labels"]) || []),
              label.id
            ]
          )
          labelActions.onCancel(true)
        }}
      />
    </div>
  )
}
