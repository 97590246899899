import { Breadcrumb, Link } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { Heading } from "@/molecules/Heading"
import { HeaderPage } from "@/organisms/HeaderPage"
import { FormWebhook } from "@/pages/IntegrationsPage/components/molecules/FormWebhook"
import { TestWebhookButton } from "@/pages/IntegrationsPage/components/molecules/TestWebhookButton"
import { useWebhookAPI } from "@/pages/IntegrationsPage/hooks/useWebhookAPI"
import { PageWithBreadcrumbTemplate } from "@/templates/PageWithBreadcrumbTemplate"
import { ConnectIntegrationFooter } from "../ConnectIntegrationPage/ConnectIntegrationFooter"

import styles from "./WebhookIntegrationPage.module.scss"

import { useIntegration } from "@/pages/IntegrationsPage/hooks/useIntegration"

export const WebhookIntegrationPage = () => {
  const { t } = useTranslation()
  const [_, formProps] = useIntegration({ vendor: "webhook" })
  const webhookApi = useWebhookAPI(formProps)
  const title = t("integrations.webhook.title")

  return (
    <PageWithBreadcrumbTemplate
      full
      header={
        <HeaderPage
          title={
            <Breadcrumb
              items={[
                {
                  title: (
                    <Link to="/integrations">
                      {t("navigation.integrations")}
                    </Link>
                  )
                },
                {
                  title: (
                    <Link to={formProps.getBackToIntegrationUrl()}>
                      Webhook
                    </Link>
                  )
                },
                { title }
              ]}
            />
          }
        />
      }
      footer={
        <ConnectIntegrationFooter
          disabled={!formProps.isDirty || webhookApi.isTesting}
          isLoading={webhookApi.isPending}
          okText={t("integrations.webhook.form.buttons.add")}
          okProps={{ form: "webhook-form", htmlType: "submit" }}
          onCancel={() => formProps.backToIntegration()}
        >
          <TestWebhookButton
            tKey="integrations.webhook.form.buttons.test"
            formProps={formProps}
            webhookApi={webhookApi}
          />
        </ConnectIntegrationFooter>
      }
    >
      <div className={styles.title}>
        <Heading level={4} title={title} />
        <Heading level={6} title={t("integrations.webhook.form.title")} />
      </div>
      <FormWebhook {...webhookApi} {...formProps} />
    </PageWithBreadcrumbTemplate>
  )
}
