import { InformationPopover } from "@/molecules/InformationPopover"

import styles from "./HeaderCell.module.scss"

import type { HeaderCellProps } from "."

export const HeaderCell = ({
  title,
  Content,
  placement = "top"
}: HeaderCellProps) =>
  function HeaderCellComponent() {
    return (
      <div className={styles.container}>
        {title}
        <InformationPopover.Popover
          placement={placement}
          classNamePopover={styles.popover}
          content={
            <InformationPopover.CommonTemplate>
              {typeof Content === "string" ? Content : <Content />}
            </InformationPopover.CommonTemplate>
          }
        />
      </div>
    )
  }
