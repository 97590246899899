import { useCreateKey } from "@/api/useAuthKeys"
import { SourceIntegrationType } from "@/api/useAuthKeys.types"
import { useDownloadIntegration } from "@/api/useIntegrations"
import { getKodemCLIExamples } from "@/api/useIntegrations.selector"
import { Button, Carousel, Flex, Modal, Segmented, Steps, Text } from "@/atoms"
import { useLogger } from "@/hooks/useLogger"
import { Trans, useTranslation } from "@/hooks/useTranslation"
import {
  InfoCircleOutlined,
  KeyIcon,
  OSIcon,
  OSPlatform,
  SyncOutlined
} from "@/icons"
import { CopyButton } from "@/molecules/CopyButton"
import { HowToUseTemplate } from "@/pages/IntegrationsPage/components/templates/HowToUseTemplate"
import { CreateKeyModal } from "@/pages/SettingsPage/components/organisms/AuthKeysTab"
import { downloadFileFromUrl } from "@/services/download"
import { useState } from "react"
import { CodeItem } from "../../molecules/CodeItem"
import { HowToUseProps } from "./HowToUseKodemCLI.types"

import styles from "./HowToUseKodemCLI.module.scss"

export const PlatformOptions: OSPlatform[] = [
  "darwin_arm64",
  "darwin_amd64",
  "linux_arm64",
  "linux_amd64",
  "windows_amd64"
]

export const HowToUseKodemCLI = (props: HowToUseProps) => {
  const { logger, EVENTS } = useLogger()
  const {
    OPENED_GENERATE_KEY,
    COMMAND_GENERATE_KEY,
    COMMAND_PLATFORM_SELECTED,
    COMMAND_GENERATE_LINK
  } = EVENTS.ANALYTIC_EVENTS.INTEGRATIONS.KODEMCLI

  const [, modalContextHolder] = Modal.useModal()
  const [showModal, setShowModal] = useState<boolean | number>(false)
  const [selectedPlatform, setSelectedPlatform] =
    useState<OSPlatform>("darwin_arm64")

  const kodemCLIVersion = (props.extraInfo?.version || "latest").split("-")[0]
  const downloadCLIContext = useDownloadIntegration(
    props.name,
    selectedPlatform
  )
  const { response } = downloadCLIContext
  const { downloadUrl } = response?.data || {}

  const { t: tKodemCLI } = useTranslation("translation", {
    keyPrefix: "integrations.connect.kodemCLI"
  })
  const { t: tKodemCLIPlatform } = useTranslation("translation", {
    keyPrefix: "integrations.connect.kodemCLI.platform"
  })

  const downloadCommandPlaceholder = `Generate command for ${tKodemCLIPlatform(selectedPlatform)}`
  const unixDownloadCommand = `sudo curl -o /usr/local/bin/kodem-cli \\
  '${downloadUrl}' && \\
  sudo chmod +x /usr/local/bin/kodem-cli`
  const windowsDownloadCommand = `curl.exe -o kodem-cli.exe "${downloadUrl}"`
  const downloadCommand =
    selectedPlatform === "windows_amd64"
      ? windowsDownloadCommand
      : unixDownloadCommand

  const pullCommand = `docker pull my-image:tag`
  const loginCommand = `kodem-cli auth login --api-key <KODEM_API_KEY>`
  const cloneCommand = `git clone <clone-url> /path/to/<repo>`

  const fetchDownloadUrl = () => {
    logger.info(COMMAND_GENERATE_LINK)
    return downloadCLIContext.refetch()
  }
  const kodemCliExamples = getKodemCLIExamples()

  return (
    <>
      <HowToUseTemplate
        aside={
          <Segmented
            className={styles.segmented}
            defaultValue={selectedPlatform}
            options={PlatformOptions.map((platform) => ({
              value: platform,
              label: tKodemCLIPlatform(platform)
            }))}
            onChange={(value) => {
              logger.info(COMMAND_PLATFORM_SELECTED, { value })
              setSelectedPlatform(value as OSPlatform)
            }}
          />
        }
        {...props}
      >
        <Steps.Step
          status="process"
          title={tKodemCLI("steps.download.title")}
          description={
            <Flex vertical gap={0}>
              <Flex align="center" justify="space-between" gap={0}>
                <Text type="secondary">
                  {tKodemCLI("steps.download.command", {
                    version: kodemCLIVersion
                  })}
                </Text>
                <Button
                  icon={<SyncOutlined />}
                  size="small"
                  type="link"
                  onClick={fetchDownloadUrl}
                >
                  {downloadUrl ? "Refresh URL" : "Generate URL"}
                </Button>
              </Flex>
              <Flex vertical gap={10}>
                <div>
                  <pre className="code-block-step-how-to-use-template-ks">
                    <Text ellipsis>
                      <code>
                        {downloadUrl
                          ? downloadCommand
                          : downloadCommandPlaceholder}
                      </code>
                    </Text>
                    <CopyButton
                      disabled={!downloadUrl}
                      text={downloadCommand}
                      type="link"
                    />
                  </pre>
                  {downloadUrl && (
                    <Text type="secondary" className={styles.noteText}>
                      <InfoCircleOutlined />
                      <span>
                        <Trans>{tKodemCLI("steps.download.note")}</Trans>
                      </span>
                    </Text>
                  )}
                </div>
                <Flex vertical gap={4}>
                  <Text type="secondary">
                    {tKodemCLI("steps.download.direct", {
                      version: kodemCLIVersion
                    })}
                  </Text>
                  <div className="button-wrapper-step-how-to-use-template-ks">
                    <Button
                      type="secondary"
                      className="text-transform-initial-ks"
                      icon={<OSIcon platform={selectedPlatform} />}
                      onClick={async () => {
                        const context = await fetchDownloadUrl()
                        const url = context.data?.data.downloadUrl

                        if (url)
                          downloadFileFromUrl({ url, filename: "kodem-cli" })
                      }}
                    >
                      {tKodemCLI("steps.download.donwloadKey", {
                        platform: tKodemCLIPlatform(selectedPlatform)
                      })}
                    </Button>
                  </div>
                </Flex>
              </Flex>
            </Flex>
          }
        />
        <Steps.Step
          status="process"
          title={tKodemCLI("steps.generateKey.title")}
          description={
            <div className="description-step-how-to-use-template-ks">
              <Text type="secondary">
                {tKodemCLI("steps.generateKey.description")}
              </Text>
              <Flex vertical gap={8}>
                <div className="button-wrapper-step-how-to-use-template-ks">
                  <Button
                    type="secondary"
                    icon={<KeyIcon />}
                    onClick={() => {
                      logger.info(OPENED_GENERATE_KEY)
                      setShowModal(Date.now())
                    }}
                  >
                    {tKodemCLI("steps.generateKey.buttonText")}
                  </Button>
                </div>
                <Flex vertical gap={0}>
                  <Text type="secondary">
                    {tKodemCLI("steps.generateKey.login")}
                  </Text>
                  <pre className="code-block-step-how-to-use-template-ks">
                    <code>{loginCommand}</code>
                    <CopyButton text={loginCommand} type="link" />
                  </pre>
                </Flex>
              </Flex>
            </div>
          }
        />
        <Steps.Step
          status="process"
          title={tKodemCLI("steps.prepare.title")}
          description={
            <Flex vertical gap={12}>
              <CodeItem
                titleLine1={tKodemCLI("steps.prepare.image")}
                codeSnippet={pullCommand}
              />
              <CodeItem
                titleLine1={tKodemCLI("steps.prepare.codeRepoScan")}
                codeSnippet={cloneCommand}
              />
            </Flex>
          }
        />
        <Steps.Step
          status="process"
          title={tKodemCLI("steps.scan.title")}
          description={
            <div className="description-step-how-to-use-template-ks">
              <Text type="secondary">
                <Trans>
                  {tKodemCLI("steps.scan.description", { count: 7 })}
                </Trans>
              </Text>
              <Carousel arrows infinite={false}>
                {kodemCliExamples.map((sample, index) => (
                  <CodeItem
                    key={index}
                    {...sample} // titleLine1, titleLine2, infoLine, codeSnippet, withCopy
                    titleLine1={`<strong>Example ${index + 1}:</strong> ${sample.titleLine1}`}
                    withCopy
                  />
                ))}
              </Carousel>
            </div>
          }
        />
      </HowToUseTemplate>
      <CreateKeyModal
        key={`${showModal}`}
        open={!!showModal}
        useCreateKey={useCreateKey}
        logCreateKey={(keyName) => {
          logger.info(COMMAND_GENERATE_KEY, { keyName })
        }}
        keyType={"API_KEYS"}
        onClose={() => {
          setShowModal(false)
        }}
        sourceIntegration={props.name as SourceIntegrationType}
      />
      {modalContextHolder}
    </>
  )
}
