import { HeaderPage } from "@/organisms/HeaderPage"
import { PageTemplate } from "@/templates/PageTemplate"
import ContentThreatPage from "./ContentThreatPage"

export const IncidentsPage = () => {
  return (
    <PageTemplate full header={<HeaderPage titleTranslationKey="Incidents" />}>
      <ContentThreatPage />
    </PageTemplate>
  )
}
