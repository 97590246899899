import {
  EXPLOITABILITY_FILTER_OPTIONS,
  getFixabilityOptions
} from "@/const/options.constants"
import { RESOURCES_NAMES } from "@/const/resource.constants"
import {
  getIssuesCountMapOrderedSeverity,
  Severity
} from "@/domain/vulnerability"
import {
  DEFAULT_LONG_FORMAT,
  getDateFromSeconds,
  getDateInUserLocale,
  getRelativeTimeFromNow
} from "@/helpers/date.helpers"
import {
  getDismissReasonFilter,
  getHasFunctionEvidenceFilter,
  getIssueTypeFilter,
  getJiraIssueIdFilter,
  getLablesFilter,
  getLanguagesFilter,
  getNotLablesFilter
} from "@/helpers/filters.helper"
import { APPLICATION_DRAWER_ID } from "@/organisms/Drawers/const"
import { INEQUALITY_SYMBOL } from "@/organisms/SmartSearch/const/symbol.constants"
import { getCVEFilter } from "@/pages/DiscoveryPage/utils/filters.utils"

import type {
  IssuesTriageResponse,
  ProjectsTriageResponse
} from "@/api/useTriage.types"
import { getExploitabilityByKeys } from "@/const/insights-constants"
import type { Issue, Project } from "@/domain/issue"
import type { Label } from "@/domain/label"
import { QueryClient } from "@/hooks/useHttp"
import type { TFunction } from "@/hooks/useTranslation"
import type {
  Filters,
  Option
} from "@/organisms/SmartSearch/types/options.types"

export const getDiscoveredDaysAgo = (timestampInSeconds: number) => {
  const date = getDateFromSeconds(timestampInSeconds)

  return {
    timestamp: timestampInSeconds,
    text: getRelativeTimeFromNow(date),
    tooltip: getDateInUserLocale(date, DEFAULT_LONG_FORMAT)
  }
}

export const getProjectsTriageSelector = (response: ProjectsTriageResponse) => {
  const { data, ...restResponseData } = response || {}

  if (!data) return response

  return {
    data: (Array.isArray(data) ? data : [data]).map((item) => {
      const { riskInsights, ...restItem } = item
      const {
        id: key,
        resources,
        issuesSummary,
        discoveredAt,
        relatedApplications
      } = restItem
      const firstResource = resources[0] || {
        name: "",
        sourceControl: "",
        instanceId: "",
        type: ""
      }
      const { sourceControl } = firstResource
      const issuesCounts = getIssuesCountMapOrderedSeverity(issuesSummary)
      const countApps = relatedApplications?.length || 0

      return {
        key,
        sourceControl,
        severity: issuesCounts,
        applicationCount: {
          copyable: true,
          title: "Applications",
          items: (relatedApplications || []).map(
            ({ name, environment, namespace, id }) => ({
              name,
              description: `${environment || "-"}/${namespace || "-"}`,
              drawerEntityId: id,
              drawerId: APPLICATION_DRAWER_ID
            })
          ),
          label: countApps
        },
        discoveredDaysAgo: getDiscoveredDaysAgo(discoveredAt),
        relatedImagesCount: resources.filter(
          ({ type }) => type === "ImageRepository"
        ).length,
        riskInsights: {
          id: `${key}_riskInsights`,
          pocExploitMaturity: riskInsights.exploitMaturities.includes("POC"),
          inTheWildExploitMaturity:
            riskInsights.exploitMaturities.includes("IN_THE_WILD"),
          ...getExploitabilityByKeys(riskInsights.exploitMaturities),
          ...riskInsights
        },
        expandable: issuesCounts.length > 0,
        rowData: { id: key },
        ...restItem
      }
    }),
    ...restResponseData
  }
}

export const getIssuesTriageSelectorCommon = (issues: Issue[]) =>
  (issues || []).map((item) => {
    const {
      discoveredAt,
      riskId,
      relatedApplications,
      packageName,
      riskInsights,
      introducedThroughInsights,
      severity,
      ...rest
    } = item

    const countApps = relatedApplications?.length || 0

    return {
      riskId,
      discoveredAt,
      relatedApplications,
      key: item.id,
      cve: riskId,
      issueTypeVulnerabilityCell: item.issueType,
      packageName: packageName,
      discoveredDaysAgo: getDiscoveredDaysAgo(discoveredAt),
      applicationsCount: {
        copyable: true,
        title: "Applications",
        items: (relatedApplications || []).map(
          ({ name, environment, namespace, id }) => ({
            name,
            description: `${environment || "-"}/${namespace || "-"}`,
            drawerEntityId: id,
            drawerId: APPLICATION_DRAWER_ID
          })
        ),
        label: countApps
      },
      riskInsights: {
        id: `${item.id}_riskInsights`,
        pocExploitMaturity: riskInsights.exploitMaturity === "POC",
        inTheWildExploitMaturity:
          riskInsights.exploitMaturity === "IN_THE_WILD",
        ...getExploitabilityByKeys(riskInsights.exploitMaturity),
        ...riskInsights
      },
      introducedThroughInsights: {
        id: `${item.id}_introducedThroughInsights`,
        allFalse: !Object.keys(introducedThroughInsights).some(
          (key) => !!(introducedThroughInsights as any)[key]
        ),
        ...introducedThroughInsights
      },
      expandable: true,
      severity: severity.toLowerCase() as Severity,
      ...rest
    }
  })

export const getIssuesTriageSelector = (response: IssuesTriageResponse) => {
  const { data = [], ...rest } = response || {}

  return {
    data: getIssuesTriageSelectorCommon(Array.isArray(data) ? data : [data]),
    ...rest
  }
}

export const getIssuesTriageSelectorLoadMore = (response: {
  pages: IssuesTriageResponse[]
}) => {
  const { pages, ...rest } = response

  return {
    pages: getIssuesTriageSelectorCommon(pages.flatMap((page) => page.data)),
    metadata: pages[0]?.metadata,
    ...rest
  }
}

type PropsFilter = { t: TFunction; internetFacingFF?: boolean }

export const getFilters = ({ t, internetFacingFF }: PropsFilter) =>
  [
    getCVEFilter(),
    {
      key: "severity",
      label: t("filters.severity"),
      type: "multiple",
      options: [],
      helper: [
        {
          label: "severity:value1,value2",
          description: "Equals value1 OR value2"
        }
      ]
    },
    getIssueTypeFilter(t),
    {
      key: "packageName",
      label: t("filters.package"),
      type: "input",
      helper: [
        {
          label: "package:value1,value2",
          description: "Matches package names as a substring (e.g. proto,java)"
        }
      ]
    },
    {
      key: "exploitMaturity",
      label: t("filters.exploitability"),
      type: "multiple",
      options: EXPLOITABILITY_FILTER_OPTIONS
    },
    {
      key: "runtime",
      label: t("filters.runtime"),
      type: "single",
      order: 1,
      options: [
        { label: "Yes", value: "YES" },
        { label: "No", value: "NO" },
        { label: "N/A", value: "UNDETERMINED" }
      ]
    },
    {
      key: "ingress",
      label: t("filters.ingress"),
      type: "single",
      order: 3,
      options: [
        { label: "Yes", value: "YES" },
        { label: "No", value: "NO" },
        { label: "N/A", value: "UNDETERMINED" }
      ]
    },
    {
      key: "internetFacing",
      label: t("filters.internetFacing"),
      type: "single",
      order: 2,
      options: [
        { label: "Yes", value: "YES" },
        { label: "No", value: "NO" },
        { label: "N/A", value: "UNDETERMINED" }
      ]
    },
    {
      key: "kodemScore",
      label: t("filters.kodemScore"),
      type: "inputNumber",
      validation: (value: number) =>
        !isNaN(value) && value >= 0 && value <= 1000,
      errorMessage: "Insert value between 0 to 1000",
      helper: [
        { description: "Is greater or equal to a number between 0 - 1000" }
      ]
    },
    {
      key: "fixability",
      label: t("filters.fixability"),
      type: "multiple",
      options: t ? getFixabilityOptions(t) : [],
      helper: [
        {
          label: "fixability:value1,value2",
          description: "Equals value1 OR value2"
        }
      ]
    },
    {
      key: "codeRepository",
      label: t("filters.codeRepository"),
      type: "input",
      helper: [
        {
          label: "code-repository:value1,value2",
          description:
            "Matches Code repository names as a substring (e.g. users-api,my-repo/)"
        }
      ]
    },
    {
      key: "imageId",
      label: t("filters.image"),
      type: "input",
      helper: [
        {
          label: "image:value1,value2",
          description:
            "Matches images name:tag as a substring (e.g. ubuntu,busybox:1.33.1)"
        }
      ]
    },
    {
      key: "applicationName",
      label: t("filters.application"),
      type: "input",
      helper: [
        {
          label: t("filters.helpers.application.label"),
          description: t("filters.helpers.application.description")
        }
      ]
    },
    {
      key: "environment",
      label: t("filters.environment"),
      type: "multiple",
      helper: [
        {
          label: "environment:value1,value2",
          description: "Equals value1 OR value2"
        }
      ],
      options: []
    },
    {
      key: "namespace",
      label: t("filters.namespace"),
      type: "input",
      helper: [
        {
          label: "namespace:value1,value2",
          description: "Matches namespaces as a substring (e.g. users,system)"
        }
      ]
    },
    {
      key: "projectResources",
      label: t("filters.project"),
      type: "input",
      helper: [
        {
          label: "project:value1,value2",
          description:
            "Filter projects that contain resources that match specific substrings (e.g. user,portal)"
        }
      ]
    },
    {
      key: "issueStatus",
      label: t("filters.issueStatus"),
      type: "multiple",
      helper: [
        {
          label: "Issue-Status:value1,value2",
          description: "Equals value1 OR value2"
        }
      ],
      options: [
        { label: "Open", value: "open" },
        { label: "Dismissed", value: "dismissed" }
      ]
    },
    {
      key: "cweId",
      label: t("filters.cweId").toLowerCase(),
      displayLabel: t("filters.cweId"),
      type: "input",
      helper: [
        {
          label: t("filters.helpers.multipleValues", {
            label: t("filters.cweId")
          }),
          description: "Matches multiple cwe identifiers (e.g. cwe-506)"
        }
      ]
    },
    {
      key: "cweDescription",
      label: t("filters.cweName").toLowerCase(),
      displayLabel: t("filters.cweName"),
      type: "input",
      helper: [
        {
          label: t("filters.helpers.multipleValues", {
            label: t("filters.cweName")
          }),
          description: 'Matches multiple cwe name (e.g. "Prototype Pollution")'
        }
      ]
    },
    {
      key: "fromBaseImage",
      label: t("filters.fromBaseImage"),
      order: 6,
      type: "single",
      options: [
        { label: "Yes", value: "YES" },
        { label: "No", value: "NO" },
        { label: "N/A", value: "UNDETERMINED", disabled: true }
      ]
    },
    {
      key: "isDirect",
      label: t("filters.isDirect"),
      order: 4,
      type: "single",
      options: [
        { label: "Yes", value: "YES" },
        { label: "No", value: "NO" },
        { label: "N/A", value: "UNDETERMINED", disabled: true }
      ]
    },
    {
      key: "isIndirect",
      label: t("filters.isIndirect"),
      order: 5,
      type: "single",
      options: [
        { label: "Yes", value: "YES" },
        { label: "No", value: "NO" },
        { label: "N/A", value: "UNDETERMINED", disabled: true }
      ]
    },
    {
      key: "introducedThrough",
      label: t("filters.introducedThrough"),
      type: "input",
      linkedKey: "introducedThrough",
      condition: "AND",
      helper: [
        {
          label: "introduced-through:value1,value2",
          description:
            "Matches introduced through paths which include the provided substrings. Accepts package names, base images and code weakness locations"
        }
      ]
    },
    {
      key: "notIntroducedThrough",
      label: `${INEQUALITY_SYMBOL}${t("filters.introducedThrough")}`,
      type: "input",
      linkedKey: "introducedThrough",
      inequality: true,
      helper: [
        {
          label: "!introduced-through:value1,value2",
          description:
            "Matches introduced through paths which does NOT include the provided substrings. Accepts package names, base images and code weakness locations"
        }
      ]
    },
    {
      key: "owasp",
      label: t("filters.owasp").toLowerCase(),
      displayLabel: t("filters.owasp"),
      type: "multiple",
      helper: [
        {
          label: t("filters.helpers.multipleValues", {
            label: t("filters.owasp")
          }),
          description: "Equals value1 OR value2"
        }
      ],
      options: []
    },
    getLablesFilter(t),
    getNotLablesFilter(t),
    {
      key: "hasLabel",
      label: "has-label",
      type: "single",
      options: [
        { label: "Yes", value: "YES" },
        { label: "No", value: "NO" }
      ]
    },
    {
      key: "hasAction",
      label: t("filters.hasAction"),
      order: 4,
      type: "single",
      options: [
        { label: "Yes", value: "YES" },
        { label: "No", value: "NO" }
      ]
    },
    getJiraIssueIdFilter(t),
    getLanguagesFilter(t),
    getHasFunctionEvidenceFilter(t),
    getDismissReasonFilter(t)
  ].filter((filter) => {
    if (internetFacingFF) return true

    return filter.key !== "internetFacing"
  })

export const getFiltersTriageSelector = (
  response: {
    data: Record<string, (string | Label)[]>
  },
  props: PropsFilter
) => {
  const { data } = response
  return getFilters(props)
    .map((filter) => {
      const key = filter.linkedKey || filter.key
      const currentFilterData = data[key]
      const { options, type } = filter

      if (currentFilterData && !!options) {
        if (Array.isArray(options) && options.length > 0) {
          if (type === "single") {
            filter.options = options.map((value) => ({
              ...value,
              disabled: !currentFilterData.includes(value.value)
            }))

            return filter
          }

          filter.options = (options as Option[]).filter((value) =>
            currentFilterData.includes(value.value)
          )
        } else {
          filter.options = currentFilterData.map((value) => {
            if (typeof value !== "string") {
              const { name, color } = value

              return { color, label: name, value: name }
            }

            let label = value

            if (key === "issueType") {
              label = props.t(`issueTypes.${value}`)
            }

            if (key === "owasp") {
              label = `"${value}"`
            }

            return { label, value }
          })
        }
      }

      return filter
    })
    .reduce(
      (acc, filter) => {
        const key = filter.linkedKey || filter.key

        if (!!acc[key] && !!filter.options) {
          acc[key] = filter.options
        }

        acc.allFilters.push(filter as Filters[number])

        return acc
      },
      {
        allFilters: [],
        severity: [],
        issueType: [],
        fixability: [],
        exploitMaturity: [],
        issueStatus: [],
        label: []
      } as Record<string, Option[]> & { allFilters: Filters }
    )
}

export const projectUpdater = (
  projectId: string,
  queryClient: QueryClient,
  cb: (project: Project) => Project
) => {
  queryClient
    .getQueriesData<ProjectsTriageResponse>({
      queryKey: [RESOURCES_NAMES.TRIAGE.PROJECTS]
    })
    .forEach(([key, response]) => {
      const { data: dataInResponse = [] } = response || {}

      if (!dataInResponse?.length) return

      const index = dataInResponse.findIndex(({ id }) => id === projectId)

      if (index === -1) return

      queryClient.setQueryData<ProjectsTriageResponse | undefined>(
        key,
        (oldData) => {
          try {
            if (!oldData?.data?.length) return oldData

            const { data, ...restOldData } = oldData

            return {
              data: data.map((project, currentIndex) =>
                index === currentIndex ? cb(project) : project
              ),
              ...restOldData
            }
          } catch {
            return oldData
          }
        }
      )
    })
}
