import {
  createContext,
  useContext,
  type Dispatch,
  type SetStateAction
} from "react"

export const DeployCommandValuesContext = createContext<
  [Record<string, string>, Dispatch<SetStateAction<object>>]
>([{}, () => {}])

export const useDeployCommandValues = () => {
  return useContext(DeployCommandValuesContext)
}
