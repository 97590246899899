// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Button, Divider } from "@/atoms"
import {
  BoldOutlined,
  CodeOutlined,
  ItalicOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  OrderedListOutlined,
  StrikethroughOutlined,
  UnderlineOutlined,
  UnorderedListOutlined
} from "@/icons"
import { $isCodeNode, getDefaultCodeLanguage } from "@lexical/code"
import { $isLinkNode } from "@lexical/link"
import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND
} from "@lexical/list"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { $isHeadingNode } from "@lexical/rich-text"
import { $isAtNodeEnd, $isParentElementRTL } from "@lexical/selection"
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils"
import {
  $getSelection,
  $isRangeSelection,
  FORMAT_TEXT_COMMAND,
  INDENT_CONTENT_COMMAND,
  OUTDENT_CONTENT_COMMAND,
  SELECTION_CHANGE_COMMAND
} from "lexical"

import { useCallback, useEffect, useRef, useState } from "react"

import styles from "../JiraIssueModal.module.scss"

const LowPriority = 1

function getSelectedNode(selection) {
  const anchor = selection.anchor
  const focus = selection.focus
  const anchorNode = selection.anchor.getNode()
  const focusNode = selection.focus.getNode()
  if (anchorNode === focusNode) {
    return anchorNode
  }
  const isBackward = selection.isBackward()
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode
  } else {
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode
  }
}

export default function ToolbarPlugin() {
  const [editor] = useLexicalComposerContext()
  const toolbarRef = useRef(null)
  const [blockType, setBlockType] = useState("paragraph")
  const [, setSelectedElementKey] = useState(null)
  const [, setCodeLanguage] = useState("")
  const [, setIsRTL] = useState(false)
  const [, setIsLink] = useState(false)
  const [isBold, setIsBold] = useState(false)
  const [isItalic, setIsItalic] = useState(false)
  const [isUnderline, setIsUnderline] = useState(false)
  const [isStrikethrough, setIsStrikethrough] = useState(false)
  const [isCode, setIsCode] = useState(false)

  const updateToolbar = useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode()
      const element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow()
      const elementKey = element.getKey()
      const elementDOM = editor.getElementByKey(elementKey)
      if (elementDOM !== null) {
        setSelectedElementKey(elementKey)
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode)
          const type = parentList ? parentList.getTag() : element.getTag()
          setBlockType(type)
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType()
          setBlockType(type)

          if ($isCodeNode(element)) {
            setCodeLanguage(element.getLanguage() || getDefaultCodeLanguage())
          }
        }
      }

      setIsBold(selection.hasFormat("bold"))
      setIsItalic(selection.hasFormat("italic"))
      setIsUnderline(selection.hasFormat("underline"))
      setIsStrikethrough(selection.hasFormat("strikethrough"))
      setIsCode(selection.hasFormat("code"))
      setIsRTL($isParentElementRTL(selection))

      const node = getSelectedNode(selection)
      const parent = node.getParent()
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true)
      } else {
        setIsLink(false)
      }
    }
  }, [editor])

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar()
        })
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateToolbar()
          return false
        },
        LowPriority
      )
    )
  }, [editor, updateToolbar])

  return (
    <div className="toolbar" ref={toolbarRef}>
      <div className={styles.buttonsContainerToolbar}>
        <Button
          size="small"
          icon={<BoldOutlined />}
          ghost={isBold}
          type={isBold ? "primary" : "default"}
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold")
          }}
        />
        <Button
          size="small"
          icon={<ItalicOutlined />}
          ghost={isItalic}
          type={isItalic ? "primary" : "default"}
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic")
          }}
        />
        <Button
          size="small"
          icon={<UnderlineOutlined />}
          ghost={isUnderline}
          type={isUnderline ? "primary" : "default"}
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline")
          }}
        />
        <Button
          size="small"
          icon={<StrikethroughOutlined />}
          ghost={isStrikethrough}
          type={isStrikethrough ? "primary" : "default"}
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough")
          }}
        />
        <Button
          size="small"
          icon={<CodeOutlined />}
          ghost={isCode}
          type={isCode ? "primary" : "default"}
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, "code")
          }}
        />
        <Divider type="vertical" />
        <Button
          size="small"
          icon={<OrderedListOutlined />}
          ghost={blockType === "ol"}
          type={blockType === "ol" ? "primary" : "default"}
          onClick={() => {
            if (blockType !== "ol") {
              editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND)
            } else {
              editor.dispatchCommand(REMOVE_LIST_COMMAND)
            }
          }}
        />
        <Button
          size="small"
          icon={<UnorderedListOutlined />}
          ghost={blockType === "ul"}
          type={blockType === "ul" ? "primary" : "default"}
          onClick={() => {
            if (blockType !== "ul") {
              editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND)
            } else {
              editor.dispatchCommand(REMOVE_LIST_COMMAND)
            }
          }}
        />
        <Divider type="vertical" />
        <Button
          size="small"
          icon={<MenuFoldOutlined />}
          type="default"
          onClick={() => {
            editor.dispatchCommand(OUTDENT_CONTENT_COMMAND)
          }}
        />
        <Button
          size="small"
          icon={<MenuUnfoldOutlined />}
          type="default"
          onClick={() => {
            editor.dispatchCommand(INDENT_CONTENT_COMMAND)
          }}
        />
      </div>
    </div>
  )
}
