import type {
  TriggerOptionsWorkflowResponse,
  WorkflowResponse
} from "@/api/useWorkflow.types"
import { ActionWorkflowResponse } from "@/api/useWorkflow.types"

import {
  DEFAULT_LONG_FORMAT,
  getDateInUserLocale,
  getTimestampInMillisecondsFromSecond
} from "@/helpers/date.helpers"

export const getWorkflow = (response: WorkflowResponse) => {
  const { data = [], metadata } = response || {}
  return {
    metadata,
    data: Array.isArray(data)
      ? data.map((workflow) => ({
          key: workflow.id,
          created: { email: workflow.createdBy },
          lastTriggeredDate: !workflow.lastTriggered
            ? ""
            : getDateInUserLocale(
                new Date(
                  getTimestampInMillisecondsFromSecond(workflow.lastTriggered)
                ),
                DEFAULT_LONG_FORMAT
              ),
          ...workflow
        }))
      : [data]
  }
}

export const getTriggerOptionsWorkflow = (
  response: TriggerOptionsWorkflowResponse
) => {
  return response.data.reduce(
    (acc, { disabled, key, displayName, types }) => {
      acc.categories.push({ disabled, label: displayName, value: key })
      acc.types[key] = types?.map(({ key: value }) => value)

      return acc
    },
    { categories: [], types: {} } as {
      categories: { label: string; value: string; disabled: boolean }[]
      types: Record<string, string[]>
    }
  )
}

export const getActionsWorkflow = (response: ActionWorkflowResponse) => {
  const { data } = response

  if (!data?.extra) return []

  switch (data.extra.type) {
    case "webhook":
      return data.extra.webhooks.map(({ id, name }) => ({
        label: name,
        value: id
      }))
    case "label":
      return data.extra.labels.map(({ id, name, color }) => ({
        label: name,
        value: id,
        color: color
      }))
    default:
      return []
  }
}
