import { INTEGRATION_VENDORS } from "@/const/vendors-constants"

import { ConfigurationPageTemplate } from "@/pages/IntegrationsPage/components/templates/ConfigurationPageTemplate"
import { AzureConfigurationTab } from "./AzureConfigurationTab"

export const AzureConfigurationPage = () => (
  <ConfigurationPageTemplate
    vendor={INTEGRATION_VENDORS.azure}
    config={<AzureConfigurationTab />}
    titleKey="integrations.configuration.azure.configurationTitle"
  />
)
