import cn from "classnames"
import { Fragment } from "react"

import { Button, Drawer, Link, Result, Text } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { CloseOutlined, ReloadOutlined } from "@/icons"
import { IconErrorResult } from "./IconErrorResult"

import styles from "./ErrorResult.module.scss"

import type { ErrorResultProps } from "./ErrorResult.types"

export const ErrorResult = (props: ErrorResultProps) => {
  const {
    errorType,
    placement,
    onRefresh = () => window.location.reload(),
    ...rest
  } = props
  const { t } = useTranslation()
  const isDrawer = placement === "DRAWER"
  const isSigninPopup = placement === "SIGNIN_POPUP"
  const drawerProps = { destroyOnClose: true, open: true, width: 700, ...rest }
  const Wrapper = isDrawer ? Drawer : Fragment
  const propsWrapper = isDrawer ? drawerProps : {}
  const replace = (href: string) => {
    window.location.href = href
  }
  const reload = () => replace("/")
  const { onClose = () => replace(window.location.pathname) } = rest
  const keyPrefix = "layout.error."
  const errorKey = errorType?.toLowerCase()
  const isUnknownError = errorType === "Unknown"
  const isSigninError = errorType === "ACL_SIGNIN"
  const showDescription = isUnknownError || isSigninError
  const supportEmail = "support@kodemsecurity.com"
  const showCloseButton = isDrawer || isSigninPopup

  return (
    <Wrapper {...propsWrapper}>
      <Result
        className={cn(
          styles.container,
          isUnknownError && styles.unknownError,
          isSigninPopup && styles.signinPopup
        )}
        icon={<IconErrorResult errorType={errorType} />}
        title={
          <div className={styles.title}>
            {t([`${keyPrefix}${errorKey}`, "Oops! Something went wrong."])}
          </div>
        }
        subTitle={
          <>
            {showDescription && (
              <div className={styles.description}>
                <Text type="secondary">
                  {t([`${keyPrefix}descriptions.${errorKey}.line1`, ""])}
                </Text>
                <Text type="secondary">
                  {t([`${keyPrefix}descriptions.line2`, ""])}
                </Text>
                <Link href={`mailto:${supportEmail}`}>
                  <Text underline type="secondary">
                    {supportEmail}
                  </Text>
                </Link>
              </div>
            )}
            {showCloseButton && (
              <Button
                ghost
                size={isSigninPopup ? "small" : "middle"}
                type="primary"
                icon={<CloseOutlined />}
                onClick={onClose}
              >
                {t("general.close")}
              </Button>
            )}
            {!showCloseButton && isUnknownError && (
              <Button
                ghost
                type="primary"
                icon={<ReloadOutlined />}
                onClick={onRefresh}
              >
                {t("general.refresh")}
              </Button>
            )}
            {!showCloseButton && !isUnknownError && (
              <Button type="link" onClick={reload}>
                {`${t(`${keyPrefix}back`)} ${t("navigation.dashboard")}`}
              </Button>
            )}
          </>
        }
      />
    </Wrapper>
  )
}
