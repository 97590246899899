import { RefObject } from "react"

type SpanList = NodeListOf<HTMLSpanElement> | []

export const getSpanList = (
  refInput: RefObject<HTMLElement>,
  selector = "span"
): SpanList => refInput?.current?.querySelectorAll(selector) || []

export const resetStyleForMinimizeSpanList = (
  refInput: RefObject<HTMLElement>
) => {
  getSpanList(refInput).forEach((span) => {
    span.style.display = ""
    span.style.width = ""
  })
}

export const iterator = (
  spans: SpanList,
  props: {
    widthInput: number
    hideElements?: boolean
    widthOfElementsBeforeLastOne?: number
    countsHiddenElements?: number
  }
) => {
  const { widthInput } = props
  let {
    hideElements = false,
    widthOfElementsBeforeLastOne = 0,
    countsHiddenElements = spans.length
  } = props

  spans.forEach((span) => {
    if (hideElements) {
      span.style.display = "none"

      return
    }

    const { width } = span.getBoundingClientRect()

    if (widthOfElementsBeforeLastOne + width < widthInput) {
      widthOfElementsBeforeLastOne += width
      countsHiddenElements -= 1
    } else {
      const elements = span.querySelectorAll("span")
      const isTagLevel = elements.length === 0

      if (isTagLevel) {
        if (span.parentElement) {
          span.parentElement.style.display = "inline-flex"
        }

        span.style.display = "inline-block"
        span.style.width = `${widthInput - widthOfElementsBeforeLastOne}px`
        hideElements = true
      } else {
        const result = iterator(elements, {
          widthOfElementsBeforeLastOne,
          countsHiddenElements,
          hideElements,
          widthInput: props.widthInput
        })

        hideElements = result.hideElements
        countsHiddenElements = result.countsHiddenElements
      }
    }
  })

  return { countsHiddenElements, hideElements }
}

export const getInputWidth = (refInput: RefObject<HTMLElement>) => {
  if (!refInput?.current) return 0

  const inputBoundRect = refInput.current.getBoundingClientRect()
  const paddingFromRightSide = 100

  return inputBoundRect.width - paddingFromRightSide
}

export const setStyleForMinimizeSpanList = (
  refInput: RefObject<HTMLElement>
) => {
  if (!refInput) return { countsHiddenElements: 0 }

  return iterator(
    getSpanList(refInput, "span[data-smart-search='container']"),
    { widthInput: getInputWidth(refInput) }
  )
}
