import { useTranslation } from "@/hooks/useTranslation"
import { IncludeExcludeField } from "./IncludeExcludeField"

import styles from "./CardField.module.scss"

import type { CardFieldProps } from "."

export const TitleCardField: React.FC<CardFieldProps> = (props) => {
  const { t } = useTranslation()
  const {
    action,
    title = `${t("general.condition")} ${Number(props.index) + 1}`,
    ...rest
  } = props

  return (
    <div className={styles.titleContainer}>
      <span className={styles.title}>{title}</span>
      {action === "showIncludeExclude" && <IncludeExcludeField {...rest} />}
    </div>
  )
}
