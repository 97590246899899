import { TFunction } from "@/hooks/useTranslation"
import { ActionsCell } from "@/molecules/ActionsCell"

export const KeyActionsCell = ({
  deleteKey,
  tAuthKeys
}: {
  deleteKey: any
  tAuthKeys: TFunction
}) =>
  function KeyActionsCellComponent(_: unknown, record: unknown) {
    return (
      <ActionsCell
        items={[
          {
            key: "delete",
            label: tAuthKeys("actions.delete")
          }
        ]}
        onClick={({ key }) => {
          if (key === "delete") {
            return deleteKey(record)
          }
        }}
      />
    )
  }
