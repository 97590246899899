import { INTEGRATION_VENDORS } from "@/const/vendors-constants"
import { ConfigurationPageTemplate } from "@/pages/IntegrationsPage/components/templates/ConfigurationPageTemplate"
import { GitLabConfigurationTab } from "./GitLabConfigurationTab"

export const GitLabConfigurationPage = () => (
  <ConfigurationPageTemplate
    vendor={INTEGRATION_VENDORS.gitlab}
    config={<GitLabConfigurationTab vendor={INTEGRATION_VENDORS.gitlab} />}
    titleKey="integrations.connect.gitlab.titleGroup"
  />
)
