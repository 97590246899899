import { useRef } from "react"

import { App } from "@/atoms"
import { useLogger } from "@/hooks/useLogger"
import { useTranslation } from "@/hooks/useTranslation"

import type { ModalFunc, ModalFuncProps } from "@/atoms"
import type { GetModalProps } from "."

export const useConfirmModal = () => {
  const { modal, message } = App.useApp()
  const { t } = useTranslation()
  const { logger, EVENTS } = useLogger()
  const currentDialog = useRef<ReturnType<ModalFunc> | null>(null)
  const getModal = () => currentDialog.current

  return {
    currentDialog,
    info: modal.info,
    confirm: (getModalProps: (props: GetModalProps) => ModalFuncProps) => {
      currentDialog.current = modal.confirm(
        getModalProps({ getModal, EVENTS, messageAPI: message, logger, t })
      )
    }
  }
}
