import { EVENTS } from "@/const/event.constants"
import { NAMES_ROUTES } from "@/const/routes.constants"
import { useTranslation } from "@/hooks/useTranslation"
import { HeaderPage } from "@/organisms/HeaderPage"
import { PageTemplate } from "@/templates/PageTemplate"
import { ScmTab } from "../../organisms/ScmTab"

export const SastPage = () => {
  const { t } = useTranslation()

  return (
    <PageTemplate
      full
      header={<HeaderPage titleTranslationKey={t("settings.sast.title")} />}
    >
      <ScmTab
        policyType="SCM"
        policyCategory="Code"
        settingsRoute={NAMES_ROUTES.GOVERNANCE.SAST}
        analyticsEvents={EVENTS.ANALYTIC_EVENTS.SETTINGS.POLICIES.SAST}
      />
    </PageTemplate>
  )
}
