import cn from "classnames"

import { Badge, Button, Col, Divider, Flex, Row, Text } from "@/atoms"
import { preventEventBubbling } from "@/helpers/utils"
import { SaveOutlined } from "@/icons"
import { ActionsDropdown } from "@/molecules/ActionsDropdown"
import { useConfirmModal } from "@/molecules/ConfirmModal"
import { Heading } from "@/molecules/Heading"
import { ModulesTree } from "@/molecules/ModulesTree/ModulesTree"
import { ROOT_KEY } from "@/molecules/ModulesTree/ModulesTree.types"
import { Loader } from "@/pages/SettingsPage/components/molecules/Loader"
import { VerticalTabTemplate } from "@/pages/SettingsPage/components/templates/VerticalTabTemplate"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { EmptyPath } from "./EmptyPath"
import { MonorepoConfigurationsForm } from "./ModuleConfigurationsForm/MonorepoConfigurationsForm"

import styles from "./MonorepoConfigurations.module.scss"

import type {
  Configs,
  MonorepoConfigurationsProps
} from "./MonorepoConfigurations.types"

type Node = {
  key: string
  children?: Node[]
}

function getAllKeys(node: Node): string[] {
  // This will store all the keys
  const keys: string[] = []

  // A recursive function to traverse the tree
  function traverse(currentNode: Node): void {
    // Add the current node's key to the keys array
    keys.push(currentNode.key)
    if (!currentNode.children) return
    // Recurse over each of the children nodes
    for (const child of currentNode.children) {
      traverse(child)
    }
  }

  // Start the traversal from the input node
  traverse(node)

  // Return the collected keys
  return keys
}

function getAllAvailablePaths(paths: string[]): string[] {
  const result: Set<string> = new Set()

  paths.forEach((path) => {
    const components = path.split("/")
    let currentPath = ""

    components.forEach((component, index) => {
      currentPath += component
      result.add(currentPath)

      // Add separator for next component if it's not the last one
      if (index < components.length - 1) {
        currentPath += "/"
      }
    })
  })

  return Array.from(result)
}

export function MonorepoConfigurations(props: MonorepoConfigurationsProps) {
  const {
    record,
    configLoading = false,
    modules = [],
    repoName,
    scm = "OnPrem",
    configs,
    onSave,
    onReset,
    loading = false
  } = props
  const normalizedModules = useMemo(() => {
    return modules.map((module) => ({
      ...module,
      path: module.path.replace(/^\//, "")
    }))
  }, [modules])
  const [selected, setSelected] = useState<string>("")
  const [highlights, setHighlights] = useState<string[]>([selected])
  const [currentConfigs, setCurrentConfigs] = useState<Configs>(configs || {})
  const { confirm } = useConfirmModal()
  const { t } = useTranslation()
  const keyPrefix = "inventory.codeRepositoriesTab.drawer"
  const { t: tConfig } = useTranslation("translation", {
    keyPrefix: `${keyPrefix}.config`
  })
  const edited = useMemo(() => {
    return Object.entries(configs || {})
      .filter(
        ([_, conf]) => conf.splitPath || conf.imageRepositories.length > 0
      )
      .map(([path, _]) => path)
  }, [configs])

  useEffect(() => {
    if (!loading) setCurrentConfigs(configs || {})
  }, [configs, loading])

  const formatProjectName = (selected: string) => {
    if (selected === ROOT_KEY) return repoName
    if (selected.startsWith("/")) return `${repoName}${selected}`
    return `${repoName}/${selected}`
  }

  const selectedConfigs = currentConfigs[
    selected === ROOT_KEY ? "" : selected!
  ] || {
    imageRepositories: [],
    exclude: false,
    splitPath: false,
    projectName: formatProjectName(selected)
  }

  const handleSave = () => {
    const onlyNoneEmpty = Object.fromEntries(
      Object.entries(currentConfigs).filter(
        ([_, value]) =>
          value.splitPath || value.exclude || value.imageRepositories.length > 0
      )
    )
    onSave(onlyNoneEmpty)
  }

  const isFile = (path: string) => {
    return !!normalizedModules.find((module) => module.path === path)
  }

  const dirname = (path: string) => {
    return path.split("/").slice(0, -1).join("/").replace(/^\/+/, "")
  }

  const allAdjacentModules = (path: string) => {
    const dir = dirname(path)
    const inSameDir = normalizedModules
      .filter((module) => module.path.startsWith(dir))
      .map((module) => module.path)
    return getAllAvailablePaths(inSameDir).filter((p) => p.startsWith(dir))
  }

  /**
   * Reset the configurations of the selected module by removing it from the currentConfigs
   * and calling save
   */
  const handleResetSelected = () => {
    if (!selected) return

    if (selected === ROOT_KEY) {
      return onReset()
    }

    const { [selected]: _, ...rest } = currentConfigs

    return onSave(rest)
  }

  const contentPopover = tConfig("currentRepositoryDescription")
  const disabledFooterButtons = currentConfigs === configs

  return (
    <VerticalTabTemplate
      placement="drawer"
      title={
        <Flex justify="space-between" align="center" className={styles.title}>
          <Flex flex={1} vertical gap={4}>
            <Heading
              level={5}
              title="Repository Tree"
              popover={{ content: contentPopover }}
            />
            <Text type="secondary">{`${t(`${keyPrefix}.overview.lastSeen`)}: ${record.lastSeen}`}</Text>
          </Flex>
          <div
            onClick={preventEventBubbling}
            data-testid="monorepo-configurations-actions-cell"
          >
            <ActionsDropdown
              size="large"
              menu={{
                items: [
                  {
                    disabled: props.isEmptyConfig,
                    key: "resetAll",
                    label: tConfig("resetAll"),
                    onClick: () => {
                      confirm(() => ({
                        width: 500,
                        title: tConfig("resetModalTitle"),
                        content: tConfig("resetModalContent"),
                        okText: tConfig("resetModalConfirmLabel"),
                        okButtonProps: {
                          style: { textTransform: "none" }
                        },
                        onOk: () => onReset()
                      }))
                    }
                  },
                  {
                    disabled: props.isEmptyConfig,
                    key: "resetSelected",
                    label: tConfig("resetSelected"),
                    onClick: () => {
                      confirm(() => ({
                        width: 500,
                        title: tConfig("resetModalTitle"),
                        content: tConfig("resetModalContent"),
                        okText: tConfig("resetModalConfirmLabel"),
                        okButtonProps: {
                          style: { textTransform: "none" }
                        },
                        onOk: handleResetSelected
                      }))
                    }
                  }
                ]
              }}
            />
          </div>
        </Flex>
      }
      footer={
        <Flex className={styles.footer} justify="space-between" align="center">
          <Flex flex={1} align="center" gap={8}>
            <Badge color="#40A9FF" size="small" /> {tConfig("edited")}
          </Flex>
          <Flex gap={8}>
            <Button
              disabled={disabledFooterButtons || loading}
              onClick={() => {
                setCurrentConfigs(configs || {})
                setHighlights([])
                setSelected("")
              }}
            >
              {t("general.cancel")}
            </Button>
            <Button
              disabled={disabledFooterButtons}
              loading={loading}
              onClick={() => {
                confirm(() => ({
                  width: 570,
                  title: tConfig("saveModalTitle"),
                  content: (
                    <div>
                      {tConfig("saveModalContent")}
                      <ul className={styles.listContentConfirm}>
                        <li>{tConfig("firstSaveModalContent")}</li>
                        <li>{tConfig("secondSaveModalContent")}</li>
                      </ul>
                    </div>
                  ),
                  okText: tConfig("saveModalConfirmLabel"),
                  okButtonProps: { style: { textTransform: "none" } },
                  onOk: () => {
                    handleSave()
                  }
                }))
              }}
              type="primary"
              icon={<SaveOutlined />}
            >
              {tConfig("saveModalConfirmLabel")}
            </Button>
          </Flex>
        </Flex>
      }
    >
      <Divider className={styles.divider} />
      <Row className={styles.row}>
        <Col span={10} className={styles.itemWrapper}>
          <div className={styles.scrollable}>
            <ModulesTree
              repo={{ name: repoName, scm }}
              selectedKeys={highlights}
              onSelect={(_, e) => {
                const nodeKey = e.node.key.toString()
                if (nodeKey.length === 0) return
                if (isFile(nodeKey)) {
                  const dir = dirname(nodeKey)
                  if (dir === "") {
                    setSelected(ROOT_KEY)
                    setHighlights([ROOT_KEY])
                    return
                  }
                  setSelected(dir === "" ? ROOT_KEY : dir)
                  setHighlights([
                    dir === "" ? ROOT_KEY : dir,
                    ...allAdjacentModules(nodeKey)
                  ])
                  return
                }
                setSelected(nodeKey)
                setHighlights(
                  nodeKey === ROOT_KEY ? [ROOT_KEY] : getAllKeys(e.node)
                )
              }}
              // directoriesOnly
              modules={normalizedModules}
              defaultExpandAll
              edited={edited}
            />
          </div>
        </Col>
        <Col
          span={14}
          className={cn(
            styles.itemWrapper,
            !selected && styles.emptyItemWrapper
          )}
        >
          {configLoading && <Loader />}
          {!configLoading && (
            <>
              {!!selected && (
                <MonorepoConfigurationsForm
                  isRootSelected={selected === ROOT_KEY}
                  onChange={(v) => {
                    if (!selected) return
                    setCurrentConfigs((c) => ({
                      ...c,
                      [selected === ROOT_KEY ? "" : selected]: v
                    }))
                  }}
                  values={selectedConfigs}
                />
              )}
              {!selected && <EmptyPath />}
            </>
          )}
        </Col>
      </Row>
    </VerticalTabTemplate>
  )
}
