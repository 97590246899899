import { useEffect, useState } from "react"

import { Flex, Skeleton, Text } from "@/atoms"
import { RESOURCES_NAMES } from "@/const/resource.constants"
import { Trans, useTranslation } from "@/hooks/useTranslation"

import styles from "./ContentConfirmDelete.module.scss"

import type { ContentConfirmModalComponent } from "."

export const ContentConfirmDelete: ContentConfirmModalComponent = ({
  record,
  http
}) => {
  const [count, setCount] = useState<number | undefined>()
  const { t } = useTranslation("translation", {
    keyPrefix: "settings.labels.message.delete"
  })

  const fn = async () => {
    try {
      const result = await http.get<{ data: number }>(
        `${RESOURCES_NAMES.TRIAGE.ISSUES_COUNT}?label=${record.name}`,
        {}
      )

      document
        .querySelector(`[data-footer-actions-label="${record.id}"]`)
        ?.classList?.remove?.(styles.footer)

      setCount(result.data)
    } catch {
      setCount(undefined)
    }
  }

  useEffect(() => {
    fn()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (count === undefined) {
    return (
      <Flex vertical gap={8}>
        <Skeleton paragraph={{ rows: 2 }} className={styles.skeleton} />
      </Flex>
    )
  }

  return (
    <div>
      <Text>
        <Trans>{t("entity", { count })}</Trans>
      </Text>
      <br />
      <Text>
        <Trans>{t("guard")}</Trans>
      </Text>
    </div>
  )
}
