import type {
  Incident,
  IncidentsHistoryResponse,
  IncidentsListResponse
} from "@/api/useIncidents.types"

export const getIncidents = (response: IncidentsListResponse) => {
  const { data, ...rest } = response

  return {
    data: data.map((incident: Incident) => {
      const binaryName =
        incident.attackProc.binaryExecutePath.split("/").pop() ||
        incident.attackProc.binaryExecutePath
      const commandLine = `${binaryName} ${incident.attackProc.callerProcessArguments}`

      return {
        key: incident.id,
        injectedCall: `${incident.evidence.frames[0]}('${commandLine}')`,
        ...incident
      }
    }),
    ...rest
  }
}

export const getIncidentsHistory = (response: IncidentsHistoryResponse) => {
  const { data, ...rest } = response
  return { data, ...rest }
}
