import type { Resource } from "."

export const defaultSortResourcesByType = (resources: Resource[]) =>
  resources.sort((a) => (a.type === "CodeRepository" ? -1 : 1))

export const isCodeRepoResource = (resource: Resource) =>
  resource?.type === "CodeRepository"

export const isImageResource = (resource: Resource) => {
  if (!resource) return false

  const { type } = resource

  return type === "Image" || type === "ImageRepository"
}

export const getImageNameWithRegistry = (
  resource: Pick<Resource, "registry">,
  name: string
) => `${resource.registry ? `${resource.registry}/` : ""}${name}`
