import { Link } from "@/atoms"
import { ExternalLinkOutlined, LinkOutlined } from "@/icons"
import type { ExternalLinkIconProps } from "."

export const ExternalLinkIcon = (props: ExternalLinkIconProps) => {
  const { to, href, text } = props
  const target = href ? props.target || "_blank" : undefined

  return (
    <>
      {text && <span>{text}&nbsp;</span>}
      <Link href={href} to={to} target={target}>
        {to ? <LinkOutlined /> : <ExternalLinkOutlined />}
      </Link>
    </>
  )
}
