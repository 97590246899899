import cn from "classnames"

import { Table } from "@/atoms"
import { useTooltip } from "@/hooks/useTooltip"

import styles from "./ExpandedRowTable.module.scss"

import type { TableProps } from "./ExpandedRowTable.types"

export const ExpandedRowTable = <T extends object>({
  initialMinHeight,
  ...props
}: TableProps<T> & { initialMinHeight?: boolean }) => {
  useTooltip(props.dataSource)

  return (
    <Table
      className={cn(
        styles.table,
        initialMinHeight && styles.initialMinimumHeight,
        !!props.footer && styles.withFooter
      )}
      pagination={false}
      showHeader={false}
      {...props}
    />
  )
}
