import { preventDefault } from "@/helpers/utils"
import { ActionsCell } from "@/molecules/ActionsCell"

import type { AutomatedActionsCellComponent } from "."

export const AutomatedActionsCell: AutomatedActionsCellComponent = (props) => {
  const { noPermissions, t, createDuplicate, onEdit, onDelete } = props
  const editKey = "general.edit"
  const duplicateKey = "general.duplicate"
  const runKey = "settings.automatedActions.table.actions.run"
  const deleteKey = "general.delete"
  const items = [
    { key: editKey, label: t(editKey) },
    { key: duplicateKey, label: t(duplicateKey) },
    { key: runKey, label: t(runKey), disabled: true },
    { key: deleteKey, label: t(deleteKey) }
  ]

  return function AutomatedActionsCellComponent(record) {
    return (
      <ActionsCell
        noPermissions={noPermissions}
        disabled={noPermissions}
        items={items}
        onClick={({ key, domEvent }) => {
          preventDefault(domEvent)

          if (key === editKey) {
            onEdit(record)
          }

          if (key === duplicateKey) {
            createDuplicate(record)
          }

          if (key === deleteKey) {
            onDelete(record)
          }
        }}
      />
    )
  }
}
