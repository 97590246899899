import { useTranslation } from "@/hooks/useTranslation"
import { List } from "@/pages/DiscoveryPage/components/molecules/List"

import { EnterKey, HierarchyLineIcon } from "@/icons"

import { BaseImageDetailsCell } from "@/molecules/BaseImageDetailsCell"
import cn from "classnames"
import {
  getCommandsHistoryTitle,
  HistoryCommandsList
} from "../HistoryCommandsList/HistoryCommandsList"
import styles from "./BaseImagesHierarchy.module.scss"
import { BaseImagesHierarchyProps } from "./BaseImagesHierarchy.types"

export const BaseImagesHierarchy: React.FC<BaseImagesHierarchyProps> = (
  props
) => {
  const { introducedThrough, tKey } = props
  const { t } = useTranslation()

  if (!introducedThrough?.length) {
    return null
  }

  return (
    <List
      classes={{ button: styles.buttonWrapper }}
      showMoreIcon={<EnterKey />}
      showMoreTitle={`${t(`${tKey}showBaseImageHierarchy`)}`}
      showLessTitle={`${t(`${tKey}hideBaseImageHierarchy`)}`}
      limit={1}
      items={introducedThrough}
      render={(image, i) => {
        if (!image.baseImageDetails || !image.baseImageDetails.baseImageDigest)
          return null
        const isFirst = i === 0
        const marginLeft = i <= 1 ? 0 : (i - 1) * 12 + "px"
        const lineMarginLeft = i <= 1 ? 6 : (i - 1) * 6 + "px"
        return (
          <div
            className={styles.baseImageComponent}
            style={{ marginLeft: marginLeft }}
          >
            {!isFirst && (
              <span
                className={styles.hierarchyLine}
                style={{ marginLeft: lineMarginLeft }}
              >
                <HierarchyLineIcon />
              </span>
            )}
            <div
              className={cn(
                "container-items-issue-expanded-cell-component-issue-tab",
                styles.baseImageCell
              )}
            >
              <BaseImageDetailsCell
                digest={image.baseImageDetails.baseImageDigest}
                items={image.baseImageDetails.baseImageNames}
              />
              <HistoryCommandsList
                historyEntries={image.historyEntries}
                tKey={tKey}
                t={t}
                title={getCommandsHistoryTitle(tKey, t, image.historyEntries)}
              />
            </div>
          </div>
        )
      }}
    />
  )
}
