import { Button, Dropdown } from "@/atoms"
import { preventEventBubbling } from "@/helpers/utils"
import { CloseCircleOutlined, MailOutlined, StopOutlined } from "@/icons"

import styles from "./Buttons.module.scss"

import type { Incident } from "@/api/useIncidents.types"
import type { Attr } from "../ThreatTab"

// Dismiss button is enabled, notify button is enabled, block button is still disabled
export const Buttons = ({
  record,
  onClose,
  isDeleting,
  dismissIncident
}: {
  record: Incident
  onClose?: () => void
} & Partial<Attr>) => {
  const blockItems = [
    { key: "1", label: "Kill Process" },
    { key: "2", label: "Kill Container" },
    { key: "3", label: "Kill Pod" }
  ]
  const dismissItems = [
    { key: "dismiss", label: "Dismiss the Incident" },
    { key: "2", label: "Dismiss and Remove This Flow in Policy" }
  ]

  return (
    <div className={styles.container} onClick={preventEventBubbling}>
      <Dropdown
        menu={{
          items: dismissItems,
          onClick: ({ key }) => {
            if (key === "dismiss") {
              // Call the dismiss incident function
              dismissIncident?.(record.id)
                .then(() => {
                  // Close the drawer after successful dismissal
                  onClose?.()
                })
                .catch(() => {
                  // Error is already handled in the hook
                })
            }
            // The "Dismiss and Remove This Flow in Policy" option does nothing
          }
        }}
      >
        <Button
          type="secondary"
          size="small"
          icon={<CloseCircleOutlined />}
          loading={isDeleting}
        >
          Dismiss
        </Button>
      </Dropdown>
      <Dropdown menu={{ items: blockItems }} disabled>
        <Button disabled type="secondary" size="small" icon={<StopOutlined />}>
          Block
        </Button>
      </Dropdown>
      <Button
        type="secondary"
        size="small"
        icon={<MailOutlined />}
        onClick={() => {
          // No action, just a click that does nothing
        }}
      >
        Notify
      </Button>
    </div>
  )
}
