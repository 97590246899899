import { TAB_KEYS_BY_PAGE } from "@/const/tab.constants"
import {
  ALL_RESOURCES,
  RESOURCES_INITIAL_VALUE,
  SPECIFIC_RESOURCES
} from "@/organisms/Form"
import { useScope } from "@/pages/SettingsPage/application/scopes/useScopes"

export const getInitialValues = (
  scope: ReturnType<typeof useScope>["data"]
) => {
  const specificResourceFieldName = `${SPECIFIC_RESOURCES}_resources`
  const initialValue = [RESOURCES_INITIAL_VALUE]

  if (!scope)
    return {
      id: undefined,
      [specificResourceFieldName]: initialValue
    }

  const { name, description, conditions, ...rest } = scope

  return {
    name_details: name,
    description_details: description,
    resources: scope.isAllResources ? ALL_RESOURCES : SPECIFIC_RESOURCES,
    [specificResourceFieldName]: scope.isAllResources
      ? initialValue
      : conditions,
    preview: {
      activeKey: TAB_KEYS_BY_PAGE.INVENTORY[0],
      dirty: false,
      open: false
    },
    ...rest
  }
}
