import { Flex, Form } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { ThunderboltOutlined } from "@/icons"
import { useActionsOptionsWorkflow } from "@/pages/GovernancePage/applications/workflow/useWorkflow"
import { ButtonListField } from "@/pages/GovernancePage/components/molecules/ButtonListField"
import { SectionFormTemplate } from "@/pages/SettingsPage/components/templates/SectionFormTemplate"
import { ActionCard } from "./ActionCard"

import { ActionsSectionFormProps } from "./ActionsSectionForm.types"

export const ActionsSectionForm: React.FC<ActionsSectionFormProps> = ({
  disabled,
  form,
  triggerCategory,
  triggerType
}) => {
  const { t } = useTranslation()
  const keyPrefix = "settings.automatedActions.form"

  const { response: optionsResponse } = useActionsOptionsWorkflow(
    { trigger_type: triggerType, trigger_category: triggerCategory },
    { enabled: !disabled }
  )
  const { data: options = [] } = optionsResponse || {}

  const actions = (Form.useWatch("actions", form) as { type: string }[]) || []

  const actionsTypes = actions.reduce(
    (acc, { type }) => {
      acc[type] = true
      return acc
    },
    {} as Record<string, boolean>
  )

  let allOptionsDisabled = true

  const availableOptions = options.reduce(
    (acc, { disabled, ...option }) => {
      const nextDisabled = disabled || !!actionsTypes[option.key]

      acc.push({ disabled: nextDisabled, ...option })

      if (allOptionsDisabled && !nextDisabled) allOptionsDisabled = false

      return acc
    },
    [] as { disabled: boolean }[]
  )

  const selectNoAction = actions.length === 0
  const maxActionNumer = actions.length == options.length
  return (
    <SectionFormTemplate
      withMargin
      id="actions"
      title={t(`${keyPrefix}.sections.actions`)}
      Icon={ThunderboltOutlined}
    >
      <Form.List
        name="actions"
        initialValue={[{ type: undefined, endpoint: undefined }]}
      >
        {(fields, { add, remove }) => (
          <Flex vertical gap={16}>
            {fields.map((field, index) => (
              <ActionCard
                key={field.key}
                field={field}
                form={form}
                options={availableOptions}
                disabled={disabled}
                t={t}
                onRemove={fields.length > 1 ? () => remove(index) : undefined}
                keyPrefix={keyPrefix}
                index={index}
              />
            ))}
            {
              <ButtonListField
                type="and"
                onClick={() => add({ type: undefined, endpoint: undefined })}
                disabled={
                  allOptionsDisabled || selectNoAction || maxActionNumer
                }
              >
                {t(`${keyPrefix}.fields.action.add`)}
              </ButtonListField>
            }
          </Flex>
        )}
      </Form.List>
    </SectionFormTemplate>
  )
}
