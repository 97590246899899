import { useTranslation } from "@/hooks/useTranslation"
import { ActionsOverTimeTooltipProps } from "."
import { ActionTypeIcon } from "../../../molecules/ActionTypeIcon"
import styles from "./ActionsOverTimeTooltip.module.scss"

export const ActionsOverTimeTooltip = (
  props: ActionsOverTimeTooltipProps
): React.ReactNode => {
  const { actions } = props
  const { t } = useTranslation("translation", {
    keyPrefix: "settings.automatedActions.widgets.actions"
  })

  return (
    actions?.length > 0 && (
      <div className={styles.actions}>
        <span className={styles.periodTitle}> {actions[0].year}</span>
        {actions.map((item) => {
          const { valueTooltip, type } = item

          return (
            <div key={type} className={styles.action}>
              <ActionTypeIcon type={type} />
              <span className={styles.count}> {`${valueTooltip} `} </span>
              <span className={styles.actionName}>{t([type])}</span>
            </div>
          )
        })}
      </div>
    )
  )
}
