import {
  DeployCommand,
  type CommonDeployCommandProps
} from "@/pages/DeploymentsPage/components/molecules/DeployCommand"
import { DeploymentKeyButton } from "@/pages/DeploymentsPage/components/molecules/DeploymentKeyButton"
import { InlineField } from "@/pages/DeploymentsPage/components/molecules/InlineField"
import { REGISTRY_FILENAME } from "@/pages/DeploymentsPage/const"
import { getDefaultValues, getDeployCommand, getLines } from "./helpers"

export const CommonDeployCommand = (props: CommonDeployCommandProps) => {
  const lines = getLines(props)

  return (
    <DeployCommand
      getDefaultValues={getDefaultValues}
      getDeployCommand={getDeployCommand}
      pullSecretName={REGISTRY_FILENAME}
      namespace="kodem"
      {...props}
    >
      {({ onError, ...props }) => {
        return (
          <>
            <InlineField
              noNullCharacter
              line={lines["kubectl_create_namespace"]}
              {...props}
            />
            <InlineField
              noNullCharacter
              line={lines["apply_namespace"]}
              {...props}
            />
            <InlineField line={lines["helm_upgrade"]} {...props} />
            <InlineField line={lines["namespace"]} {...props} />
            <InlineField line={lines["environment"]} {...props} />
            <InlineField
              line={lines["deploymentKey"]}
              buttons={<DeploymentKeyButton onError={onError} />}
              {...props}
            />
            <InlineField line={lines["pullSecretName"]} {...props} />
            <InlineField
              noNullCharacter
              line={lines["create_namespace"]}
              {...props}
            />
          </>
        )
      }}
    </DeployCommand>
  )
}
