import { Text } from "@/atoms"

import type { ConfirmModalProps } from "."

export const CancelConfirmModal = (
  props: Omit<ConfirmModalProps, "entityName">
) => {
  const { t, messageAPI } = props

  return {
    destroyOnClose: true,
    content: (
      <Text data-testid="content-cancel-confirm-modal">
        {props.content || t("forms.messages.cancel.update")}
      </Text>
    ),
    okText: props.okText || t(props.keyOkText || "forms.buttons.cancel.update"),
    okButtonProps: {
      "data-testid": "ok-button-cancel-confirm-modal"
    },
    cancelText: t("forms.buttons.stay"),
    cancelButtonProps: { "data-testid": "cancel-button-cancel-confirm-modal" },
    onOk: () => {
      try {
        props.onOk()
      } catch (err: any) {
        messageAPI.open({
          type: "error",
          content: err?.data?.displayMessage || err?.message || "Error"
        })
      }
    }
  }
}
