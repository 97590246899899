import { matchPath } from "react-router-dom"

import { Badge, Link, type ItemType } from "@/atoms"
import { NAMES_ROUTES } from "@/const/routes.constants"
import {
  AppstoreOutlined,
  BankOutlined,
  CompassOutlined,
  SortAscendingOutlined
} from "@/icons"
import { LabelPrimaryNavigation } from "@/molecules/LabelPrimaryNavigation"

import type { TFunction } from "@/hooks/useTranslation"
import type { FeatureStatusType } from "@/molecules/FeatureStatusTag"
import type { CustomProps, ItemMenu } from ".."

export const getLabel = (
  props: ItemMenu & { countByChildren?: number; tooltip?: boolean }
) => {
  const { label, key, children, countByChildren } = props

  if (countByChildren)
    return (
      <LabelPrimaryNavigation subMenu label={label} count={countByChildren} />
    )

  const isChildrenExist = !!children?.length

  if (isChildrenExist) {
    return label
  }

  const { count, beta, isNew, soon, defaultQueryString, tooltip } =
    props.customProps || {}
  const to = `${key}${defaultQueryString ? `?${defaultQueryString}` : ""}`

  let typeTag: FeatureStatusType | undefined

  if (beta) {
    typeTag = "beta"
  }

  if (isNew) {
    typeTag = "new"
  }

  if (soon) {
    typeTag = "soon"
  }

  if (count)
    return <LabelPrimaryNavigation to={to} label={label} count={count} />

  if (typeTag)
    return (
      <LabelPrimaryNavigation
        to={to}
        label={label}
        tag={typeTag}
        tooltip={tooltip ? label : undefined}
      />
    )

  return <Link to={to}>{label}</Link>
}

export const getItem = (
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: ItemMenu[],
  customProps?: CustomProps
): ItemMenu & { "data-level-item-menu-side-navigation": string } => {
  const countByChildren = children?.reduce((acc, item) => {
    if (item.customProps?.count) return acc + item.customProps.count

    return acc
  }, 0)

  return {
    key,
    "data-level-item-menu-side-navigation": !icon && !!children ? "2" : "",
    icon: countByChildren ? (
      <Badge dot className="badge-item-menu-side-nav-ks">
        {icon}
      </Badge>
    ) : (
      icon
    ),
    children,
    label: getLabel({ label, key, children, customProps, countByChildren }),
    customProps
  }
}

export const getIncidentsCount = (pathname: string) => {
  try {
    const incidents = matchPath("/triage/incidents/:incident?", pathname)
    const count =
      !!incidents?.params.incident &&
      JSON.parse(atob(incidents.params.incident)).length

    return count
  } catch {
    return undefined
  }
}

export const getMenuItems = (
  attr: {
    t: TFunction
    isDemo: boolean
  } & Record<string, any>
) => {
  const { isDemo, pathname, collapsed, t } = attr
  const { DISCOVERY, TRIAGE, GOVERNANCE } = NAMES_ROUTES
  const { ROOT: DISCOVERY_ROOT, SBOM, VM, API, AI } = DISCOVERY
  const { ROOT: TRIAGE_ROOT, INCIDENTS, ISSUES, ATTACK_SCENARIOS } = TRIAGE
  const {
    ROOT: GOVERNANCE_ROOT,
    CI,
    SCM,
    SCAN_HISTORY,
    THREAT_DETECTION_POLICIES,
    AUTOMATION_HISTORY,
    WORKFLOWS,
    SAST
  } = GOVERNANCE
  const tKey = "navigation."
  const issuesKey = `/${TRIAGE_ROOT}/${ISSUES}`
  const issueDefaultQueryString = "issueStatus=open&runtime=YES"
  const soon = !isDemo
  const tooltip = !collapsed

  return [
    getItem(t(`${tKey}dashboard`), "/", <AppstoreOutlined />),
    getItem(t(`${tKey}discovery`), "discovery", <CompassOutlined />, [
      getItem(t(`${tKey}branch`), "/discovery/branch"),
      getItem(t(`${tKey}applications`), "/discovery/applications"),
      getItem(t(`${tKey}images`), "/discovery/images"),
      getItem(t(`${tKey}baseImages`), "/discovery/baseImages"),
      getItem(
        t(`${tKey}vm`),
        `/${DISCOVERY_ROOT}/${VM}`,
        undefined,
        undefined,
        { vmInstancesEnabledFF: true }
      ),
      getItem(t(`${tKey}sbom`), `/${DISCOVERY_ROOT}/${SBOM}`),
      getItem(
        t(`${tKey}api`),
        `/${DISCOVERY_ROOT}/${API}`,
        undefined,
        undefined,
        { soon }
      ),
      getItem(
        t(`${tKey}ai`),
        `/${DISCOVERY_ROOT}/${AI}`,
        undefined,
        undefined,
        { soon, demo: true }
      )
    ]),
    getItem(t(`${tKey}triage`), "triage", <SortAscendingOutlined />, [
      getItem(t(`${tKey}issues`), issuesKey, undefined, undefined, {
        defaultQueryString: issueDefaultQueryString
      }),
      getItem(
        t(`${tKey}incidents`),
        `/${TRIAGE_ROOT}/${INCIDENTS}`,
        undefined,
        undefined,
        {
          count: getIncidentsCount(pathname),
          soon: !attr.enableIncidents,
          beta: attr.enableIncidents
        }
      ),
      getItem(
        t(`${tKey}attackScenarios`),
        `/${TRIAGE_ROOT}/${ATTACK_SCENARIOS}`,
        undefined,
        undefined,
        { soon, demo: true }
      )
    ]),
    getItem(t(`${tKey}governance`), "governance", <BankOutlined />, [
      getItem(t(`${tKey}scmPolicies`), t(`${tKey}scmPolicies`), undefined, [
        getItem(
          t(`${tKey}openSourcePolicies`),
          `/${GOVERNANCE_ROOT}/${SCM}`,
          undefined,
          undefined
        ),
        getItem(
          t(`${tKey}codePolicies`),
          `/${GOVERNANCE_ROOT}/${SAST}`,
          undefined,
          undefined,
          { isNew: true }
        )
      ]),
      getItem(t(`${tKey}ciPolicies`), `/${GOVERNANCE_ROOT}/${CI}`),
      getItem(
        t(`${tKey}automatedActions`),
        `/${GOVERNANCE_ROOT}/${WORKFLOWS}`,
        undefined,
        undefined,
        { isNew: true }
      ),
      getItem(
        t(`${tKey}scanHistory`),
        `/${GOVERNANCE_ROOT}/${SCAN_HISTORY}`,
        undefined,
        undefined,
        { soon, demo: true }
      ),
      getItem(
        t(`${tKey}automationHistory`),
        `/${GOVERNANCE_ROOT}/${AUTOMATION_HISTORY}`,
        undefined,
        undefined,
        { soon, demo: true }
      ),
      getItem(
        t(`${tKey}threatDetectionPolicies`),
        `/${GOVERNANCE_ROOT}/${THREAT_DETECTION_POLICIES}`,
        undefined,
        undefined,
        { soon, tooltip }
      )
    ])
  ]
}

export const filterMenuItems = (
  items: ItemMenu[],
  {
    isDemo,
    vmInstancesEnabledFF
  }: {
    isDemo?: boolean
    vmInstancesEnabledFF?: boolean
  } = {}
) =>
  items.map((item: ItemMenu, i) => {
    const { children, customProps, ...rest } = item

    if (!children) return rest

    return {
      children: [
        { key: `${i}_title`, label: item.label, disabled: true } as ItemMenu,
        ...children
      ]
        .filter(({ customProps }) => {
          if (customProps?.demo) return isDemo
          if (customProps?.vmInstancesEnabledFF) return vmInstancesEnabledFF

          return true
        })
        .map(({ customProps, ...rest }) => rest),
      ...rest
    }
  })

export const filterItemsByItemKey = (items: ItemType[], itemKey: string) =>
  items
    .filter((item: any) =>
      (item as { children?: { key: string }[] }).children?.some(
        ({ key }) => key === itemKey
      )
    )
    .map((item) => (item as { key: string }).key)
