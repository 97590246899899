import { Link } from "@/atoms"
import { NAMES_ROUTES } from "@/const/routes.constants"
import { INTEGRATION_VENDORS } from "@/const/vendors-constants"
import { useTranslation } from "@/hooks/useTranslation"
import { FlagOutlined } from "@/icons"
import { InformationAlert } from "@/molecules/InformationAlert"
import { ConfigurationPageTemplate } from "@/pages/IntegrationsPage/components/templates/ConfigurationPageTemplate"
import { useIntegration } from "@/pages/IntegrationsPage/hooks/useIntegration"
import { WebhookConfigurationTab } from "./WebhookConfigurationTab"

import styles from "./WebhookConfigurationPage.module.scss"

export const WebhookConfigurationPage = () => {
  const vendor = INTEGRATION_VENDORS.webhook
  const [data] = useIntegration({ vendor })
  const { configuration } = data || {}
  const noWebhooks = !configuration?.installations?.length
  const { t: tGeneral } = useTranslation("translation")
  const keyPrefix = "integrations.webhook.configuration"
  const { t: tWebhook } = useTranslation("translation", { keyPrefix })
  const { ROOT, WORKFLOWS } = NAMES_ROUTES.GOVERNANCE
  const workflowsUrl = `/${ROOT}/${WORKFLOWS}`

  return (
    <ConfigurationPageTemplate
      vendor={vendor}
      config={<WebhookConfigurationTab vendor={vendor} />}
      titleKey={`${keyPrefix}.title`}
      prefixConfig={
        !noWebhooks && (
          <InformationAlert
            icon={<FlagOutlined />}
            message={tWebhook("completeTitle")}
            description={
              <span className={styles.description}>
                {tWebhook("configureFirst")}
                <Link to={workflowsUrl}>
                  {tGeneral("general.workflow", { count: 1 })}
                </Link>
                {tWebhook("configureSecond")}
              </span>
            }
          />
        )
      }
    />
  )
}
