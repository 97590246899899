import {
  FIXABILITY_VALUE_QUERY_STRING,
  RUNTIME_ID
} from "@/const/default-values"
import { Exploite, Runtime, SevereIssue, WarningOutlined } from "@/icons"
import { DRAWERS_PARAM_NAME } from "@/organisms/Drawers/const"
import {
  getActiveTabFieldName,
  getIdsDrawer
} from "@/organisms/Drawers/hooks/useDrawer"
import { helpers } from "@/organisms/Funnel"

import type { TFunction } from "@/hooks/useTranslation"
import type { GetCardProps } from "."

const getStringForCompare = (str: string) => str.split(",").sort().join(",")

const getQueryString = (
  params: Record<string, any> | undefined,
  searchParams: URLSearchParams
) => {
  if (!params) return { search: "", match: false }

  const query = new URLSearchParams(params)

  searchParams.delete("pageNumber")

  const currentFixability = searchParams.get("fixability")

  if (currentFixability) {
    const fixabilityArr = FIXABILITY_VALUE_QUERY_STRING.split(",")
    const currentFixabilityArr = currentFixability.split(",")

    // both cases with and without fixability should be indicated as active in funnel cards
    if (
      currentFixabilityArr.length === fixabilityArr.length &&
      currentFixabilityArr.every((value) => fixabilityArr.includes(value))
    )
      searchParams.delete("fixability")
  }

  const orderBy = searchParams.get("orderBy")
  const ascending = searchParams.get("ascending")
  const drawers = searchParams.get(DRAWERS_PARAM_NAME)

  if (orderBy) query.set("orderBy", orderBy)
  if (ascending) query.set("ascending", ascending)
  if (drawers) {
    query.set(DRAWERS_PARAM_NAME, drawers)
    getIdsDrawer(searchParams).forEach((id) => {
      const value = searchParams.get(id)
      const tabId = getActiveTabFieldName(id)
      const tabValue = searchParams.get(tabId)

      if (value) query.set(id, value)
      if (tabValue) query.set(tabId, tabValue)
    })
  }

  if (query.size !== searchParams.size)
    return { search: query.toString(), match: false }

  if (query.size === 0) {
    return { search: query.toString(), match: true }
  }

  let isMatch = true

  query.forEach((value, key) => {
    if (
      getStringForCompare(value) !==
      getStringForCompare(searchParams.get(key) || "")
    )
      isMatch = false
  })

  return { search: query.toString(), match: isMatch }
}

export const getCards = (
  t: TFunction,
  searchParams: URLSearchParams,
  props: GetCardProps
) => {
  const keyPrefix = "funnel.cards."
  let currentParams = {}

  return [
    {
      key: "open",
      params: { issueStatus: "open" },
      Icon: WarningOutlined
    },
    {
      key: RUNTIME_ID,
      params: props.runtime ? { runtime: "YES" } : { runtime: "UNDETERMINED" },
      Icon: Runtime
    },
    {
      key: "severe",
      params: { severity: "Critical,High" },
      Icon: SevereIssue
    },
    {
      key: "exploitable",
      params: { exploitMaturity: "IN_THE_WILD,POC" },
      Icon: Exploite
    }
  ].map(({ params, ...rest }) => {
    currentParams = { ...currentParams, ...params, ...props?.overrideParams }

    const { search, match } = getQueryString(currentParams, searchParams)

    return {
      link: `/triage/issues?${search}`,
      selected: match,
      title: t(`${keyPrefix}${rest.key}`),
      params: currentParams,
      enabled: props.runtime !== undefined,
      ...rest
    }
  })
}

export const getColorsByIndex = (index: number) => {
  return helpers.getColorsByIndex(index)
}
