import { CardField } from "../../CardField"
import { Fields } from "./Fields"

import type { CardProps } from ".."

export const ResourcesCard = (props: CardProps) => {
  const { name, fieldName = "filters", remove, form, ...attr } = props
  const cardFieldName = [name, fieldName]
  const { isAllResources, disabled } = props.componentProps || {}
  const testId = "add-row-resources-card"

  return (
    <CardField
      action={isAllResources ? undefined : "showIncludeExclude"}
      buttonProps={
        isAllResources
          ? undefined
          : { disabled: !!disabled, type: "or", dataTestId: testId }
      }
      name={cardFieldName}
      form={form}
      removeCard={remove}
      render={Fields}
      {...attr}
    />
  )
}
