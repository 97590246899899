import { useParams } from "react-router-dom"

import {
  useDeleteScope as useDeleteScopeApi,
  useGetFiltersScopes,
  useGetScopes
} from "@/api/useScopes"
import { VALUES_PARAMS } from "@/const/routes.constants"
import { useUser } from "@/hooks/useUser"
import { DeleteConfirmModal, useConfirmModal } from "@/molecules/ConfirmModal"
import { getOptions, getScopesData } from "./useScopes.selector"

import type { Scope, ScopesResponse } from "@/api/useScopes.types"
import type { Params } from "@/hooks/useHttp.types"

export const useFiltersScopes = () => {
  const context = useGetFiltersScopes({}, { select: getOptions })

  return context
}

export const useScopes = (params = {}) => {
  const context = useGetScopes(params, { select: getScopesData })

  return context
}

export const useScope = () => {
  const { id, duplicateId } = useParams()
  const needle = duplicateId || id
  const enabled = !!needle && needle !== VALUES_PARAMS.NEW_ENTITY
  const context = useGetScopes({ needle }, { enabled, select: getScopesData })
  const { response, isLoading } = context

  return { data: response?.data[0], isLoading }
}

export const useDeleteScope = (params: Params) => {
  const { user, setCurrentScope } = useUser()

  const remover = useDeleteScopeApi(
    params,
    (oldData: ScopesResponse, newData: string) => {
      try {
        return {
          ...oldData,
          data: oldData.data.filter(({ id }) => id !== newData)
        }
      } catch {
        return oldData
      }
    }
  )

  const { confirm } = useConfirmModal()

  const deleteScope = async (scope: Scope) => {
    const { id: scopeId, name } = scope

    confirm((props) =>
      DeleteConfirmModal({
        name,
        entityName: "scope",
        onOk: async () => {
          await remover.mutateAsync(scopeId)

          if (scopeId === user.currentScopeId) {
            //TODO: should decide if apply default scope or apply selection mode
            setCurrentScope(null)
          }
        },
        ...props
      })
    )
  }

  return { deleteScope, isLoading: remover.isPending }
}
