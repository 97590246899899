import { usePreInstallECR } from "@/api/useIntegrations"
import { FolderOpenOutlined } from "@/icons"
import {
  ConfigItem,
  DescriptionLabelConfigItem
} from "@/pages/IntegrationsPage/components/molecules/ConfigItem"
import { useIntegration } from "@/pages/IntegrationsPage/hooks/useIntegration"
import { EcrConfigurationForm } from "./EcrConfigurationForm"

import type { EcrConfiguration } from "@/api/useIntegrations.types"
import type { TabEcrProps } from "."

export const EcrConfigurationTab = (props: TabEcrProps) => {
  const [data, { t }] = useIntegration(props)
  const { accountRegistries = [] } =
    (data?.configuration as EcrConfiguration) || {}
  const noData = accountRegistries.length === 0

  usePreInstallECR("ecr")

  return (
    <>
      {noData && t("integrations.configuration.ecr.noConfiguration")}
      {!noData &&
        accountRegistries.map((account) => {
          const { accountId, registriesCount } = account
          const values = { id: accountId, name: accountId }

          return (
            <ConfigItem
              key={accountId}
              values={values}
              Description={
                <DescriptionLabelConfigItem
                  Icon={FolderOpenOutlined}
                  tKey="integrations.connect.gitlab.project_connected"
                  count={registriesCount}
                />
              }
            >
              <EcrConfigurationForm account={account} {...props} />
            </ConfigItem>
          )
        })}
    </>
  )
}
