import { SelectProps } from "@/atoms"
import { Role } from "@/domain/user"
import { DescriptiveSelect, TypedOption } from "@/molecules/DescriptiveSelect"

export const RoleSelect = (props: SelectProps<Role, TypedOption<Role>>) => {
  const roles: Role[] = ["VIEWER", "TICKET_CREATOR", "OPERATOR", "ADMIN"]

  return (
    <DescriptiveSelect<Role>
      values={roles}
      translationKey="settings.users.roles"
      listHeight={300}
      {...props}
    />
  )
}
