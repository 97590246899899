import { ActionsCell } from "@/molecules/ActionsCell"

import type { Policy } from "@/api/usePolicies.types"
import type { TFunction } from "@/hooks/useTranslation"
import { Protected } from "@/molecules/Protected"

export const ActionsCellScmTab = (
  t: TFunction,
  {
    onEdit,
    onDelete
  }: { onEdit: (policy: Policy) => void; onDelete: (policy: Policy) => void }
) =>
  function ActionsCellScmTabComponent(_: unknown, record: Policy) {
    return (
      <Protected permission={{ resource: "scm.policies", action: "write" }}>
        <ActionsCell
          items={[
            { key: "edit", label: <div>{t("general.edit")}</div> },
            { key: "delete", label: <div>{t("general.delete")}</div> }
          ]}
          onClick={({ key }) => {
            const action = key === "delete" ? onDelete : onEdit

            action(record)
          }}
        />
      </Protected>
    )
  }
