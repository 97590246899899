import { Form, Select } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { FilterOutlined } from "@/icons"
import {
  ALL_RESOURCES,
  ResourcesList,
  SPECIFIC_RESOURCES,
  SPECIFIC_SCOPES
} from "."
import { RadioGroup } from "../RadioGroup"
import { SectionFormTemplate } from "../SectionFormTemplate"

import type { ResourcesSectionFormProps } from "."

const sectionId = "resources"

export const ResourcesSectionForm = (props: ResourcesSectionFormProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "forms" })

  const { form, isAllResources, scopeOptions } = props
  const { fieldName = "resources", title = t("sections.resources") } = props
  const fieldValue = Form.useWatch(fieldName, form)
  const isSpecificResources = fieldValue === SPECIFIC_RESOURCES
  const isSpecificScope = fieldValue === SPECIFIC_SCOPES
  const resourcesFieldName = `${SPECIFIC_RESOURCES}_${fieldName}`
  const resourcesProps = { fieldName: resourcesFieldName, ...props }
  const specificScopesFieldName = `${SPECIFIC_SCOPES}_${fieldName}`
  const { onlySpecificResource, specificScopes } = props
  const showRadioGroup = !onlySpecificResource && !isAllResources
  const showSpecificScopes = !!specificScopes && showRadioGroup

  return (
    <SectionFormTemplate id={sectionId} title={title} Icon={FilterOutlined}>
      <Form.Item required={false} name={fieldName}>
        {showRadioGroup && (
          <RadioGroup id="radio_group_resources_section_form">
            <RadioGroup.Card
              label={t(`fields.resources.${ALL_RESOURCES}`)}
              value={ALL_RESOURCES}
            />
            {!showSpecificScopes && (
              <RadioGroup.Card
                label={t(`fields.resources.${SPECIFIC_RESOURCES}`)}
                value={SPECIFIC_RESOURCES}
                selected={isSpecificResources}
              >
                <ResourcesList {...resourcesProps} />
              </RadioGroup.Card>
            )}
            {showSpecificScopes && (
              <RadioGroup.Card
                label={t(`fields.resources.${SPECIFIC_SCOPES}`)}
                value={SPECIFIC_SCOPES}
                selected={isSpecificScope}
              >
                <Form.Item
                  label="Report Scopes"
                  name={specificScopesFieldName}
                  required={false}
                >
                  <Select
                    className="tag-ant-select-selection-item-ant-select-selector"
                    mode="multiple"
                    options={scopeOptions}
                  />
                </Form.Item>
              </RadioGroup.Card>
            )}
          </RadioGroup>
        )}
        {!showRadioGroup && <ResourcesList {...resourcesProps} />}
      </Form.Item>
    </SectionFormTemplate>
  )
}

ResourcesSectionForm.sectionId = sectionId
