import { Component } from "react"

import { ERROR_EVENTS } from "@/const/event.constants"
import { getErrorType, isAuthorizationError } from "@/helpers/error.helper"
import { ErrorResult } from "./ErrorResult"

import type { ErrorBoundaryProps, ErrorBoundaryState } from "."

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, reason: getErrorType(error) }
  }

  componentDidCatch(error: Error) {
    if (isAuthorizationError(error)) return // 403 http errors are logged only in useHttp hook

    const { placement = "LAYOUT" } = this.props
    const tags =
      ERROR_EVENTS.ERROR_BOUNDARY[placement] ||
      ERROR_EVENTS.ERROR_BOUNDARY.LAYOUT

    this.props.logger.error(error, { tags })
  }

  componentDidUpdate(prevProps: Readonly<ErrorBoundaryProps>): void {
    const updatedScopeAfterForbiddenError =
      this.state.reason === "Forbidden" &&
      this.props.user?.currentScopeId !== prevProps.user?.currentScopeId

    if (updatedScopeAfterForbiddenError)
      this.setState({ hasError: false, reason: undefined })
  }

  render() {
    if (this.state.hasError && this.state.reason)
      return <ErrorResult errorType={this.state.reason} {...this.props} />

    return this.props.children
  }
}
