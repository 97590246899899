import type { ActionType } from "@/api/useWorkflow.types"
import type { TFunction } from "@/hooks/useTranslation"
import { InformationPopover } from "@/molecules/InformationPopover"
import { ActionTypeIcon } from "../ActionTypeIcon"
import styles from "./AutomatedActionsCell.module.scss"

export const AutomatedActionsCell = ({ t }: { t: TFunction }) =>
  function AutomatedActionsCellComponent(value: { type: ActionType }[]) {
    if (!value?.length) return null

    return (
      <div className={styles.cell}>
        <InformationPopover.Popover
          placement="top"
          content={
            <InformationPopover.CommonTemplate title="Included Actions">
              {value.map(({ type }, index) => (
                <span key={`${type}${index}`} className={styles.itemPopover}>
                  <ActionTypeIcon type={type} />
                  <span>
                    {t(`settings.automatedActions.table.actionsByType.${type}`)}
                  </span>
                </span>
              ))}
            </InformationPopover.CommonTemplate>
          }
        >
          <div className={styles.icons}>
            {value.map(({ type }, index) => (
              <span key={`${type}${index}`}>
                <ActionTypeIcon type={type} />
              </span>
            ))}
          </div>
        </InformationPopover.Popover>
      </div>
    )
  }
