import cn from "classnames"

import { Collapse, Flex } from "@/atoms"
import { LabelConfigItem } from "./LabelConfigItem"

import styles from "./ConfigItem.module.scss"

import type { ConfigItemProps } from "."

export const ConfigItem = (props: ConfigItemProps) => {
  const { values, children, Aside, Description } = props
  const noChildren = !children
  const collapseProps = noChildren
    ? { activeKey: "disabled", expandIcon: () => null }
    : undefined

  return (
    <Collapse
      className={cn(styles.configItem, styles.noChildren)}
      items={[
        {
          key: `${values.id}`,
          label: (
            <Flex align="center">
              <Flex flex={1}>
                <LabelConfigItem Description={Description} values={values} />
              </Flex>
              <Flex className={styles.aside}>{Aside}</Flex>
            </Flex>
          ),
          children: (
            <Flex vertical className={styles.configItemSection}>
              {children}
            </Flex>
          )
        }
      ]}
      {...collapseProps}
    />
  )
}
