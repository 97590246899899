import { lazy, Suspense, useEffect } from "react"

import { useDismissWelcomeScreen, useWelcomeStatus } from "@/api/useOnboarding"
import { useLogger } from "@/hooks/useLogger"
import { useUser } from "@/hooks/useUser"

const SplashScreenModal = lazy(() => import("./components/SplashScreenModal"))

export const SplashScreen: React.FC = () => {
  const { isLoading, response, refetch } = useWelcomeStatus()
  const dismissWelcomeContext = useDismissWelcomeScreen()
  const isWelcomeDismissed = response?.data?.isWelcomeDismissed
  const showSplashScreen = !isLoading && isWelcomeDismissed === false
  const { updateFeatureFlags } = useUser()

  useEffect(() => {
    if (isWelcomeDismissed) updateFeatureFlags({ isWelcomeDismissed })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWelcomeDismissed])

  if (!showSplashScreen) return null

  const onFinished = async () => {
    try {
      await dismissWelcomeContext.mutate(undefined)
      refetch()
    } catch {
      return
    }
  }

  return (
    <Suspense>
      <SplashScreenModal useLogger={useLogger} onFinished={onFinished} />
    </Suspense>
  )
}
