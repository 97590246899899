import { ActionsCell } from "@/molecules/ActionsCell"
import { ContentConfirmDelete } from "./ContentConfirmDelete"

import type { Label } from "@/domain/label"
import type { ActionsLabelFunction } from "./ActionsLabelCell.types"

export const ActionsLabelCell: ActionsLabelFunction = ({ actions, t, http }) =>
  function ActionsLabelCellComponent(_: unknown, record: Label) {
    const items = [
      { key: "edit", label: t("general.edit") },
      { key: "delete", label: t("general.delete") }
    ]
    return (
      <ActionsCell
        items={items}
        onClick={({ key }) => {
          if (key === items[0].key) return actions.onEdit(record)

          actions.onDelete(
            record,
            <ContentConfirmDelete record={record} http={http} />
          )
        }}
      />
    )
  }
