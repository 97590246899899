import { useEffect, useRef, useState } from "react"

import { Form } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"

import type { InputRef } from "@/atoms"

export const useMultiFactorForm = (name: string) => {
  const inputRef = useRef<InputRef>(null)
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const value = Form.useWatch(name, form)
  const [okDisabled, setOkDisabled] = useState(!value)
  const [loading, setLoading] = useState(false)

  const onSubmit = () => {
    form.submit()
  }
  const onKeyDown = ({ code }: { code: string }) => {
    if (code === "Enter" && !okDisabled) {
      onSubmit()
    }
  }

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  useEffect(() => {
    if (value) {
      form
        .validateFields([name])
        .then(() => {
          setOkDisabled(false)
        })
        .catch(() => {
          setOkDisabled(true)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return {
    loading,
    okDisabled,
    inputRef,
    form,
    value,
    t,
    setLoading,
    onKeyDown,
    onSubmit
  }
}
