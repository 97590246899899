import { Text } from "@/atoms"
import { List } from "@/pages/DiscoveryPage/components/molecules/List"

import { HistoryImage, splitCommand } from "@/domain/package"
import { ImportCommandCard } from "@/molecules/ImportCommandCard"
import { HistoryCommandsListProps } from "./HistoryCommandsList.types"

import { TFunction } from "@/hooks/useTranslation"
import styles from "./HistoryCommandsList.module.scss"

function isEmptyCommand(entry: HistoryImage) {
  return entry.createdBy && entry.createdBy !== ""
}

function hasCommandsInHistory(historyEntries: HistoryImage[]) {
  return historyEntries?.some(isEmptyCommand)
}

export function getCommandsHistoryTitle(
  tKey: string,
  t: TFunction,
  historyEntries: HistoryImage[]
) {
  return hasCommandsInHistory(historyEntries)
    ? t(`${tKey}dockerHistoryCommands`)
    : t(`${tKey}dockerHistoryCommandsNotAvailable`)
}

export const HistoryCommandsList: React.FC<HistoryCommandsListProps> = (
  props
) => {
  const { historyEntries, title, tKey, t } = props

  if (!historyEntries?.length) {
    return null
  }

  return (
    <>
      {title && <Text className={styles.title}>{title}</Text>}
      <List
        classes={{ list: styles.commandsWrapper, button: styles.buttonWrapper }}
        limit={3}
        items={historyEntries}
        render={(command, i) => {
          const { instruction, commandArgument } = splitCommand(
            command.createdBy
          )
          if (!instruction || !commandArgument) {
            return <Text>{t(`${tKey}noCreatedByCommand`)}</Text>
          }

          return (
            <ImportCommandCard
              key={`${instruction}_${i}`}
              isImportedFromBaseImage={false}
              layerIndex={command.layerIndex}
              instruction={instruction}
              commandArgument={commandArgument}
              createdBy={command.createdBy}
              dockerCommand={null}
              dockerfileMatch={false}
              dockerfilePath={null}
            />
          )
        }}
      />
    </>
  )
}
