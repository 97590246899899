import { INTEGRATION_VENDORS } from "@/const/vendors-constants"
import { ConfigurationPageTemplate } from "@/pages/IntegrationsPage/components/templates/ConfigurationPageTemplate"
import { GitHubConfigurationTab } from "./GitHubConfigurationTab"

export const GitHubConfigurationPage = () => (
  <ConfigurationPageTemplate
    vendor={INTEGRATION_VENDORS.github}
    config={<GitHubConfigurationTab vendor={INTEGRATION_VENDORS.github} />}
    titleKey="integrations.configuration.GitHub.configurationTitle"
  />
)
