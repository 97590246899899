import { Button, Flex, Form, Input, Steps, Text } from "@/atoms"
import { generateExternalID } from "@/helpers/utils"
import { Trans, useTranslation } from "@/hooks/useTranslation"

import { CopyButton } from "@/molecules/CopyButton"
import { InformationPopover } from "@/molecules/InformationPopover"

import connectStyles from "../../../ConnectIntegrationPage/ConnectIntegrationPage.module.scss"
import styles from "./FormGitLabIntegrationPage.module.scss"

import type { FormGitLabIntegrationPageComponent } from "."

export const FormGitLabIntegrationPage: FormGitLabIntegrationPageComponent = ({
  focusRef,
  form,
  onFieldsChange
}) => {
  const webhookUrl =
    "https://integrations.kodemsecurity.com/api/gitlab/webhooks"
  const { t } = useTranslation("translation", {
    keyPrefix: "integrations.connect.gitlab.steps"
  })
  const { t: tError } = useTranslation()
  const rules = [{ required: true, message: tError("error.requiredField") }]
  const secretTokenFieldName = "secretToken"
  const initialValues = { [secretTokenFieldName]: generateExternalID().value }

  const secretToken = Form.useWatch(secretTokenFieldName, form)

  return (
    <Form
      initialValues={initialValues}
      layout="vertical"
      form={form}
      onFieldsChange={onFieldsChange}
    >
      <Steps className={connectStyles.steps} size="small" direction="vertical">
        <>
          <Steps.Step
            title={t("generate.title")}
            description={
              <>
                <Text type="secondary">{t("generate.description")}</Text>
                <ol className={styles.list}>
                  {[1, 2, 3].map((number) => (
                    <li key={number}>
                      <Text>
                        <Trans>{t(`generate.${number}`)}</Trans>
                      </Text>
                    </li>
                  ))}
                  <li>
                    <Flex gap={4}>
                      <Text className="neutral-mark-text-form-trans-ks">
                        <Trans>{t("generate.4.text")}</Trans>
                      </Text>
                      <InformationPopover.Popover
                        content={
                          <InformationPopover.CommonTemplate>
                            {t("generate.4.tooltip")}
                          </InformationPopover.CommonTemplate>
                        }
                      />
                    </Flex>
                  </li>
                </ol>
              </>
            }
            status="process"
          />
          <Steps.Step
            title={t("accessToken.title")}
            description={
              <Form.Item
                required={false}
                name="accessToken"
                label={t("accessToken.description")}
                rules={rules}
              >
                <Input
                  ref={focusRef}
                  placeholder="e.g. glpat-igULz9SzAyW6uhvrv1Qy"
                />
              </Form.Item>
            }
            status="process"
          />
          <Steps.Step
            title={t("createWebhook.title")}
            description={
              <>
                <Text type="secondary">{t("createWebhook.description")}</Text>
                <ol className={styles.list}>
                  <li>
                    <Text>
                      <Trans>{t("createWebhook.1")}</Trans>
                    </Text>
                  </li>

                  <li>
                    <Text>
                      <Trans>{t("createWebhook.2.title")}</Trans>
                    </Text>
                    <ul className={styles.nestedList}>
                      <li>
                        <Text strong>{`${t("createWebhook.2.steps.1")}:`}</Text>
                        <Form.Item className={styles.formItem}>
                          <Input
                            value={webhookUrl}
                            addonBefore={t("createWebhook.2.steps.1")}
                            addonAfter={
                              <CopyButton text={webhookUrl} type="link" />
                            }
                          />
                        </Form.Item>
                      </li>
                      <li>
                        <Flex vertical gap={10}>
                          <Flex align="center" gap={6}>
                            <Text strong>
                              {`${t("createWebhook.2.steps.2")}:`}
                            </Text>
                            <InformationPopover.Popover
                              content={
                                <InformationPopover.CommonTemplate>
                                  {t("createWebhook.2.steps.tooltip")}
                                </InformationPopover.CommonTemplate>
                              }
                            />
                          </Flex>
                          <Flex gap={12}>
                            <Form.Item
                              className={styles.formItem}
                              required={false}
                              name={secretTokenFieldName}
                              rules={rules}
                            >
                              <Input
                                addonBefore={t("createWebhook.2.steps.2")}
                                ref={focusRef}
                                placeholder="YZA9KFV2QHHH"
                                addonAfter={
                                  <CopyButton text={secretToken} type="link" />
                                }
                              />
                            </Form.Item>
                            <Button
                              onClick={() => {
                                form.setFieldValue(
                                  secretTokenFieldName,
                                  generateExternalID().value
                                )
                                form.validateFields([secretTokenFieldName])
                              }}
                            >
                              {t("createWebhook.2.steps.generate")}
                            </Button>
                          </Flex>
                        </Flex>
                      </li>
                      <li>
                        <Flex vertical>
                          <Text
                            strong
                          >{`${t("createWebhook.2.steps.3")}:`}</Text>
                          <Form.Item>
                            <Text className="neutral-mark-text-form-trans-ks">
                              <strong>Push event</strong>,{" "}
                              <strong>Merge request event</strong>
                            </Text>
                          </Form.Item>
                        </Flex>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Text>
                      <Trans>{t("createWebhook.3")}</Trans>
                    </Text>
                  </li>
                </ol>
              </>
            }
            status="process"
          />
        </>
      </Steps>
    </Form>
  )
}
