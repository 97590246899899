import {
  ConfigItem,
  FooterConfigItem
} from "@/pages/IntegrationsPage/components/molecules/ConfigItem"
import { FormWebhook } from "@/pages/IntegrationsPage/components/molecules/FormWebhook"
import { TestWebhookButton } from "@/pages/IntegrationsPage/components/molecules/TestWebhookButton"
import { useIntegration } from "@/pages/IntegrationsPage/hooks/useIntegration"
import { useWebhookAPI } from "@/pages/IntegrationsPage/hooks/useWebhookAPI"

import type { Action, Resource } from "@/domain/user"

export const WebhookConfigurationItem = (props: any) => {
  const [_, { onRemove, ...actions }] = useIntegration(props)
  const webhookApi = useWebhookAPI({
    values: props.values,
    ...actions
  })
  const resource = "governance.workflows" as Resource
  const permissions = { resource, action: "write" as Action }
  const formId = props.values.id

  return (
    <ConfigItem values={props.values}>
      <FormWebhook
        formId={formId}
        URL_FIELD_NAME={webhookApi.URL_FIELD_NAME}
        onFinish={webhookApi.onFinish}
        {...props}
        {...actions}
      />

      <FooterConfigItem
        formId={formId}
        permissions={permissions}
        removeTKey="general.disconnect"
        onRemove={() => onRemove(props.values)}
        isTesting={webhookApi.isTesting}
        {...actions}
      >
        <TestWebhookButton
          size="small"
          formProps={actions}
          webhookApi={webhookApi}
        />
      </FooterConfigItem>
    </ConfigItem>
  )
}
