import { Form } from "@/atoms"
import { ButtonListField } from "@/pages/GovernancePage/components/molecules/ButtonListField"

import type { ListFieldComponent } from "."

export const ListField: ListFieldComponent = (props) => {
  const { name, buttonProps, render, path, ...rest } = props

  const currentName = Array.isArray(name) ? name[name.length - 1] : name
  const parentNamePath = Array.isArray(path)
    ? [...path, currentName]
    : [path || currentName]

  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, ...field }, index) => (
            <div key={key}>
              {render({
                index,
                path: [...parentNamePath, field.name],
                remove: () => remove(index),
                ...field,
                ...rest
              })}
            </div>
          ))}
          {!!buttonProps && <ButtonListField onClick={add} {...buttonProps} />}
        </>
      )}
    </Form.List>
  )
}
