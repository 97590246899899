import cn from "classnames"

import { Divider, Title } from "@/atoms"

import styles from "./SectionFormTemplate.module.scss"

import type { SectionFormTemplateProps } from "."

export const SectionFormTemplate: React.FC<SectionFormTemplateProps> = ({
  title,
  children,
  id,
  Icon,
  withMargin,
  classes = {}
}) => (
  <div id={id} className={cn(withMargin && styles.withMargin)}>
    <Title level={4} className={cn(styles.title, classes.title)}>
      {Icon && <Icon />}
      {title}
    </Title>
    <Divider className={styles.divider} />
    {children}
  </div>
)
