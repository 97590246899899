import { ICONS_INSIGHTS } from "@/const/insights-constants"
import { withInternetFacing } from "@/helpers/insights.helper"
import { InsightsCell } from "@/molecules/InsightsCell"

import type { CellProps } from "@/molecules/InsightsCell"
import type { InsightsApplicationCellProps } from "./InsightsApplicationCell.types"

export const InsightsApplicationCell = (props: CellProps) =>
  function InsightsApplicationCellComponent(
    values: InsightsApplicationCellProps
  ) {
    return (
      <InsightsCell
        hide
        items={withInternetFacing(
          [ICONS_INSIGHTS.publicImage, ICONS_INSIGHTS.ingress],
          props.internetFacingFF
        )}
        values={values}
        t={props.t}
      />
    )
  }
