import { getCodeRepoPackagesData } from "@/api/useInventory.selectors"
import { RESOURCES_NAMES } from "@/const/resource.constants"
import type { Config, Params } from "@/hooks/useHttp"
import {
  ConfigInfinityQuery,
  useDelete,
  useHttp,
  useLoadMore,
  useUpdate
} from "@/hooks/useHttp"
import type { Module } from "@/molecules/ModulesTree/ModulesTree.types"
import { Configs } from "@/organisms/MonorepoConfigurations/MonorepoConfigurations.types"
import type {
  ApplicationParams,
  ApplicationResponse,
  ArchivedCountsResponse,
  BaseImageDetailsParams,
  BranchResponse,
  CodeRepoPackagesParams,
  CodeRepoPackagesResponse,
  ContainerBaseImageDetailsResponse,
  ContainerBaseImageResponse,
  CorrelationSource,
  CountsResponse,
  EntityByTypeAndId,
  EnvironmentResponse,
  ImageResponse,
  InventoryCodeRepoPackagesParams,
  PackageCommonProps,
  PackageDescribeResponse,
  PackageResponse,
  PackageVulnerabilitiesResponse,
  RepositoryConfigResponse,
  RepositoryCorrelation,
  RepositoryModulesResponse,
  SnapshotResponse,
  TransitiveFixesResponse,
  UpdateRepositoryConfigRequestBody,
  VMResponse
} from "./useInventory.types"

export type { Snapshot } from "./useInventory.types"

export const useGetInventorySnapshot = <T = SnapshotResponse, S = T>(
  params: Params = {},
  config?: Config<T, S>
) => {
  const context = useHttp<T, S>(
    RESOURCES_NAMES.INVENTORIES.SNAPSHOT,
    params,
    config
  )

  return context
}

export const useGetInventoryEnvironment = <T = EnvironmentResponse, S = T>(
  params: Params = {},
  config?: Config<T, S>
) => {
  const context = useHttp<T, S>(
    RESOURCES_NAMES.INVENTORIES.ENVIRONMENT,
    params,
    config
  )

  return context
}

export const useGetInventoryApplication = <T = ApplicationResponse, S = T>(
  params: Params<ApplicationParams> = { pageNumber: "0" },
  config?: Config<T, S>
) => {
  const { needle = "", ...restParams } = params
  const context = useHttp<T, S>(
    `${RESOURCES_NAMES.INVENTORIES.APPLICATION}${needle}`,
    restParams,
    config
  )

  return context
}

export const useGetInventoryImage = <T = ImageResponse, S = T>(
  params: Params<ApplicationParams> = { pageNumber: "0" },
  config?: Config<T, S>
) => {
  const { needle = "", ...restParams } = params

  const context = useHttp<T, S>(
    `${RESOURCES_NAMES.INVENTORIES.IMAGES}${needle}`,
    restParams,
    config
  )

  return context
}

export const useGetInventoryBranch = <T = BranchResponse, S = T>(
  params: Params<ApplicationParams> = { pageNumber: "0" },
  config?: Config<T, S>
) => {
  const { needle = "", ...restParams } = params

  const context = useHttp<T, S>(
    `${RESOURCES_NAMES.INVENTORIES.BRANCH}${needle}`,
    restParams,
    config
  )

  return context
}

export const useGetInventoryContainerBaseImage = <
  T = ContainerBaseImageResponse,
  S = T
>(
  params: Params<ApplicationParams> = { pageNumber: "0" },
  config?: Config<T, S>
) => {
  const context = useHttp<T, S>(
    RESOURCES_NAMES.INVENTORIES.CONTAINER_BASE_IMAGE,
    params,
    config
  )

  return context
}

export const useGetInventoryContainerBaseImageDetails = <
  T = ContainerBaseImageDetailsResponse,
  S = T
>(
  baseImageDigest: string,
  params?: Params<BaseImageDetailsParams>,
  config?: Config<T, S>
) => {
  const context = useHttp<T, S>(
    `${RESOURCES_NAMES.INVENTORIES.CONTAINER_BASE_IMAGE}/${baseImageDigest}`,
    params,
    config
  )

  return context
}

export const useGetInventoryCounts = <T = CountsResponse, S = T>(
  params: Params = {},
  config?: Config<T, S>
) => {
  const context = useHttp<T, S>(
    RESOURCES_NAMES.INVENTORIES.COUNTS,
    params,
    config
  )

  return context
}

export const useGetInventoryCodeRepoPackages = <
  T extends CodeRepoPackagesResponse = CodeRepoPackagesResponse,
  S = ReturnType<typeof getCodeRepoPackagesData>
>(
  repoName: string,
  params: InventoryCodeRepoPackagesParams = {},
  config: Config<T, S> = {}
) => {
  const context = useHttp<T, S>(
    `${RESOURCES_NAMES.INVENTORIES.BRANCH}/${repoName}`,
    params,
    config
  )

  return context
}

export const useGetTransitiveFixesCodeRepository = <
  T extends TransitiveFixesResponse = TransitiveFixesResponse,
  S = T
>(
  repoName: string,
  params: Params<CodeRepoPackagesParams> = {},
  config: Config<T, S> = {}
) => {
  const context = useHttp<T, S>(
    `${RESOURCES_NAMES.INVENTORIES.BRANCH}/${repoName}`,
    params,
    config
  )

  return context
}

export const useGetItemByIdInventory = <T, S = T>(
  { id, type }: EntityByTypeAndId,
  params?: Params,
  config?: Config<T, S>
) => {
  const context = useHttp<T, S>(
    `${RESOURCES_NAMES.INVENTORY}/${type}/${id}`,
    params,
    config
  )

  return context
}

export const useGetArchivedRepositoriesCount = <
  T = ArchivedCountsResponse,
  S = T
>() => {
  const context = useHttp<T, S>(RESOURCES_NAMES.INVENTORIES.ARCHIVED)

  return context
}

export const useGetPackages = <T = PackageResponse, S = T>(
  params: Params,
  config?: Config<T, S>
) => {
  const context = useHttp<T, S>(
    RESOURCES_NAMES.INVENTORIES.PACKAGE,
    params,
    config
  )

  return context
}

export const useLoadMoreProjectsByPackage = <T = any>(
  params: Params<{ needle: string; pageNumber?: number | string }>,
  config: ConfigInfinityQuery<T>
) => {
  const { needle = "", pageNumber, ...restParams } = params

  const context = useLoadMore<T>(
    `${RESOURCES_NAMES.INVENTORIES.PACKAGE}${needle}`,
    restParams,
    config
  )

  return context
}

export const useGetPackageVulnerabilities = <
  T = PackageVulnerabilitiesResponse,
  S = T
>(
  params: PackageCommonProps,
  config: Config<T, S>
) => {
  const { projectId, id, ...rest } = params
  const context = useHttp(
    `${RESOURCES_NAMES.INVENTORIES.PACKAGE}/${projectId}/${id}/vulnerabilities`,
    rest,
    config
  )

  return context
}

export const useGetPackageDescribe = <T = PackageDescribeResponse, S = T>(
  params: PackageCommonProps,
  config: Config<T, S>
) => {
  const { projectId, id, ...rest } = params
  const context = useHttp(
    `${RESOURCES_NAMES.INVENTORIES.PACKAGE}/${projectId}/${id}/describe`,
    rest,
    config
  )

  return context
}

export const useGetInventoryVirtualMachine = <T = VMResponse, S = T>(
  params: Params<ApplicationParams> = { pageNumber: "0" },
  config?: Config<T, S>
) => {
  const { needle = "", ...restParams } = params
  const context = useHttp<T, S>(
    `${RESOURCES_NAMES.INVENTORIES.VM}${needle}`,
    restParams,
    config
  )

  return context
}

export const useGetRepositoryModules = (repositoryName: string) => {
  const context = useHttp<RepositoryModulesResponse, Module[]>(
    RESOURCES_NAMES.INVENTORIES.BRANCH_MODULES(repositoryName),
    {},
    {
      enabled: !!repositoryName,
      select(data) {
        if (!data || !data.data || data.data.length === 0) return []

        const modules = data.data.flatMap(({ path, files }) =>
          files.map((file) => {
            const moduleInfo = {
              name: file.name,
              path: `${path}/${file.name}`,
              type: "manifest"
            }

            // Remove undefined properties
            return Object.fromEntries(
              Object.entries(moduleInfo).filter(([_, v]) => v !== undefined)
            ) as Module
          })
        )
        return modules
      }
    }
  )
  return context
}

export const useUpdateRepositoryConfig = (repositoryName: string) => {
  const mutation = useUpdate<
    UpdateRepositoryConfigRequestBody,
    UpdateRepositoryConfigRequestBody
  >(RESOURCES_NAMES.INVENTORIES.BRANCH_CONFIG(repositoryName))
  return mutation
}

const removeLeadingSlash = (path: string) => path.replace(/^\//, "")

export const useGetRepositoryConfig = (repositoryName: string) => {
  const context = useHttp<
    RepositoryConfigResponse,
    { config: Configs; correlations: RepositoryCorrelation[] }
  >(
    RESOURCES_NAMES.INVENTORIES.BRANCH_CONFIG(repositoryName),
    {},
    {
      enabled: !!repositoryName,
      select(data) {
        if (!data || !data.data)
          return {
            config: {},
            correlations: []
          }
        const config = data.data.config
        const correlations = data.data.imageCorrelations || []
        const correlationSource = correlations.reduce(
          (acc, correlation) => ({
            ...acc,
            [correlation.imageRepository]: correlation.source
          }),
          {}
        ) as Record<string, CorrelationSource>
        if (!config) return { config: {}, correlations }
        const formattedConfigurations = config.projects.reduce(
          (acc, project) => {
            for (const path of project.paths) {
              acc[removeLeadingSlash(path)] = {
                projectName: project.name,
                splitPath: project.split,
                exclude: !!project.ignore,
                imageRepositories: project.imageRepositories.map(
                  (imageRepository) => ({
                    imageRepository,
                    correlationType:
                      correlationSource[imageRepository] || "manual"
                  })
                )
              }
            }
            return acc
          },
          {} as Configs
        )
        return { config: formattedConfigurations, correlations }
      }
    }
  )
  return context
}

export const useResetRepositoryConfig = (repositoryName: string) => {
  const mutation = useDelete<undefined, undefined>(
    RESOURCES_NAMES.INVENTORIES.BRANCH_CONFIG(repositoryName)
  )
  return mutation
}
