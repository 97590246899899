import { EVENTS } from "@/const/event.constants"
import { CHECKBOX_ISSUE_DATA_ID, ISSUES_LIMIT } from "@/const/table-constants"
import { IssuesSummaryCell } from "@/molecules/IssuesSummaryCell"

import type { SortOrder } from "@/atoms"
import type { NumberConvertor } from "@/hooks/useNumberConvertor"
import type { ORDER_BY_PROPS } from "@/hooks/useOrderBy"
import type { TFunction } from "@/hooks/useTranslation"
import type Logger from "@/services/logger-adapter"

export const preventDisplayTitleOnCell = () => ({ title: "" })

export const getDefaultColumnParams = (
  dataIndex: string,
  sorter?: Omit<ORDER_BY_PROPS, "onSorterChange">,
  attributes: { customSorterName?: string } = {}
) => {
  return {
    dataIndex,
    key: dataIndex,
    ellipsis: true,
    ...(sorter
      ? {
          sorter: true,
          showSorterTooltip: false,
          defaultSortOrder:
            sorter.orderBy === dataIndex ? sorter.ascending : undefined,
          sortDirections: ["descend", "ascend", "descend"] as SortOrder[],
          ...attributes
        }
      : {})
  }
}

export const scrollToElement = (rowKey: string, index?: number) => {
  const element = document.querySelector(`[data-row-key="${rowKey}"]`)

  if (element) {
    const rect = element.getBoundingClientRect()
    const offsetProperty = getComputedStyle(element).getPropertyValue(
      "--offset-scroll-margin-top"
    )
    const offset = parseInt(offsetProperty, 10)

    if (rect.top < offset) {
      element.setAttribute("style", "background: var(--selected-bg-color)")

      if (index !== undefined)
        document.getElementById("PageWithTabsTemplate")?.scrollTo({
          top: 60 + index * 40,
          behavior: "smooth"
        })
      else
        element.scrollIntoView({
          behavior: "smooth",
          inline: "start"
        })

      setTimeout(() => {
        element.removeAttribute("style")
      }, 2000)
    }
  }
}

export const checkboxOnClick = <R extends { key: string }>(
  event: React.MouseEvent<HTMLElement>,
  props: {
    record: R
    cb: (record: R) => void
    logger?: Logger
    querySelector?: string
    analyticEvent?: Record<string, unknown>
  }
) => {
  const { record, cb, logger } = props

  try {
    const target = event.target as HTMLElement
    const { querySelector } = props

    if (
      target?.tagName?.toLowerCase() === "label" &&
      target.querySelector(querySelector || `[${CHECKBOX_ISSUE_DATA_ID}]`)
    )
      return

    logger?.info(EVENTS.ANALYTIC_EVENTS.DRAWER.OPENED, {
      id: record.key,
      ...props.analyticEvent
    })

    cb(record)
  } catch (err: any) {
    logger?.error(err)
  }
}

export const CELLS = {
  getIssuesSummaryCell: (
    props: {
      convertors: NumberConvertor
      t: TFunction
      sorter?: Omit<ORDER_BY_PROPS, "onSorterChange">
    },
    attr: Record<string, unknown> = {}
  ) => ({
    title: "Issues Summary",
    width: 230,
    render: IssuesSummaryCell(props, ISSUES_LIMIT),
    ...getDefaultColumnParams("severity", props.sorter),
    defaultSortOrder: "descend" as SortOrder,
    sortDirections: ["descend", "ascend", "descend"] as SortOrder[],
    ...attr
  })
}
