import { useNotifyAdminsOnCallbackIntegrations } from "@/api/useIntegrations"
import { Button, Form, message as messageToast, Text } from "@/atoms"
import { useSlugSignInRedirect } from "@/hooks/useSlugSignInRedirect"
import { useTranslation } from "@/hooks/useTranslation"
import { CheckCircleFilled } from "@/icons"
import { SlugInputFormItem } from "@/molecules/SlugInputFormItem"
import { useIntegrationCallbackParams } from "@/pages/IntegrationsPage/hooks/useIntegrationCallbackParams"
import { useState } from "react"
import styles from "./UnauthorizedIntegrationCallbackPage.module.scss"

export const UnauthorizedIntegrationCallbackPage = () => {
  const { t: tCallback } = useTranslation("translation", {
    keyPrefix: "integrations.callback"
  })
  const { t } = useTranslation()
  const { mutateAsync, isPending: isLoading } =
    useNotifyAdminsOnCallbackIntegrations()
  const [notificationSent, setNotificationSent] = useState<boolean>(false)
  const navigate = useSlugSignInRedirect()
  const { values, error } = useIntegrationCallbackParams()
  const { invalidReason } = error
  const [form] = Form.useForm()
  if (invalidReason === "INVALID_PARAMS") {
    return (
      <Text className={styles.text} type="danger">
        {tCallback("invalidCallback")}
      </Text>
    )
  }
  const { vendor } = values

  const onSignIn = () => {
    form
      .validateFields()
      .then((values: { slug: string }) => {
        const { slug } = values
        navigate(slug)
      })
      .catch(() => {
        return
      })
  }

  const onFinish = async (value?: { slug: string }) => {
    if (!value) return
    const { slug } = value
    try {
      await mutateAsync({
        slug: slug,
        vendor: tCallback(`vendors.${vendor}`),
        callbackUrl: window.location.href
      })
      setNotificationSent(true)
    } catch {
      messageToast.error(tCallback("errorNotifyAdmin"))
    }
  }

  return (
    <div className={styles.container}>
      {notificationSent && (
        <>
          <CheckCircleFilled className={styles.successIcon} />
          <Text className={styles.title}>
            {tCallback("successNotifyAdminTitle")}
          </Text>
          <Text className={styles.text}>{tCallback("successNotifyAdmin")}</Text>
        </>
      )}
      {!notificationSent && (
        <>
          <Text className={styles.title}>
            {tCallback("unauthorizedCallbackTitle")}
          </Text>
          <Text className={styles.text}>
            {tCallback("unauthorizedCallback", {
              name: tCallback(`vendors.${vendor}`)
            })}
          </Text>
          <Form
            id="slug-form-unauthorized-callback"
            form={form}
            layout="vertical"
            className={styles.form}
            onFinish={onFinish}
          >
            <SlugInputFormItem name="slug" size="large" />
            <Text type="secondary" className={styles.secondaryText}>
              {tCallback("unauthorizedCallbackInstructions")}
            </Text>
            <div className={styles.buttonsContainer}>
              <Form.Item className={styles.itemForm}>
                <Button
                  className={styles.button}
                  type="ks"
                  htmlType="submit"
                  loading={isLoading}
                  disabled={isLoading}
                  size="large"
                >
                  {tCallback("notifyAdmin")}
                </Button>
              </Form.Item>
              <Form.Item className={styles.itemForm}>
                <Button
                  className={styles.button}
                  disabled={isLoading}
                  onClick={onSignIn}
                  size="large"
                >
                  {t("auth.signIn")}
                </Button>
              </Form.Item>
            </div>
          </Form>
        </>
      )}
    </div>
  )
}
