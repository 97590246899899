import type { FormInstance } from "@/atoms"
import { Button, Modal } from "@/atoms"
import { useModal } from "@/hooks/useModal"
import { useTranslation } from "@/hooks/useTranslation"
import { Webhook } from "@/icons"
import { FormWebhook } from "@/pages/IntegrationsPage/components/molecules/FormWebhook"
import { TestWebhookButton } from "@/pages/IntegrationsPage/components/molecules/TestWebhookButton"
import { ConnectIntegrationFooter } from "@/pages/IntegrationsPage/components/pages/ConnectIntegrationPage/ConnectIntegrationFooter"
import { useIntegration } from "@/pages/IntegrationsPage/hooks/useIntegration"
import { useWebhookAPI } from "@/pages/IntegrationsPage/hooks/useWebhookAPI"

import type { QueryObserverBaseResult } from "@/hooks/useHttp.types"
import styles from "./ActionsSectionForm.module.scss"

interface WebhookModalAutomationActionFormProps {
  refetch: QueryObserverBaseResult["refetch"]
  form: FormInstance
  path: string
}

export const WebhookModalAutomationActionForm: React.FC<
  WebhookModalAutomationActionFormProps
> = ({ refetch, form, path }) => {
  const { visible, show, hide } = useModal()
  const [_, formProps] = useIntegration({ vendor: "webhook" })
  const { createOrUpdateWebhook, onFinish, ...attr } = useWebhookAPI(formProps)
  const { t } = useTranslation()
  const text = t("integrations.webhook.form.buttons.add")

  return (
    <>
      <Button
        className={styles.button}
        icon={<Webhook />}
        type="link"
        onClick={show}
      >
        {text}
      </Button>
      <Modal
        title={text}
        open={visible}
        okText={text}
        footer={
          <>
            <TestWebhookButton formProps={formProps} webhookApi={attr} />
            <ConnectIntegrationFooter
              okText="Add Endpoint"
              isLoading={attr.isPending}
              okProps={{ form: "webhook-form", htmlType: "submit" }}
              onCancel={hide}
            />
          </>
        }
        onCancel={hide}
      >
        <FormWebhook
          onFinish={async (values) => {
            const result = (await createOrUpdateWebhook(values)) as
              | { data: { webhook: { id: string } } }
              | false

            if (result) {
              await refetch()

              form.setFieldValue(
                ["actions", path, "webhooks"],
                [
                  ...(form.getFieldValue(["actions", path, "webhooks"]) || []),
                  result.data.webhook.id
                ]
              )
            }

            hide()
          }}
          {...attr}
          {...formProps}
        />
      </Modal>
    </>
  )
}
