import { Flex } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"

import type { DescriptionProps } from "."

export const DescriptionLabelConfigItem = (props: DescriptionProps) => {
  const { count, tKey, Icon } = props
  const { t } = useTranslation()

  return (
    <Flex gap={4}>
      <Icon />
      {t(tKey, { count })}
    </Flex>
  )
}
