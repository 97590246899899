import { Button, Dropdown, Tooltip } from "@/atoms"
import { useExport } from "@/hooks/useExport"
import { useLogger } from "@/hooks/useLogger"
import { DownloadOutlined } from "@ant-design/icons"

import { useNumberConvertor } from "@/hooks/useNumberConvertor"
import { useTranslation } from "@/hooks/useTranslation"
import { useSearchParams } from "react-router-dom"
import type { ExportButtonProps } from "."
import { getExportItems } from "./getExportItems.helper"

export const ExportButton = ({
  exportType,
  isLoading,
  filters,
  exportLimit,
  exceededLimit
}: ExportButtonProps) => {
  const exportItems = getExportItems(exportType, filters)
  const { tasksByUrl, addTask } = useExport()
  const { logger } = useLogger()
  const {
    url: keyByUrl,
    label,
    queryStringParams = [],
    params,
    analytic,
    excludeParams = false
  } = exportItems[0]
  const [searchParams] = useSearchParams()
  const convertors = useNumberConvertor()
  const { t } = useTranslation()
  const disabledTooltip = exportLimit
    ? t("general.exportLimitReachedTooltip", {
        exportLimit: convertors.intlNumber(exportLimit)
      })
    : label
  const isDisabled = !!tasksByUrl[keyByUrl] || isLoading || !!exceededLimit
  if (exportItems.length > 1) {
    return (
      <Dropdown
        disabled={isDisabled}
        menu={{
          items: exportItems,
          onClick: ({ key }) => {
            const result = exportItems.find((item) => item.key === key)

            if (result) {
              addTask(result.url, result.params)

              if (analytic) {
                const { event, type } = analytic

                logger.info(event, { type })
              }
            }
          }
        }}
        trigger={["click"]}
      >
        <Button disabled={isDisabled} icon={<DownloadOutlined />} />
      </Dropdown>
    )
  }
  const queryParams = params
    ? new URLSearchParams(params.filters)
    : searchParams
  const include = () => {
    const params = queryStringParams.reduce(
      (acc, name) => {
        const value = queryParams.get(name)

        if (value) acc[name] = value

        return acc
      },
      {} as Record<string, string>
    )
    return params
  }

  const exclude = () => {
    queryStringParams.forEach((name) => {
      queryParams.delete(name)
    })

    const result: Record<string, string> = {}
    queryParams.forEach((value, key) => {
      result[key] = value
    })

    return result
  }
  const calculatedParams = excludeParams ? exclude() : include()
  return (
    <Tooltip title={exceededLimit ? disabledTooltip : label} placement="left">
      <Button
        disabled={isDisabled}
        icon={<DownloadOutlined />}
        onClick={() => addTask(keyByUrl, calculatedParams, analytic)}
      />
    </Tooltip>
  )
}
