import { Alert, Form, Modal, Spin } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { useEffect, useState } from "react"

import styles from "./UserModal.module.scss"

import { useGetUserScopes } from "@/api/useUsers"
import type { UserModalProps } from "."
import { getScopeOptions } from "."
import { UserForm } from "../UserForm"

export const UserModal = ({
  actions,
  onClose,
  currentUser
}: UserModalProps) => {
  const { t } = useTranslation("translation", {})
  const keyPrefix = "settings.userModal"
  const { t: tModal } = useTranslation("translation", { keyPrefix })
  const { t: tAction } = useTranslation("translation", {
    keyPrefix: `${keyPrefix}.${currentUser?.id ? "edit" : "invite"}`
  })
  const userId = currentUser?.id
  const [form] = Form.useForm()
  const dirty = Form.useWatch("dirty", form)
  const [values, setValues] = useState<any>(null)
  const [actionLoading, setActionLoading] = useState(false)
  const [serverError, setServerError] = useState(false)
  const {
    response,
    refetch,
    isLoading: isScopesLoading
  } = useGetUserScopes(
    { user_id: userId },
    {
      select: getScopeOptions,
      enabled: !!currentUser,
      gcTime: 0,
      staleTime: 0
    }
  )

  const isFormLoading = !currentUser || isScopesLoading

  useEffect(() => {
    if (currentUser === null) {
      return
    }
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  const onSubmit = () => {
    form.submit()
  }

  return (
    <Modal
      destroyOnClose
      width={650}
      open={!!currentUser}
      className={styles.container}
      title={tAction("title")}
      cancelText={tModal("cancelText")}
      okText={tAction("okText")}
      okButtonProps={{
        loading: actionLoading,
        disabled: !values || isFormLoading || !dirty
      }}
      onOk={onSubmit}
      onCancel={onClose}
      afterOpenChange={(isOpen) => {
        if (!isOpen) {
          setServerError(false)
        }
      }}
    >
      {serverError && (
        <Alert type="error" message={t("error.serverError")} banner />
      )}
      {isFormLoading && <Spin className={styles.spinContainer} spinning />}
      {!isFormLoading && (
        <UserForm
          user={currentUser}
          form={form}
          actions={actions}
          scopeOptions={response}
          setLoading={setActionLoading}
          setValues={setValues}
          serverError={serverError}
          setServerError={setServerError}
          onClose={onClose}
          isScopesLoading={isScopesLoading}
        />
      )}
    </Modal>
  )
}
