import { Form, FormInstance } from "@/atoms"
import { useAuthMethods } from "@/pages/SettingsPage/application/auth/useAuthMethods"

export const useScimSettingsState = (
  form: FormInstance,
  idpFormItemKey: string = "saml",
  scimTokenIdFormItemKey: string = "scimTokenId",
  scimEnabledFormItemKey: string = "scimProvisioningEnabled"
) => {
  const { response } = useAuthMethods()
  const scimEnabled = Form.useWatch<boolean>(
    [idpFormItemKey, scimEnabledFormItemKey],
    form
  )
  const scimTokenId = Form.useWatch<string>(
    [idpFormItemKey, scimTokenIdFormItemKey],
    form
  )
  const initialTokenId = response?.data?.saml?.scimTokenId ?? ""
  const isScimValid = !scimEnabled || (scimTokenId ?? "") !== ""
  const shouldOpenGuard =
    scimEnabled &&
    initialTokenId &&
    scimTokenId &&
    initialTokenId !== scimTokenId

  return {
    isScimValid,
    shouldOpenGuard
  }
}
