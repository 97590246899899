import { ResourceWithPopover } from "./ResourceWithPopover"

import type { RowClick } from "@/hooks/useLinkDrawerTooltip"
import type { ResourceCellProps } from "."

export const ResourceCell = (onRowClick: RowClick) =>
  function ResourceCellComponent(
    value: string,
    { key, ...props }: ResourceCellProps
  ) {
    return (
      <ResourceWithPopover
        key={key}
        valueCell={value}
        onRowClick={onRowClick}
        {...props}
      />
    )
  }
