import { Flex, Text } from "@/atoms"

import styles from "./ConfigItem.module.scss"

import type { LabelConfigItemProps } from "."

export const LabelConfigItem = (props: LabelConfigItemProps) => {
  const { values, Description } = props
  const description = Description || values.description

  return (
    <Flex vertical>
      <Text strong>{values.name}</Text>
      {description && (
        <Text type="secondary">
          <Flex gap={4} className={styles.labelConfigItem__description}>
            <Text type="secondary">{Description || description}</Text>
          </Flex>
        </Text>
      )}
    </Flex>
  )
}
