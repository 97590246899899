import { ICONS_INSIGHTS } from "@/const/insights-constants"
import { InsightsCell } from "@/molecules/InsightsCell"

import type { TFunction } from "@/hooks/useTranslation"
import type { InsightsImageCellProps } from "./InsightsImageCell.types"

export const InsightsImageCell = (t: TFunction) =>
  function InsightsImageCell(values: InsightsImageCellProps) {
    return (
      <InsightsCell
        hide
        items={[ICONS_INSIGHTS.publicImage]}
        values={values}
        t={t}
      />
    )
  }
