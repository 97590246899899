export type ErrorType = "Forbidden" | "ACL_SIGNIN" | "Unknown" // Add more error types as needed

export const isAuthorizationError = (error: any) => {
  const { status } = error || {}
  return status === 403
}

export const isNotFoundError = (error: any) => {
  const { status } = error || {}
  return status === 404
}

export const isPreconditionError = (error: any) => {
  const { status } = error || {}
  return status === 412
}

export const getErrorType = (error: any) => {
  const { status } = error || {}
  return status === 403 ? "Forbidden" : "Unknown"
}

export const isNetworkError = (error: any) => {
  return !!error.status
}
