export type RegionCode = "hk" | "us"

const SUBDOMAIN_PREFIX_BY_REGION = {
  hk: "hk1"
} as const

export const getHref = () => window?.location?.href || ""

export const getHostname = () => window?.location?.hostname || ""

export const getRegionCode = (): RegionCode =>
  getHostname().split(".")?.[1] === SUBDOMAIN_PREFIX_BY_REGION.hk ? "hk" : "us"

export const isHK = () => getRegionCode() === "hk"
export const isUS = () => !isHK()

export const getLinkToRegion = (region: RegionCode) => {
  const currentHostname = getHostname()
  const list = getHostname().split(".")
  const defaultDomain = "app"

  if (list[0] !== defaultDomain) return

  const getSubdomain = (
    prefix: (typeof SUBDOMAIN_PREFIX_BY_REGION)[keyof typeof SUBDOMAIN_PREFIX_BY_REGION]
  ) => `${defaultDomain}.${prefix}`
  const getNextHref = (subdomain: string) =>
    getHref().replace(
      currentHostname,
      `${subdomain}.${list.slice(-2).join(".")}`
    )

  if (region === "hk")
    return getNextHref(getSubdomain(SUBDOMAIN_PREFIX_BY_REGION.hk))
  else return getNextHref(defaultDomain)
}
