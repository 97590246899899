import type { Issue, Ticket } from "@/domain/issue"
import type { TFunction } from "@/hooks/useTranslation"
import { ShowMore } from "@/molecules/ShowMore"
import { ActionCard } from "@/organisms/Drawers/components/TriageDrawer/components/ActionsCard"
import styles from "./TriageActionCell.module.scss"

export const TriageIssueActionCell = (t: TFunction) =>
  function TriageIssueActionCellComponent(tickets: Ticket[], record: Issue) {
    const count = tickets?.length

    if (!count) {
      return null
    }

    return (
      <ShowMore
        id={`actions_${record.id}`}
        label={
          <div className={styles.actionLabel}>
            <ShowMore.Label.Component>
              <ActionCard.Count count={count} />
            </ShowMore.Label.Component>
          </div>
        }
      >
        <ShowMore.Component title={t("general.actions")}>
          <ActionCard.List tickets={tickets} type={"secondary"} />
        </ShowMore.Component>
      </ShowMore>
    )
  }
