import { Button, Dropdown } from "@/atoms"
import { MoreOutlined } from "@/icons"

import styles from "./ActionsDropdown.module.scss"

import type { ActionsDropdownComponent } from "."

export const ActionsDropdown: ActionsDropdownComponent = (props) => {
  const { noPermissions, menu: menuProps, size = "small", ...rest } = props
  const menu = noPermissions ? { items: [] } : menuProps

  return (
    <Dropdown rootClassName={styles.rootClassName} menu={menu} {...rest}>
      <Button
        size={size}
        type="text"
        icon={<MoreOutlined />}
        disabled={noPermissions}
      />
    </Dropdown>
  )
}
