import { Checkbox, Collapse, Form, Text } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { SCIMCollapseFormProps } from "."
import { ScimTokenContainer } from "../../molecules/ScimTokenContainer"
import styles from "./SCIMCollapseForm.module.scss"

export const SCIMCollapseForm = ({
  idpFormItemKey,
  form,
  classNames = {}
}: SCIMCollapseFormProps) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "settings.auth.methods.saml.form"
  })

  const scimEnabledFormItemKey = "scimProvisioningEnabled"
  const scimTokenIdFormItemKey = "scimTokenId"

  const checked = Form.useWatch([idpFormItemKey, scimEnabledFormItemKey], form)

  return (
    <Collapse
      activeKey={checked && "checked"}
      ghost
      bordered={false}
      className={classNames.authorizationCollapse}
      items={[
        {
          key: "checked",
          showArrow: false,
          label: (
            <Form.Item
              name={[idpFormItemKey, scimEnabledFormItemKey]}
              valuePropName="checked"
              noStyle
            >
              <Checkbox>
                {t("enableSCIM")}{" "}
                <span className={classNames.optionalLabel}>
                  {t("optional")}
                </span>
              </Checkbox>
            </Form.Item>
          ),
          className: styles.panel,
          children: (
            <>
              <div className={classNames.note}>
                <Text>{`${t("scimNotes.mes")}:`}</Text>
                <Text>
                  {`${t("scimNotes.scimUrl")}:`}
                  <b>https://{window.location.hostname}/api/public/v1/scim/v2</b>
                </Text>
                <Text>
                  {`${t("scimNotes.authenticationMode")}:`}
                  <b>HTTP Header Authentication</b>
                </Text>
                <Text>
                  {`${t("scimNotes.provisioningActions")}:`}
                  <b>{t("scimNotes.userProvisioning")}</b>,
                  <b>{t("scimNotes.userDeProvisioning")}</b> and
                  <b>{t("scimNotes.userProfileUpdates")}</b>.
                </Text>
                <Text>
                  {`${t("scimNotes.important")}:`}{" "}
                  <b>{t("scimNotes.requiredIdentifier")}</b>
                </Text>
              </div>
              <Form.Item
                name={[idpFormItemKey, scimTokenIdFormItemKey]}
                label={
                  <>
                    {t("tokenLabel")}&nbsp;
                    <span className={classNames.optionalLabel}>
                      {t("required")}
                    </span>
                  </>
                }
              >
                <ScimTokenContainer
                  form={form}
                  formItemKey={[idpFormItemKey, scimTokenIdFormItemKey]}
                />
              </Form.Item>
            </>
          )
        }
      ]}
    />
  )
}
