import { useApiLoggingDemo } from "@/api/useDemo"
import { RESOURCES_NAMES } from "@/const/resource.constants"
import type { Issue } from "@/domain/issue"
import {
  Config,
  ConfigInfinityQuery,
  Params,
  useCreate,
  useHttp,
  useLoadMore,
  useUpdate
} from "@/hooks/useHttp"
import type { GetUseMutationOptions } from "@/hooks/useHttp.types"
import useServices from "@/hooks/useServices"
import { useQuery } from "@tanstack/react-query"
import { mockData } from "./useTriage.mockdata"

import type {
  AttackChainFeedback,
  AttackChainsResponse,
  FindingsTriageResponse,
  FixesTriageResponse,
  IssuesTriageResponse,
  ProjectsDescriptionTriageResponse,
  ProjectsTriageResponse
} from "./useTriage.types"

export const useProjectsTriage = <T extends ProjectsTriageResponse>(
  params?: Params<Record<string, unknown>>,
  config?: Config<T>
) => {
  const context = useHttp<T>(RESOURCES_NAMES.TRIAGE.PROJECTS, params, config)

  return context
}

export const useProjectDescriptionTriage = <
  T extends ProjectsDescriptionTriageResponse
>(
  params: Params<{ needle: string }>,
  config?: Config<T>
) => {
  const { needle, ...restParams } = params
  const context = useHttp<T>(
    `${RESOURCES_NAMES.TRIAGE.PROJECTS}/${needle}`,
    restParams,
    { enabled: !!needle, ...config }
  )

  return context
}

export const useLoadMoreIssuesProjectTriage = <T = Issue>(
  params: Params<{ needle: string; pageNumber?: number | string }>,
  config: ConfigInfinityQuery<T>
) => {
  const { needle = "", pageNumber, ...restParams } = params

  const context = useLoadMore<T>(
    `${RESOURCES_NAMES.TRIAGE.PROJECTS}${needle}`,
    restParams,
    config
  )

  return context
}

export const useIssuesProjectTriage = <T extends IssuesTriageResponse>(
  params: Params<{ needle: string; pageNumber?: number | string }>,
  config?: Config<T>
) => {
  const { needle = "", ...restParams } = params

  const context = useHttp(
    `${RESOURCES_NAMES.TRIAGE.PROJECTS}${needle}`,
    restParams,
    config
  )

  return context
}

export const useIssuesByResourceTriage = <T extends IssuesTriageResponse>(
  params: Params<{
    resourceId?: string
    pageNumber?: number | string
    issueType?: string
  }>,
  config?: Config<T>
) => {
  const context = useHttp(`${RESOURCES_NAMES.TRIAGE.ISSUES}`, params, config)
  const nextContext = useApiLoggingDemo(context)

  if (params.issueType === "Vulnerable_Code,Exposed_Secret") return nextContext

  return context
}

export const useFiltersTriage = <
  T = KosmosApi.Response<Record<string, string[]>>
>(
  config: Config<T>
) => {
  const context = useHttp<T>(RESOURCES_NAMES.TRIAGE.FILTERS, undefined, config)

  return context
}

export const useIssueFindings = <T extends FindingsTriageResponse, S = T>(
  params: Params<{ needle: string }>,
  config: Config<T, S>
) => {
  const { needle, ...restParams } = params

  const context = useHttp<T, S>(
    `${RESOURCES_NAMES.TRIAGE.ISSUES}/${needle}/findings`,
    restParams,
    config
  )
  return context
}

export const useDismissIssues = <T, S>(
  params: Record<string, unknown>,
  options?: GetUseMutationOptions<T, S>
) => {
  const context = useUpdate(
    RESOURCES_NAMES.TRIAGE.PROJECTS,
    params,
    undefined,
    options
  )
  return context
}

export const useCreateTicketIssue = () => {
  const context = useCreate<
    { data: Jira.CreateIssuePayload; needle?: string },
    Jira.CreateIssuePayload
  >(`${RESOURCES_NAMES.TRIAGE.ISSUES}`, {}, (oldData, _) => oldData)

  return context
}

export const useCalculateFixes = <T = FixesTriageResponse, S = unknown>() => {
  const context = useCreate<T, S>(`${RESOURCES_NAMES.TRIAGE.ISSUES}/fix`)

  return context
}

export const useIssue = <T = KosmosApi.Response<Issue>, S = T>(
  id: undefined | string,
  params?: Params,
  config?: Config<T, S>
) => {
  const context = useHttp<T, S>(
    `${RESOURCES_NAMES.TRIAGE.ISSUES}/${id}`,
    params,
    { enabled: !!id, ...config }
  )

  return context
}

export const useAttackChains = <T = AttackChainsResponse, S = T>(
  issueId: undefined | string,
  params?: Params,
  config?: Config<T, S>
) => {
  const { auth } = useServices()
  const isDemo = auth.isDemo()
  const url = `${RESOURCES_NAMES.TRIAGE.ISSUES}/${issueId}/chains`
  // TODO: remove once we migrate demo data
  const { data, ...demoCtx } = useQuery<T>({
    queryKey: ["demo", url, params],
    queryFn: () => {
      if (!issueId) return null
      const attack = mockData[issueId]
      if (!attack) return null
      return {
        data: attack.scenarios,
        metadata: null,
        ok: true
      } as any
    },
    enabled: !!issueId && isDemo
  })
  // demo uses mockdata. until data is fully migrated, continue service the mock data
  const context = useHttp<T, S>(
    `${RESOURCES_NAMES.TRIAGE.ISSUES}/${issueId}/chains`,
    params,
    { enabled: !!issueId && !isDemo, ...config }
  )

  return isDemo ? { ...demoCtx, response: data } : context
}

export const useAttackChainFeedback = <
  T = { needle: string },
  S = { data: { feedback: AttackChainFeedback } }
>(
  params?: Record<string, unknown>,
  options?: GetUseMutationOptions<T, S>
) => {
  const context = useUpdate<T, S>(
    RESOURCES_NAMES.TRIAGE.CHAINS,
    params,
    undefined,
    options
  )
  return context
}
