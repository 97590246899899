import { useTenantSignup } from "@/api/useTenantSignup"
import { Button, Form, Input, Rule, Title } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import styles from "./TenantSignupForm.module.scss"

import { useRecaptcha } from "@/pages/TenantSignupPage/hooks/useRecaptcha"
import type { TenantSignupFormProps, ValuesTenantSignupForm } from "."

export const TenantSignupForm = (props: TenantSignupFormProps) => {
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const { t: tModal } = useTranslation("translation", {
    keyPrefix: "auth.tenantSignup"
  })
  const { entitlementId, setIsTenantCreated } = props
  const { mutateAsync: signupMutateAsync, isPending: isSignupLoading } =
    useTenantSignup()
  const requiredRule: Rule = {
    required: true,
    message: t("error.requiredField")
  }
  const { reset, getToken, isRecaptchaLoaded } = useRecaptcha({
    containerId: "recaptcha-container",
    size: "normal",
    userAction: "signup"
  })

  const setErrorMessage = (fieldName: string, error: string) => {
    form.setFields([
      {
        name: fieldName,
        errors: [error]
      }
    ])
  }

  const onFinish = async (values: ValuesTenantSignupForm) => {
    if (!isRecaptchaLoaded) {
      setErrorMessage("recaptcha", "Error loading recaptcha")
      return
    }
    const captchaValue = getToken()
    if (!captchaValue) {
      setErrorMessage("recaptcha", tModal("recaptchaError"))
      return
    }
    try {
      setErrorMessage("recaptcha", "")

      await signupMutateAsync({
        tenant_name: values.tenantName,
        slug: values.tenantSlug,
        contact_name: values.contactName,
        contact_email: values.tenantEmail,
        re_token: captchaValue,
        entitlement_id: entitlementId ?? ""
      })

      setIsTenantCreated(true)
    } catch (error: any) {
      reset()
      const status = (error as Response).status
      switch (status) {
        case 403:
          setErrorMessage("submit", tModal("forbiddenMethodError"))
          break
        case 409:
          setErrorMessage("tenantSlug", tModal("slugAlreadyExists"))
          setErrorMessage("submit", "")
          break
        default:
          setErrorMessage("submit", tModal("errorInCreatingOrganization"))
      }
    }
  }

  return (
    <Form
      form={form}
      validateTrigger={["onBlur"]}
      className={styles.form}
      name="tenantSignupForm"
      layout="vertical"
      requiredMark={true}
      onFinish={onFinish}
      scrollToFirstError={true}
    >
      <Title className={styles.title} level={4}>
        {tModal("title")}
      </Title>

      <Form.Item
        name="tenantName"
        label={tModal("organizationName")}
        rules={[
          requiredRule,
          { min: 4, message: tModal("organizationNameLengthMessage") },
          {
            pattern: new RegExp("^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$"),
            message: tModal("organizationNamePatternMessage")
          }
        ]}
      >
        <Input
          placeholder={tModal("organizationName") || ""}
          data-testid="tenantName"
        />
      </Form.Item>

      <Form.Item
        name="tenantSlug"
        label={tModal("organizationSlug")}
        rules={[
          requiredRule,
          { min: 4, message: tModal("organizationSlugLengthMessage") },
          {
            pattern: new RegExp("^[a-z0-9-]*$"),
            message: tModal("organizationSlugPatternMessage")
          }
        ]}
      >
        <Input
          placeholder={tModal("organizationSlug") || ""}
          data-testid="tenantSlug"
        />
      </Form.Item>

      <Form.Item
        name="contactName"
        label={tModal("contactName")}
        rules={[requiredRule]}
      >
        <Input
          placeholder={tModal("contactName") || ""}
          data-testid="contactName"
        />
      </Form.Item>

      <Form.Item
        name="tenantEmail"
        label={tModal("contactEmail")}
        rules={[
          requiredRule,
          { type: "email", message: t("error.emailInvalid") || "" }
        ]}
      >
        <Input
          placeholder={tModal("emailPlaceholder") || ""}
          data-testid="tenantEmail"
        />
      </Form.Item>

      <Form.Item name="recaptcha">
        <div
          id="recaptcha-container"
          className={isRecaptchaLoaded ? "" : styles.error}
        >
          {isRecaptchaLoaded ? "" : tModal("recaptchaNotLoadedError")}
        </div>
      </Form.Item>
      <Form.Item name="submit">
        <Button
          block
          className={styles.button}
          disabled={isSignupLoading || !isRecaptchaLoaded}
          loading={isSignupLoading}
          htmlType="submit"
          size="large"
          type="primary"
        >
          {t("general.submit")}
        </Button>
      </Form.Item>
    </Form>
  )
}
