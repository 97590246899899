import { createUser, Permission, User } from "@/domain/user"
import { createContext, useContext } from "react"

export const UserContext = createContext<{
  user: User
  signOut: () => void
  setCurrentScope: (scopeId: string | null) => void
  updateFeatureFlags: (featureFlags: Partial<Features.Flags>) => void
  hasPermission: (permission: Permission) => boolean | undefined
}>({
  user: createUser(),
  signOut: () => {},
  setCurrentScope: () => {},
  updateFeatureFlags: () => {},
  hasPermission: () => true
})

export const useUser = () => {
  const user = useContext(UserContext)

  return user
}

type FeatureFlagsKeys = keyof Features.Flags

export function useFeatureFlags<K extends FeatureFlagsKeys>(
  name: K
): Features.Flags[K]
export function useFeatureFlags(name: undefined): Features.Flags
export function useFeatureFlags(name?: FeatureFlagsKeys) {
  const { user } = useUser()
  const { featureFlags } = user

  if (!name) {
    return featureFlags
  }

  return featureFlags[name]
}
