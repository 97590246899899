import { useInstallIntegration, useTestWebhook } from "@/api/useIntegrations"
import { INTEGRATION_VENDORS } from "@/const/vendors-constants"

import { Values, WebhookAPIProps } from "./useWebhookAPI.types"

export const useWebhookAPI = (props: WebhookAPIProps) => {
  const { values, t, message: messageApi } = props || {}
  const params = { vendor: INTEGRATION_VENDORS.webhook }
  const webhookId = values?.id
  const formId = webhookId || `${params.vendor}-form`
  const installer = useInstallIntegration(params)
  const testWebhook = useTestWebhook()
  const messagePrefix = "integrations.webhook.messages"

  const createOrUpdateWebhook = async (
    values: { name: string } & Record<string, unknown>
  ) => {
    const prefix = `${messagePrefix}.${webhookId ? "update" : "add"}`

    try {
      const payload = {
        data: { webhook: webhookId ? { id: webhookId, ...values } : values }
      }
      let result

      if (webhookId) result = await props.onUpdate(payload)
      else result = await installer.mutateAsync(payload)

      messageApi?.success(t(`${prefix}.success`, { name: values.name }))

      return result
    } catch (error: any) {
      const displayMessage = error?.data?.displayMessage

      messageApi?.error(displayMessage || t(`${prefix}.failed`))

      return false
    }
  }

  const onFinish = async (values: Values) => {
    const result = await createOrUpdateWebhook(values)

    if (!result) return

    if (!webhookId) props.backToIntegration(true)
    else props.setIsDirty(false)
  }

  const onTest = async (url: string, secret?: string) => {
    try {
      messageApi?.loading("Testing webhook")

      const { data } = await testWebhook.mutateAsync({
        webhook: { url, secret }
      })

      const { success, message } = data

      messageApi?.destroy()

      if (!success) {
        if (message) return messageApi?.error(message)

        throw new Error()
      }

      messageApi?.success(t("general.success"))
    } catch {
      messageApi?.error(t("general.failed"))
    }
  }

  const isPending = installer.isPending

  return {
    formId,
    isPending,
    onFinish,
    onTest,
    t,
    createOrUpdateWebhook,
    isTesting: testWebhook.isPending,
    SECRET_FIELD_NAME: "secret",
    URL_FIELD_NAME: "url",
    initialValues: values
  }
}
