import { Pagination } from "@/atoms"
import { useNumberConvertor } from "@/hooks/useNumberConvertor"
import { useTranslation } from "@/hooks/useTranslation"
import { useEffect, useState, useTransition } from "react"
import { useSearchParams } from "react-router-dom"

import styles from "./PaginationTab.module.scss"

import type { PaginationTabProps } from "."

export const PaginationTab = (props: PaginationTabProps) => {
  const { loading, metadata, onPaginationChange, pageSize = 100 } = props
  const { page: pageFromMetadata, total: totalFromMetadata } = metadata || {}
  const [total, setTotal] = useState(totalFromMetadata)
  const { t } = useTranslation()
  const hook = onPaginationChange ? useState : useSearchParams
  const initialState = onPaginationChange
    ? new URLSearchParams({ pageNumber: "0" })
    : undefined
  const [searchParams, setSearchParams] = hook(initialState)
  const [_, startTransition] = useTransition()
  const { intlNumber } = useNumberConvertor()
  const totalValue = intlNumber(total || 0)
  const totalText = t("general.result", { count: total })
  const current =
    (props.current ?? parseInt(searchParams?.get("pageNumber") || "0")) + 1

  const onChange =
    props.onChange ??
    ((pageNumber: number) => {
      startTransition(() => {
        const nextValue = `${pageNumber - 1}`
        searchParams?.set("pageNumber", nextValue)
        setSearchParams(searchParams)
        onPaginationChange?.(nextValue)
      })
    })

  useEffect(() => {
    const shouldUpdateTotal =
      totalFromMetadata !== undefined && totalFromMetadata !== total

    if (shouldUpdateTotal) {
      const pageNumberFromMetadata = (pageFromMetadata || 0) + 1
      const shouldUpdateInitialPage =
        onPaginationChange && current !== pageNumberFromMetadata

      if (shouldUpdateInitialPage) {
        searchParams?.set("pageNumber", `${pageFromMetadata}`)
        setSearchParams(searchParams)
      }

      setTotal(totalFromMetadata)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalFromMetadata])

  if (total === undefined) return null

  const { totalRender } = props
  const totalDisplayValue = `${totalValue} ${totalText}`

  return (
    <div className={styles.container}>
      {!totalRender && <span>{totalDisplayValue}</span>}
      {totalRender && totalRender({ loading, total })}
      {total !== 0 && (
        <Pagination
          simple
          size="small"
          showSizeChanger={false}
          pageSize={pageSize}
          current={current}
          onChange={onChange}
          total={total}
        />
      )}
    </div>
  )
}
