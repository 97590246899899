import cn from "classnames"

import { Link } from "@/atoms"
import { isMaliciousPackageType, isVulnerablePackageType } from "@/domain/issue"
import { getLinkByVulnerability } from "@/domain/vulnerability"
import {
  DEFAULT_LONG_FORMAT,
  getDateFromSeconds,
  getDateInUserLocale
} from "@/helpers/date.helpers"
import { useTranslation } from "@/hooks/useTranslation"
import { ImpactCell } from "@/molecules/ImpactCell"
import { OverviewDescriptions } from "@/organisms/Drawers/components/OverviewDescriptions"
import { OverviewCardTemplate } from "@/organisms/Drawers/components/PackageDrawer/components/OverviewCardTemplate"
import { useDrawer } from "@/organisms/Drawers/hooks/useDrawer"
import { IntroduceThroughInsightsICell } from "@/pages/TriagePage/components/molecules/IntroduceThroughInsightsICell"

import type { Issue } from "@/domain/issue"
import type { Item } from "@/organisms/Drawers/components/OverviewDescriptions"
import { PackageVersions } from "../PackageVersions"

import styles from "./SummaryCard.module.scss"

export const SummaryCard = ({ record }: { record: any }) => {
  const { t } = useTranslation()
  const { onRowClick } = useDrawer("")

  if (!record) return null

  const { packageName, introducedThroughInsights, cwe, discoveredAt } = record
  const { issueType } = record as Issue
  const { applicationsCount } = record as any
  const emptyValue = "-"
  const someIntroducedThroughInsightsExist =
    !introducedThroughInsights.allFalse &&
    Object.keys(introducedThroughInsights).some(
      (key) =>
        !!introducedThroughInsights[
          key as keyof typeof introducedThroughInsights
        ]
    )
  const isVulnerablePackage = isVulnerablePackageType(issueType)
  const isMaliciousPackage = isMaliciousPackageType(issueType)

  const items: Item[] = [
    {
      label: (
        <OverviewDescriptions.Label>
          {t("general.type")}
        </OverviewDescriptions.Label>
      ),
      children: t(`issueTypes.${record.issueType}`)
    }
  ]

  if (isVulnerablePackage || isMaliciousPackage) {
    items.push(
      {
        label: (
          <OverviewDescriptions.Label>
            {t("general.packageVersion_other")}
          </OverviewDescriptions.Label>
        ),
        children: (
          <PackageVersions
            name={packageName}
            version={record.packageVersions.join(", ")}
          />
        )
      },
      {
        label: (
          <OverviewDescriptions.Label>
            {t("general.introducedThrough")}
          </OverviewDescriptions.Label>
        ),
        children: someIntroducedThroughInsightsExist
          ? IntroduceThroughInsightsICell({ tag: true, t })({
              ...introducedThroughInsights
            })
          : emptyValue
      }
    )
  }

  items.push({
    label: <OverviewDescriptions.Label>CWE</OverviewDescriptions.Label>,
    children: cwe.description ? (
      <Link
        className={styles.cweLink}
        href={getLinkByVulnerability({ cve: cwe.id })}
        target="_blank"
      >
        {cwe.id}
      </Link>
    ) : (
      emptyValue
    )
  })

  if (!isMaliciousPackage) {
    items.push({
      label: <OverviewDescriptions.Label>OWASP</OverviewDescriptions.Label>,
      children: record?.owasp ? record.owasp : emptyValue
    })
  }

  items.push(
    {
      label: (
        <OverviewDescriptions.Label>
          {t("general.impact")}
        </OverviewDescriptions.Label>
      ),
      children:
        applicationsCount.items.length > 0
          ? ImpactCell({ onRowClick, t })(applicationsCount)
          : emptyValue
    },
    {
      label: (
        <OverviewDescriptions.Label>
          {t("general.discoveredAt")}
        </OverviewDescriptions.Label>
      ),
      children: discoveredAt
        ? getDateInUserLocale(
            getDateFromSeconds(discoveredAt),
            DEFAULT_LONG_FORMAT
          )
        : emptyValue
    }
  )

  return (
    <OverviewCardTemplate
      title="Summary"
      items={items}
      column={isMaliciousPackage ? 2 : 1}
      classes={{
        card: cn(isMaliciousPackage && styles.twoColumnsDescriptionContainer)
      }}
    />
  )
}
