import { Navigate, Route, Routes } from "react-router-dom"

import { message } from "@/atoms"
import { NAMES_ROUTES } from "@/const/routes.constants"
import { INTEGRATION_VENDORS } from "@/const/vendors-constants"
import { isUserAdmin } from "@/domain/user"
import { useUser } from "@/hooks/useUser"
import { EcrConfigurationPage } from "@/pages/IntegrationsPage/components/pages/EcrConfigurationPage"
import { GitHubConfigurationPage } from "@/pages/IntegrationsPage/components/pages/GitHubConfigurationPage"
import { GitLabConfigurationPage } from "@/pages/IntegrationsPage/components/pages/GitLabConfigurationPage"
import { WebhookConfigurationPage } from "@/pages/IntegrationsPage/components/pages/WebhookConfigurationPage"
import { AzureIntegrationPage } from "./components/pages/AzureIntegrationPage"
import { CommonIntegrationPage } from "./components/pages/CommonIntegrationPage"
import { ConnectIntegrationPage } from "./components/pages/ConnectIntegrationPage"
import { GitlabIntegrationPage } from "./components/pages/GitLabIntegrationPage"
import { IntegrationPage } from "./components/pages/IntegrationPage"
import { ListIntegrationsPage } from "./components/pages/ListIntegrationsPage"
import { UnauthorizedIntegrationCallbackPage } from "./components/pages/UnauthorizedIntegrationCallbackPage"
import { WebhookIntegrationPage } from "./components/pages/WebhookIntegrationPage"

import type { IntegrationsPageProps } from "."
import { AzureConfigurationPage } from "./components/pages/AzureConfigurationPage"

export const IntegrationsPage = (props: IntegrationsPageProps) => {
  const { authorizedUser } = props
  const { INTEGRATIONS } = NAMES_ROUTES
  const [messageApi, messageContextHolder] = message.useMessage()
  const { user } = useUser()

  return (
    <>
      {messageContextHolder}
      <Routes>
        {!authorizedUser && (
          <>
            <Route path="/" element={<UnauthorizedIntegrationCallbackPage />} />
          </>
        )}
        {authorizedUser && (
          <>
            <Route path="/" element={<ListIntegrationsPage />} />
            <Route
              path={`/${INTEGRATIONS.CALLBACK}`}
              element={<IntegrationPage />}
            />
            <Route
              path={`/${INTEGRATION_VENDORS.gitlab}`}
              element={<GitLabConfigurationPage />}
            />
            <Route
              path={`/${INTEGRATION_VENDORS.github}`}
              element={<GitHubConfigurationPage />}
            />
            <Route
              path={`/${INTEGRATION_VENDORS.webhook}`}
              element={<WebhookConfigurationPage />}
            />
            <Route
              path={`/${INTEGRATION_VENDORS.ecr}`}
              element={<EcrConfigurationPage />}
            />
            <Route
              path={`/${INTEGRATION_VENDORS.azure}`}
              element={<AzureConfigurationPage />}
            />
            <Route path="/:vendor" element={<CommonIntegrationPage />} />

            {isUserAdmin(user) && (
              <>
                <Route
                  path={`/${INTEGRATION_VENDORS.gitlab}/connect`}
                  element={<GitlabIntegrationPage messageApi={messageApi} />}
                />
                <Route
                  path={`/${INTEGRATION_VENDORS.azure}/connect`}
                  element={<AzureIntegrationPage messageApi={messageApi} />}
                />
                <Route
                  path={`/${INTEGRATION_VENDORS.webhook}/connect`}
                  element={<WebhookIntegrationPage />}
                />
                <Route
                  path="/:nameIntegration/connect"
                  element={<ConnectIntegrationPage messageApi={messageApi} />}
                />
              </>
            )}
            <Route
              path="*"
              element={<Navigate to={`/${NAMES_ROUTES.INTEGRATIONS.ROOT}`} />}
            />
          </>
        )}
      </Routes>
    </>
  )
}
