import { useGetInventoryCounts } from "@/api/useInventory"
import { Tabs } from "@/atoms"
import { TAB_KEYS_BY_PAGE } from "@/const/tab.constants"
import { useNumberConvertor } from "@/hooks/useNumberConvertor"
import { useQueryStringStorage } from "@/hooks/useQueryStringStorage"
import { useTranslation } from "@/hooks/useTranslation"
import { useFeatureFlags } from "@/hooks/useUser"
import { LabelTabItem } from "@/molecules/LabelTabItem"
import { HeaderPage } from "@/organisms/HeaderPage"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { ApplicationsTab } from "../ApplicationsTab"
import { BranchTab } from "../BranchTab"
import { ContainerBaseImagesTab } from "../ContainerBaseImagesTab"
import { ImagesTab } from "../ImagesTab"
import { SbomTab } from "../SbomTab"
import { VirtualMachinesTab } from "../VirtualMachinesTab"

import type { HeaderInventoryPageProps } from "."

export const HeaderInventoryPage: React.FC<HeaderInventoryPageProps> = ({
  useActiveKeyTabsInventory
}) => {
  const pageBaseUrl = "/inventory"
  const TAB_KEYS = TAB_KEYS_BY_PAGE.INVENTORY
  const { activeKey, setActiveKey, config = {} } = useActiveKeyTabsInventory()
  const { response } = useGetInventoryCounts({}, { ...config })
  const { data } = response || {}
  const {
    application = 0,
    containerImage = 0,
    branch = 0,
    containerBaseImage = 0,
    packages = 0,
    vm = 0
  } = data || {}
  const navigation = useNavigate()
  const { t } = useTranslation("translation", { keyPrefix: "general" })
  const dataReadinessFF = useFeatureFlags("dataReadiness")
  const vmInstancesEnabledFF = useFeatureFlags("vmInstancesEnabled")
  const { abbreviateNumber } = useNumberConvertor({
    showPlaceholder: !!dataReadinessFF
  })
  const previewMode = !!setActiveKey
  const onClickCb = previewMode
    ? (key: string) => () => setActiveKey(key)
    : undefined
  const getLabelProps = (key: string) => ({
    to: key,
    keyStorageRestoreQueryString: key,
    overflowCount: 999,
    onClick: onClickCb?.(key)
  })

  const items = [
    {
      key: TAB_KEYS[0],
      label: (
        <LabelTabItem
          title={t("code_repositories")}
          count={abbreviateNumber(branch)}
          {...getLabelProps(TAB_KEYS[0])}
        />
      ),
      children: (
        <BranchTab useActiveKeyTabsInventory={useActiveKeyTabsInventory} />
      )
    },
    {
      key: TAB_KEYS[1],
      label: (
        <LabelTabItem
          title={t("application_other")}
          count={abbreviateNumber(application)}
          {...getLabelProps(TAB_KEYS[1])}
        />
      ),
      children: (
        <ApplicationsTab
          useActiveKeyTabsInventory={useActiveKeyTabsInventory}
        />
      )
    },
    {
      key: TAB_KEYS[2],
      label: (
        <LabelTabItem
          title={t("image_other")}
          count={abbreviateNumber(containerImage)}
          {...getLabelProps(TAB_KEYS[2])}
        />
      ),
      children: (
        <ImagesTab useActiveKeyTabsInventory={useActiveKeyTabsInventory} />
      )
    },
    {
      key: TAB_KEYS[3],
      label: (
        <LabelTabItem
          title={t("base_image_other")}
          count={abbreviateNumber(containerBaseImage)}
          {...getLabelProps(TAB_KEYS[3])}
        />
      ),
      children: (
        <ContainerBaseImagesTab
          useActiveKeyTabsInventory={useActiveKeyTabsInventory}
        />
      )
    },
    {
      key: TAB_KEYS[5],
      label: (
        <LabelTabItem
          title={t("vm")}
          count={abbreviateNumber(vm)}
          {...getLabelProps(TAB_KEYS[5])}
        />
      ),
      children: (
        <VirtualMachinesTab
          useActiveKeyTabsInventory={useActiveKeyTabsInventory}
        />
      )
    },
    {
      key: TAB_KEYS[4],
      label: (
        <LabelTabItem
          title={t(`sbom_title`, { title: t("package_other") })}
          count={abbreviateNumber(packages)}
          {...getLabelProps(TAB_KEYS[4])}
        />
      ),
      children: (
        <SbomTab useActiveKeyTabsInventory={useActiveKeyTabsInventory} />
      )
    }
  ].filter((item) => {
    if (vmInstancesEnabledFF) return true

    return item.key !== TAB_KEYS[5]
  })

  useEffect(() => {
    if (activeKey === undefined) {
      navigation(`${pageBaseUrl}/${items[0].key}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKey])

  useQueryStringStorage(activeKey, { keys: TAB_KEYS })

  return (
    <HeaderPage
      noMenu={previewMode}
      titleTranslationKey={previewMode ? "" : "navigation.inventory"}
      tabs={<Tabs activeKey={activeKey} items={items} />}
    />
  )
}
