import { Resource } from "."

import styles from "./Resources.module.scss"

import type { SingleImageResourcePopoverProps } from "."

export const SingleImageResourcePopover: React.FC<
  SingleImageResourcePopoverProps
> = (props) => {
  const { onClick } = props
  let data

  if ("resources" in props) data = props.resources
  else {
    const { digest, tag, name, type: _, ...rest } = props
    const type = "ImageRepository" as const

    data = [{ type, name: `${name}:${tag}`, tag: digest, ...rest }]
  }

  return (
    <div>
      {data.map(({ tag, name, popoverName, ...resource }, index) => {
        const displayName = popoverName || name

        return (
          <Resource
            key={index}
            copyable={{
              tag: { text: tag, tooltips: false },
              name: {
                text: displayName,
                tooltips: false
              }
            }}
            tag={`Digest: ${tag}`}
            onClick={onClick}
            classes={{ container: styles.contentSingleResourcePopover }}
            name={displayName}
            {...resource}
          />
        )
      })}
    </div>
  )
}
