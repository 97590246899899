import { Text } from "@/atoms"
import { ExclamationCircleOutlined } from "@/icons"
import cn from "classnames"
import { TFunction } from "node_modules/i18next"
import styles from "./ConfirmModal.module.scss"

interface InfoModalProps {
  content: React.ReactNode
  t: TFunction
}

export const InfoModal = (props: InfoModalProps) => {
  const { content, t } = props

  return {
    destroyOnClose: true,
    className: cn(styles.container, styles.infoContainer),
    icon: <ExclamationCircleOutlined className={styles.infoIcon} />,
    content: <Text>{content}</Text>,
    okText: t("general.close"),
    width: 470
  }
}
