import { Navigate, Route, Routes } from "react-router-dom"

import { NAMES_ROUTES } from "@/const/routes.constants"
import { ComingSoonPage } from "@/pages/ComingSoonPage"
import { IncidentsPage } from "./components/pages/IncidentsPage"
import { IssuesPage } from "./components/pages/IssuesPage"

export const TriagePage = ({
  isIncidentsEnabled
}: {
  isIncidentsEnabled: boolean
}) => {
  const { TRIAGE } = NAMES_ROUTES

  return (
    <Routes>
      <Route path={`/${TRIAGE.ISSUES}`} element={<IssuesPage />} />
      <Route
        path={`/${TRIAGE.INCIDENTS}`}
        element={
          isIncidentsEnabled ? (
            <IncidentsPage />
          ) : (
            <ComingSoonPage keyPage="incidents" />
          )
        }
      />
      <Route
        path={`/${TRIAGE.ATTACK_SCENARIOS}`}
        element={<ComingSoonPage keyPage="attackScenarios" />}
      />
      <Route
        path="/*"
        element={<Navigate to={`/${TRIAGE.ROOT}/${TRIAGE.ISSUES}`} replace />}
      />
    </Routes>
  )
}
