import { Button, Form } from "@/atoms"
import { PlayCircleOutlined } from "@/icons"

import type { TestWebhookButtonProps } from "."

export const TestWebhookButton = (props: TestWebhookButtonProps) => {
  const { size, formProps, webhookApi, tKey = "general.test" } = props
  const url = Form.useWatch(webhookApi.URL_FIELD_NAME, formProps.form)
  const secret = Form.useWatch(webhookApi.SECRET_FIELD_NAME, formProps.form)
  const disabled =
    webhookApi.isTesting || webhookApi.isPending || !url || !secret

  return (
    <Button
      size={size}
      icon={<PlayCircleOutlined />}
      type="default"
      disabled={disabled}
      onClick={() => webhookApi.onTest(url, secret)}
    >
      {formProps.t(tKey)}
    </Button>
  )
}
