import { useActionsOverTimeStatisticsWorkflow } from "@/api/useWorkflow"
import { Col, Flex, Row, Text } from "@/atoms"
import { EVENTS } from "@/const/event.constants"
import { NAMES_ROUTES } from "@/const/routes.constants"
import { useNumberConvertor } from "@/hooks/useNumberConvertor"
import { useQueryString } from "@/hooks/useQueryString"
import { useTooltip } from "@/hooks/useTooltip"
import { useTranslation } from "@/hooks/useTranslation"
import { useUser } from "@/hooks/useUser"
import { PartitionOutlined } from "@/icons"
import { DeleteConfirmModal, useConfirmModal } from "@/molecules/ConfirmModal"
import { CreateActionEmptyTable } from "@/molecules/EmptyTable"
import { LinkButton } from "@/molecules/LinkButton"
import { Protected } from "@/molecules/Protected"
import { useWorkflow } from "@/pages/GovernancePage/applications/workflow/useWorkflow"
import { ActionsOverTimeWidget } from "@/pages/GovernancePage/components/organisms/ActionsOverTimeWidget"
import { ActionsTakenWidget } from "@/pages/GovernancePage/components/organisms/ActionsTakenWidget"
import { Filter } from "@/pages/SettingsPage/components/organisms/Filter"
import { TabTemplate } from "@/templates/TabTemplate"
import { getColumnsTable } from "./getColumnsTable"

import styles from "./AutomatedActionsTab.module.scss"

import type { Workflow } from "@/api/useWorkflow.types"
import type { Action, Resource } from "@/domain/user"
import type { AutomatedActionsTabComponent } from "."

export const AutomatedActionsTab: AutomatedActionsTabComponent = () => {
  const { ROOT, WORKFLOWS } = NAMES_ROUTES.GOVERNANCE
  const params = useQueryString()
  const [context, { actions, loading }] = useWorkflow(params)
  const { data, metadata } = context.response || {}
  const { t } = useTranslation()
  const { confirm } = useConfirmModal()
  const { hasPermission } = useUser()
  const resource = "governance.workflows" as Resource
  const permissions = { resource, action: "write" as Action }
  const noPermissions = !hasPermission(permissions)

  const onDelete = async (record: Pick<Workflow, "id" | "name">) => {
    const { name } = record

    confirm((attr) =>
      DeleteConfirmModal({
        name,
        ANALYTIC_EVENT: EVENTS.ANALYTIC_EVENTS.WORKFLOW.DELETE,
        ANALYTIC_TAGS: { id: record.id },
        entityName: "workflow",
        onOk: () => actions.deletion.mutateAsync(record.id),
        ...attr
      })
    )
  }

  useTooltip(data)

  const createUrl = `/${ROOT}/${WORKFLOWS}/new`
  const contextOvertimeStatistic = useActionsOverTimeStatisticsWorkflow()
  const convertors = useNumberConvertor()

  return (
    <TabTemplate
      columns={getColumnsTable({
        noPermissions,
        onDelete,
        convertors,
        ...actions
      })}
      loading={loading}
      dataSource={data}
      metadata={metadata}
      headerComponent={
        <Flex vertical gap={14}>
          <Flex justify="space-between" align="center">
            <Text type="secondary">
              {t("settings.automatedActions.table.description")}
            </Text>
            <Protected shouldDisable permission={permissions}>
              <LinkButton
                size="middle"
                type="primary"
                to={createUrl}
                icon={<PartitionOutlined />}
              >
                {t("settings.automatedActions.table.actions.create")}
              </LinkButton>
            </Protected>
          </Flex>
          <Row gutter={12} className={styles.row}>
            <Col>
              <ActionsTakenWidget />
            </Col>
            <Col>
              <ActionsOverTimeWidget context={contextOvertimeStatistic} />
            </Col>
          </Row>
        </Flex>
      }
      SearchComponent={<Filter />}
      onRowClick={actions.onEdit}
      locale={{
        customEmptyComponent: (
          <Protected shouldDisable permission={permissions}>
            <CreateActionEmptyTable tKey="workflow" to={createUrl} />
          </Protected>
        )
      }}
    />
  )
}
