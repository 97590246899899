import { useNumberConvertor } from "@/hooks/useNumberConvertor"
import { LabelTabItem, LabelTabItemProps } from "@/molecules/LabelTabItem"
import { TooltipLabelTab } from "./TooltipLabelTab"

import type { LabelTabProps } from "."

export const LabelTab: React.FC<LabelTabProps> = (props) => {
  const { count, name, tooltip, featureStatus } = props
  const { abbreviateNumber, t } = useNumberConvertor()
  const commonProps: LabelTabItemProps = {
    featureStatus,
    count: abbreviateNumber(count || 0),
    title: t(`general.${name}`),
    overflowCount: 999
  }
  const Component = LabelTabItem

  return (
    <TooltipLabelTab tooltip={tooltip ? t(tooltip) : undefined}>
      <Component {...commonProps} />
    </TooltipLabelTab>
  )
}
