import { usePostScope, usePutScope } from "@/api/useScopes"
import { NAMES_ROUTES } from "@/const/routes.constants"
import { useTranslation } from "@/hooks/useTranslation"
import { Form } from "@/organisms/Form"
import { useScope } from "@/pages/SettingsPage/application/scopes/useScopes"
import { ScopeForm } from "@/pages/SettingsPage/components/organisms/ScopeForm"
import { PreviewButton } from "./PreviewButton"
import { PreviewDrawerScopePage } from "./PreviewDrawerScopePage"

export const ScopePage = () => {
  const resourceKey = Form.Section.Resources.sectionId
  const asideItems = [
    { key: Form.Section.Details.sectionId, tKey: "general.details" },
    { key: resourceKey, tKey: "settings.scopes.form.label.resources" }
  ]
  const { ROOT, SCOPES } = NAMES_ROUTES.SETTINGS
  const rootLink = `/${ROOT}`
  const headerItems = [
    { link: rootLink, tKey: "navigation.settings" },
    { link: `${rootLink}/${SCOPES}`, tKey: "settings.scopes.title" },
    { fieldName: "name_details", tKey: "settings.scopes.createScope" }
  ]
  const { data } = useScope()
  const creator = usePostScope()
  const updater = usePutScope()
  const submitting = creator.isPending || updater.isPending
  const { id } = data || {}
  const { t } = useTranslation()

  return (
    <Form.Page
      key={id}
      id="scope-form"
      resource="settings.scopes"
      aside={<Form.Aside items={asideItems} />}
      header={<Form.Header items={headerItems} />}
      footer={
        <Form.Footer
          cancelRedirect={`/${NAMES_ROUTES.SETTINGS.ROOT}/${NAMES_ROUTES.SETTINGS.SCOPES}`}
          okText={(isNewEntity) =>
            t(`settings.scopes.${isNewEntity ? "createScope" : "saveScope"}`)
          }
          submitting={submitting}
        >
          <PreviewButton />
        </Form.Footer>
      }
    >
      <ScopeForm creator={creator} updater={updater} />
      <PreviewDrawerScopePage />
    </Form.Page>
  )
}
