import { useState } from "react"
import { Navigate, useNavigate } from "react-router-dom"

import {
  useGetIntegrationDetails,
  useInstallIntegration
} from "@/api/useIntegrations"
import { Form, Title } from "@/atoms"
import { isUserAdmin } from "@/domain/user"
import { useCustomFunctionsForm } from "@/hooks/useCustomFunctionsForm"
import { useLogger } from "@/hooks/useLogger"
import { useUser } from "@/hooks/useUser"
import { PageWithBreadcrumbTemplate } from "@/templates/PageWithBreadcrumbTemplate"
import { ConnectIntegrationFooter } from "../ConnectIntegrationPage/ConnectIntegrationFooter"
import { FormAzureIntegrationPage } from "./components/FormAzureIntegrationPage"

import connectStyles from "../ConnectIntegrationPage/ConnectIntegrationPage.module.scss"

import type { MessageInstance } from "@/atoms"
import { ErrorAlert } from "../GitLabIntegrationPage/components/ErrorAlert/ErrorAlert"
import { HeaderIntegrationPage } from "../GitLabIntegrationPage/components/HeaderIntegrationPage"

export const AzureIntegrationPage = (props: {
  messageApi: MessageInstance
}) => {
  const { messageApi } = props
  const vendor = "azure_repos"
  const [form] = Form.useForm()
  const { logger, EVENTS } = useLogger()
  const navigate = useNavigate()
  const { isDirty, focusRef, onFieldsChange } = useCustomFunctionsForm()
  const updater = useInstallIntegration({ vendor })
  const { response, isLoading, refetch } = useGetIntegrationDetails(vendor)
  const { user } = useUser()
  const [error, setError] = useState<string | null>(null)

  if (!isUserAdmin(user)) return <Navigate to="/integrations" />
  if (!isLoading && response?.data.integration?.isInstalled)
    return <Navigate to={`/integrations/${vendor}?tab=configuration`} />

  return (
    <PageWithBreadcrumbTemplate
      full
      header={<HeaderIntegrationPage vendor={vendor} />}
      footer={
        <ConnectIntegrationFooter
          disabled={!isDirty}
          isLoading={updater.isPending}
          onSubmit={async () => {
            try {
              await form.validateFields()
              logger.info(EVENTS.ANALYTIC_EVENTS.INTEGRATIONS.COMMAND_CONNECT, {
                vendor,
                ...form.getFieldsValue()
              })

              await updater.mutateAsync({ data: form.getFieldsValue() })
              await refetch()

              messageApi.success("Connected successfully")

              navigate(`/integrations/${vendor}?tab=configuration`)
            } catch (err: Error | any) {
              const { data, values, errorFields } = err || {}

              if (!!values && !!errorFields) return // validation error

              setError(data?.error || "Connection failed")
              document
                .getElementById("main-page-with-breadcrumbs-template")
                ?.scrollTo({ top: 0, behavior: "smooth" })
            }
          }}
          onCancel={() => {
            form.resetFields()
            logger.info(EVENTS.ANALYTIC_EVENTS.INTEGRATIONS.COMMAND_CANCEL, {
              vendor
            })
            navigate(`/integrations/${vendor}`)
          }}
        />
      }
    >
      <ErrorAlert description={error} />
      <Title className={connectStyles.title} level={4}>
        Connect Azure Account
      </Title>
      <FormAzureIntegrationPage
        form={form}
        focusRef={focusRef}
        onFieldsChange={onFieldsChange}
      />
    </PageWithBreadcrumbTemplate>
  )
}
