import { ActionsCell } from "@/molecules/ActionsCell"

import { Protected } from "@/molecules/Protected"
import type { ScopeActionsCellComponent } from "."

export const ScopeActionsCell: ScopeActionsCellComponent = (
  t,
  { deleteScope, duplicateScope }
) =>
  function ScopeActionsCellComponent(_, record) {
    const items = [
      {
        key: "duplicate",
        label: t("general.duplicate"),
        disabled: record.isAllResources
      },
      {
        key: "delete",
        label: `${t("general.delete")} ${t("general.scope")}`,
        disabled: record.isAllResources
      }
    ]

    return (
      <Protected permission={{ resource: "settings.scopes", action: "write" }}>
        <ActionsCell
          items={items}
          onClick={({ key }) => {
            if (key === items[1].key) return deleteScope(record)

            duplicateScope(record.key)
          }}
        />
      </Protected>
    )
  }
