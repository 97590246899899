import { CellTable } from "@/atoms"
import { getDefaultColumnParams } from "@/helpers/table.helpers"
import { CreatorCell } from "@/molecules/CreatorCell"
import { HeaderCell } from "@/molecules/HeaderCell"
import { NumberCell } from "@/molecules/NumberCell"
import { StatusSwitcherCell } from "@/molecules/StatusSwitcherCell"
import { AutomatedActionsCell } from "@/pages/GovernancePage/components/molecules/AutomatedActionsCell"
import { AutomatedActionsCell as Control } from "./components/AutomatedActionsCell"

import type { PropsAutomatedActionsCellComponent } from "./components/AutomatedActionsCell"

export const getColumnsTable = (props: PropsAutomatedActionsCellComponent) => [
  {
    title: "Workflow Name",
    render: CellTable,
    ...getDefaultColumnParams("name")
  },
  {
    title: "Description",
    render: CellTable,
    ...getDefaultColumnParams("description")
  },
  {
    title: HeaderCell({
      title: "Included Actions",
      Content: props.t("settings.automatedActions.table.actionsByType.tooltip")
    }),
    width: 150,
    render: AutomatedActionsCell(props),
    ...getDefaultColumnParams("actions")
  },
  {
    title: "Actions Triggered",
    width: 133,
    className: "center-align-tab-template-component",
    render: NumberCell(props.convertors),
    ...getDefaultColumnParams("timesTriggered")
  },
  {
    title: "Last Triggered",
    width: 154,
    render: CellTable,
    ...getDefaultColumnParams("lastTriggeredDate")
  },
  {
    title: "Creator",
    width: 70,
    render: CreatorCell,
    className: "center-align-tab-template-component",
    ...getDefaultColumnParams("created")
  },
  {
    title: "Status",
    width: 70,
    render: StatusSwitcherCell(
      props.noPermissions,
      props.statusChange,
      props.noPermissions
    ),
    ...getDefaultColumnParams("enabled")
  },
  {
    title: null,
    width: 35,
    render: Control(props)
  }
]
