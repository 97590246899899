import { RESOURCES_NAMES } from "@/const/resource.constants"
import {
  Config,
  Params,
  useCreate,
  useDelete,
  useHttp,
  usePatch
} from "@/hooks/useHttp"
import {
  getIntegrationDetailsData,
  getIntegrationsListData,
  getPreInstallOptionsECR
} from "./useIntegrations.selector"

import type {
  DownloadKodemCLIResponse,
  IntegrationDetailsResponse,
  IntegrationsListResponse,
  TestWebhookResponse
} from "./useIntegrations.types"

export const useGetIntegrationsList = <
  T extends IntegrationsListResponse = IntegrationsListResponse
>(
  params: Params = {},
  config: Config<T, ReturnType<typeof getIntegrationsListData>> = {}
) => {
  const context = useHttp<T, ReturnType<typeof getIntegrationsListData>>(
    RESOURCES_NAMES.INTEGRATIONS.LIST,
    params,
    { select: getIntegrationsListData, ...config }
  )

  return context
}

export const useGetIntegrationDetails = <
  T extends IntegrationDetailsResponse = IntegrationDetailsResponse
>(
  name: string,
  params: Params = {},
  config: Config<T, ReturnType<typeof getIntegrationDetailsData>> = {}
) => {
  const context = useHttp<T, ReturnType<typeof getIntegrationDetailsData>>(
    `${RESOURCES_NAMES.INTEGRATIONS.LIST}/${name}`,
    params,
    { select: getIntegrationDetailsData, ...config }
  )

  return context
}

export const usePatchIntegration = (params: Params<{ vendor: string }>) => {
  const updater = usePatch(
    `${RESOURCES_NAMES.INTEGRATIONS.LIST}/${params.vendor}`
  )

  return updater
}

export const useDeleteIntegration = <T, S = T>(
  params: Params<{ vendor: string }>
) => {
  const remover = useDelete<T, S>(
    `${RESOURCES_NAMES.INTEGRATIONS.LIST}/${params.vendor}`,
    params
  )

  return remover
}

export const useGetCallbackIntegrations = (
  vendor: string,
  params: Params<Record<string, string>>,
  config: Config
) => {
  const context = useHttp(
    `${RESOURCES_NAMES.INTEGRATIONS.CALLBACK}/${vendor}`,
    params,
    config
  )

  return context
}

export type InstallStatus = {
  status: string
  installedRegistriesUrls: string[]
}

export type InstallRegistryResponse = { data: InstallStatus }

export const useInstallIntegration = (params: Params<{ vendor: string }>) => {
  const updater = useCreate(
    `${RESOURCES_NAMES.INTEGRATIONS.LIST}/${params.vendor}`,
    undefined,
    undefined,
    (client) => ({
      onSuccess() {
        return Promise.all([
          client.invalidateQueries({
            queryKey: [`${RESOURCES_NAMES.INTEGRATIONS.LIST}/${params.vendor}`]
          }),
          client.invalidateQueries({
            queryKey: [
              RESOURCES_NAMES.SETTINGS.WORKFLOWS.ACTIONS,
              { type: params.vendor }
            ]
          })
        ])
      }
    })
  )

  return updater
}

export const useNotifyAdminsOnCallbackIntegrations = <T = unknown, S = T>() => {
  const context = useCreate<T, S>(
    `${RESOURCES_NAMES.INTEGRATIONS.NOTIFY_CALLBACK}`
  )

  return context
}

export const usePreInstallECR = (vendor?: string) => {
  const context = useHttp(
    RESOURCES_NAMES.INTEGRATIONS.ECR.PRE_INSTALL,
    undefined,
    { enabled: vendor === "ecr", select: getPreInstallOptionsECR }
  )

  return context
}

export const useTestWebhook = <
  T = TestWebhookResponse,
  S = { webhook: { url: string; secret?: string } }
>() => useCreate<T, S>(`${RESOURCES_NAMES.INTEGRATIONS.LIST}/webhooks/test`)

export const useDownloadIntegration = (vendor: string, platform: string) => {
  const context = useHttp<DownloadKodemCLIResponse>(
    `${RESOURCES_NAMES.INTEGRATIONS.DOWNLOAD}/${vendor}`,
    { platform },
    { enabled: false, staleTime: Infinity }
  )
  return context
}
