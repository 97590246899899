import cn from "classnames"
import { useState } from "react"
import { Outlet } from "react-router-dom"

import { Button, Layout } from "@/atoms"
import { noScopeSelected } from "@/domain/user"
import { useLogger } from "@/hooks/useLogger"
import { useFeatureFlags, useUser } from "@/hooks/useUser"
import { LeftOutlined, RightOutlined } from "@/icons"
import { ErrorBoundary } from "@/organisms/ErrorBoundary"
import { NoScopeResult } from "@/organisms/NoScopeResult"
import { SideNavigation } from "@/organisms/SideNavigation"
import { InfoBanner } from "./InfoBanner"

import styles from "./LayoutTemplate.module.scss"

export const LayoutTemplate = () => {
  const { logger } = useLogger()
  const [collapsed, setCollapsed] = useState(false)
  const { Sider } = Layout
  const { user } = useUser()
  const showScopePlaceHolder = noScopeSelected(user)
  const trialDays = useFeatureFlags("trialDays")
  const showInfoBanner = !!trialDays && trialDays > 0

  return (
    <Layout className={styles.containerLayoutTemplate}>
      <Sider width={220} collapsed={collapsed} onCollapse={setCollapsed}>
        <Button
          size="small"
          className={styles.collapsedButton}
          icon={collapsed ? <RightOutlined /> : <LeftOutlined />}
          onClick={() => setCollapsed(!collapsed)}
        />
        <SideNavigation collapsed={collapsed} user={user} />
      </Sider>
      <Layout
        id="outlet-layout-container"
        className={cn(
          styles.outletSection,
          collapsed && styles.collapsed,
          showInfoBanner && styles.showInfoBanner
        )}
      >
        <ErrorBoundary user={user} logger={logger}>
          {showInfoBanner && <InfoBanner days={trialDays} />}
          {showScopePlaceHolder && <NoScopeResult />}
          {!showScopePlaceHolder && <Outlet />}
        </ErrorBoundary>
      </Layout>
    </Layout>
  )
}
