import { useEffect, useState } from "react"

import { useServices } from "@/hooks/useServices"

import type { Issue } from "@/domain/issue"

const CACHE: Record<string, Promise<any>> = {}

export const useApiLoggingDemo = (context: any) => {
  const { auth } = useServices()
  const isDemo = auth.isDemo()
  const [responseState, setResponse] = useState<any>()
  const data = context.response?.data

  useEffect(() => {
    if (!isDemo) return
    if (context.isLoading || !data) return

    const projectId =
      "3034306465366537656466333236656635336663396635613038363666656164"

    CACHE[projectId] =
      CACHE[projectId] ||
      fetch(`/${projectId}.json?key=${Date.now()}`).then((res) => res.json())

    const fn = async () => {
      CACHE[projectId].then((json) => {
        setResponse({
          ...context,
          response: {
            ...context.response,
            data: data.map((issue: Issue) => {
              if (json[issue.id]) {
                return { ...issue, ...json[issue.id] }
              }

              return issue
            })
          }
        })
      })
    }
    fn()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDemo, context.isLoading, data])

  return responseState || context
}
