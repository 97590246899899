import cn from "classnames"

import { Text } from "@/atoms"
import { isPreconditionError } from "@/helpers/error.helper"
import { Trans } from "@/hooks/useTranslation"
import { ExclamationCircleOutlined } from "@/icons"

import styles from "./ConfirmModal.module.scss"

import type { ConfirmModalProps } from "."

export const DeleteConfirmModal = (props: ConfirmModalProps) => {
  const { t, getModal, messageAPI, logger } = props
  const deleteVerb = props.okText || t(props.keyOkText || "delete")

  return {
    destroyOnClose: true,
    className: cn(styles.container, styles.deleteContainer),
    icon: <ExclamationCircleOutlined className={styles.deleteIcon} />,
    content: (
      <Text>
        <>
          <div className={styles.contentTitle}>
            <Trans>
              {t(`prompt.delete.content`, {
                name: props.name,
                deleteVerb: deleteVerb.toLocaleLowerCase()
              })}
            </Trans>
          </div>
          <div className={styles.contentBody}>{props.content}</div>
        </>
      </Text>
    ),
    okText: deleteVerb,
    okButtonProps: { danger: true },
    cancelText: t("cancel"),
    onOk: async () => {
      getModal()?.update({ okButtonProps: { loading: true } })

      const getMessageProps = (
        type: "success" | "error",
        message?: string
      ) => ({
        type,
        content: message || (
          <Trans>{t(`prompt.delete.${type}`, { name: props.name })}</Trans>
        )
      })

      const { ANALYTIC_EVENT, ANALYTIC_TAGS, EVENTS } = props

      try {
        await props.onOk()

        messageAPI.open(getMessageProps("success"))

        if (ANALYTIC_EVENT) logger.info(ANALYTIC_EVENT, ANALYTIC_TAGS)
      } catch (err: any) {
        if (isPreconditionError(err) && props.preconditionFailed) {
          getModal()?.destroy()
          props.preconditionFailed()

          return
        }

        const { status, data } = err || {}
        const displayMessage = data?.displayMessage

        if (!displayMessage || !status || status < 500)
          logger.error(err, {
            tags: {
              entity_name: props.entityName,
              custom_error:
                EVENTS.ERROR_EVENTS.ACTIONS.DELETE_ENTITY_CONFIRM_MODAL
            }
          })

        messageAPI.open(getMessageProps("error", displayMessage))
      } finally {
        getModal()?.update({ okButtonProps: { loading: false } })
      }
    }
  }
}
