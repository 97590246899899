import { CellTable } from "@/atoms"

import type { useNumberConvertor } from "@/hooks/useNumberConvertor"

export const NumberCell =
  (convertors: ReturnType<typeof useNumberConvertor>) => (value: number) =>
    CellTable({
      text: convertors.abbreviateNumber(value),
      tooltip: convertors.intlNumber(value)
    })
