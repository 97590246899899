import { isArray, toArray } from "@/helpers/utils"

export const isAssigneeOrReporterField = (field: Jira.IssueTypeField) =>
  field.autoCompleteUrl &&
  (field.schema.items === "user" || field.schema.type === "user")

export const isLablesField = (field: Jira.IssueTypeField) =>
  field.autoCompleteUrl &&
  field.key === "labels" &&
  field.schema.items === "string"

export const isSprintOrEpicLinkField = (field: Jira.IssueTypeField) =>
  isSprintField(field) || isEpicField(field)

export const isEpicField = (field: Jira.IssueTypeField) =>
  field.schema.custom === "com.pyxis.greenhopper.jira:gh-epic-link"

export const isSprintField = (field: Jira.IssueTypeField) =>
  field.schema.custom === "com.pyxis.greenhopper.jira:gh-sprint"

export const isPeopleField = (field: Jira.IssueTypeField) =>
  field.schema.custom ===
  "com.atlassian.jira.plugin.system.customfieldtypes:people"

export const isDropDownField = (field: Jira.IssueTypeField) =>
  field.schema.custom ===
  "com.atlassian.jira.plugin.system.customfieldtypes:select"

export const isParagraphField = (field: Jira.IssueTypeField) =>
  field.schema.custom ===
  "com.atlassian.jira.plugin.system.customfieldtypes:textarea"

export const isCustomCheckboxField = (field: Jira.IssueTypeField) =>
  field.schema.custom ===
  "com.atlassian.jira.plugin.system.customfieldtypes:multicheckboxes"

function isComponentsField(field: Jira.IssueTypeField): boolean {
  return field.schema.system === "components"
}

function isAffecctedVersionsField(field: Jira.IssueTypeField): boolean {
  return field.schema.system === "versions"
}

export const isParentField = (field: Jira.IssueTypeField) =>
  field.schema.type === "issuelink"

export const isSecurityLevelField = (field: Jira.IssueTypeField) =>
  field.schema.type === "securitylevel"

export const isPriorityField = (field: Jira.IssueTypeField) =>
  field.schema.type === "priority"

export const isSinglePredefinedOptionsField = (
  field: Jira.IssueTypeField
): field is Jira.IssueTypeField & { allowedValues: Jira.AllowedValue[] } =>
  (isSecurityLevelField(field) ||
    isPriorityField(field) ||
    field.schema.type === "option") &&
  isArray(field.allowedValues)

export const isSingleFetchableOptionsField = (field: Jira.IssueTypeField) =>
  isAssigneeOrReporterField(field) ||
  isParentField(field) ||
  isSprintOrEpicLinkField(field) ||
  isLablesField(field)

export const isMultiplePredefinedOptionsField = (
  field: Jira.IssueTypeField
): field is Jira.IssueTypeField & { allowedValues: Jira.AllowedValue[] } =>
  field.schema.type === "array" &&
  field.schema.items !== "string" &&
  isArray(field.allowedValues)

export const isTextAreaField = (field: Jira.IssueTypeField) =>
  field.schema.custom ===
  "com.atlassian.jira.plugin.system.customfieldtypes:textarea"

export const isTextField = (field: Jira.IssueTypeField) =>
  field.schema.custom ===
  "com.atlassian.jira.plugin.system.customfieldtypes:textfield"

export const isNumberField = (field: Jira.IssueTypeField) =>
  field.schema.custom === "com.pyxis.greenhopper.jira:jsw-story-points" ||
  field.schema.custom ===
    "com.atlassian.jira.plugin.system.customfieldtypes:float"

export const isDateField = (field: Jira.IssueTypeField) =>
  field.schema.type === "date"

const buildDescription = (description: string) => ({
  type: "doc",
  version: 1,
  content: [
    {
      type: "paragraph",
      content: [
        {
          text: `${description ?? ""}`,
          type: "text"
        }
      ]
    }
  ]
})

export const buildCreateIssuePayload = ({
  fieldsMetadata,
  fieldsValues
}: {
  fieldsMetadata: Record<string, Jira.IssueTypeField>
  fieldsValues: {
    [key: string]: string | string[] | undefined
    summary: string
    project: string
    issuetype: string
    reporter: string
    description?: string
    labels?: string[]
  }
}): Jira.CreateIssuePayload => {
  const normalizedFields: Record<string, any> = {}
  Object.entries(fieldsValues).forEach(([key, value]) => {
    if (!value) return
    if (["project", "issuetype", "priority", "assignee"].includes(key)) {
      normalizedFields[key] = {
        id: value
      }
      return
    }
    if (key === "description") {
      normalizedFields[key] = value // buildDescription(value as string)
      return
    }
    if (isSprintField(fieldsMetadata[key])) {
      normalizedFields[key] = Number(value)
      return
    }
    if (
      isPeopleField(fieldsMetadata[key]) ||
      isDropDownField(fieldsMetadata[key])
    ) {
      normalizedFields[key] = {
        id: value
      }
      return
    }
    if (
      isCustomCheckboxField(fieldsMetadata[key]) ||
      isComponentsField(fieldsMetadata[key]) ||
      isAffecctedVersionsField(fieldsMetadata[key])
    ) {
      const valueArray = Array.isArray(value) ? value : [value]
      normalizedFields[key] = valueArray.map((id) => ({ id }))
      return
    }
    if (isParagraphField(fieldsMetadata[key])) {
      normalizedFields[key] = buildDescription(value as string)
      return
    }
    if (isDateField(fieldsMetadata[key]) || isEpicField(fieldsMetadata[key])) {
      normalizedFields[key] = value
      return
    }
    if (isNumberField(fieldsMetadata[key])) {
      normalizedFields[key] = Number(value)
      return
    }
  })

  const payload: Jira.CreateIssuePayload = {
    jiraIssueFields: {
      fields: {
        ...normalizedFields,
        issuetype: {
          id: fieldsValues.issuetype
        },
        project: {
          id: fieldsValues.project
        },
        summary: fieldsValues.summary,
        labels: [...toArray(fieldsValues.labels ?? [])]
      }
    }
  }

  return payload
}
