import { useRef } from "react"

import { Anchor } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"

import styles from "./Aside.module.scss"

import type { AsideComponent } from "."

export const Aside: AsideComponent = (props) => {
  const { items } = props
  const { t } = useTranslation()
  const getItems = () =>
    items.map((item) => {
      const { key, tKey = "", title = t(tKey) } = item

      return { key, title, href: `#${key}` }
    })
  const anchorItems = useRef(getItems()).current

  return (
    <Anchor
      replace
      className={styles.container}
      items={anchorItems}
      getCurrentAnchor={(activeLink) => activeLink || anchorItems[0].href}
      targetOffset={80}
      getContainer={() =>
        document.getElementById("PageWithBreadcrumbTemplate") || window
      }
    />
  )
}
