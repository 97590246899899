import { Button, Form, Input, Select } from "@/atoms"
import { CloseOutlined } from "@/icons"
import { FIELD_NAMES, getOperatorOptions, isCustomProperty } from ".."

import styles from "./Fields.module.scss"

import type { FieldsProps } from ".."

export const Fields = (props: FieldsProps) => {
  const { name, path, form, componentProps } = props
  const values = form.getFieldValue(path)
  const { resource, property: propertyName } = values || {}
  const property = { resourceName: resource, propertyName }
  const conditionsNumber = form.getFieldValue(path[0])
  const filtersNumber = form.getFieldValue(path.slice(0, -1))
  const onlyOneCardAndRecord =
    conditionsNumber.length === 1 && filtersNumber.length === 1

  const { disabled, options, t } = componentProps
  const rules = [{ required: true, message: t("error.requiredField") }]

  return (
    <div className={styles.container}>
      <Form.Item
        name={[name, FIELD_NAMES.resource]}
        className={styles.formItem}
        rules={rules}
      >
        <Select
          placeholder={t(`general.${FIELD_NAMES.resource}`)}
          options={options.resources}
          disabled={disabled}
          onChange={() =>
            form.setFields([
              { name: [...path, FIELD_NAMES.property], value: undefined },
              { name: [...path, FIELD_NAMES.operator], value: undefined }
            ])
          }
          //data-testid={`${name}_${FIELD_NAMES.resource}`}
        />
      </Form.Item>
      <Form.Item
        shouldUpdate
        name={[name, FIELD_NAMES.property]}
        className={styles.propertyFormItem}
        rules={rules}
      >
        <Select
          placeholder={t(`general.${FIELD_NAMES.property}`)}
          options={options.fields[resource]}
          disabled={disabled}
          onChange={() =>
            form.setFieldValue([...path, FIELD_NAMES.operator], undefined)
          }
          //data-testid={`${name}_${FIELD_NAMES.property}`}
        />
      </Form.Item>
      {isCustomProperty(property) && (
        <Form.Item
          name={[name, FIELD_NAMES.exactKey]}
          className={styles.value}
          rules={rules}
        >
          <Input
            placeholder={t(`general.${FIELD_NAMES.exactKey}`)}
            disabled={disabled}
            //data-testid={`${name}_${FIELD_NAMES.exactKey}`}
          />
        </Form.Item>
      )}
      <Form.Item
        name={[name, FIELD_NAMES.operator]}
        className={styles.operator}
        rules={rules}
      >
        <Select
          placeholder={t(`general.${FIELD_NAMES.operator}`)}
          disabled={disabled}
          options={getOperatorOptions(t, property)}
          //data-testid={`${name}_${FIELD_NAMES.operator}`}
        />
      </Form.Item>
      <Form.Item
        name={[name, FIELD_NAMES.value]}
        className={styles.value}
        rules={rules}
      >
        <Input
          placeholder={t("general.value")}
          disabled={disabled}
          //data-testid={`${name}_${FIELD_NAMES.value}`}
        />
      </Form.Item>
      <Button
        block
        disabled={onlyOneCardAndRecord || disabled}
        className={styles.removeButton}
        type="text"
        onClick={() => {
          if (onlyOneCardAndRecord) return

          if (filtersNumber.length === 1) {
            props.removeCard()
          } else {
            props.remove(props.index)
          }
        }}
        icon={<CloseOutlined />}
        data-testid={`${name}_remove_button`}
      />
    </div>
  )
}
