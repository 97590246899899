import { Button, Flex, Steps, Title } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { ReadOutlined } from "@/icons"

import styles from "./HowToUseTemplate.module.scss"
import { HowToUseTemplaeProps } from "./HowToUseTemplatete.types"

export const HowToUseTemplate = ({
  title,
  instructionsLink,
  aside,
  children
}: HowToUseTemplaeProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Flex
        className={styles.titleContainer}
        align="center"
        justify="space-between"
      >
        <Title level={5} className={styles.title}>
          {title}
        </Title>
        <Button
          icon={<ReadOutlined />}
          type="link"
          href={instructionsLink}
          target="_blank"
        >
          {t("general.readFullInstructions")}
        </Button>
      </Flex>
      {aside}
      <Steps size="small" className={styles.steps} direction="vertical">
        {children}
      </Steps>
    </>
  )
}
