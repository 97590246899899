import { Skeleton } from "@/atoms"
import cn from "classnames"
import { useEffect, useState } from "react"

import { useGetJiraCreateIssueMetadata } from "@/api/useJira"
import { JiraCreateIssueMetadataResponse } from "@/api/useJira.types"
import {
  Button,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Select,
  Text,
  Title
} from "@/atoms"
import { JiraFieldKey } from "@/const/jira.constants"
import { buildCreateIssuePayload } from "@/helpers/jira.helpers"
import { toDictionary } from "@/helpers/utils"
import { useTranslation } from "@/hooks/useTranslation"
import { JiraSoftwareLogo, WarningOutlined } from "@/icons"
import { FormError } from "@/organisms/JiraIssueModal/JiraIssueModal.types"
import Editor from "./Editor"

import "react-quill/dist/quill.snow.css"
import styles from "./JiraIssueModal.module.scss"

import type { JiraIssueModalProps } from "."
import { JiraIssueModalFields } from "."

export const getFieldsFromResponse = (
  response: JiraCreateIssueMetadataResponse | undefined,
  projectId: string,
  issueTypeId: string
) => {
  const { data } = response || {}
  const { projects = [] } = data || {}
  const selectedProject = projects.find(({ id }) => id === projectId)
  const issueTypesOptions = selectedProject?.issuetypes || []
  const fields: Jira.IssueTypeField[] =
    issueTypesOptions?.find(({ id }) => id === issueTypeId)?.fields || []
  return fields
}

export const JiraIssueModal = ({
  jira,
  type,
  closeModal,
  onCreateJiraIssue,
  createJiraIssueMutation
}: JiraIssueModalProps) => {
  const jiraKeyPrefix = "jiraIssueModal"
  const { t } = useTranslation("translation", {
    keyPrefix: jiraKeyPrefix
  })
  const { t: tGeneral } = useTranslation("translation", {
    keyPrefix: "general"
  })

  const [creating, setCreating] = useState(false)
  const [formError, setFormError] = useState<FormError | null>(null)

  const [initialDescription, setInitialDescription] = useState<null | string>(
    null
  )
  const { response, isLoading } = useGetJiraCreateIssueMetadata()
  const { data, metadata } = response || {}
  const { url } = metadata || {}
  const { projects = [] } = data || {}
  const [form] = Form.useForm<{
    [key: string]: string | string[] | undefined
    summary: string
    project: string
    issuetype: string
    reporter: string
    description?: string
    labels?: string[]
  }>()

  const handleCloseModal = () => {
    setInitialDescription(null)
    setFormError(null)
    closeModal()
  }

  useEffect(() => {
    if (!isLoading && projects.length > 0) {
      const { savedProjectId, savedIssueTypeId } = getSavedProjectAndIssueType()
      const projectToSet =
        savedProjectId && projects.some((p) => p.id === savedProjectId)
          ? savedProjectId
          : projects[0].id
      const selectedProject = projects.find((p) => p.id === projectToSet)
      const issueTypeToSet =
        savedIssueTypeId &&
        selectedProject?.issuetypes.some((it) => it.id === savedIssueTypeId)
          ? savedIssueTypeId
          : selectedProject?.issuetypes[0]?.id

      form.setFieldsValue({
        [JiraFieldKey.Project]: projectToSet,
        [JiraFieldKey.IssueType]: issueTypeToSet
      })
    }
  }, [isLoading, projects, form])

  const getSavedProjectAndIssueType = (): {
    savedProjectId: string | null
    savedIssueTypeId: string | null
  } => {
    return {
      savedProjectId: localStorage.getItem("selectedJiraProject"),
      savedIssueTypeId: localStorage.getItem("selectedJiraIssueType")
    }
  }

  const saveProjectAndIssueType = (projectId: string, issueTypeId: string) => {
    localStorage.setItem("selectedJiraProject", projectId)
    localStorage.setItem("selectedJiraIssueType", issueTypeId)
  }

  const projectId = Form.useWatch(JiraFieldKey.Project, form) as string
  const selectedProject = projects.find(({ id }) => id === projectId)

  const issueTypeId = Form.useWatch(JiraFieldKey.IssueType, form) as string
  const issueTypesOptions = selectedProject?.issuetypes || []

  const { isError } = createJiraIssueMutation || {}

  const errorProcessing = (
    isError: boolean | undefined,
    error: { data: Jira.CreateIssueError; status: number }
  ) => {
    try {
      if (isError) {
        const {
          data,
          status
        }: { data: Jira.CreateIssueError; status: number } = error || {}
        const { detail } = data || {}
        const { errorCode } = detail || {}

        if (status === 422) {
          const fieldsNames = toDictionary(fields, "name")

          if (errorCode === "missingRequiredField") {
            const currentUiFields = form.getFieldsValue()
            const errorFields = detail as Jira.MissingRequiredFieldError
            setFormError("completeRequiredFields")

            errorFields?.fields?.forEach((field: string) => {
              const fieldKey = fieldsNames[field].key
              const fieldName = fieldsNames[field].name
              if (!(fieldKey in currentUiFields)) {
                setFormError(
                  t("error.notSupportedRequiredField", { fieldName })
                )
              }
              form.setFields([
                {
                  name: fieldsNames[field].key,
                  errors: [t("error.missingRequiredField")]
                }
              ])
            })

            return
          } else if (errorCode === "invalidSprint") {
            form.setFields([
              {
                name: fieldsNames["Sprint"].key,
                errors: [t("error.invalidSprint")]
              }
            ])

            return
          }
        } else if (status === 400) {
          if (errorCode === "JiraError") {
            const { userMessage } =
              detail || ({} as Jira.CreateIssueError["detail"])

            setFormError(userMessage)
            return
          }
        }

        setFormError("creationFailed")
      }
    } catch {
      setFormError("creationFailed")
    }
  }

  useEffect(() => {
    // only for remedies
    errorProcessing(isError, createJiraIssueMutation?.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  const onFieldsChange = () => {
    if (formError === "completeRequiredFields") {
      const fieldErrors = form.getFieldsError()
      const someFieldsAreRequired = fieldErrors.some((f) =>
        f.errors.some((e) => e === "Required field")
      )
      if (!someFieldsAreRequired) {
        setFormError(null)
      }
    }
  }

  useEffect(() => {
    setFormError(null)
  }, [issueTypeId, projectId])

  useEffect(() => {
    if (jira) {
      const descriptionValue = jira?.description ?? ""
      const { savedProjectId, savedIssueTypeId } = getSavedProjectAndIssueType()
      const projectToSet =
        savedProjectId && projects.some((p) => p.id === savedProjectId)
          ? savedProjectId
          : projects[0]?.id
      const selectedProject = projects.find((p) => p.id === projectToSet)
      const issueTypeToSet =
        savedIssueTypeId &&
        selectedProject?.issuetypes.some((it) => it.id === savedIssueTypeId)
          ? savedIssueTypeId
          : selectedProject?.issuetypes[0]?.id

      setInitialDescription(descriptionValue)

      form.setFieldsValue({
        [JiraFieldKey.Project]: projectToSet,
        [JiraFieldKey.IssueType]: issueTypeToSet,
        [JiraFieldKey.Summary]: jira?.summary ?? "",
        [JiraFieldKey.Description]: descriptionValue
      })
    } else {
      form.resetFields([
        JiraFieldKey.Summary,
        JiraFieldKey.Project,
        JiraFieldKey.IssueType,
        JiraFieldKey.Description
      ])
    }
  }, [jira, form, projects])

  const errorTranslationKey = `error.${formError}`
  const errorTranslationResult = t(errorTranslationKey)
  const errorMessage =
    errorTranslationResult === `${jiraKeyPrefix}.${errorTranslationKey}`
      ? formError
      : errorTranslationResult

  const [messageApi, messageContext] = message.useMessage()

  const { response: projectResponse, isLoading: fieldsIsLoading } =
    useGetJiraCreateIssueMetadata({
      projectId
    })
  const fields = getFieldsFromResponse(projectResponse, projectId, issueTypeId)
  const fieldsMetadata = toDictionary(fields, "key")

  const createButtonDisabled = fieldsIsLoading || creating
  const LoadingComponent = isLoading && (
    <Skeleton active paragraph={{ rows: 6 }} />
  )

  return (
    <>
      <Modal
        destroyOnClose
        zIndex={1001}
        maskClosable={false}
        className={cn(styles.modal, isLoading && styles.isLoading)}
        open={type === "jira"}
        width={650}
        onCancel={handleCloseModal}
        afterOpenChange={(isOpen) => {
          if (!isOpen) {
            form.resetFields()
          }
        }}
        title={
          <div className={styles.header}>
            <JiraSoftwareLogo className={styles.icon} />
            <div className={styles.textSection}>
              <Title level={5}>{t("title")}</Title>
              {!isLoading && (
                <Text type="secondary">
                  {`${t("description")}`} <a href={url}>{url}</a>
                </Text>
              )}
            </div>
          </div>
        }
        footer={[
          <div key="error" className={styles.formErrorContainer}>
            {formError ? (
              <>
                <WarningOutlined className={styles.formErrorIcon} />
                <Text className={styles.errorMessage}>{errorMessage}</Text>
              </>
            ) : null}
          </div>,
          <Button key="cancel" onClick={handleCloseModal}>
            {tGeneral("cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            disabled={createButtonDisabled}
            loading={creating}
            onClick={async () => {
              setCreating(true)

              try {
                const fieldsValues = form.getFieldsValue()
                const jiraIssue = buildCreateIssuePayload({
                  fieldsMetadata,
                  fieldsValues
                })
                const response = await onCreateJiraIssue(jiraIssue, jira)

                if (!response) return

                const { data } = response
                const { name, url } = data

                const ClickableMessage: React.FC<{
                  name: string
                  url: string
                }> = ({ name, url }) => (
                  <span>
                    Jira ticket-{" "}
                    <a
                      href={url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        color: "inherit"
                      }}
                    >
                      {name}
                    </a>{" "}
                    opened successfully
                  </span>
                )

                handleCloseModal()

                messageApi.open({
                  type: "success",
                  content: <ClickableMessage name={name} url={url} />,
                  duration: 10
                })
              } catch (error: any) {
                errorProcessing(true, error)
              } finally {
                setCreating(false)
              }
            }}
          >
            {tGeneral("create")}
          </Button>
        ]}
      >
        {LoadingComponent}
        {!isLoading && (
          <Form
            onFieldsChange={onFieldsChange}
            className={styles.form}
            layout="vertical"
            form={form}
          >
            <div className={styles.itemForm}>
              <Form.Item
                className={styles.selectSection}
                name={JiraFieldKey.Project}
                label={
                  <Text className={styles.label}>{t("projectField")}</Text>
                }
              >
                <Select
                  fieldNames={{ value: "id", label: "name" }}
                  options={projects}
                  filterOption={(input, option) => {
                    if (!option) return false

                    return (option.name || "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }}
                  showSearch
                  onChange={(value: string) => {
                    const selectedProject = projects.find((p) => p.id === value)
                    const issueTypeId = selectedProject?.issuetypes[0]?.id
                    saveProjectAndIssueType(value, issueTypeId || "")
                    form.setFieldsValue({
                      [JiraFieldKey.IssueType]: issueTypeId
                    })
                  }}
                />
              </Form.Item>
            </div>
            <div className={styles.itemForm}>
              <Form.Item
                className={styles.selectSection}
                name={JiraFieldKey.IssueType}
                label={
                  <Text className={styles.label}>{t("issueTypeField")}</Text>
                }
              >
                <Select
                  fieldNames={{ value: "id", label: "name" }}
                  options={issueTypesOptions}
                  onChange={(value: string) => {
                    const projectId = form.getFieldValue(JiraFieldKey.Project)
                    saveProjectAndIssueType(projectId, value)
                  }}
                />
              </Form.Item>
            </div>
            <Divider className={styles.divider} />
            <Form.Item
              className={styles.itemForm}
              label={<Text className={styles.label}>{t("summaryField")}</Text>}
              rules={[{ required: true, message: t("error.summary") || "" }]}
              name={JiraFieldKey.Summary}
            >
              <Input />
            </Form.Item>

            <div className={styles.itemForm}>
              <Form.Item
                name={JiraFieldKey.Description}
                style={{ display: "none" }}
              />
              <Text className={styles.label}>{t("descriptionField")}</Text>
              {initialDescription === null ? null : (
                <Editor
                  html={initialDescription}
                  onChange={(value: string) => {
                    form.setFieldValue(JiraFieldKey.Description, value)
                  }}
                />
              )}
            </div>
            {!isLoading && (
              <JiraIssueModalFields
                projectId={projectId}
                issueTypeId={issueTypeId}
              />
            )}
          </Form>
        )}
      </Modal>
      {messageContext}
    </>
  )
}
