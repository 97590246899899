import { Navigate } from "react-router-dom"

import { Breadcrumb, Flex, Link, Skeleton, Tabs, Text } from "@/atoms"
import { NAMES_ROUTES } from "@/const/routes.constants"
import { TAB_KEYS_BY_PAGE } from "@/const/tab.constants"
import { useActiveKeySearchParams } from "@/hooks/useActiveKeySearchParams"
import { Heading } from "@/molecules/Heading"
import { HeaderPage } from "@/organisms/HeaderPage"
import { HeaderItem } from "@/pages/IntegrationsPage/components/molecules/HeaderItem"
import { ItemIntegration } from "@/pages/IntegrationsPage/components/organisms/ItemIntegration"
import { useIntegration } from "@/pages/IntegrationsPage/hooks/useIntegration"
import { PageWithBreadcrumbTemplate } from "@/templates/PageWithBreadcrumbTemplate"

import styles from "./ConfigurationPageTemplate.module.scss"

import type { ConfigurationPageTemplateComponent } from "."

export const ConfigurationPageTemplate: ConfigurationPageTemplateComponent = (
  props
) => {
  const { INTEGRATION: keys } = TAB_KEYS_BY_PAGE
  const [activeTabKey, setActiveTabKey] = useActiveKeySearchParams(keys)
  const { heading, connect } = props
  const { titleKey, prefixConfig, config } = props
  const [data, { t }, { isLoading }] = useIntegration(props)
  const { integration, extraInfo } = data || {}
  const disabled = !integration?.isInstalled || !config

  if (!isLoading && !integration)
    return <Navigate to={`/${NAMES_ROUTES.INTEGRATIONS.ROOT}`} />

  return (
    <PageWithBreadcrumbTemplate
      classes={{ main: styles.configurationPageTemplate }}
      header={
        <HeaderPage
          title={
            <Breadcrumb
              items={[
                {
                  title: (
                    <Link to="/integrations">
                      {t("navigation.integrations")}
                    </Link>
                  )
                },
                {
                  title: integration?.displayName || (
                    <Skeleton.Input active size="small" />
                  )
                }
              ]}
            />
          }
        />
      }
    >
      {heading || <HeaderItem isLoading={isLoading} {...integration} />}
      {isLoading && (
        <div
          className={`${styles.configurationPageTemplateWrap} ${styles.loading}`}
        >
          <Skeleton active paragraph={{ rows: 3 }} />
          <Skeleton active paragraph={{ rows: 3 }} />
          <Skeleton active paragraph={{ rows: 1 }} />
        </div>
      )}
      {!isLoading && (
        <Tabs
          activeKey={activeTabKey}
          items={[
            {
              key: keys[0],
              label: t("integrations.connectTab"),
              children: (
                <div className={styles.configurationPageTemplateWrap}>
                  {connect || (
                    <ItemIntegration extraInfo={extraInfo} {...integration} />
                  )}
                </div>
              )
            },
            {
              disabled,
              key: keys[1],
              label: t("integrations.configuration.configurationTab"),
              children: !!config && (
                <Flex
                  vertical
                  gap={16}
                  className={styles.configurationPageTemplateWrap}
                >
                  {prefixConfig}
                  <Heading level={5} title={t(titleKey)} />
                  <Text>{config}</Text>
                </Flex>
              )
            }
          ]}
          onChange={setActiveTabKey}
        />
      )}
    </PageWithBreadcrumbTemplate>
  )
}
