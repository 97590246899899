import { useEffect } from "react"
import { Outlet, useLocation } from "react-router-dom"

import { Card, Flex, LogoImage, message } from "@/atoms"
import { RegionDropdown } from "@/pages/SignInPage/components/molecules/RegionDropdown"

import styles from "./SignInPageTemplate.module.scss"

export const SignInPageTemplate = () => {
  const [messageApi, contextHolder] = message.useMessage()
  const { state } = useLocation()
  const { successMessage } = state || {}

  useEffect(() => {
    if (successMessage) {
      messageApi.success(successMessage)
    }
  }, [successMessage, messageApi])

  return (
    <div className={styles.containerSignInPageTemplate}>
      {contextHolder}
      <Flex justify="flex-end" className={styles.region}>
        <RegionDropdown />
      </Flex>
      <div className={styles.logo}>
        <LogoImage height={60} />
      </div>
      <Card id="outlet-card-signin-template" className={styles.card}>
        <Outlet />
      </Card>
      <div className={styles.footer}>
        Kodem Security &#169;2022-{new Date().getFullYear()}
      </div>
    </div>
  )
}
