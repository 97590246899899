import { Card, Radio } from "@/atoms"

import styles from "./CardRadio.module.scss"

import type { CardRadioComponent } from "."

export const CardRadio: CardRadioComponent = (props) => (
  <Card className={styles.card}>
    <Radio value={props.value}>{props.label}</Radio>
    {props.selected && <div className={styles.wrapper}>{props.children}</div>}
  </Card>
)
