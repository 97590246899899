import type { ItemType } from "@/atoms"
import { getColorNumberForUserAvatar, type User } from "@/domain/user"
import { LogoutOutlined } from "@/icons"
import { UserItem } from "@/molecules/UserItem"

import styles from "./MenuHeader.module.scss"

export const getUserMenu = (user: User, signOut: () => void) => ({
  items: [
    { key: "0", label: "Hello", disabled: true },
    {
      key: "1",
      label: (
        <UserItem
          colorNumber={getColorNumberForUserAvatar(user.email)}
          classes={{ description: styles.description }}
          {...user}
        />
      )
    },
    { key: "divider", type: "divider", className: styles.divider },
    {
      key: "2",
      className: styles.button,
      icon: <LogoutOutlined />,
      label: "Sign Out",
      onClick: signOut
    }
  ] as ItemType[]
})
