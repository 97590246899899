import { useTranslation } from "@/hooks/useTranslation"
import { useIntegration } from "@/pages/IntegrationsPage/hooks/useIntegration"
import { WebhookConfigurationItem } from "./WebhookConfigurationItem"

import type { WebhookConfiguration } from "@/api/useIntegrations.types"
import type { WebhookConfigurationTabProps } from "."

export const WebhookConfigurationTab = (
  props: WebhookConfigurationTabProps
) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "integrations.webhook.configuration"
  })
  const [data] = useIntegration(props)
  const installations =
    (data?.configuration as WebhookConfiguration)?.installations || []
  const noData = installations.length === 0

  return (
    <>
      {noData && t("noData")}
      {!noData &&
        installations.map((values) => (
          <WebhookConfigurationItem
            key={values.id}
            values={values}
            {...props}
          />
        ))}
    </>
  )
}
