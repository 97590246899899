import { Navigate, useParams } from "react-router-dom"

import { NAMES_ROUTES } from "@/const/routes.constants"
import { ConfigurationPageTemplate } from "@/pages/IntegrationsPage/components/templates/ConfigurationPageTemplate"

import type { Vendor } from "@/pages/IntegrationsPage/hooks/useIntegration.types"

export const CommonIntegrationPage = () => {
  const { vendor } = useParams<{ vendor: Vendor }>()

  if (!vendor) return <Navigate to={`/${NAMES_ROUTES.INTEGRATIONS.ROOT}`} />

  return (
    <ConfigurationPageTemplate
      vendor={vendor}
      titleKey={`integrations.configuration.${vendor}.configurationTitle`}
    />
  )
}
