export const ALL_RESOURCES = "all_resources"
export const SPECIFIC_RESOURCES = "specific_resources"
export const SPECIFIC_SCOPES = "specific_scopes"
export const OPTIONS_DEFAULT_VALUE = { resources: [], fields: {} }
export const RESOURCES_INITIAL_VALUE = {
  filters: [{}],
  includeExclude: "Include"
}
export const FIELD_NAMES = {
  resource: "resource",
  property: "property",
  operator: "operator",
  exactKey: "exactKey",
  value: "value"
}
