import { InformationAlert } from "@/molecules/InformationAlert/InformationAlert"

import { DismissReason } from "@/pages/TriagePage/components/molecules/DismissModal/DismissReason"
import type { ActionProps } from "."
import styles from "./ActionsSectionForm.module.scss"

export const DismissAction = (props: ActionProps) => {
  const { t, path } = props

  const alertMessage = `${t("triage.dismiss_modal.explain")} ${t("triage.dismiss_modal.excludeFromSummary")}`
  return (
    <div className={styles.actionExtraFields}>
      <InformationAlert
        message={alertMessage}
        className={styles.informationAlert}
      />
      <DismissReason
        name={[path, "reason"]}
        t={t}
        classes={{ title: styles.dismissReason }}
      />
    </div>
  )
}
