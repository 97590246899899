import { useCreateKey } from "@/api/useAuthKeys"
import { SourceIntegrationType } from "@/api/useAuthKeys.types"
import { getKodemCICommonExamples } from "@/api/useIntegrations.selector"
import { KodemCIPublicImageName } from "@/api/useIntegrations.types"
import { Button, Carousel, Flex, Modal, Steps, Text } from "@/atoms"
import { NAMES_ROUTES } from "@/const/routes.constants"
import { useLogger } from "@/hooks/useLogger"
import { Trans, useTranslation } from "@/hooks/useTranslation"
import { KeyIcon } from "@/icons"
import { CopyButton } from "@/molecules/CopyButton"
import { Protected } from "@/molecules/Protected"
import { HowToUseTemplate } from "@/pages/IntegrationsPage/components/templates/HowToUseTemplate"
import { CreateKeyModal } from "@/pages/SettingsPage/components/organisms/AuthKeysTab"
import { useState } from "react"
import { useNavigate } from "react-router"
import type { HowToUseProps, KodemCiIntegration } from "."
import { CodeItem } from "../../molecules/CodeItem"

import styles from "./HowToUseCIIntegration.module.scss"

export const HowToUseCIIntegration = (props: HowToUseProps) => {
  const { logger, EVENTS } = useLogger()

  const eventKey = props.name.toUpperCase() as KodemCiIntegration
  const { OPENED_GENERATE_KEY, COMMAND_GENERATE_KEY } =
    EVENTS.ANALYTIC_EVENTS.INTEGRATIONS.KODEMCI[eventKey]

  const navigate = useNavigate()

  const [, modalContextHolder] = Modal.useModal()
  const [showModal, setShowModal] = useState<boolean | number>(false)
  const { t: tKodemCI } = useTranslation("translation", {
    keyPrefix: "integrations.connect.kodemCI"
  })

  const kodemCIVersion = props.extraInfo?.version || "latest"
  const kodemCiCommonExamples = getKodemCICommonExamples(kodemCIVersion)
  const pullCommand = `docker pull ${KodemCIPublicImageName}:${kodemCIVersion}`
  const exportCommand = "export KODEM_SERVICE_KEY=secret"
  const { GOVERNANCE } = NAMES_ROUTES

  return (
    <>
      <HowToUseTemplate {...props}>
        <Steps.Step
          status="process"
          title={tKodemCI("steps.pull.title")}
          description={
            <div>
              <Text type="secondary">{tKodemCI("steps.pull.description")}</Text>
              <pre className="code-block-step-how-to-use-template-ks">
                <code>{pullCommand}</code>
                <CopyButton text={pullCommand} type="link" />
              </pre>
            </div>
          }
        />
        <Steps.Step
          status="process"
          title={tKodemCI("steps.generateKey.title")}
          description={
            <div className={styles.stepItem}>
              <Flex vertical gap={4}>
                <Text type="secondary">
                  {tKodemCI("steps.generateKey.description")}
                </Text>
                <Flex vertical gap={8}>
                  <div className="button-wrapper-step-how-to-use-template-ks">
                    <Protected
                      permission={{
                        resource: "settings.service-keys",
                        action: "write"
                      }}
                      shouldDisable
                    >
                      <Button
                        type="primary"
                        icon={<KeyIcon />}
                        onClick={() => {
                          logger.info(OPENED_GENERATE_KEY)
                          setShowModal(Date.now())
                        }}
                      >
                        {tKodemCI("steps.generateKey.buttonText")}
                      </Button>
                    </Protected>
                  </div>
                  <Text type="secondary">
                    {tKodemCI("steps.generateKey.note")}
                  </Text>
                </Flex>
              </Flex>
              <pre className="code-block-step-how-to-use-template-ks">
                <code>{exportCommand}</code>
                <CopyButton text={exportCommand} type="link" />
              </pre>
            </div>
          }
        />
        <Steps.Step
          status="process"
          title={tKodemCI("steps.prep_scan.title")}
          description={
            <Flex vertical gap={8}>
              <CodeItem
                titleLine1={tKodemCI("steps.prep_scan.image_description")}
                codeSnippet={tKodemCI("steps.prep_scan.image_pull")}
              />
              <CodeItem
                titleLine1={tKodemCI("steps.prep_scan.repo_description")}
                codeSnippet={tKodemCI("steps.prep_scan.repo_pull")}
              />
            </Flex>
          }
        />
        <Steps.Step
          status="process"
          title={tKodemCI("steps.scan.title")}
          description={
            props.howToUse && (
              <div className="description-step-how-to-use-template-ks">
                <Text type="secondary">
                  <Trans>
                    {tKodemCI("steps.scan.description", {
                      count: kodemCiCommonExamples.length
                    })}
                  </Trans>
                </Text>
                <Carousel arrows infinite={false}>
                  {kodemCiCommonExamples.map((sample, index) => (
                    <CodeItem
                      key={index}
                      {...sample} // titleLine1, titleLine2, infoLine, code
                      titleLine1={`<strong>Example ${index + 1}:</strong> ${sample.titleLine1}`}
                      withCopy
                    />
                  ))}
                </Carousel>
              </div>
            )
          }
        />
        <Steps.Step
          status="process"
          title={tKodemCI(`steps.integrate.title.${props.name}`)}
          description={
            props.howToUse?.integrationSample !== "TBD" && (
              <div>
                <Text type="secondary">
                  {tKodemCI(`steps.integrate.description.${props.name}`)}
                </Text>
                <div className={styles.integrationSample}>
                  <div>
                    <pre className={styles.codeBlock}>
                      <code className={styles.scrollable}>
                        {props.howToUse?.integrationSample}
                      </code>
                    </pre>
                  </div>
                  <div>
                    <CopyButton
                      text={props.howToUse?.integrationSample}
                      type="link"
                    />
                  </div>
                </div>
              </div>
            )
          }
        />
        <Steps.Step
          status="process"
          title={tKodemCI("steps.ciPolicies.title")}
          description={
            <Button
              className={styles.noPadding}
              type="link"
              target="_blank"
              onClick={() => navigate(`/${GOVERNANCE.ROOT}/${GOVERNANCE.CI}`)}
            >
              {tKodemCI("steps.ciPolicies.buttonText")}
            </Button>
          }
        />
      </HowToUseTemplate>
      <CreateKeyModal
        key={`${showModal}`}
        open={!!showModal}
        useCreateKey={useCreateKey}
        logCreateKey={(keyName) => {
          logger.info(COMMAND_GENERATE_KEY, { keyName })
        }}
        keyType={"SERVICE_KEYS"}
        onClose={() => {
          setShowModal(false)
        }}
        sourceIntegration={props.name as SourceIntegrationType}
      />
      {modalContextHolder}
    </>
  )
}
