import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { Breadcrumb, Link, Tabs } from "@/atoms"
import { NAMES_ROUTES } from "@/const/routes.constants"
import { isKnownUserRole } from "@/domain/user"
import { useTranslation } from "@/hooks/useTranslation"
import { useUser } from "@/hooks/useUser"
import { HeaderPage } from "@/organisms/HeaderPage"
import { useActiveKeyTabsUsers } from "@/pages/SettingsPage/hooks/useActiveKeyTabsUsers"
import { PageWithBreadcrumbTemplate } from "@/templates/PageWithBreadcrumbTemplate"
import { getItemsTabsByRole } from "./ItemsTabs"

import styles from "./SettingsPage.module.scss"

export const SettingsPage = () => {
  const { t } = useTranslation("translation", { keyPrefix: "settings" })
  const { t: tNav } = useTranslation("translation", { keyPrefix: "navigation" })
  const { user } = useUser()
  const { hasPermission } = useUser()
  const navigation = useNavigate()
  const { activeKey } = useActiveKeyTabsUsers()
  const items = getItemsTabsByRole(hasPermission, { activeKey, t })
  const knownUserRole = isKnownUserRole(user)

  useEffect(() => {
    if (!items.length || !knownUserRole) return

    if (activeKey === undefined) {
      navigation(`/settings/${items[1].key}`)

      return
    }

    const activeKeyIndex = items.findIndex(({ key }) => key === activeKey)

    if (activeKeyIndex === -1) {
      navigation(`/settings/${items[1].key}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKey, items])

  return (
    <PageWithBreadcrumbTemplate
      classes={{ main: styles.main }}
      header={
        knownUserRole && (
          <HeaderPage
            title={
              <Breadcrumb
                items={[
                  {
                    title: <Link to="/settings">{tNav("settings")}</Link>,
                    className: "capitalize-text-ks"
                  },
                  {
                    title: t(`${activeKey}.title`),
                    className: "capitalize-text-ks"
                  }
                ]}
              />
            }
          />
        )
      }
      loading={!knownUserRole}
    >
      <Tabs
        destroyInactiveTabPane
        className={styles.tab}
        tabPosition={"left"}
        activeKey={activeKey}
        items={items}
        onTabClick={(key) => {
          navigation(`/${NAMES_ROUTES.SETTINGS.ROOT}/${key}`)
        }}
      />
    </PageWithBreadcrumbTemplate>
  )
}
