import { useMemo } from "react"
import { useParams } from "react-router-dom"

import { App, Form } from "@/atoms"
import { VALUES_PARAMS } from "@/const/routes.constants"
import { useCustomFunctionsForm } from "@/hooks/useCustomFunctionsForm"
import { usePrompt } from "@/hooks/usePrompt"
import { useTranslation } from "@/hooks/useTranslation"
import { useUser } from "@/hooks/useUser"
import { FormContext, type Action } from "@/organisms/Form/hooks/useForm"
import { PageWithBreadcrumbTemplate } from "@/templates/PageWithBreadcrumbTemplate"

import type { FormProps } from "."

export const FormPage = (props: FormProps) => {
  const { message } = App.useApp()
  const [form] = Form.useForm()
  const custom = useCustomFunctionsForm()
  const { id: needle } = useParams()
  const isNewEntity = needle === VALUES_PARAMS.NEW_ENTITY
  const { children, id: formId, ...rest } = props
  const { t } = useTranslation()
  const currentAction: Action = isNewEntity ? "created" : "updated"
  const { hasPermission } = useUser()
  const writeActionAllowed = !!hasPermission({
    resource: props.resource || "*",
    action: props.resource ? "write" : "*"
  })

  const value = useMemo(
    () => ({
      form,
      formId,
      custom,
      isNewEntity,
      permissions: { write: writeActionAllowed },
      message: {
        instance: message,
        onSuccess: (name: string, { action = currentAction } = {}) =>
          message.success(t(`forms.messages.${action}`, { name })),
        onFailed: (_: string, { action = currentAction } = {}) => {
          message.error(t(`forms.messages.failed`, { action }))
        }
      }
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [custom, isNewEntity, writeActionAllowed]
  )

  usePrompt(t("prompt.form.message"), custom.isDirty)

  return (
    <FormContext.Provider value={value}>
      <PageWithBreadcrumbTemplate {...rest}>
        {children}
      </PageWithBreadcrumbTemplate>
    </FormContext.Provider>
  )
}
