import { useReducer } from "react"

import type { Label } from "@/domain/label"

interface State {
  searchValue?: string
  dropdownOpen?: boolean
  label?: Partial<Label>
  open: boolean
}

const SET_LABEL = "SET_LABEL"
const SET_SEARCH_VALUE = "SET_SEARCH_VALUE"
const CANCEL = "CANCEL"
const DROPDOWN_CHANGE_VISIBLE = "DROPDOWN_CHANGE_VISIBLE"

const reducer = (
  state: State,
  action: { type: string; payload?: string | boolean }
) => {
  const isDropdownOpen = () =>
    (!!action.payload && !state.open) || state.dropdownOpen

  switch (action.type) {
    case SET_LABEL:
      return {
        ...state,
        open: true,
        label: { name: state.searchValue }
      }

    case SET_SEARCH_VALUE:
      if (typeof action.payload !== "string") return state

      return {
        ...state,
        searchValue: action.payload,
        dropdownOpen: isDropdownOpen()
      }

    case CANCEL:
      return {
        open: false,
        label: undefined,
        dropdownOpen: true,
        searchValue: action.payload === true ? undefined : state.searchValue
      }

    case DROPDOWN_CHANGE_VISIBLE: {
      if (action.payload === state.dropdownOpen) return state

      return {
        ...state,
        dropdownOpen: !!action.payload
      }
    }

    default:
      return state
  }
}

export const useCreateLabelModal = () => {
  const [state, dispatch] = useReducer(reducer, { open: false })

  const onSearch = (value: string) =>
    dispatch({
      type: SET_SEARCH_VALUE,
      payload: value
    })

  const onCreate = () => dispatch({ type: SET_LABEL })

  const onCancel = (reset?: boolean) =>
    dispatch({
      type: CANCEL,
      payload: reset
    })

  const onDropdownVisibleChange = (payload: boolean) =>
    dispatch({
      type: DROPDOWN_CHANGE_VISIBLE,
      payload
    })

  return [
    state,
    { onCreate, onSearch, onCancel, onDropdownVisibleChange }
  ] as const
}
