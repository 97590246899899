import cn from "classnames"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router"

import {
  InstallStatus,
  useGetIntegrationDetails,
  useInstallIntegration,
  usePreInstallECR
} from "@/api/useIntegrations"
import {
  Breadcrumb,
  Button,
  Form,
  Input,
  Link,
  Paragraph,
  Segmented,
  Skeleton,
  Steps,
  Tabs,
  Text,
  Title,
  Tooltip
} from "@/atoms"
import { generateExternalID } from "@/helpers/utils"
import { useLogger } from "@/hooks/useLogger"
import { Trans, useTranslation } from "@/hooks/useTranslation"
import { PlusCircleOutlined, ReadOutlined } from "@/icons"
import { CopyButton } from "@/molecules/CopyButton"
import { HeaderPage } from "@/organisms/HeaderPage"
import { InlineInput } from "@/pages/DeploymentsPage/components/molecules/InlineInput"
import { InlineInputButton } from "@/pages/DeploymentsPage/components/molecules/InlineInputButton"
import { TagInput } from "@/pages/GovernancePage/components/organisms/ScmForm/components/TagInput"
import { MessageApiProps } from "@/pages/IntegrationsPage/IntegrationsPage.types"
import { PageWithBreadcrumbTemplate } from "@/templates/PageWithBreadcrumbTemplate"
import { BaseReposLabel } from "../../molecules/BaseReposLabel"
import { SelectRegions } from "../../molecules/SelectRegions"
import { ConnectIntegrationFooter } from "./ConnectIntegrationFooter"

import styles from "./ConnectIntegrationPage.module.scss"

import type { CopyButtonProps } from "@/molecules/CopyButton/CopyButton.types"
import type { Data } from "@/pages/DeploymentsPage/components/molecules/InlineInput/InlineInput.types"

export const ConnectIntegrationPage = ({ messageApi }: MessageApiProps) => {
  const { t } = useTranslation()
  const { logger, EVENTS } = useLogger()
  const { nameIntegration: name } = useParams()
  const { t: tConnectEcr } = useTranslation("translation", {
    keyPrefix: `integrations.connect.${name}`
  })
  const { response, isLoading } = useGetIntegrationDetails(name!)
  const { data } = response || {}
  const { integration } = data || {}
  const { connectPage, displayName = "" } = integration || {}
  const { submitButton } = connectPage || {}
  const { label, link: instructionsLink } = submitButton || {}
  const { response: preInstallResponse } = usePreInstallECR(name)

  const [form] = Form.useForm()
  const [isFormDirty, setFormDirty] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [externalIdValue, setExternalIdValue] = useState(generateExternalID())

  const regenerateExternalID = () => {
    setExternalIdValue(generateExternalID())
  }

  const updater = useInstallIntegration({ vendor: name! })
  const navigation = useNavigate()

  const policyTextPre = `// Create a role for Kodem with AssumeRole permission
aws iam create-role --role-name kodem-role --assume-role-policy-document '{
  "Version": "2012-10-17",
  "Statement": [
    {
      "Effect": "Allow",
      "Principal": {
        "AWS": "${preInstallResponse?.kodemAwsAccountId}"
      },
      "Action": "sts:AssumeRole",
      "Condition": {
        "StringEquals": {`
  const lineBreakPre = "\n            "
  const stsExternalID = `"sts:ExternalId": "${externalIdValue.value}"`
  const policyTextPost = `
        }
      }
    }
  ]
}'

// Attach ECR read only policy to the created role
aws iam attach-role-policy --role-name kodem-role --policy-arn arn:aws:iam::aws:policy/AmazonEC2ContainerRegistryReadOnly

// Optional - Attach EC2 for SSM policy when using Kodem deployment on EC2 instances
aws iam attach-role-policy --role-name kodem-role --policy-arn arn:aws:iam::aws:policy/service-role/AmazonEC2RoleforSSM
  `

  type ConnectMethod = "console" | "cli"
  const defaultMethod: ConnectMethod = "console"
  const [methodValue, setMethodValue] = useState<ConnectMethod>(defaultMethod)
  const copyButtonProps: CopyButtonProps = {
    type: "link",
    className: styles.copyButton
  }

  useEffect(() => {
    form.setFieldValue("externalId", externalIdValue.value)
  }, [form, externalIdValue])

  return (
    <PageWithBreadcrumbTemplate
      full
      header={
        <HeaderPage
          title={
            <Breadcrumb
              items={[
                {
                  title: (
                    <Link to="/integrations">
                      {t("navigation.integrations")}
                    </Link>
                  )
                },
                {
                  title: displayName ? (
                    <Link to={`/integrations/${name}`}>{displayName}</Link>
                  ) : (
                    <Skeleton.Input active size="small" />
                  )
                },
                { title: label }
              ]}
            />
          }
        />
      }
      footer={
        <ConnectIntegrationFooter
          onSubmit={async () => {
            try {
              await form.validateFields()
              logger.info(EVENTS.ANALYTIC_EVENTS.INTEGRATIONS.COMMAND_CONNECT, {
                ...form.getFieldsValue()
              })
              form.submit()
            } catch {
              setHasError(true)
            }
          }}
          onCancel={() => {
            form.resetFields()
            setHasError(false)
            logger.info(EVENTS.ANALYTIC_EVENTS.INTEGRATIONS.COMMAND_CANCEL)
            navigation(`/integrations/${name}`)
          }}
          disabled={hasError || !isFormDirty}
          isLoading={updater.isPending}
        />
      }
    >
      <Title
        className={cn(styles.title, isLoading && styles.loading)}
        level={5}
      >
        {`${tConnectEcr("title")}`}
      </Title>
      <Form
        scrollToFirstError
        form={form}
        onFieldsChange={() => {
          setFormDirty(true)
          setHasError(false)
        }}
        onFinish={async () => {
          try {
            const res: any = await updater.mutateAsync({
              data: form.getFieldsValue()
            })
            const { data }: { data: InstallStatus } = res
            if (data.status === "failure") {
              messageApi.error(tConnectEcr("error"))
              return
            }
            messageApi.success({ content: tConnectEcr("success"), duration: 3 })
            navigation(`/integrations/${name}?tab=configuration`)
          } catch {
            messageApi.error(tConnectEcr("error"))
          }
        }}
        layout="vertical"
      >
        <Steps size="small" className={styles.steps} direction="vertical">
          <>
            <Steps.Step
              title={tConnectEcr("grantStep.title")}
              description={
                <>
                  <Text
                    type="secondary"
                    className="neutral-mark-text-form-trans-ks"
                  >
                    <Trans>{tConnectEcr("grantStep.description")}</Trans>
                  </Text>
                  <div className={styles.asBlock}>
                    <Paragraph>Select your preferred method:</Paragraph>
                    <Segmented
                      defaultValue={defaultMethod}
                      options={["console", "cli"].map((platform) => ({
                        value: platform,
                        label: tConnectEcr(`method.${platform}`)
                      }))}
                      onChange={(value) => {
                        logger.info(
                          EVENTS.ANALYTIC_EVENTS.INTEGRATIONS.METHOD_SELECTED,
                          { value }
                        )
                        setMethodValue(value as ConnectMethod)
                      }}
                    />
                  </div>
                  <Tabs
                    items={[
                      {
                        key: "console",
                        label: "",
                        children: (
                          <>
                            <Button
                              icon={<ReadOutlined />}
                              target="_blank"
                              href={instructionsLink}
                              type="link"
                              className={styles.readButton}
                            >
                              Read Instructions
                            </Button>
                            <div className={styles.formWrapper}>
                              <div>
                                <Form.Item>
                                  <Input
                                    name="accoundId"
                                    value={
                                      preInstallResponse?.kodemAwsAccountId
                                    }
                                    addonBefore="Kodem AWS Account ID"
                                    addonAfter={
                                      <CopyButton
                                        text={
                                          preInstallResponse?.kodemAwsAccountId
                                        }
                                        {...copyButtonProps}
                                      />
                                    }
                                    readOnly
                                  />
                                </Form.Item>
                                <Form.Item>
                                  <Input
                                    name="externalId"
                                    value={externalIdValue.value}
                                    addonBefore="External ID"
                                    addonAfter={
                                      <CopyButton
                                        text={externalIdValue.value}
                                        {...copyButtonProps}
                                      />
                                    }
                                    onChange={(e) => {
                                      setExternalIdValue({
                                        key: externalIdValue.key,
                                        value: e.target.value
                                      })
                                    }}
                                  />
                                </Form.Item>
                              </div>
                              <Form.Item>
                                <Button onClick={regenerateExternalID}>
                                  {tConnectEcr("generateExternalID")}
                                </Button>
                              </Form.Item>
                            </div>
                          </>
                        )
                      },
                      {
                        key: "cli",
                        label: "",
                        children: (
                          <>
                            <Paragraph className={styles.cliDescription}>
                              {tConnectEcr("cliDescription")}
                            </Paragraph>
                            <div className={styles.copyCommandCli}>
                              <div>
                                <pre>
                                  <code>
                                    <Text className={styles.codeBlock}>
                                      {policyTextPre}
                                      {lineBreakPre}
                                      {'"sts:ExternalId":'}
                                      <InlineInput
                                        key={`${externalIdValue.key}_external_id_inline`}
                                        name="external_id_inline"
                                        value={externalIdValue.value}
                                        onChange={({ value }: Data) => {
                                          setExternalIdValue({
                                            value: value || "",
                                            key: externalIdValue.key
                                          })
                                        }}
                                        buttons={
                                          <InlineInputButton
                                            loading={isLoading}
                                            icon={
                                              <Tooltip
                                                title={tConnectEcr(
                                                  "generateExternalID"
                                                )}
                                              >
                                                <Tooltip
                                                  title={tConnectEcr(
                                                    "generateExternalID"
                                                  )}
                                                >
                                                  <PlusCircleOutlined />
                                                </Tooltip>
                                              </Tooltip>
                                            }
                                            onClick={regenerateExternalID}
                                          />
                                        }
                                      />
                                      {policyTextPost}
                                    </Text>
                                  </code>
                                </pre>
                              </div>
                              <div>
                                <CopyButton
                                  type="primary"
                                  text={
                                    policyTextPre +
                                    lineBreakPre +
                                    stsExternalID +
                                    policyTextPost
                                  }
                                >
                                  {t("general.copy")}
                                </CopyButton>
                              </div>
                            </div>
                          </>
                        )
                      }
                    ]}
                    activeKey={methodValue}
                  />
                </>
              }
              status="process"
            />
            <Steps.Step
              title={tConnectEcr("completeStep.title")}
              description={
                <>
                  <span>{tConnectEcr("completeStep.description")}</span>
                  <Form.Item
                    name="account_id"
                    label={tConnectEcr("accountID.label")}
                    rules={[
                      {
                        pattern: new RegExp("^\\d{12}$"),
                        message: tConnectEcr(
                          "accountID.validationErrorMessage"
                        ),
                        validateTrigger: "onSubmit"
                      }
                    ]}
                  >
                    <Input placeholder={tConnectEcr("accountID.placeholder")} />
                  </Form.Item>
                  <Form.Item name="externalId" label="External ID">
                    <Input disabled />
                  </Form.Item>
                  <Form.Item
                    name="role"
                    label={tConnectEcr("iamRoleArn.label")}
                    rules={[
                      {
                        validator: async (_, value) => {
                          const pattern = new RegExp(
                            /^arn:aws:iam::\d{12}:role\/[a-zA-Z0-9\-+/=,.@_]+$/
                          )

                          if (value && pattern.test(value)) {
                            return Promise.resolve()
                          }
                          return Promise.reject()
                        },
                        message: tConnectEcr(
                          "iamRoleArn.validationErrorMessage"
                        ),
                        validateTrigger: "onSubmit"
                      }
                    ]}
                  >
                    <Input
                      placeholder={tConnectEcr("iamRoleArn.placeholder")}
                    />
                  </Form.Item>
                  <Form.Item
                    name="regions"
                    label="Regions"
                    rules={[
                      {
                        message: tConnectEcr("regions.validationErrorMessage"),
                        validateTrigger: "onSubmit",
                        validator: async (_, values) => {
                          if (values && values.length > 0) {
                            return Promise.resolve()
                          }
                          return Promise.reject()
                        }
                      }
                    ]}
                  >
                    <SelectRegions
                      options={preInstallResponse?.regionOptions}
                      placeholder={tConnectEcr("regions.placeholder")}
                    />
                  </Form.Item>
                  <Form.Item
                    name="repositories"
                    label={<BaseReposLabel t={tConnectEcr} />}
                  >
                    <TagInput
                      placeholder={tConnectEcr("baseReposInput.placeholder")}
                    />
                  </Form.Item>
                </>
              }
              status="process"
            />
          </>
        </Steps>
      </Form>
    </PageWithBreadcrumbTemplate>
  )
}
