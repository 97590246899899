import { Col, Flex, Row } from "@/atoms"
import { isCodeIssue, isMaliciousPackageIssue } from "@/domain/issue"
import { useTranslation } from "@/hooks/useTranslation"
import { DescriptionIssueCard } from "@/molecules/DescriptionIssueCard"
import { useIssue } from "@/organisms/Drawers/applications/triage/useTriage"
import { RuntimeEvidenceCard } from "@/organisms/Drawers/components/RuntimeEvidenceTab/RuntimeEvidenceCard"
import { ActionCard } from "@/organisms/Drawers/components/TriageDrawer/components/ActionsCard"
import { FixesCard } from "../FixesCard"
import { MetaIssue } from "../MetaIssue"
import { ScoreCardExplanation } from "../ScoreCardExplanation"
import { SummaryCard } from "../SummaryCard"
import { VulnerableCodeCards } from "../VulnerableCodeCards"

import styles from "./OverviewTabTriageDrawer.module.scss"

import type { TabComponentProps } from "@/organisms/Drawers/components/TabsDrawerTemplate"

export const OverviewTabTriageDrawer = (props: TabComponentProps) => {
  const issueId = props.record?.id
  const { response, isLoading } = useIssue(issueId)
  const record = response?.data?.[0]
  const { t } = useTranslation()

  if (isLoading || !record) return null

  const isVulnerableCode = isCodeIssue(record)
  const isMaliciousPackage = isMaliciousPackageIssue(record)
  const summaryCardSpan = isMaliciousPackage ? 18 : 16

  return (
    <>
      <MetaIssue record={record} />
      <Row gutter={[0, 16]} className={styles.rowContainer}>
        <Row gutter={[0, 16]} className={styles.rowWrapper}>
          <Col span={24}>
            <Row gutter={[16, 0]}>
              <Col span={isMaliciousPackage ? 18 : 16}>
                <SummaryCard record={record} />
              </Col>
              <Col span={24 - summaryCardSpan}>
                <Flex
                  vertical
                  gap={16}
                  className={styles.scoreAndRuntimeWrapper}
                >
                  <ScoreCardExplanation record={record} />
                  {!isMaliciousPackage && (
                    <RuntimeEvidenceCard
                      {...props}
                      record={record}
                      isCodeIssue={isVulnerableCode}
                      eventFrom="overviewLink"
                    />
                  )}
                </Flex>
              </Col>
            </Row>
          </Col>
          {record.status != "processing" && (
            <DescriptionIssueCard t={t} record={record.findings[0]} />
          )}
          {record?.tickets?.length > 0 && (
            <Col span={24}>
              <ActionCard.Collapse tickets={record.tickets} />
            </Col>
          )}
          {!isVulnerableCode && (
            <Col span={24} className={styles.fixesCard}>
              <FixesCard record={record} />
            </Col>
          )}
          {isVulnerableCode && <VulnerableCodeCards record={record} />}
        </Row>
        {!isVulnerableCode && (
          <Col span={24} className={styles.fixesCard}>
            <FixesCard type="resources" record={record} />
          </Col>
        )}
      </Row>
    </>
  )
}
