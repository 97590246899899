import { Avatar, Link } from "@/atoms"
import { NAMES_ROUTES } from "@/const/routes.constants"
import { getColorNumberForUserAvatar, getInitials } from "@/domain/user"
import { Kodem } from "@/icons"
import styles from "./DismissedTag.module.scss"
import { DismissedByComponent } from "./DismissedTag.types"

export const DismissedBy: DismissedByComponent = (props) => {
  const { dismissMethod, dismissedBy, t } = props
  const isManual = dismissMethod === "manual"
  const {
    GOVERNANCE: { ROOT, WORKFLOWS }
  } = NAMES_ROUTES

  return (
    <div className={styles.dismissContainer}>
      {isManual ? (
        <Avatar
          style={{
            backgroundColor: `var(--color-${getColorNumberForUserAvatar(dismissedBy)}-avatar-palette)`
          }}
          className={styles.avatar}
          size="small"
        >
          {`${getInitials(dismissedBy)}`}
        </Avatar>
      ) : (
        <Kodem className={styles.kodemIcon} />
      )}
      {isManual ? (
        dismissedBy
      ) : (
        <Link
          href={`/${ROOT}/${WORKFLOWS}/${dismissedBy}`}
          target="_blank"
          type="link"
        >
          {t("automaticDismissedBy")}
        </Link>
      )}
    </div>
  )
}
