import { useCallback, useContext, useEffect, useRef } from "react"
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom"

import { App } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"

const useConfirmExit = (confirmExit: () => Promise<boolean>, when = true) => {
  const { navigator } = useContext(NavigationContext)

  useEffect(() => {
    if (!when) {
      return
    }

    const push = navigator.push

    navigator.push = async (...args: Parameters<typeof push>) => {
      const result = await confirmExit()

      if (result !== false) {
        push(...args)
      }
    }

    return () => {
      navigator.push = push
    }
  }, [navigator, confirmExit, when])
}

export function usePrompt(message: string, when = true) {
  const hash = useRef(window.location.hash)
  const pushed = useRef(false)
  const replacePushedState = () => {
    if (pushed.current) {
      pushed.current = false
      window.history.back()
    }
  }
  const { t } = useTranslation("translation", { keyPrefix: "prompt.form" })
  const { modal } = App.useApp()

  const confirmExit = useCallback(
    () =>
      new Promise<boolean>((resolve) => {
        modal.confirm({
          content: message,
          okText: t("ok"),
          cancelText: t("cancel"),
          onOk() {
            resolve(false)
          },
          onCancel() {
            resolve(true)
          }
        })
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    if (when) {
      window.onbeforeunload = function () {
        return message
      }

      if (!pushed.current)
        window.history.pushState(null, "", window.location.href)

      window.onpopstate = async (event: any) => {
        const nextHash = event.target?.location.hash

        if (nextHash !== hash.current) {
          hash.current = nextHash

          return
        }

        const confirm = await confirmExit()

        if (confirm) {
          window.onpopstate = null
          window.history.back()
        } else {
          window.history.replaceState(null, "", window.location.href)
        }
      }
    }

    return () => {
      window.onpopstate = null
      window.onbeforeunload = null
      replacePushedState()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, when])

  useEffect(() => {
    return () => {
      replacePushedState()
    }
  }, [])

  useConfirmExit(confirmExit, when)
}
