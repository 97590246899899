import { Button, Tooltip } from "@/atoms"
import { isUserAdmin } from "@/domain/user"
import { useTranslation } from "@/hooks/useTranslation"
import { useUser } from "@/hooks/useUser"
import { EyeOutlined } from "@/icons"
import { Form } from "@/organisms/Form"

export const PreviewButton = () => {
  const { t } = useTranslation()
  const { user } = useUser()
  const isNotAdmin = !!user.role && !isUserAdmin(user)
  const { form, custom } = Form.useForm()
  const currentScopeId = Form.useWatch("id", form)
  const preview = Form.useWatch("preview", form)
  const { isDirty } = custom || {}
  const disabledPreview = isNotAdmin ? false : isDirty || !currentScopeId

  return (
    <Tooltip
      title={
        disabledPreview
          ? t("settings.scopes.messages.previewTooltip")
          : undefined
      }
    >
      <Button
        disabled={disabledPreview}
        icon={<EyeOutlined />}
        onClick={() =>
          form.setFieldValue("preview", { ...preview, open: true })
        }
      >
        {t("general.preview")}
      </Button>
    </Tooltip>
  )
}
