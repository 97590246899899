import { DisconnectOutlined, FolderOpenOutlined } from "@/icons"
import {
  ConfigItem,
  DescriptionLabelConfigItem,
  FooterConfigItem
} from "@/pages/IntegrationsPage/components/molecules/ConfigItem"
import { useIntegration } from "@/pages/IntegrationsPage/hooks/useIntegration"

import type { GitLabIntegration } from "@/api/useIntegrations.types"
import type { GitLabConfigurationTabProps } from "."

export const GitLabConfigurationTab = (props: GitLabConfigurationTabProps) => {
  const keyPrefix = "integrations.connect.gitlab."
  const [data, { isPending, onRemove, t }] = useIntegration(props)
  const installations =
    (data?.configuration?.installations as GitLabIntegration[]) || []
  const noData = !installations.length

  return (
    <>
      {noData && t(`${keyPrefix}noGroup`)}
      {!noData &&
        installations.map(({ groupName: name, projectsCount }, index) => {
          const id = `${name}${projectsCount}${index}`
          const values = { id, name }

          return (
            <ConfigItem
              key={id}
              values={values}
              Description={
                <DescriptionLabelConfigItem
                  Icon={FolderOpenOutlined}
                  tKey={`${keyPrefix}project_connected`}
                  count={Number(projectsCount)}
                />
              }
            >
              <FooterConfigItem
                isPending={isPending}
                permissions={{ action: "*", resource: "*" }}
                removeTKey="general.disconnect"
                RemoveIcon={DisconnectOutlined}
                onRemove={() => onRemove(values)}
              />
            </ConfigItem>
          )
        })}
    </>
  )
}
