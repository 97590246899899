import { Button, Divider, Flex } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { CheckCircleOutlined, DeleteOutlined } from "@/icons"
import { Protected } from "@/molecules/Protected"

import styles from "./FooterConfigItem.module.scss"

import type { FooterConfigItemProps } from "./FooterConfigItem.types"

export const FooterConfigItem = (props: FooterConfigItemProps) => {
  const { t } = useTranslation()
  const { permissions, isDirty, formId, isPending, isTesting, children } = props
  const { removeTKey = "general.remove", RemoveIcon = DeleteOutlined } = props
  const { onReset, onRemove } = props
  const onlyRemove = !formId || !onReset
  const disabled = isPending || isTesting

  return (
    <>
      {!onlyRemove && <Divider className={styles.footerConfigItemDivider} />}
      <Flex
        flex={1}
        justify="space-between"
        className={styles.footerConfigItemWrapper}
      >
        <Flex gap={8}>
          <Protected shouldDisable permission={permissions}>
            <Button
              danger
              icon={<RemoveIcon />}
              size="small"
              type="default"
              onClick={onRemove}
              disabled={disabled}
            >
              {t(removeTKey)}
            </Button>
            {children}
          </Protected>
        </Flex>
        {!onlyRemove && (
          <Flex gap={8}>
            <Protected shouldDisable permission={permissions}>
              <Button
                size="small"
                type="text"
                disabled={disabled || !isDirty}
                onClick={onReset}
              >
                {t("general.reset")}
              </Button>
            </Protected>
            <Protected shouldDisable permission={permissions}>
              <Button
                icon={<CheckCircleOutlined />}
                size="small"
                form={formId}
                htmlType="submit"
                type="primary"
                disabled={!isDirty || isTesting}
                loading={isPending}
              >
                {t("general.apply")}
              </Button>
            </Protected>
          </Flex>
        )}
      </Flex>
    </>
  )
}
