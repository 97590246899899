import { Column } from "@ant-design/plots"
import { memo, useRef } from "react"

import { Bucket, Incident } from "@/api/useIncidents.types"
import { Space, Text } from "@/atoms"
import {
  DEFAULT_LONG_FORMAT,
  getDateFromSeconds,
  getDateInUserLocale,
  getRelativeTimeFromNow
} from "@/helpers/date.helpers"

export const ColumnGraph = ({
  dataSource
}: {
  dataSource: Bucket[]
  incidents?: Incident[]
}) => {
  const config = useRef({
    height: 115,
    data: Array(dataSource.length)
      .fill(undefined)
      .map((_, index) => {
        return {
          timestamp: new Date(dataSource[index].startTime).getTime(),
          value: dataSource[index].count
        }
      })
      .reverse(),
    color: "l(90) 0:#DC8D95 1:#ffffff",
    xField: "timestamp",
    yField: "value",
    xAxis: {
      tickCount: 4,
      label: {
        formatter: (timestampSeconds: string) =>
          getRelativeTimeFromNow(
            getDateFromSeconds(Number(timestampSeconds) / 1000)
          )
      }
    },
    yAxis: {
      min: 0,
      max:
        Math.ceil(Math.max(...dataSource.map((bucket) => bucket.count)) / 4) * 4
    },
    minColumnWidth: 5,
    maxColumnWidth: 5,
    tooltip: {
      customContent: (timestamp: string, data: { value: string }[]) => {
        const { value } = data?.[0] || {}

        if (!timestamp || value === undefined) return null

        return (
          <Space split=":">
            <Text type="secondary">
              {getDateInUserLocale(
                getDateFromSeconds(Number(timestamp) / 1000),
                DEFAULT_LONG_FORMAT
              )}
            </Text>
            <Text type="danger">{value}</Text>
          </Space>
        )
      }
    }
  }).current

  return <Column {...config} />
}

export default memo(
  ColumnGraph,
  (prevProps, nextProps) => prevProps.dataSource === nextProps.dataSource
)
