import { Button, Form } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"

import { useSlugSignInRedirect } from "@/hooks/useSlugSignInRedirect"
import { SlugInputFormItem } from "@/molecules/SlugInputFormItem"
import styles from "./OrganizationSlugForm.module.scss"

export const OrganizationSlugForm = () => {
  const { t } = useTranslation()
  const navigate = useSlugSignInRedirect()

  const onFinish = (value?: { slug: string }) => {
    const { slug } = value || {}

    if (slug) {
      navigate(slug)
    }
  }

  return (
    <Form
      id="slug-form"
      name="slugForm"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      layout="vertical"
      requiredMark={false}
    >
      <SlugInputFormItem name="slug" size="large" />
      <Form.Item className={styles.buttonFormItem}>
        <Button
          block
          type="ks"
          htmlType="submit"
          size="large"
          data-testid="submit_slugForm"
        >
          {t("general.continue")}
        </Button>
      </Form.Item>
    </Form>
  )
}
