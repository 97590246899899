import { Button } from "@/atoms"
import { getRuntimeEvidenceJson } from "@/domain/runtime-evidence"
import { useLogger } from "@/hooks/useLogger"
import { useTranslation } from "@/hooks/useTranslation"
import { DownloadOutlined } from "@/icons"
import { CodeHighlight } from "@/molecules/CodeHighlight"
import { Heading } from "@/molecules/Heading"
import dayjs from "dayjs"

import type { RuntimeEvidenceTabProps } from "../../RuntimeEvidenceTab.types"

import styles from "../../RuntimeEvidenceTab.module.scss"

export const DetailedEvidenceCard = (props: RuntimeEvidenceTabProps) => {
  const { logger, EVENTS } = useLogger()
  const { t } = useTranslation()
  const { record, drawerName } = props
  const runtimeData = record?.runtimeData
  const runtimeEvidence = runtimeData?.runtimeEvidence

  if (!runtimeData || !runtimeEvidence || runtimeEvidence.length <= 0)
    return null

  const jsonString = getRuntimeEvidenceJson(runtimeEvidence)

  const downloadAsJson = () => {
    if (drawerName) {
      logger.info(EVENTS.ANALYTIC_EVENTS.EVIDENCE.DOWNLOAD, {
        name: drawerName,
        id: record?.id
      })
    }

    // Create a blob with the data and specify the content type
    const blob = new Blob([jsonString], { type: "application/json" })

    // Create a link element to download the JSON
    const link = document.createElement("a")
    link.href = URL.createObjectURL(blob)
    link.download = `kodem_evidence_${dayjs().format("YYYY-MM-DD_HHmmss")}.json`

    // Append the link and simulate a click to trigger the download
    document.body.appendChild(link)
    link.click()

    // Clean up and remove the link element
    link.parentNode?.removeChild(link)
  }

  return (
    <div className={styles.detailedEvidence}>
      <Heading level={5} title={t("runtimeEvidence.detailedEvidence")} />
      <div className={styles.downloadContainer}>
        <div className={styles.download}>
          <Button
            icon={<DownloadOutlined />}
            onClick={downloadAsJson}
            size="small"
            type="secondary"
          >
            {t("general.download")}
          </Button>
        </div>
        <CodeHighlight code={jsonString} language="unknown" />
      </div>
    </div>
  )
}
