import { ICONS_INSIGHTS } from "@/const/insights-constants"
import { InsightsCell } from "@/molecules/InsightsCell"

import type { TFunction } from "@/hooks/useTranslation"
import type { InsightsValues } from "."

export const IntroduceThroughInsightsICell = (props: {
  t: TFunction
  tag?: boolean
}) =>
  function IntroduceThroughInsightsCell({ id, ...values }: InsightsValues) {
    return (
      <InsightsCell
        isKeyTranslation
        id={id}
        items={[
          ICONS_INSIGHTS.isDirect,
          ICONS_INSIGHTS.isIndirect,
          ICONS_INSIGHTS.fromBaseImage
        ]}
        values={values}
        {...props}
      />
    )
  }
