import * as actions from "./useSelection.actions"
import { Action, KeyRow, SelectedState, State } from "./useSelection.types"

export const getInitialState = (): State => ({
  items: {},
  expandedRowKeys: [],
  selectedParentIds: [],
  selectedNestedIds: [],
  wasUpdated: false
})

export const getSelectedState = (
  nestedItems: unknown[] | undefined,
  selectedNestedIds: unknown[] | undefined
): SelectedState => {
  if (!selectedNestedIds?.length || !nestedItems?.length) return "none"

  if (selectedNestedIds.length === nestedItems?.length) return "all"

  return "indeterminate"
}

export const getSelectedParentIds = (
  parentRowId: KeyRow,
  selectedParentIds: State["selectedParentIds"],
  selectedState?: SelectedState
) => {
  const nextSelectedParentIds = selectedParentIds.filter(
    (id) => id !== parentRowId
  )

  if (selectedState === "all") {
    nextSelectedParentIds.push(parentRowId)
  }

  return nextSelectedParentIds
}

export const getNextSet = (arr: KeyRow[], id: KeyRow, addition?: boolean) =>
  addition ? arr.concat(id) : arr.filter((currentId) => currentId !== id)

export const reducer = (state: State, action: Action): State => {
  const { type } = action

  if (type === actions.SET_UPDATING_STATE_FOR_PARENT_ROWS) {
    const { parentRowIds, withErrorRowIds = [] } = action
    let wasUpdated = false

    const nextItems = parentRowIds.reduce(
      (acc, key) => {
        const parentRowId = `${key}`
        const currentProject = acc[parentRowId] || {}
        const updating = !currentProject.updating
        const selectedNestedIds = updating
          ? currentProject.selectedNestedIds
          : []
        const selectedState = updating ? currentProject.selectedState : "none"

        wasUpdated = !wasUpdated && !!currentProject.updating && !updating

        acc[parentRowId] = {
          ...currentProject,
          selectedNestedIds,
          selectedState,
          updating
        }

        return acc
      },
      { ...state.items }
    )

    withErrorRowIds.forEach((parentId) => {
      if (!nextItems[`${parentId}`]?.updating) return

      nextItems[`${parentId}`] = {
        ...nextItems[`${parentId}`],
        updating: false
      }
    })

    return {
      ...state,
      wasUpdated,
      items: nextItems,
      selectedNestedIds: !wasUpdated ? state.selectedNestedIds : [],
      selectedParentIds: state.selectedParentIds.filter(
        (id) => !parentRowIds.includes(id)
      )
    }
  }

  if (type === actions.SET_FLAGS_STATE) {
    const { payload } = action
    const { closeAllExpandedParentRows, unselect, ...rest } = payload
    const nextItemsState = unselect
      ? Object.keys(state.items || {}).reduce(
          (acc, key) => {
            const { nestedItems, metadata } = state.items[key] || {}

            acc[key] = { nestedItems, metadata }

            return acc
          },
          {} as typeof state.items
        )
      : state.items

    return {
      ...state,
      expandedRowKeys: closeAllExpandedParentRows ? [] : state.expandedRowKeys,
      selectedParentIds: unselect ? [] : state.selectedParentIds,
      selectedNestedIds: unselect ? [] : state.selectedNestedIds,
      items: nextItemsState,
      ...rest
    }
  }

  if (action.type === actions.UNSELECT_ALL) {
    return {
      ...state,
      items: Object.keys(state.items).reduce(
        (acc, key) => {
          acc[key] = {
            ...state.items[key],
            selectedState: getSelectedState([], []),
            selectedNestedIds: []
          }

          return acc
        },
        {} as typeof state.items
      ),
      selectedParentIds: [],
      selectedNestedIds: []
    }
  }

  const { parentRowId } = action
  const { selectedParentIds } = state
  const parentRowKey = `${parentRowId}`
  const currentRow = state.items[parentRowKey]

  switch (type) {
    case actions.SET_IDS_NESTED_TABLE: {
      const currentNestedItems = currentRow?.nestedItems
      const nextNestedItems = action.items
      let selectedNestedIds = currentRow?.selectedNestedIds

      if (!currentNestedItems) {
        const isRowSelected = state.selectedParentIds.includes(parentRowId)

        if (isRowSelected) {
          selectedNestedIds = nextNestedItems.map(({ id }) => id)
        }
      }

      const nextSelectedState = getSelectedState(
        nextNestedItems,
        selectedNestedIds
      )

      const nextExpandedRowKeys = !nextNestedItems.length
        ? state.expandedRowKeys.filter((key) => key !== parentRowId)
        : state.expandedRowKeys

      return {
        ...state,
        expandedRowKeys: nextExpandedRowKeys,
        selectedParentIds: getSelectedParentIds(
          parentRowKey,
          selectedParentIds,
          nextSelectedState
        ),
        items: {
          ...state.items,
          [parentRowKey]: {
            ...currentRow,
            selectedNestedIds,
            selectedState: nextSelectedState,
            nestedItems: nextNestedItems,
            metadata: action.metadata
          }
        }
      }
    }

    case actions.SELECT_ROWS_NESTED_TABLE: {
      const selectedState = getSelectedState(
        currentRow?.nestedItems,
        action.nestedRowIds
      )

      return {
        ...state,
        selectedParentIds: getSelectedParentIds(
          parentRowId,
          selectedParentIds,
          selectedState
        ),
        items: {
          ...state.items,
          [`${action.parentRowId}`]: {
            ...currentRow,
            selectedState,
            selectedNestedIds: action.nestedRowIds
          }
        }
      }
    }

    case actions.SELECT_ALL_ROWS_NESTED_TABLE: {
      const { selected } = action
      const nestedItems = currentRow?.nestedItems
      const nextSelectedNestedIds = selected
        ? nestedItems?.map(({ id }) => id)
        : []
      let selectedState = getSelectedState(nestedItems, nextSelectedNestedIds)
      const issuesWouldNotLoad = !nestedItems && selectedState === "none"
      let nextExpandedRowKeys = state.expandedRowKeys

      // if issues would not load, we should select row and extend parent row
      if (issuesWouldNotLoad) {
        selectedState = "all"
        nextExpandedRowKeys = nextExpandedRowKeys.filter(
          (key) => key !== parentRowId
        )
        nextExpandedRowKeys.push(parentRowId)
      }

      return {
        ...state,
        expandedRowKeys: nextExpandedRowKeys,
        selectedParentIds: getSelectedParentIds(
          parentRowKey,
          selectedParentIds,
          selectedState
        ),
        items: {
          ...state.items,
          [parentRowKey]: {
            ...currentRow,
            selectedState,
            selectedNestedIds: nextSelectedNestedIds
          }
        }
      }
    }

    case actions.EXPANDED_PARENT_ROW: {
      const { expanded = !state.expandedRowKeys.includes(parentRowId) } = action
      const nextExpandedRowKeys = expanded
        ? state.expandedRowKeys.concat(parentRowId)
        : state.expandedRowKeys.filter((key) => key !== parentRowId)

      return {
        ...state,
        expandedRowKeys: nextExpandedRowKeys
      }
    }

    case actions.SELECT_ROW: {
      const { nestedRowId, selected } = action
      const selectedNestedIds = currentRow?.selectedNestedIds || []
      const nextSelectedNestedIds = getNextSet(
        selectedNestedIds,
        nestedRowId,
        selected
      )
      const nextAllSelectedNestedIds = getNextSet(
        state.selectedNestedIds,
        nestedRowId,
        selected
      )

      return {
        ...state,
        selectedNestedIds: nextAllSelectedNestedIds,
        items: {
          ...state.items,
          [parentRowKey]: {
            ...currentRow,
            selectedNestedIds: nextSelectedNestedIds
          }
        }
      }
    }

    default:
      return state
  }
}
