import { Flex, Text } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { FolderOutlined } from "@/icons"

import styles from "./EmptyPath.module.scss"

export const EmptyPath = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "inventory.codeRepositoriesTab.drawer.config"
  })

  return (
    <Flex vertical gap={12} align="center">
      <Text type="secondary" className={styles.icon}>
        <FolderOutlined />
      </Text>
      <Flex vertical align="center">
        <Text type="secondary" className={styles.title}>
          {t("noSelectionTitle")}
        </Text>
        <Text type="secondary" className={styles.text}>
          {t("noSelectionContent")}
        </Text>
      </Flex>
    </Flex>
  )
}
