import cn from "classnames"
import { Fragment } from "react"

import { Space } from "@/atoms"
import {
  DEFAULT_LONG_FORMAT,
  getDateFromSeconds,
  getDateInUserLocale
} from "@/helpers/date.helpers"
import { preventEventBubbling } from "@/helpers/utils"
import { useTranslation } from "@/hooks/useTranslation"
import { RowContentDismissedTag } from "@/molecules/DismissedTag/RowContentDismissedTag"

import styles from "./DismissedTag.module.scss"

import { ContentDismissedTagComponent } from "."
import { DismissedBy } from "./DismissedBy"

export const ContentDismissedTag: ContentDismissedTagComponent = (props) => {
  const { dismissedBy, dismissMethod, reason, timestamp, placement } = props
  const timeFromNow = getDateInUserLocale(
    getDateFromSeconds(timestamp),
    DEFAULT_LONG_FORMAT
  )
  const relativeTimeExist = !!timeFromNow
  const isPopover = placement === "popover"
  const Wrap = isPopover ? Fragment : Space
  const propsWrap = isPopover ? {} : { split: "|", className: styles.space }
  const labelSuffix = isPopover ? "" : ":"
  const { t } = useTranslation("translation", { keyPrefix: "dismissedIssue" })
  return (
    <div className={styles.content} onClick={preventEventBubbling}>
      <div
        className={cn(
          styles.titleWrapperContent,
          isPopover && styles.titleWrapperContentPopover
        )}
      >
        <span>{t("title")}</span>
      </div>
      <div
        className={cn(
          styles.bodyWrapperContent,
          isPopover && styles.bodyWrapperContentPopover,
          !isPopover && styles.bodyWrapperContentPrimary
        )}
        onClick={preventEventBubbling}
      >
        <Wrap {...propsWrap}>
          <RowContentDismissedTag label={`${t("by")}${labelSuffix}`}>
            <DismissedBy
              dismissedBy={dismissedBy}
              dismissMethod={dismissMethod}
              t={t}
            />
          </RowContentDismissedTag>
          <RowContentDismissedTag label={`${t("at")}${labelSuffix}`}>
            {relativeTimeExist && <span>{timeFromNow}</span>}
          </RowContentDismissedTag>
        </Wrap>
        {reason && (
          <RowContentDismissedTag
            textArea
            label={`${t("reason")}${labelSuffix}`}
          >
            {reason}
          </RowContentDismissedTag>
        )}
      </div>
    </div>
  )
}
