import { Link } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { FolderOpenOutlined } from "@/icons"
import {
  ConfigItem,
  DescriptionLabelConfigItem
} from "@/pages/IntegrationsPage/components/molecules/ConfigItem"
import { useIntegration } from "@/pages/IntegrationsPage/hooks/useIntegration"

import type { GitHubInstallation } from "@/api/useIntegrations.types"
import type { GitHubConfigurationTabProps } from "."

export const GitHubConfigurationTab = (props: GitHubConfigurationTabProps) => {
  const keyPrefix = "integrations.configuration.GitHub."
  const { t } = useTranslation()
  const [data] = useIntegration(props)
  const installations =
    (data?.configuration?.installations as GitHubInstallation[]) || []
  const noData = !installations.length

  return (
    <>
      {noData && t(`${keyPrefix}noInstallations`)}
      {!noData &&
        installations.map(
          ({ organizationName: name, repoCount, installationId }, index) => {
            const id = `${name}${repoCount}${index}`
            const values = { id, name }

            return (
              <ConfigItem
                key={id}
                values={values}
                Aside={
                  <Link
                    target="_blank"
                    href={`https://github.com/apps/kodem-security/installations/${installationId}`}
                  >
                    {t(`${keyPrefix}configureInGithub`)}
                  </Link>
                }
                Description={
                  <DescriptionLabelConfigItem
                    Icon={FolderOpenOutlined}
                    tKey={`${keyPrefix}reposConnected`}
                    count={Number(repoCount)}
                  />
                }
              />
            )
          }
        )}
    </>
  )
}
