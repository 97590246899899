import { useFeatureFlags } from "@/hooks/useUser"
import { WIDTH_DEFAULT } from "@/organisms/Drawers/const"
import { CodeIssueTab } from "../CodeIssueTab"
import { CodeRepoConfigTab } from "../ConfigTab/ConfigTab"
import { SbomTab } from "../SbomTab"
import { TabComponentProps, TabsDrawerTemplate } from "../TabsDrawerTemplate"
import { IssuesTabCodeRepoDrawer } from "./components/IssuesTabCodeRepoDrawer"
import { OverviewTabCodeRepositoryDrawer } from "./components/OverviewTabCodeRepositoryDrawer"
import { TitleCodeRepositoryDrawer } from "./components/TitleCodeRepositoryDrawer"

import type { CodeRepositoryDrawerComponent } from "./CodeRepositoryDrawer.types"

export const CodeRepositoryDrawer: CodeRepositoryDrawerComponent = ({
  widthAdditive,
  ...props
}) => {
  const showCodeIssueTab = useFeatureFlags("sastScanFeature")

  const tabs = [
    "overview",
    "sbom",
    "scaIssues",
    showCodeIssueTab ? "sastIssues" : null,
    "config"
  ].filter(Boolean) as string[]

  const components = [
    OverviewTabCodeRepositoryDrawer,
    SbomTab,
    IssuesTabCodeRepoDrawer,
    showCodeIssueTab ? CodeIssueTab : null,
    CodeRepoConfigTab
  ].filter(Boolean) as React.FC<TabComponentProps>[]

  return (
    <TabsDrawerTemplate
      name="CodeRepositoryDrawer"
      tabs={tabs}
      components={components}
      Title={TitleCodeRepositoryDrawer}
      width={WIDTH_DEFAULT + widthAdditive}
      {...props}
    />
  )
}
