import { Flex } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { OPTIONS_DEFAULT_VALUE, RESOURCES_INITIAL_VALUE } from ".."
import { ListField } from "../../ListField"
import { ResourcesCard } from "./ResourcesCard"

import styles from "./ResourcesList.module.scss"

import type { ResourcesListProps } from ".."

export const ResourcesList = (props: ResourcesListProps) => {
  const { fieldName, form, options = OPTIONS_DEFAULT_VALUE } = props
  const { isAllResources, disabled = !!isAllResources } = props
  const { t } = useTranslation()
  const buttonProps = isAllResources
    ? undefined
    : { disabled, type: "and" as const, initialValues: RESOURCES_INITIAL_VALUE }

  return (
    <Flex vertical gap={8} className={styles.container}>
      <ListField
        name={fieldName}
        buttonProps={buttonProps}
        componentProps={{ options, t, disabled, isAllResources }}
        form={form}
        render={ResourcesCard}
      />
    </Flex>
  )
}
