import { Card, Form } from "@/atoms"
import { TitleCardField } from "."
import { ListField } from "../ListField"

import styles from "./CardField.module.scss"

import type { CardFieldComponent } from "."

export const CardField: CardFieldComponent = (props) => {
  const { form, name, path = name } = props

  Form.useWatch(path, form)

  return (
    <Card className={styles.card} title={<TitleCardField {...props} />}>
      <ListField {...props} />
    </Card>
  )
}
