import { Radio } from "@/atoms"
import { CardRadio } from "../CardRadio"

import styles from "./RadioGroup.module.scss"

import type { RadioGroupProps } from "."

export const RadioGroup = ({ children, ...props }: RadioGroupProps) => (
  <Radio.Group className={styles.container} {...props}>
    {children}
  </Radio.Group>
)

RadioGroup.Card = CardRadio
