import { Avatars } from "@/molecules/Avatars"

import type { ContributorsCellComponent } from "."

export const ContributorsCell: ContributorsCellComponent = (t) =>
  function ContributorsCellComponent(value, { contributorsCount } = {}) {
    return (
      <Avatars
        list={value}
        contributorsCount={contributorsCount}
        maxCount={contributorsCount ? 2 : 1}
        t={t}
      />
    )
  }
