import { NAMESPACE_DEFAULT_VALUE } from "@/const/default-values"
import { getIssuesCountMapOrderedSeverity } from "@/domain/vulnerability"
import {
  DEFAULT_LONG_FORMAT,
  getDateFromSeconds,
  getDateInUserLocale
} from "@/helpers/date.helpers"
import { getBooleanValuesFromInsights } from "@/helpers/insights.helper"

import type { ApplicationResponse } from "@/api/useInventory.types"
import { IMAGE_DRAWER_ID } from "@/organisms/Drawers/const"

export const getApplicationTabData = (response: ApplicationResponse) => {
  const { data = [], metadata } = response || {}

  return {
    metadata,
    data: data.map(({ data: dataRecord, id, displayName }, index) => {
      const { issuesSummary, insights } = dataRecord
      const issuesCounts = getIssuesCountMapOrderedSeverity(issuesSummary)
      const relatedImages = (dataRecord.relatedImages || []).map((image) => ({
        drawerEntityId: image.id,
        drawerId: IMAGE_DRAWER_ID,
        ...image
      }))
      const relatedImagesExist = !!dataRecord.relatedImages?.length
      const firstRelatedImageName = relatedImagesExist
        ? dataRecord.relatedImages[0].name
        : ""
      return {
        key: id,
        displayName,
        ...dataRecord,
        namespace: dataRecord.namespace || NAMESPACE_DEFAULT_VALUE,
        relatedImages: {
          id: `relatedImages-${index}`,
          count: relatedImages.length - 1,
          label: firstRelatedImageName,
          items: relatedImages,
          copyable: true,
          width: "100%"
        },
        lastSeen: getDateInUserLocale(
          getDateFromSeconds(Number(dataRecord.lastSeen)),
          DEFAULT_LONG_FORMAT
        ),
        severity: issuesCounts,
        insights: getBooleanValuesFromInsights(insights)
      }
    })
  }
}
