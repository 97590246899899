import { Flex, Text } from "@/atoms"
import { Trans } from "@/hooks/useTranslation"
import { InfoCircleOutlined } from "@/icons"
import { CopyButton } from "@/molecules/CopyButton"

import styles from "./CodeItem.module.scss"
import { CodeItemProps } from "./CodeItem.types"

export const CodeItem = ({
  titleLine1,
  titleLine2,
  infoLine,
  codeSnippet,
  withCopy
}: CodeItemProps) => (
  <div className={styles.codeItem}>
    <div className={styles.codeItemTitle}>
      <Text type="secondary">
        <Trans>{titleLine1}</Trans>
        {titleLine2 || infoLine ? <br /> : null}
        {titleLine2 && <Trans>{titleLine2}</Trans>}
        {infoLine && (
          <Flex align="center" gap={4}>
            <InfoCircleOutlined />
            <span>
              <Trans>{infoLine}</Trans>
            </span>
          </Flex>
        )}
      </Text>
      {withCopy && codeSnippet && <CopyButton text={codeSnippet} type="link" />}
    </div>
    {codeSnippet && (
      <pre className={styles.codeBlock}>
        <code className={styles.scrollable}>{codeSnippet}</code>
      </pre>
    )}
  </div>
)
