import { useCreateAuthMethods, useGetAuthMethods } from "@/api/useAuthMethods"
import { useLogger } from "@/hooks/useLogger"

import { useCreateScimAuthKey } from "@/api/useAuthKeys"
import type { UpdateAuthMethodAction, Updater } from "./useAuthMethods.types"

export const useAuthMethods = () => {
  const context = useGetAuthMethods()

  return context
}

export const useScimAuthKey = () => {
  const context = useCreateScimAuthKey()

  return context
}

export const updater: Updater = (oldData, newData) => ({
  ...oldData,
  data: newData
})

export const useUpdateAuthMethods = () => {
  const context = useCreateAuthMethods({}, updater)
  const { logger } = useLogger()

  const updateAuthMethod: UpdateAuthMethodAction = async (values) => {
    try {
      const res = await context.mutateAsync(values)

      return res
    } catch (error: any) {
      const { data, status } = error || {}
      const message = data?.detail
      const validationErrors = (status === 400 || status === 422) && !!message

      if (!validationErrors) {
        logger.error(error)
      }

      return Promise.reject({ message })
    }
  }

  return { updateAuthMethod, isLoading: context.isPending }
}
