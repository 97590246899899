import { ErrorBoundary } from "@sentry/react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"

import { ErrorResult } from "@/organisms/ErrorBoundary"
import { ExportProvider } from "@/organisms/ExportProvider"
import ServicesProvider from "@/organisms/ServicesProvider"
import ThemeProvider from "@/organisms/ThemeProvider"
import UserProvider from "@/organisms/UserProvider"
import App from "./App"

import "@/theme/index.scss"
import "tippy.js/dist/tippy.css"
import "tippy.js/themes/light.css"

const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
const fallback = () => <ErrorResult errorType="Unknown" />

root.render(
  <ErrorBoundary fallback={fallback}>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <ServicesProvider>
          <BrowserRouter>
            <UserProvider>
              <ExportProvider>
                <App />
              </ExportProvider>
            </UserProvider>
          </BrowserRouter>
        </ServicesProvider>
      </ThemeProvider>
    </QueryClientProvider>
  </ErrorBoundary>
)
