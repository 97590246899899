import cn from "classnames"

import styles from "./VerticalTabTemplate.module.scss"

import type { VerticalTabTemplateProps } from "./VerticalTabTemplate.types"

export const VerticalTabTemplate: React.FC<VerticalTabTemplateProps> = (
  props
) => {
  const { title, button, children, footer, placement } = props
  const isDrawer = placement === "drawer"
  const containerClassName = cn(
    styles.container,
    footer && styles.fullHeight,
    isDrawer && styles.inDrawer
  )

  return (
    <div className={containerClassName}>
      <div className={cn(styles.wrap, isDrawer && styles.wrapInDrawer)}>
        <div className={styles.header}>
          {title}
          {button}
        </div>
        <div className={cn(styles.content, isDrawer && styles.contentInDrawer)}>
          {children}
        </div>
      </div>
      {footer && (
        <div className={cn(styles.footer, isDrawer && styles.footerInDrawer)}>
          {footer}
        </div>
      )}
    </div>
  )
}
