import { Flex, Form, Select } from "@/atoms"
import { EXTERNAL_ROUTES } from "@/const/routes.constants"
import { ReadOutlined } from "@/icons"
import { LinkButton } from "@/molecules/LinkButton"

import type { FormInstance } from "@/atoms"
import type { QueryObserverBaseResult } from "@/hooks/useHttp.types"
import type { ActionProps } from "."
import styles from "./ActionsSectionForm.module.scss"
import { WebhookModalAutomationActionForm } from "./WebhookModalAutomationActionForm"

interface WebhookActionProps extends ActionProps {
  refetch: QueryObserverBaseResult["refetch"]
  form: FormInstance
}

export const WebhookAction: React.FC<WebhookActionProps> = (
  props: WebhookActionProps
) => {
  const { t, selectTitle, actions, path } = props
  const rules = [{ required: true, message: t("error.requiredField") }]
  const keyPrefix = "settings.automatedActions.form"
  const { refetch, form } = props

  return (
    <div className={styles.actionExtraFields}>
      <Form.Item
        required={false}
        name={[path, "webhooks"]}
        className={styles.endpointField}
        label={
          <Flex align="center" justify="space-between">
            <span>{selectTitle}</span>
            <WebhookModalAutomationActionForm
              refetch={refetch}
              form={form}
              path={path}
            />
          </Flex>
        }
        rules={rules}
      >
        <Select placeholder={selectTitle} mode="multiple" options={actions} />
      </Form.Item>
      <LinkButton
        id="anchor-scroll-actions-section"
        size="small"
        type="link"
        icon={<ReadOutlined />}
        href={`${EXTERNAL_ROUTES.API_DOCS}/governance/webhook-payload-examples`}
        target="_blank"
      >
        {t(`${keyPrefix}.fields.action.link`)}
      </LinkButton>
    </div>
  )
}
