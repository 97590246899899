import { App, ConfigProvider } from "antd"
import { useRef } from "react"

const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const theme = useRef({
    token: {
      colorPrimary: getComputedStyle(document.documentElement).getPropertyValue(
        "--primary-color"
      ),
      colorLink: getComputedStyle(document.documentElement).getPropertyValue(
        "--daybreak-blue-6-color"
      ),
      fontFamily: `Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI",
"Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
      colorSplit: getComputedStyle(document.documentElement).getPropertyValue(
        "--neutral-4-color"
      ),
      fontWeightStrong: 500
    },
    components: {
      Table: {
        cellFontSize: 13,
        rowExpandedBg: "white"
      },
      Segmented: {
        itemColor: getComputedStyle(document.documentElement).getPropertyValue(
          "--neutral-7-color"
        )
      }
    }
  }).current

  return (
    <ConfigProvider theme={theme}>
      <App>{children}</App>
    </ConfigProvider>
  )
}

export default ThemeProvider
