import { Button } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { CloseOutlined, PlusOutlined } from "@/icons"

import styles from "./ButtonListField.module.scss"

import type { ButtonListFieldComponent } from "."

export const ButtonListField: ButtonListFieldComponent = (props) => {
  const { t } = useTranslation()
  const { type, children, ...rest } = props

  if (type === "remove")
    return <Button type="text" icon={<CloseOutlined />} {...rest} />

  const { disabled, initialValues, onClick, dataTestId } = rest
  const size = type === "or" ? "small" : "middle"

  return (
    <Button
      block={size === "middle"}
      size={size}
      type="dashed"
      disabled={disabled}
      className={styles.button}
      onClick={() => onClick(initialValues)}
      icon={<PlusOutlined />}
      dataTestId={dataTestId}
    >
      {children || t(`general.${type}`)}
    </Button>
  )
}
