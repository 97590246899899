import { RESOURCES_NAMES } from "@/const/resource.constants"
import { Config, useCreate, useDelete, useHttp } from "@/hooks/useHttp"
import { AuthKeyTypeValues } from "./useAuthKeys.selector"
import { AuthKeysResponse, AuthKeyType } from "./useAuthKeys.types"

const NULL_PARAMS = undefined // used to make sure the urls share the same key for refresh

export const useGetAuthKeys = <T = AuthKeysResponse, S = T>(
  needle: AuthKeyType,
  config?: Config<T, S>
) => {
  const context = useHttp<T, S>(AuthKeyTypeValues[needle], NULL_PARAMS, config)

  return context
}

export const useCreateScimAuthKey = <T = any, S = T>() => {
  const context = useCreate<T, S>(
    RESOURCES_NAMES.AUTHENTICATION.SCIM_KEY,
    NULL_PARAMS
  )

  return context
}

export const useCreateKey = (needle: AuthKeyType) => {
  const context = useCreate<any, AuthKeysResponse>(
    AuthKeyTypeValues[needle],
    NULL_PARAMS
  )

  return context
}

export const useDeleteKey = <T = unknown>(needle: AuthKeyType) => {
  const context = useDelete<T>(AuthKeyTypeValues[needle], NULL_PARAMS)

  return context
}
