import { RESOURCES_NAMES } from "@/const/resource.constants"
import { useCreate, useHttp } from "@/hooks/useHttp"

import type { Config, Params } from "@/hooks/useHttp"
import type { AuthenticationMethodsResponse } from "./useAuthMethods.types"

export const useGetAuthMethods = <T = AuthenticationMethodsResponse, S = T>(
  params: Params<object> = {},
  config?: Config<T, S>
) => {
  const context = useHttp<T, S>(
    RESOURCES_NAMES.SETTINGS.AUTH_METHODS,
    params,
    config
  )

  return context
}

export const useCreateAuthMethods = <T = AuthenticationMethodsResponse, S = T>(
  params: Params<object> = {},
  updater?: (oldData: T, newData: S) => T
) => {
  const context = useCreate<T, S>(
    RESOURCES_NAMES.SETTINGS.AUTH_METHODS,
    params,
    updater
  )

  return context
}
