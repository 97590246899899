import * as incidentsApi from "@/api/useIncidents"
import { message } from "@/atoms"
import { RESOURCES_NAMES } from "@/const/resource.constants"
import { Params, useQueryClient } from "@/hooks/useHttp"
import { useLogger } from "@/hooks/useLogger"
import { getIncidents, getIncidentsHistory } from "./useIncidents.select"

export const useGetIncidents = (params?: Params) => {
  const context = incidentsApi.useGetIncidents(params, {
    select: getIncidents
  })

  return context
}

export const useGetIncidentsHistory = (params?: Params) => {
  const context = incidentsApi.useGetIncidentsHistory(params, {
    select: getIncidentsHistory
  })

  return context
}

export const useDismissIncident = () => {
  const remover = incidentsApi.useDismissIncident()
  const { logger } = useLogger()
  const queryClient = useQueryClient()

  const dismissIncident = async (incidentId: string) => {
    try {
      await remover.mutateAsync(incidentId)

      // Show success message
      message.success("Incident dismissed successfully")

      // Invalidate all queries related to incidents
      queryClient.invalidateQueries({
        predicate: (query) => {
          const queryKey = query.queryKey[0]
          return (
            typeof queryKey === "string" &&
            (queryKey.startsWith(RESOURCES_NAMES.INCIDENTS.LIST) ||
              queryKey.startsWith(RESOURCES_NAMES.INCIDENTS.HISTORY))
          )
        },
        refetchType: "all"
      })

      return Promise.resolve()
    } catch (error) {
      // Show error message
      message.error("Failed to dismiss incident")

      // Log error
      logger.error(error as Error, {
        tags: { custom_error: "ERROR_DISMISS_INCIDENT" }
      })

      return Promise.reject(error)
    }
  }

  return { dismissIncident, isLoading: remover.isPending }
}
