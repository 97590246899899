import { getDefaultColumnParams } from "@/helpers/table.helpers"
import { ThreatCard } from "../ThreatCard"

import type { TFunction } from "@/hooks/useTranslation"

export interface Attr {
  isDeleting: boolean
  t: TFunction
  dismissIncident: (incidentId: string) => Promise<void>
}

export const getColumnsTable = (attr: Attr) => {
  return [
    {
      render: ThreatCard(attr),
      ...getDefaultColumnParams("name")
    }
  ]
}
