import { usePreInstallECR } from "@/api/useIntegrations"
import { Form } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { DisconnectOutlined } from "@/icons"
import { TagInput } from "@/pages/GovernancePage/components/organisms/ScmForm/components/TagInput"
import { BaseReposLabel } from "@/pages/IntegrationsPage/components/molecules/BaseReposLabel"
import { FooterConfigItem } from "@/pages/IntegrationsPage/components/molecules/ConfigItem"
import { SelectRegions } from "@/pages/IntegrationsPage/components/molecules/SelectRegions"
import { useIntegration } from "@/pages/IntegrationsPage/hooks/useIntegration"

import type { FormEcrProps } from "."

export const EcrConfigurationForm = (props: FormEcrProps) => {
  const { account } = props
  const getInitialValues = () => ({
    regions: Object.keys(account.regionToRegistryUrl || {}).map((key) => key),
    ...account
  })
  const contextPreInstall = usePreInstallECR("ecr")
  const { response, isLoading } = contextPreInstall
  const options = response?.regionOptions || []

  const [_, attr] = useIntegration(props)
  const { form, onFieldsChange, isDirty, isPending, onRemove, onUpdate } = attr

  const { accountId } = account
  const formId = accountId
  const { t: tConnectEcr } = useTranslation("translation", {
    keyPrefix: `integrations.connect.ecr`
  })

  return (
    <>
      <Form
        id={formId}
        form={form}
        layout="vertical"
        initialValues={getInitialValues()}
        onFieldsChange={onFieldsChange}
        onFinish={(values) =>
          onUpdate(
            { data: { params: [{ accountId, ...values }] } },
            { name: accountId }
          )
        }
      >
        <Form.Item name="regions" label="Regions">
          <SelectRegions
            disabled={isLoading}
            loading={isLoading}
            options={options}
          />
        </Form.Item>
        <Form.Item
          name="repositories"
          label={<BaseReposLabel t={tConnectEcr} />}
        >
          <TagInput placeholder={tConnectEcr("baseReposInput.placeholder")} />
        </Form.Item>
      </Form>
      <FooterConfigItem
        formId={formId}
        isDirty={isDirty}
        isPending={isPending}
        permissions={{ action: "*", resource: "*" }}
        removeTKey="general.disconnect"
        RemoveIcon={DisconnectOutlined}
        onReset={() => form.resetFields()}
        onRemove={() => onRemove({ id: accountId, name: accountId })}
      />
    </>
  )
}
