import { Button, Card, Form, Select } from "@/atoms"
import { CloseOutlined } from "@/icons"
import { useActionsWorkflow } from "@/pages/GovernancePage/applications/workflow/useWorkflow"
import cn from "classnames"
import styles from "./ActionsSectionForm.module.scss"
import { ActionCardProps } from "./ActionsSectionForm.types"
import { DismissAction } from "./DismissAction"
import { LabelAction } from "./LabelAction"
import { WebhookAction } from "./WebhookAction"

const ACTION_COMPONENTS = {
  label: LabelAction,
  webhook: WebhookAction,
  dismiss: DismissAction
} as const

export const ActionCard: React.FC<ActionCardProps> = ({
  field,
  form,
  options,
  disabled,
  t,
  onRemove,
  keyPrefix,
  index
}) => {
  const actionType = Form.useWatch(["actions", field.name, "type"], form)
  const ActionComponent =
    ACTION_COMPONENTS[actionType as keyof typeof ACTION_COMPONENTS]
  const { response: actions = [], refetch } = useActionsWorkflow(
    { type: actionType },
    { enabled: !!actionType }
  )

  return (
    <Card
      key={field.key}
      className={cn("embedded-card-ks", styles.card)}
      title={
        <div className={styles.cardHeader}>
          <span>{`${t(`${keyPrefix}.fields.action.title`)} ${index + 1}`}</span>
          {onRemove && (
            <Button
              size="small"
              type="text"
              className={styles.removeButton}
              icon={<CloseOutlined />}
              onClick={onRemove}
            />
          )}
        </div>
      }
    >
      <Form.Item
        required={false}
        name={[field.name, "type"]}
        label={t(`${keyPrefix}.fields.action.title`)}
        rules={[{ required: true, message: t("error.requiredField") }]}
      >
        <Select
          placeholder={t(`${keyPrefix}.fields.action.placeholder`)}
          disabled={disabled}
          options={options.map(({ key, displayName, disabled }) => ({
            disabled,
            label: displayName,
            value: key
          }))}
          onChange={(value: string) => {
            form.setFieldValue(["actions", field.name], {
              type: value,
              endpoint: undefined
            })
          }}
        />
      </Form.Item>
      {ActionComponent && (
        <ActionComponent
          selectTitle={t(`${keyPrefix}.fields.selectActionTitle.${actionType}`)}
          actions={actions}
          t={t}
          form={form}
          refetch={refetch}
          path={field.name}
        />
      )}
    </Card>
  )
}
