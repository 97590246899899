import { createContext, useContext } from "react"

import type { FormInstance, MessageInstance } from "@/atoms"
import type { useCustomFunctionsForm } from "@/hooks/useCustomFunctionsForm"

export type Action = "created" | "updated"
type OnMessage = (name: string, props: { action?: Action; error?: any }) => void

interface ContextProps {
  custom: ReturnType<typeof useCustomFunctionsForm>
  isNewEntity: boolean
  formId: string
  form: FormInstance
  message: {
    instance: MessageInstance
    onSuccess: OnMessage
    onFailed: OnMessage
  }
  permissions: { write: boolean }
}

export const FormContext = createContext<ContextProps>({} as ContextProps)

export const useForm = () => {
  const context = useContext(FormContext)

  return context
}
