import { useTranslation } from "@/hooks/useTranslation"

export const AzureConfigurationTab = () => {
  const { t: tAzure } = useTranslation("translation", {
    keyPrefix: `integrations.configuration.azure`
  })
  return (
    <>
      <div>{tAzure("configurationSupport")}</div>
      <div>{tAzure("configurationDetails")}</div>
    </>
  )
}
