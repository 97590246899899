import { useActionsSummaryStatisticsWorkflow } from "@/api/useWorkflow"
import { Col, Row } from "@/atoms"
import { useNumberConvertor } from "@/hooks/useNumberConvertor"
import { useTranslation } from "@/hooks/useTranslation"
import { StatisticWidget, Widget } from "@/molecules/Widget"
import { ActionTypeIcon } from "../../molecules/ActionTypeIcon"

export const ActionsTakenWidget = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "settings.automatedActions.widgets"
  })
  const context = useActionsSummaryStatisticsWorkflow()
  const { data } = context.response || {}
  const { webhook, label, dismiss } = data || {}
  const { abbreviateNumber } = useNumberConvertor()

  return (
    <Widget
      loading={context.isLoading}
      title={t("taken.title")}
      popover={{ content: t("taken.tooltip") }}
    >
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <StatisticWidget
            title={t(`actions.${"webhook"}`)}
            value={abbreviateNumber(webhook || 0)}
            icon={<ActionTypeIcon type={"webhook"} />}
          />
        </Col>
        <Col span={12}>
          <StatisticWidget
            title={t(`actions.${"label"}`)}
            value={abbreviateNumber(label || 0)}
            icon={<ActionTypeIcon type={"label"} />}
          />
        </Col>
        <Col span={12}>
          <StatisticWidget
            title={t(`actions.${"dismiss"}`)}
            value={abbreviateNumber(dismiss || 0)}
            icon={<ActionTypeIcon type={"dismiss"} />}
          />
        </Col>
      </Row>
    </Widget>
  )
}
