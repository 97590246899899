import {
  captureConsoleIntegration,
  extraErrorDataIntegration,
  reactRouterV6BrowserTracingIntegration
} from "@sentry/react"
import { useEffect } from "react"
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from "react-router-dom"

import { transports } from "."

interface ConfigCreateTransportByEnv {
  ENV?: string
  isDev?: boolean
  MIXPANEL_TOKEN?: string
  MIXPANEL_PROXY_URL?: string
  SENTRY_DSN?: string
  RELEASE?: string
}

export const createTransportsByEnv = (config: ConfigCreateTransportByEnv) => {
  const result = []

  if (!config.isDev) {
    if (config.MIXPANEL_TOKEN && config.MIXPANEL_PROXY_URL) {
      result.push(
        new transports.Mixpanel(
          config.MIXPANEL_TOKEN,
          {
            api_host: config.MIXPANEL_PROXY_URL,
            record_sessions_percent: 0,
            record_mask_text_selector: ""
          },
          { level: "info" }
        )
      )
    }

    if (config.SENTRY_DSN) {
      result.push(
        new transports.Sentry(
          {
            dsn: config.SENTRY_DSN,
            environment: config.ENV,
            release: config.RELEASE,
            tracesSampleRate: 0.3,
            replaysSessionSampleRate: 0,
            replaysOnErrorSampleRate: 1,
            integrations: [
              captureConsoleIntegration({ levels: ["error"] }),
              extraErrorDataIntegration(),
              reactRouterV6BrowserTracingIntegration({
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
              })
            ],
            ignoreErrors: ["TypeError: Failed to fetch", "Firebase: Error"]
          },
          { level: "error" }
        )
      )
    }

    return result
  }

  result.push(new transports.Console({ level: "info" }))
  result.push(new transports.Console({ level: "error" }))
  return result
}
