import { Dropdown, Flex, Link, Space, Text } from "@/atoms"
import { preventDefault } from "@/helpers/utils"
import { useTranslation } from "@/hooks/useTranslation"
import { DownOutlined, Global } from "@/icons"
import { getLinkToRegion, getRegionCode, type RegionCode } from "./helpers"

import styles from "./RegionDropdown.module.scss"

export const RegionDropdown = () => {
  const { t } = useTranslation("translation", { keyPrefix: "auth.regions" })
  const regions = ["us", "hk"] as RegionCode[]
  const currentRegionCode = getRegionCode()
  const items = regions.map((key) => ({
    key,
    disabled: currentRegionCode === key,
    label: <Link href={getLinkToRegion(key)}>{t(key)}</Link>
  }))

  return (
    <Flex gap={4} align="center">
      <Global />
      <Text type="secondary">{`${t("region")}:`}</Text>
      <Dropdown menu={{ items }}>
        <a onClick={preventDefault}>
          <Space>
            {t(currentRegionCode)}
            <DownOutlined className={styles.icon} />
          </Space>
        </a>
      </Dropdown>
    </Flex>
  )
}
