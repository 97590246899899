import { useEffect, useState } from "react"
import { DRAWERS_PARAM_NAME } from "../const"
import { getActiveTabFieldName, getIdsDrawer } from "./useDrawer"

import type { DrawerState, Props } from "./useStateDrawer.types"

export const filterNotValidDrawers = (
  drawerId: string,
  searchParams: URLSearchParams
) => {
  const ids = getIdsDrawer(searchParams)

  const nextIds = ids.filter((id) => {
    if (id !== drawerId) return true

    searchParams.delete(id)
    searchParams.delete(getActiveTabFieldName(id))

    return false
  })

  if (!nextIds.length) searchParams.delete(DRAWERS_PARAM_NAME)
  else searchParams.set(DRAWERS_PARAM_NAME, nextIds.toString())

  return searchParams
}

export const useStateDrawer = (
  props: Props,
  { open, previousId: previousDrawerId }: { open: boolean; previousId?: string }
) => {
  const { drawer, decode } = props
  const { drawerId, searchParams, setSearchParams } = drawer
  const [drawerState, setDrawerState] = useState<DrawerState>({ open: true })
  const isValueExist = searchParams.has(drawerId)

  useEffect(() => {
    if (isValueExist) {
      const value = searchParams.get(drawerId)

      if (!value) return

      const result = decode(value)

      if (!result)
        return setSearchParams(filterNotValidDrawers(drawerId, searchParams))

      setDrawerState({ drawerId, previousDrawerId, open: true, ...result })
    }

    if (!isValueExist) {
      setDrawerState((prevState) => ({ ...prevState, open: false }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValueExist])

  useEffect(() => {
    if (drawerState.open && !open) {
      setDrawerState((prevState) => ({ ...prevState, open: false }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return drawerState
}
