import { MoreTableCell } from "@/molecules/MoreTableCell"

import type { RowClick } from "@/hooks/useLinkDrawerTooltip"
import type { MoreTableCellProps } from "@/molecules/MoreTableCell"

export const MoreTableCellDrawer = (onRowClick: RowClick) =>
  function MoreTableCellDrawerComponent(
    value: Omit<MoreTableCellProps, "onRowClick">
  ) {
    return <MoreTableCell onRowClick={onRowClick} {...value} />
  }
