import { Popover, Text } from "@/atoms"
import {
  useLinkDrawerTooltip,
  type RowClick
} from "@/hooks/useLinkDrawerTooltip"
import { IconTextCell } from "@/molecules/IconTextCell"
import { IconResource } from "@/molecules/Resources"
import { CODE_REPOSITORY_DRAWER_ID } from "@/organisms/Drawers/const"
import { Resource } from "./Resource"
import styles from "./ResourceCell.module.scss"

import { useMemo } from "react"
import type { ResourceCellProps } from "."
import { ImageIcon } from "../../../../../icons/Images"

type KeyOf<T> = keyof T

function uniqueByField<T>(array: T[], ...field: KeyOf<T>[]): T[] {
  const seen = new Map<string, boolean>()
  return array.reduce((acc, item) => {
    const compositeKey = field.map((f) => item[f]).join()
    if (!seen.has(compositeKey)) {
      seen.set(compositeKey, true)
      acc.push(item)
    }
    return acc
  }, [] as T[])
}

export const ResourceWithPopover = (
  props: ResourceCellProps & { valueCell: string; onRowClick: RowClick }
) => {
  const { valueCell, resources, sourceControl, relatedImagesCount } = props
  const isRepoResource = !!sourceControl
  const showRelatedImages = isRepoResource && !!relatedImagesCount
  const { open, openDrawer } = useLinkDrawerTooltip(props)
  const { type: typeFirstRResource, imageSource } = resources[0] || {}
  const type = isRepoResource ? "CodeRepository" : typeFirstRResource
  const uniqueResources = useMemo(
    () => uniqueByField(resources, "id", "path"),
    [resources]
  )
  return (
    <div className={styles.container}>
      <Popover
        open={open}
        className={styles.labelWrapper}
        rootClassName={styles.root}
        content={
          <div className={styles.tooltipWrapper}>
            {uniqueResources.map((resource, index) => {
              const isCodeRepo = resource.type === "CodeRepository"
              if (!isCodeRepo) {
                return (
                  <Text key={index}>
                    <Resource key={index} {...resource} />
                  </Text>
                )
              }
              return (
                <Text
                  underline
                  key={index}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    openDrawer?.(CODE_REPOSITORY_DRAWER_ID, resource.id)
                  }}
                >
                  <Resource key={index} {...resource} name={resource.name} />
                </Text>
              )
            })}
          </div>
        }
      >
        <IconTextCell
          noTooltip
          icon={
            <IconResource
              type={type}
              sourceControl={sourceControl}
              imageSource={imageSource}
            />
          }
          text={valueCell}
        />
        {showRelatedImages && resources[1].imageSource && (
          <IconTextCell
            noTooltip
            icon={<ImageIcon imageSource={resources[1].imageSource} />}
            text={`+${relatedImagesCount} related images`}
            classes={{ container: styles.relatedResourceContainer }}
          />
        )}
      </Popover>
    </div>
  )
}
