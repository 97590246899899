import {
  JiraSoftwareLogoOutlined,
  StopOutlined,
  TagOutlined,
  Webhook
} from "@/icons"
import styles from "./ActionTypeIcon.module.scss"
import { ActionTypeIconProps } from "./ActionTypeIcon.types"

export const ActionTypeIcon = ({ type }: ActionTypeIconProps) => {
  if (type === "webhook") {
    return <Webhook />
  } else if (type === "dismiss") {
    return <StopOutlined className={styles.dismiss} />
  } else if (type === "label") {
    return <TagOutlined className={styles.label} />
  } else if (type === "jira_issue") {
    return <JiraSoftwareLogoOutlined className={styles.jiraIssue} />
  }
  return <span></span>
}
