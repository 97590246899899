import { FrownOutlined, InfoCircleTwoTone } from "@/icons"

import styles from "./ErrorResult.module.scss"

import type { ErrorResultProps } from "."

export const IconErrorResult = (props: ErrorResultProps) => {
  if (props.errorType === "Forbidden")
    return <FrownOutlined className={styles.icon} />

  return <InfoCircleTwoTone className={styles.icon} />
}
