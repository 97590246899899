import { useNavigate } from "react-router-dom"

import * as IntegrationAPI from "@/api/useIntegrations"
import { App, Form } from "@/atoms"
import { useCustomFunctionsForm } from "@/hooks/useCustomFunctionsForm"
import { useTranslation } from "@/hooks/useTranslation"
import { DeleteConfirmModal, useConfirmModal } from "@/molecules/ConfirmModal"

import type { IntegrationProps } from "./useIntegration.types"

export const useIntegration = (props: IntegrationProps) => {
  const { vendor } = props
  const updater = IntegrationAPI.usePatchIntegration({ vendor })
  const remover = IntegrationAPI.useDeleteIntegration(props)
  const { confirm } = useConfirmModal()
  const { focusRef, isDirty, setIsDirty, onFieldsChange } =
    useCustomFunctionsForm()
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const { message } = App.useApp()

  const onRemove = async ({ id, name }: { id: string; name: string }) =>
    confirm((attr) =>
      DeleteConfirmModal({
        name,
        entityName: vendor,
        onOk: () => remover.mutateAsync(id),
        ...attr
      })
    )

  const onReset = () => {
    form.resetFields()
    setIsDirty(false)
  }

  const onUpdate = async (
    payload: Record<string, any>,
    attr?: { name: string; key?: string }
  ) => {
    const { key, name } = attr || {}
    const tKey = key || "integrations.configuration.update"

    try {
      await updater.mutateAsync(payload)
      setIsDirty(false)
      if (attr) message.success(t(`${tKey}.success`, { name }))
    } catch {
      if (attr) message.error(t(`${tKey}.failed`, { name }))
    }
  }

  const context = IntegrationAPI.useGetIntegrationDetails(vendor)
  const { response } = context || {}
  const { data } = response || {}
  const isPending = remover.isPending || updater.isPending

  const navigate = useNavigate()
  const getBackToIntegrationUrl = (configTab?: boolean) =>
    `/integrations/${vendor}${configTab ? "?tab=configuration" : ""}`
  const backToIntegration = (configTab?: boolean) =>
    navigate(getBackToIntegrationUrl(configTab))

  return [
    data,
    {
      isDirty,
      isPending,
      setIsDirty,
      onFieldsChange,
      backToIntegration,
      getBackToIntegrationUrl,
      form,
      message,
      focusRef,
      onRemove,
      onReset,
      onUpdate,
      t
    },
    context
  ] as const
}
