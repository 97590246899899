import { Text } from "@/atoms"
import { Counter } from "./Counter"

import styles from "./CountLabelShowMore.module.scss"

import type { CountLabelShowMoreProps } from "."

export const CountLabelShowMore = ({
  count,
  children,
  copyable,
  ...props
}: CountLabelShowMoreProps) => {
  return (
    <span className={styles.container}>
      <Text data-tooltip-show-more={copyable ? undefined : "true"} {...props}>
        <Text className={styles.text} ellipsis>
          {children}
        </Text>
        {count > 0 && <Counter count={count} />}
      </Text>
    </span>
  )
}
