import { UserEntity } from "@/domain/user"
import { ShowMore } from "@/molecules/ShowMore"
import { UserScopesCellComponent } from "."
import styles from "./UserScopesCell.module.scss"

export const UserScopesCell: UserScopesCellComponent = (
  t: (v: string) => string
) =>
  function UserScopesCellComponent(
    record: Pick<UserEntity, "id" | "permissions">
  ) {
    const { id, permissions } = record || {}
    const { specificScopes, areAllScopesPermitted } = permissions || {}
    if (areAllScopesPermitted) {
      return <div className={styles.text}>{t("settings.users.allScopes")}</div>
    }
    const valueSpecificScopes = specificScopes ?? []
    const count = valueSpecificScopes.length - 1
    const LabelComponent =
      count <= 0 ? ShowMore.Label.Text : ShowMore.Label.Count
    const label = (
      <LabelComponent count={count}>
        <div className={styles.text}>{valueSpecificScopes[0]?.name || ""}</div>
      </LabelComponent>
    )

    return (
      <div className={styles.container}>
        <ShowMore id={id} label={label}>
          <ShowMore.Tooltip width={700} items={valueSpecificScopes} />
        </ShowMore>
      </div>
    )
  }
