import { InfoCircleOutlined } from "@/icons"

import { Alert } from "@/atoms"
import cn from "classnames"
import styles from "./InformationAlert.module.scss"
import { Props } from "./InformationAlert.types"

export const InformationAlert = (props: Props) => {
  const { className, icon, ...rest } = props

  return (
    <Alert
      showIcon
      className={cn(className, styles.infoIcon)}
      type="info"
      icon={icon || <InfoCircleOutlined />}
      {...rest}
    />
  )
}
