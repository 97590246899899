import type { Fix } from "@/api/useTriage.types"
import type {
  ApplicativeImageFinding,
  BaseImageFinding,
  BaseImageFix,
  CodeRepoFinding,
  ExposedSecretFinding,
  InternetFacing,
  Issue,
  VulnerableCodeFinding
} from "@/domain/issue"

import { isCodeIssue } from "@/domain/issue"

import type { ExploitMaturity } from "@/domain/package/package.types"
import type { TFunction } from "@/hooks/useTranslation"
import { getUniqueBaseImageFixes } from "@/organisms/Drawers/components/IssuesTab/IssueExpandableCell/components/Fixes/BaseImageFixes/BaseImageFixes"
import { getUniqueImagePackageFixes } from "@/organisms/Drawers/components/IssuesTab/IssueExpandableCell/components/Fixes/PackagesUpdates/PackageUpdates"
import { SourceItem } from "@/organisms/Drawers/components/SourceList/SourceList.types"
import { findingsToSources } from "@/organisms/Drawers/components/SourceList/utils"

const escapeMarkdown = (value: string) =>
  (value || "").replace(/[-_*[\](){}`#+.!\\]/g, "\\$&")

export const getIssueHTML = (
  fixes: Fix[],
  attr: { t: TFunction; linkToDrawer?: string }
) => {
  const summary = getSummary(fixes)
  const description = fixes.map((fix) => getFixHTML(fix, attr)).join("\n")

  return { description, summary }
}

const getSummary = (fixes: Fix[]) => {
  const hasFix = fixes.length > 0
  if (!hasFix) {
    return ""
  }
  // currently we only support in one issue:
  const issue = fixes[0].issues[0]
  const issueName =
    issue.cwe.description !== "" ? issue.cwe.description : issue.riskId
  return `Fix '${issueName}' issue in project ${fixes[0]?.projectName}`
}

const internetFacingConverion: Record<InternetFacing, string> = {
  YES: "Yes",
  NO: "No",
  UNDETERMINED: "Undetermined"
}

const exploitMaturityConverion: Record<ExploitMaturity, string> = {
  POC: "Proof of concept",
  IN_THE_WILD: "In the wild",
  UNDETERMINED: "Undetermined"
}

export const getIssueInfo = (issue: Issue, linkToDrawer?: string) => {
  const titleText = getIssueTitle(issue)
  const title = linkToDrawer ? `[${titleText}](${linkToDrawer})` : titleText
  const issueImpact = getIssueImpact(issue)
  return `###${issue.severity} Severity Issue \n ${title}\n\n ${issueImpact}\n\n`
}

export const getIssueImpact = (issue: Issue) => {
  let internetFacing: string = ""
  const internetFacingValue =
    internetFacingConverion[issue.riskInsights.internetFacing]
  if (internetFacingValue !== "Undetermined") {
    internetFacing = `|| internet facing: ${internetFacingValue}`
  }
  const ingress =
    issue.riskInsights.ingress === true ? "|| ingress traffic: Yes" : ""
  const runtime =
    issue.riskInsights.runtime === true ? "|| package used in runtime: Yes" : ""
  let exploit: string = ""
  const exploitValue =
    exploitMaturityConverion[issue.riskInsights.exploitMaturity]
  if (exploitValue !== "Undetermined") {
    exploit = `|| known exploit: ${exploitValue}`
  }
  return `  * Security impact: impacted applications: ${issue.relatedApplications.length} ${internetFacing} ${ingress} ${runtime} ${exploit}`
}

export const getFixVersions = (issue: Issue) => {
  if (issue.fixVersions.length !== 0) {
    const fixVersions = `\`\`\`${issue.packageName} ${issue.fixVersions.join(
      ", "
    )}\`\`\``
    return `The issue is fixed in package version: ${fixVersions} \n\n`
  }
  if (issue.fixability !== "not_fixable") {
    return `The issue is fixed in package version: - \n\n`
  }
  return ""
}

const getSourceFixes = (source: SourceItem, t: TFunction): string => {
  const { computedFixes, fixability, computationError } = source
  const tKey = "inventory.images.drawer.issue."
  let acc = ""

  const hasCodeRepoFixes = computedFixes.length > 0

  if (hasCodeRepoFixes) {
    acc = computedFixes.reduce((result, fix) => {
      const fixText = fix.reinstall
        ? `${fix.name} to \`\`\`${fix.version}\`\`\` (${t(
            "general.reinstall"
          )})`
        : `${fix.name} to \`\`\`${fix.version}\`\`\``

      result += `    * Update ${fixText}\n`
      return result
    }, "")
  }

  if (!hasCodeRepoFixes && fixability === "partial") {
    acc += `    * _${t(`${tKey}kodemNoFix`)}_\n`
  }

  if (computationError) {
    acc += `    * _${t(`${tKey}${computationError}`)}_\n`
  }

  if (!computationError && fixability === "has_fix") {
    acc += `    * _${t(`${tKey}kodemNoFix`)}_\n`
  }

  return acc
}

const getImageFixes = (
  imageFindings: ApplicativeImageFinding[],
  t: TFunction
): string => {
  let acc = ""
  const hasImageFixes = imageFindings?.some((f) => f.computedFixes?.length > 0)
  const uniqueFixes = getUniqueImagePackageFixes(imageFindings)

  if (hasImageFixes) {
    uniqueFixes.forEach((fix, index) => {
      const { packageName, fixType } = fix
      const title = t(`general.${fixType}Update`)
      acc += `${index + 1}. ${title} (${packageName})\n`
    })
  }

  return acc
}

export const getCodeRepoPackageUpdates = (
  codeRepoPkgFindings: CodeRepoFinding[],
  t: TFunction
): string => {
  const sources: SourceItem[] = findingsToSources(codeRepoPkgFindings)
  const updates = sources
    .map((source, i) => {
      return `\n\n ${i + 1}. Source: ${source.name}\n\n${getSourceFixes(
        source,
        t
      )}`
    })
    .join("\n")
  return updates
}

export const getMaliciusePackageUpdate = (
  codeRepoPkgFindings: any,
  t: TFunction
): string => {
  //code repo finding can be only one for one issue
  const { computedFixes, computationError } = codeRepoPkgFindings[0]
  const tKey = "inventory.images.drawer.issue."
  let acc = ``

  if (computedFixes.length === 1) {
    //code repo finding can has only one fix for malicouse pkg
    const fix = computedFixes[0]
    acc += `${fix.name || fix.packageName} (${t(
      "inventory.codeRepositoriesTab.drawer.issue.nestedRow.remove"
    )})`
    if (computationError) {
      acc += `    * _${t(`${tKey}${computationError}`)}_\n`
    }
  }
  return acc
}

export const getPackageUpdates = (
  fix: Fix,
  issueType: string,
  t: TFunction
): string => {
  const { codeRepoPkgFindings, imagePkgFindings } = fix
  const codeRepoPackageUpdats = getCodeRepoPackageUpdates(
    codeRepoPkgFindings,
    t
  )

  const imagePackageUpdates = getImageFixes(fix.imagePkgFindings, t)

  if (issueType === "Malicious_Package") {
    const findings = codeRepoPkgFindings?.length
      ? codeRepoPkgFindings
      : imagePkgFindings
    return getMaliciusePackageUpdate(findings, t)
  }
  return codeRepoPackageUpdats.length > 0 || imagePackageUpdates.length > 0
    ? `\n\n**Update the following direct packages:**\n\n ${imagePackageUpdates}${codeRepoPackageUpdats}`
    : ``
}

const getBaseImageFixes = (
  baseImageFindings: BaseImageFinding[],
  fixability: string,
  t: TFunction,
  tKey: string
): string => {
  if (baseImageFindings.length === 0) {
    return ""
  }
  let acc = ""
  const allBaseImageFixes: BaseImageFix[] = baseImageFindings
    .map((f) => f.computedFixes)
    .flat()
  const uniqueFixes = getUniqueBaseImageFixes(allBaseImageFixes)
  const computedErrors = baseImageFindings.map((f) => f.computationError)
  const hasComputedError = computedErrors.some((error) => !!error)

  if (hasComputedError) {
    computedErrors.forEach((error) => {
      if (error) {
        acc += `${t(`${tKey}${error}`)}\n`
      }
    })
  } else if (fixability === "has_fix") {
    acc += `_${t(`${tKey}undeterminedBaseImageFix`)}_\n`
  }

  if (fixability === "partial" && uniqueFixes.length === 0) {
    acc += `_${t(`${tKey}undeterminedBaseImageFix`)}_\n`
  }

  uniqueFixes.forEach((fix, index) => {
    const { fixType, tags, repository } = fix
    const isRebuild = fixType === "rebuild"
    const isReplaceOrRemove = fixType === "replace" || fixType === "remove"
    const title = isRebuild
      ? t("general.patchUpdate")
      : t(`general.${fixType}Update`)
    const value = isReplaceOrRemove ? "" : `${repository}:${tags?.[0] || ""}`

    acc += `${index + 1}. ${title}${value ? `: ${value}` : ""}\n`
  })

  return `\n\n**Update the Base Image to resolve the issues:**\n\n ${acc}`
}

const getImagesList = (fix: Fix): string => {
  let acc = ""
  const allImagesFindings = [...fix.imagePkgFindings, ...fix.baseImageFindings]

  const uniqueImages = Array.from(
    new Set(
      allImagesFindings.map(
        (finding) => `${finding.resource.name}:${finding.resource.tag}`
      )
    )
  )
  if (uniqueImages.length === 0) return ""

  uniqueImages.forEach((image, index) => {
    acc += `${index + 1}. **Image**: ${image} \n\n`

    const findingsForImage = allImagesFindings.filter(
      (finding) => `${finding.resource.name}:${finding.resource.tag}` === image
    )

    findingsForImage.forEach((finding) => {
      if ("introducedThrough" in finding) {
        const introducedThrough =
          finding.introducedThrough
            .filter((item) => item.baseImageDetails?.baseImageDigest !== "")
            .pop() || null

        if (introducedThrough?.baseImageDetails?.baseImageNames?.length) {
          const baseImageNames =
            introducedThrough.baseImageDetails.baseImageNames
          const baseImageString = baseImageNames[0]
          acc += `    * From Base image: \`\`\`${baseImageString}\`\`\`\n`
        }
      }
    })

    acc += "\n"
  })

  return "\n\n###Impacted Images\n\n" + acc
}

const getFixHTML = (
  fix: Fix,
  attr: { t: TFunction; linkToDrawer?: string }
): string => {
  const { linkToDrawer, t } = attr
  //currently we only support in one issue per fix:
  const issue = fix.issues[0]

  if (isCodeIssue(issue)) {
    return getVulnerableCodeFix(fix, t, linkToDrawer)
  }

  const tKey = "inventory.images.drawer.issue."

  const issueInfo = getIssueInfo(issue, linkToDrawer)
  const packageUpdates = getPackageUpdates(fix, issue.issueType, t)
  const impactedImages = getImagesList(fix)
  if (issue.issueType === "Malicious_Package") {
    return getMaliciousPackageFix(issueInfo, packageUpdates, impactedImages)
  }

  const fixVersions = getFixVersions(issue)

  if (issue.fixability === "not_fixable") {
    return getUnfixable(issueInfo, fixVersions, impactedImages, t, tKey)
  }
  const baseImageFixes = getBaseImageFixes(
    fix.baseImageFindings,
    issue.fixability,
    t,
    tKey
  )
  return `${issueInfo}\n\n
  \n\n###What to do\n\n
  \n\n${fixVersions}\n\n
  ${packageUpdates}\n
  ${baseImageFixes}\n
  ${impactedImages}\n\n`
}

const getMaliciousPackageFix = (
  issueInfo: string,
  packageUpdates: string,
  impactedImages: string
): string => {
  return `${issueInfo}\n\n
  \n\n###What to do\n\n
  ${packageUpdates}\n
  ${impactedImages}\n\n`
}

const getVulnerableCodeFix = (
  fix: Fix,
  t: TFunction,
  linkToDrawer?: string
): string => {
  const issue = fix.issues[0]
  const issueInfo = getIssueInfo(issue, linkToDrawer)
  // vulnurable code fix can includes only one code repo finding for one issue
  const vulnFinding = fix.vulnerabilityCodeFindings[0] as
    | VulnerableCodeFinding
    | ExposedSecretFinding
  const vulnDescription = vulnFinding.description
  const fixDescription = vulnFinding?.computedFixes?.[0].fix
    ? vulnFinding?.computedFixes?.[0].fix
    : t("issueTypes.components.fixLocationCard.noFixesDescription")
  const fixLocations = vulnFinding?.introducedThrough?.map(
    ({ url, displayText }) => `* [${escapeMarkdown(displayText)}](${url})`
  )

  return `${issueInfo}\n\n
  \n\n###Description\n\n
  \n\n${vulnDescription}\n\n
  \n\n###Where To Fix\n\n
  \n\n${fixLocations.join("\n")}\n\n
  \n\n###What To Do\n\n
  \n\n${fixDescription}\n\n`
}

const getUnfixable = (
  issueInfo: string,
  fixVersions: string,
  impactedImages: string,
  t: TFunction,
  tKey: string
): string => {
  const unfixableMessage = t(`${tKey}kodemUnfixable`)

  return `${issueInfo}\n\n
  \n\n###What to do\n\n
  \n\n${fixVersions}\n\n
  ${unfixableMessage}\n
  ${impactedImages}\n\n`
}

function getIssueTitle(issue: Issue) {
  const filePath = escapeMarkdown(issue.filePath || "")
  if (isCodeIssue(issue)) return `${issue.riskId} on ${filePath}`

  const issueName = issue.cwe.description
    ? `${issue.cwe.description}: ${issue.riskId}`
    : issue.riskId
  return `${issueName} on ${issue.packageName}`
}
