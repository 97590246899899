import cn from "classnames"

import { SmileOutlined } from "@/icons"

import styles from "./EmptyText.module.scss"

export const EmptyText = () => (
  <div className={cn(styles.container, styles.gap)}>
    <SmileOutlined className={styles.icon} />
    <div className={styles.container}>
      <span>All is well here!</span>
      <span>No incidents had been detected</span>
    </div>
  </div>
)
