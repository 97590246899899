import { useDeleteLabel, useGetLabels } from "@/api/useLabels"
import { RESOURCES_NAMES } from "@/const/resource.constants"
import { useQueryClient } from "@/hooks/useHttp"
import { useQueryString } from "@/hooks/useQueryString"
import { useTranslation } from "@/hooks/useTranslation"
import {
  DeleteConfirmModal,
  InfoModal,
  PreconditionFailedContent,
  useConfirmModal
} from "@/molecules/ConfirmModal"
import { getIssueRequestFilter } from "@/organisms/LabelModal/applications/useLabelsModal"
import { getLabelsSelector } from "./useLabels.selector"

import type { Label } from "@/domain/label"
import type { Config } from "@/hooks/useHttp"

export const useLabels = (
  config?: Config<ReturnType<typeof getLabelsSelector>>
) => {
  const params = useQueryString()
  const context = useGetLabels(params, {
    select: getLabelsSelector,
    ...config
  })
  const { mutateAsync, isPending } = useDeleteLabel()
  const queryClient = useQueryClient()

  const { confirm, info } = useConfirmModal()
  const { t } = useTranslation()

  const onDelete = async (record: Label, content: React.ReactNode) => {
    const { id, name } = record

    confirm((props) =>
      DeleteConfirmModal({
        name,
        entityName: "Label",
        content,
        onOk: async () => {
          await mutateAsync(id)
          await Promise.all([
            queryClient.invalidateQueries({
              queryKey: [RESOURCES_NAMES.LABELS.LIST]
            }),
            queryClient.invalidateQueries({
              queryKey: [RESOURCES_NAMES.TRIAGE.FILTERS]
            })
          ])

          queryClient.removeQueries(getIssueRequestFilter())
        },
        preconditionFailed: () =>
          info(
            InfoModal({
              content: PreconditionFailedContent({
                t,
                name: name,
                type: "label",
                action: "delete"
              }),
              t: t
            })
          ),
        ...props
      })
    )
  }

  const { response, isLoading } = context
  const { data = [] } = response || {}

  return [
    data,
    { context, onDelete, isLoading: isLoading || isPending }
  ] as const
}
