import { CellTable } from "@/atoms"
import { CELLS, getDefaultColumnParams } from "@/helpers/table.helpers"
import { CellTableCustomTooltip } from "@/molecules/CellTableCustomTooltip"
import { HeaderCell } from "@/molecules/HeaderCell"
import { ImpactCell } from "@/molecules/ImpactCell"
import { TriageActionCell } from "@/molecules/TriageActionCell"
import { ProjectsTooltipContent } from "../../molecules/InfoHeader"
import { InsightsTriageCell } from "../../molecules/InsightsTriageCell"
import { KodemScoreHeaderCell } from "../../molecules/KodemScoreHeaderCell"
import { ResourceCell } from "../../molecules/ResourceCell"

import type { SortOrder } from "@/atoms"
import type { RowClick } from "@/hooks/useLinkDrawerTooltip"
import type { NumberConvertor } from "@/hooks/useNumberConvertor"
import type { ORDER_BY_PROPS } from "@/hooks/useOrderBy"
import type { TFunction } from "@/hooks/useTranslation"

export const getColumnsTable = (props: {
  t: TFunction
  convertors: NumberConvertor
  orderByProps: Omit<ORDER_BY_PROPS, "onSorterChange">
  onRowClick: RowClick
  internetFacingFF: boolean
}) => {
  const { t, onRowClick, orderByProps: sorter, internetFacingFF } = props

  return [
    {
      title: HeaderCell({
        title: "Projects",
        Content: ProjectsTooltipContent(t)
      }),
      width: "100%",
      render: ResourceCell(onRowClick),
      ...getDefaultColumnParams("name")
    },
    {
      title: KodemScoreHeaderCell(t),
      width: 135,
      className: "center-align-tab-template-component",
      render: CellTableCustomTooltip(
        "This is the highest issue score on this project"
      ),
      ...getDefaultColumnParams("kodemScore", sorter),
      defaultSortOrder: "descend" as SortOrder,
      sortDirections: ["descend", "ascend", "descend"] as SortOrder[]
    },
    {
      title: "Insights",
      width: 150,
      render: InsightsTriageCell(t, internetFacingFF),
      ...getDefaultColumnParams("riskInsights")
    },
    CELLS.getIssuesSummaryCell(props),
    {
      title: "Apps",
      width: 70,
      className: "center-align-tab-template-component",
      render: ImpactCell({ onRowClick }),
      ...getDefaultColumnParams("applicationCount", sorter),
      sortDirections: ["descend", "ascend", "descend"] as SortOrder[]
    },
    {
      title: "Actions",
      width: 84,
      className: "center-align-tab-template-component",
      render: TriageActionCell
    },
    {
      title: "Discovered",
      width: 125,
      render: CellTable,
      ...getDefaultColumnParams("discoveredDaysAgo", sorter, {
        customSorterName: "discoveredAt"
      })
    }
  ]
}
