import { useState } from "react"

import { useLogger } from "@/hooks/useLogger"
import useServices from "@/hooks/useServices"
import { ErrorResult } from "@/organisms/ErrorBoundary"
import { Loader } from "@/pages/SignInPage/components/molecules/Loader/Loader"
import { useEffect } from "react"
import { useParams, useSearchParams } from "react-router-dom"

export const AcsAuth = () => {
  const { slug } = useParams()
  const [searchParams] = useSearchParams()
  const [isError, setError] = useState(false)
  const { auth } = useServices()
  const { logger, EVENTS } = useLogger()

  const isModal = window.opener && window.opener !== window

  const onClose = (replaceUrl: string) => {
    try {
      if (isModal) {
        window.opener.location.replace(replaceUrl)
        window.close()
      } else window.location.replace(replaceUrl)
    } catch (error: any) {
      logger.error(error, {
        tags: {
          custom_error: EVENTS.ERROR_EVENTS.SIGNIN_POPUP,
          provider: "saml",
          action: "close"
        }
      })
    }
  }

  useEffect(() => {
    const onError = () => {
      logger.error("SAML ACS Auth Error", {
        tags: {
          custom_error: EVENTS.ERROR_EVENTS.SIGN_IN,
          provider: "saml"
        }
      })
      setError(true)
    }

    const fn = async () => {
      try {
        const token = searchParams.get("token")
        const firebaseTenantId = searchParams.get("firebaseTenantId")
        const isPopupError = !!searchParams.get("error")
        const isParamsValid = slug && token && firebaseTenantId

        if (!isParamsValid || isPopupError) return onError()

        auth.setCompany(slug)
        auth.setTenantId(firebaseTenantId)
        await auth.signInWithToken(token)

        if (isModal) onClose(window.location.href)
      } catch {
        onError()
      }
    }
    fn()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, slug])

  if (isError)
    return (
      <ErrorResult
        placement={isModal ? "SIGNIN_POPUP" : "LAYOUT"}
        errorType={isModal ? "ACL_SIGNIN" : "Unknown"}
        onClose={() => onClose("/")}
        onRefresh={() => onClose("/")}
      />
    )

  return <Loader />
}
