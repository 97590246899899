import { RESOURCES_NAMES } from "@/const/resource.constants"
import {
  Config,
  GetUseMutationOptions,
  Params,
  Updater,
  useDelete,
  useHttp
} from "@/hooks/useHttp"
import type {
  IncidentDescribeResponse,
  IncidentsHistoryResponse,
  IncidentsListResponse
} from "./useIncidents.types"

export const useGetIncidents = <T = IncidentsListResponse, S = T>(
  params: Params<object> = {},
  config?: Config<T, S>
) => {
  const context = useHttp<T, S>(RESOURCES_NAMES.INCIDENTS.LIST, params, config)

  return context
}

export const useGetIncident = <T = IncidentDescribeResponse, S = T>(
  incidentId: string,
  config?: Config<T, S>
) => {
  const context = useHttp<T, S>(
    `${RESOURCES_NAMES.INCIDENTS.DESCRIBE}/${incidentId}`,
    undefined,
    config
  )

  return context
}

export const useGetIncidentsHistory = <T = IncidentsHistoryResponse, S = T>(
  params: Params<{
    daysAgo?: number
    monthsAgo?: number
    yearsAgo?: number
    bucketSizeHours?: number
    firstBucketPercentage?: number
  }> = {},
  config?: Config<T, S>
) => {
  const context = useHttp<T, S>(
    RESOURCES_NAMES.INCIDENTS.HISTORY,
    params,
    config
  )

  return context
}

export const useDismissIncident = <T = unknown, S = T>(
  params?: Params,
  updater?: Updater<T, S>,
  options?: GetUseMutationOptions<T, S>
) => {
  const context = useDelete<T, S>(
    RESOURCES_NAMES.INCIDENTS.DISMISS,
    params,
    updater,
    options
  )

  return context
}
