import { Skeleton, Tag, Title } from "@/atoms"
import { FeatureStatusTag } from "@/molecules/FeatureStatusTag"
import { LogoIntegration } from "../../molecules/LogoIntegration"

import styles from "./HeaderItem.module.scss"

import type { HeaderItemProps } from "."

export const HeaderItem = (props: HeaderItemProps) => {
  const { displayName, tags, isLoading, isBeta, name, ...rest } = props

  return (
    <div className={styles.container}>
      {isLoading && (
        <>
          <Skeleton.Node>
            <div />
          </Skeleton.Node>
          <Skeleton active paragraph={{ rows: 1 }} />
        </>
      )}
      {!isLoading && (
        <>
          {name && (
            <LogoIntegration name={name} className={styles.logo} {...rest} />
          )}
          <div className={styles.text}>
            {displayName && (
              <Title className={styles.title} level={2}>
                {displayName}
                {isBeta && <FeatureStatusTag type="beta" />}
              </Title>
            )}
            <div>
              {(tags || []).map((tag) => (
                <Tag key={tag}>{tag}</Tag>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  )
}
