import { useNavigate } from "react-router-dom"

import { Input } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { InformationAlert } from "@/molecules/InformationAlert/InformationAlert"
import { Form, OPTIONS_DEFAULT_VALUE } from "@/organisms/Form"
import {
  useFiltersScopes,
  useScope
} from "@/pages/SettingsPage/application/scopes/useScopes"
import { getInitialValues } from "./helpers"

import styles from "./ScopeForm.module.scss"

export const ScopeForm = ({ creator, updater }: any) => {
  const { t: tForm } = useTranslation("translation", {
    keyPrefix: "settings.scopes.form"
  })

  const { response } = useFiltersScopes()
  const { data: options = OPTIONS_DEFAULT_VALUE } = response || {}
  const { data, isLoading } = useScope()
  const isAllResources = !!data?.isAllResources
  const { form, formId, isNewEntity, permissions, message } = Form.useForm()
  const navigate = useNavigate()
  const initialValues = getInitialValues(data)

  const disabled = !permissions.write || isAllResources

  return (
    <Form
      key={initialValues.id}
      id={formId}
      loading={isLoading}
      initialValues={initialValues}
      onFinish={async (values) => {
        if (isAllResources) return

        const scope = {
          name: values.name_details,
          description: values.description_details,
          conditions: values.specific_resources_resources
        }
        const action = isNewEntity ? "created" : "updated"

        try {
          if (isNewEntity) {
            const response = await creator.mutateAsync({ scope })
            const id = response.data[0].id

            navigate(`/settings/scopes/${id}`, { replace: true })
          } else {
            const payload = { needle: `/${values.id}`, data: { scope } }

            await updater.mutateAsync(payload)
          }

          message.onSuccess(scope.name, { action })
        } catch (error) {
          message.onFailed(scope.name, { action, error })
        }
      }}
    >
      {isAllResources && (
        <InformationAlert
          id={`allResourcesInfoMessage-${formId}`}
          message={tForm("allResourcesInfoMessage")}
          className={styles.informationAlert}
        />
      )}
      <Form.Item hidden name="preview" />
      <Form.Section.Details
        disabled={disabled}
        label={tForm("label.name")}
        suffix={
          !isNewEntity && (
            <Form.Item name="id" label="ID">
              <Input disabled />
            </Form.Item>
          )
        }
      />
      <Form.Section.Resources
        onlySpecificResource
        form={form}
        isAllResources={isAllResources}
        options={options}
        disabled={disabled}
      />
    </Form>
  )
}
