import { Trans, useTranslation } from "@/hooks/useTranslation"
import { ClockCircleOutlined } from "@/icons"

import styles from "./LayoutTemplate.module.scss"

export const InfoBanner = ({ days }: { days?: number }) => {
  const { t } = useTranslation("translation", { keyPrefix: "layout.banner" })
  const message = days
    ? `${t("trial_message")} ${t("days", { count: days })}`
    : t("sampleDate")

  return (
    <div className={styles.infoBanner}>
      {!!days && <ClockCircleOutlined className={styles.icon} />}
      <Trans>{message}</Trans>
    </div>
  )
}
