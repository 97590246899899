import type { Action, Workflow } from "@/api/useWorkflow.types"
import { getStepsValues } from "@/helpers/form.helper"
export const ALL_SCOPE = "all"
export const SPECIFIC_SCOPE = "specific"

export const getInitialValues = (data: Workflow | undefined) => {
  const {
    name,
    description,
    trigger,
    actions = [],
    resourceConditions = [],
    filterConditions = []
  } = data || {}
  const { category, type: triggerType } = trigger || {}
  const isResourceConditions = !!resourceConditions.length
  const transformedActions = transformActionsToInitialValues(actions)
  return {
    "details.name": name,
    "details.description": description,
    "trigger.category": category,
    "trigger.type": triggerType,
    actions: transformedActions.length == 0 ? [{}] : transformedActions,
    conditions: isResourceConditions
      ? resourceConditions
      : [{ filters: [{}], includeExclude: "Include" }],
    currentConditions: getStepsValues(filterConditions),
    currentScope: isResourceConditions ? SPECIFIC_SCOPE : ALL_SCOPE
  }
}

export const getResetFieldsValuesByTriggerKey = (
  key: string,
  state: Record<string, Record<string, unknown>>
) => {
  const defaultState = {
    currentConditions: getStepsValues([])
  }
  const prevState = state[key]
  const nextState = prevState || defaultState

  return {
    currentConditions: nextState.currentConditions
  }
}

const transformActionsToInitialValues = (actions: Action[]) => {
  return actions.map((action) => {
    switch (action.type) {
      case "webhook":
        return {
          type: action.type,
          webhooks: action.values.webhookIds
        }
      case "label":
        return {
          type: action.type,
          labels: action.values.labelIds
        }
      case "dismiss":
        return {
          type: action.type,
          reason: action.values.reason
        }
    }
  })
}

export const transformActionsToPayload = (actions: any[]) => {
  return actions.map((action) => {
    let values = {}

    switch (action.type) {
      case "webhook":
        values = { webhookIds: action.webhooks }
        break
      case "label":
        values = { labelIds: action.labels }
        break
      case "dismiss":
        values = { reason: action.reason }
        break
    }

    return {
      type: action.type,
      values
    }
  })
}
