import cn from "classnames"

import { Card } from "@/atoms"
import { Heading } from "@/molecules/Heading"
import { OverviewDescriptions } from "@/organisms/Drawers/components/OverviewDescriptions"

import styles from "./OverviewCardTemplate.module.scss"

import type { OverviewCardTemplateComponent } from "."

export const EMPTY_VALUE = "-"

export const OverviewCardTemplate: OverviewCardTemplateComponent = ({
  items,
  title,
  classes = {},
  column = 1
}) => (
  <Card
    className={cn(
      styles.card,
      classes.card,
      column === 2 && styles.rowDirection
    )}
    classNames={classes}
    title={<Heading level={5} title={title} classes={classes} />}
  >
    <OverviewDescriptions flex layout="horizontal" items={items} />
  </Card>
)
