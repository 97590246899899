import { Button, Input, Paragraph, Password } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { CopyButton } from "@/molecules/CopyButton"
import {
  useAuthMethods,
  useScimAuthKey
} from "@/pages/SettingsPage/application/auth/useAuthMethods"
import { ScimTokenContainerProps } from "."
import styles from "./ScimTokenContainer.module.scss"

export const ScimTokenContainer = ({
  form,
  formItemKey
}: ScimTokenContainerProps) => {
  const { response } = useAuthMethods()
  const { t } = useTranslation("translation", {
    keyPrefix: "settings.auth.methods.saml.form"
  })
  const { mutateAsync: createScimAuthKey, isPending: isCreatingScimAuthKey } =
    useScimAuthKey()

  const value = form.getFieldValue(formItemKey)
  const { token, id } = typeof value === "string" ? { id: value } : value || {}

  const isDisabled = !token
  const isTouched = response?.data.saml.scimTokenId !== id
  const showPassword = !isTouched && id

  return (
    <div className={styles.container}>
      <div className={styles.tokenContainer}>
        <Button
          type="primary"
          onClick={async () => {
            const { data } = await createScimAuthKey({} as any, {})

            form.setFieldValue(formItemKey, { id: data.id, token: data.plain })
            form.setFieldValue("dirty", true)
          }}
          loading={isCreatingScimAuthKey}
        >
          {t("generateToken")}
        </Button>
        {showPassword && (
          <Password
            className={styles.hiddenToken}
            readOnly
            visibilityToggle={false}
            disabled
            value={"**********"}
            addonAfter={<CopyButton text={token} disabled={true} />}
          />
        )}
        {!showPassword && (
          <Input
            readOnly
            disabled={isDisabled}
            value={token}
            addonAfter={<CopyButton text={token} disabled={isDisabled} />}
          />
        )}
      </div>
      {isTouched && (
        <Paragraph type="secondary" className={styles.tokenGenerated}>
          {t("tokenGeneratedMessage")}
        </Paragraph>
      )}
    </div>
  )
}
